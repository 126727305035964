import { AppContext } from "components/app/RemExprertProvider";
import { applyFormat } from "components/ui/ui-helpers";
import { useContext } from "react";

const DetailCotisationsCarmf = (props) => {
  const { DetailCotisations, DetailCSG, TotalChargesSociales } = props;
  const context = useContext(AppContext);
  let Periode = [];
  if (props.ClientConjoint === "Conjoint" && context.state["PeriodeActuelle" + props.ClientConjoint].length === 0) {
    Periode = context.state["PeriodeActuelle"][0];
  } else {
    Periode = context.state["PeriodeActuelle" + props.ClientConjoint][0];
  }
  if (Periode === undefined) return;
  return (
    <>
      <table className="cotisations">
        <thead>
          <tr className="dark-title">
            <th></th>
            <th>Assiette</th>
            <th>Taux</th>
            <th>Cotisation</th>
            <th>Part CPAM</th>
            <th>Montant dû</th>
          </tr>
        </thead>
        <tbody>
          <tr className="light-title">
            <td colSpan={6}>Cotisations de {props.Prenom}</td>
          </tr>
          <tr>
            <td>
              {Periode["ConventionneCarmf" + props.ClientConjoint] === "Secteur2" && Periode["OptionRsi" + props.ClientConjoint] === "oui"
                ? "Assurance maladie T1"
                : "Assurance maladie T1 (hors dépassement)"}
            </td>
            <td>{DetailCotisations?.assiette_assurance_maladie}</td>
            <td>{DetailCotisations?.taux_assurance_maladie}</td>
            <td>{DetailCotisations?.assurance_maladie}</td>
            <td>{Periode["ConventionneCarmf" + props.ClientConjoint] === "Secteur1" ? DetailCotisations?.assurance_maladie_Cpam : "-"}</td>
            <td>{DetailCotisations?.assurance_maladie_restant}</td>
          </tr>
          {DetailCotisations?.assurance_maladie2 !== "0 €" && (
            <tr>
              <td>
                {Periode["ConventionneCarmf" + props.ClientConjoint] === "Secteur2" && Periode["OptionRsi" + props.ClientConjoint] === "oui"
                  ? "Assurance maladie T2"
                  : "Assurance maladie T2 (hors dépassement)"}
              </td>
              <td>{DetailCotisations?.assiette_assurance_maladie2}</td>
              <td>{DetailCotisations?.taux_assurance_maladieT2}</td>
              <td>{DetailCotisations?.assurance_maladie2}</td>
              <td>{Periode["ConventionneCarmf" + props.ClientConjoint] === "Secteur1" ? DetailCotisations?.assurance_maladie_Cpam2 : "-"}</td>
              <td>{DetailCotisations?.assurance_maladie_restant2}</td>
            </tr>
          )}
          {DetailCotisations?.assurance_maladie_depassement_honoraire !== "0 €" && (
            <tr>
              <td>Assurance maladie (en dépassement)</td>
              <td>{DetailCotisations?.assiette_maladie_depassement_honoraire}</td>
              <td>{DetailCotisations?.taux_MaladieDh}</td>
              <td>{DetailCotisations?.assurance_maladie_depassement_honoraire}</td>
              <td>-</td>
              <td>{DetailCotisations?.assurance_maladie_depassement_honoraire}</td>
            </tr>
          )}
          {Periode["ConventionneCarmf" + props.ClientConjoint] === "Secteur1" ? (
            <>
              <tr>
                <td>Allocations familiales (hors dépassement)</td>
                <td>{DetailCotisations?.assiette_allocation_familiale}</td>
                <td>{DetailCotisations?.taux_allocation_familiale}</td>
                <td>{DetailCotisations?.allocation_familiale}</td>
                <td>{DetailCotisations?.allocation_familiale_Cpam}</td>
                <td>{DetailCotisations?.allocation_familiale_restant}</td>
              </tr>
              {DetailCotisations?.allocation_familiale_depassement_honoraire !== "0 €" && (
                <tr>
                  <td>Allocations familiales (en dépassement)</td>
                  <td>{DetailCotisations?.assiette_allocation_familiale_depassement_honoraire}</td>
                  <td>{DetailCotisations?.taux_allocation_familiale}</td>
                  <td>{DetailCotisations?.allocation_familiale_depassement_honoraire}</td>
                  <td>-</td>
                  <td>{DetailCotisations?.allocation_familiale_depassement_honoraire}</td>
                </tr>
              )}
            </>
          ) : (
            <tr>
              <td>Allocations familiales</td>
              <td>{DetailCotisations?.assiette_allocation_familiale}</td>
              <td>{DetailCotisations?.taux_allocation_familiale}</td>
              <td>{DetailCotisations?.allocation_familiale}</td>
              <td>-</td>
              <td>{DetailCotisations?.allocation_familiale}</td>
            </tr>
          )}
          <tr>
            <td>Indemnités journalières</td>
            <td>{DetailCotisations?.assiette_ij_cpam}</td>
            <td>{DetailCotisations?.taux_ij_cpam}</td>
            <td>{DetailCotisations?.ij_cpam}</td>
            <td>-</td>
            <td>{DetailCotisations?.ij_cpam}</td>
          </tr>
          <tr>
            <td>Invalidité / Décès</td>
            <td>-</td>
            <td>-</td>
            <td>{DetailCotisations?.prevoyance}</td>
            <td>-</td>
            <td>{DetailCotisations?.prevoyance}</td>
          </tr>
          <tr>
            <td>Retraite de base tranche 1</td>
            <td>{DetailCotisations?.assiette_retraite_de_base1}</td>
            <td>{DetailCotisations?.taux_retraite_de_base1}</td>
            <td>{DetailCotisations?.retraite_de_base1}</td>
            <td>{DetailCotisations?.retraite_de_base1_Cpam !== "0 €" ? DetailCotisations?.retraite_de_base1_Cpam : "-"}</td>
            <td>{DetailCotisations?.retraite_de_base1_restant}</td>
          </tr>
          <tr>
            <td>Retraite de base tranche 2</td>
            <td>{DetailCotisations?.assiette_retraite_de_base2}</td>
            <td>{DetailCotisations?.taux_retraite_de_base2}</td>
            <td>{DetailCotisations?.retraite_de_base2}</td>
            <td>{DetailCotisations?.retraite_de_base2_Cpam !== "0 €" ? DetailCotisations?.retraite_de_base2_Cpam : "-"}</td>
            <td>{DetailCotisations?.retraite_de_base2_restant}</td>
          </tr>
          <tr>
            <td>Retraite complémentaire</td>
            <td>{DetailCotisations?.assiette_retraite_complementaire}</td>
            <td>{DetailCotisations?.taux_retraite_complementaire1}</td>
            <td>{DetailCotisations?.retraite_complementaire}</td>
            <td>-</td>
            <td>{DetailCotisations?.retraite_complementaire}</td>
          </tr>
          {Periode["ConventionneCarmf" + props.ClientConjoint] !== "non" && (
            <>
              <tr>
                <td>Retraite complémentaire forfaitaire ASV</td>
                <td>-</td>
                <td>-</td>
                <td>{DetailCotisations?.asv_forfaitaire}</td>
                <td>{DetailCotisations?.asv_forfaitaire_Cpam !== "0 €" ? DetailCotisations?.asv_forfaitaire_Cpam : "-"}</td>
                <td>{DetailCotisations?.asv_forfaitaire_restant}</td>
              </tr>
              <tr>
                <td>Retraite complémentaire proportionnelle ASV</td>
                <td>{DetailCotisations?.assiette_asv_proportionnelle}</td>
                <td>{DetailCotisations?.taux_AsvPp}</td>
                <td>{DetailCotisations?.asv_proportionnelle}</td>
                <td>{DetailCotisations?.asv_proportionnelle_Cpam !== "0 €" ? DetailCotisations?.asv_proportionnelle_Cpam : "-"}</td>
                <td>{DetailCotisations?.asv_proportionnelle_restant}</td>
              </tr>
            </>
          )}
          <tr className="light-title">
            <td colSpan={5}>
              Cotisations sociales obligatoires <span style={{ fontSize: "8pt" }}> (hors formation et CSG-CRDS)</span>{" "}
            </td>
            <td>{applyFormat(DetailCSG?.ChargesSocialesObligatoire, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
          </tr>
          <tr>
            <td>Formation professionnelle</td>
            <td>{DetailCotisations?.assiette_formation_professionnelle}</td>
            <td>{DetailCotisations?.taux_formation_professionnelle}</td>
            <td>{DetailCotisations?.formation_professionnelle}</td>
            <td>-</td>
            <td>{DetailCotisations?.formation_professionnelle}</td>
          </tr>
          <tr>
            <td>Contribution URPS</td>
            <td>{DetailCotisations?.assiette_contribution_urps}</td>
            <td>{DetailCotisations?.taux_Urps}</td>
            <td>{DetailCotisations?.contribution_urps}</td>
            <td>-</td>
            <td>{DetailCotisations?.contribution_urps}</td>
          </tr>
          <tr>
            <td>CSG déductible</td>
            <td>{applyFormat(DetailCSG?.assiette_csg, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            <td>{applyFormat(DetailCSG?.TauxCSGDeductible, "ufloat", { thousands: " ", decimal: ",", precision: 2, unit: "%" })}</td>
            <td>{applyFormat(DetailCSG?.csg_deductible, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            <td>-</td>
            <td>{applyFormat(DetailCSG?.csg_deductible, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
          </tr>
          <tr>
            <td>CSG non déductible + CRDS</td>
            <td>{applyFormat(DetailCSG?.assiette_csg, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            <td>{applyFormat(DetailCSG?.TauxCSGNonDeductible, "ufloat", { thousands: " ", decimal: ",", precision: 2, unit: "%" })}</td>
            <td>{applyFormat(DetailCSG?.csg_non_deductible, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            <td>-</td>
            <td>{applyFormat(DetailCSG?.csg_non_deductible, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
          </tr>
          {Number(DetailCSG?.csg_primes) > 0 && (
            <tr>
              <td>CSG non déductible + CRDS sur épargne salariale</td>
              <td>{applyFormat(DetailCSG?.assiette_csg_primes, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
              <td>{applyFormat(DetailCSG?.TauxCSG, "ufloat", { thousands: " ", decimal: ",", precision: 2, unit: "%" })}</td>
              <td>{applyFormat(DetailCSG?.csg_primes, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
              <td>-</td>
              <td>{applyFormat(DetailCSG?.csg_primes, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            </tr>
          )}
          <tr className="light-title">
            <td colSpan={5}>Total des cotisations sociales </td>
            <td>{applyFormat(TotalChargesSociales, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default DetailCotisationsCarmf;
