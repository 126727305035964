import axios from "axios";
import { Client, Dossier, User, ContratPrev } from "./Api/ApiUser";
import _ from "lodash";
import React, { Component, useContext } from "react";
import {
  RemExpertEnvironnementJuridique,
  RemExpertPeriodeActuelle,
  RemExpertFinPeriodeActuelle,
  RemExpertEnvironnementSocial,
  RemExpertRevenusActivite,
  RemExpertForfaitSocial,
  RemExpertRevenusFoyer,
  RemExpertContratEnregistrePrevoyance,
  RemExpertPrevoyance,
  Tabij2,
  RemExpertContratEnregistreSante,
  RemExpertSante,
  RemExpertDateDepartRetraite,
  InitialListeActiviteRetraite,
  InitialListeActiviteRetraite2,
  RemExpertPeriodeRetraite,
  RemExpertTableauPoints,
  RemExpertMajorationEnfantRetraite,
  RemExpertParametreRetraite,
  RemExpertListeContratRetraite,
  RemExpertContratRetraite,
  ParamPerpContratRetraite,
  RemExpertListeOptimisation,
  RemExpressListeOptimisation,
  PrevTns,
  PrevTns2,
  RemExpress,
  CalculatriceAgircArrco,
  DeclaPER,
  CorrectionPER,
  StrategiePER,
} from "./RemExpertIntialState";
// import Retraite from "./ProtectionSociale/Retraite/Retraite";
// import {handleGestionIJ1} from "./Handlers/PrevoyanceHandler";
import { CalculArbitrageTns, CalculArbitrageSalarie, CalculResultatEntreprise, GetCotisation83 } from "./Api/Remuneration/ApiRemuneration";
import {
  CalculCumulEmploiRetraite,
  CalculPrestationContrat,
  CalculRetraite,
  CalculTrimestre,
  CalculTrimestreAnnee,
  GetFormattedDate,
  GetFormattedDateAmerican,
  GetPass,
} from "./Api/Retraite/ApiRetraite";
import { CreationTableauDetailRetraite, RevenusFutur, HandlerVerifPointRetraite, ModificationCarriere, VersementContrat } from "./Handlers/RetraiteHandler";
import { RemoveUnecessaryData, ApiUrl } from "./Api/ApiData";
import { SaveAsDossierPartnerRemExpress, SaveAsDossierPartner } from "./Api/ApiPartner";
import { RemExpertResultatTNS } from "./Api/ResultatRemExpertTNSProvider";
import { RemExpertResultatSalarie } from "./Api/ResultatRemExpertSalarieProvider";
import { Scheduler, SchedulerEntry } from "classes/scheduler";
import { majAuditComplet, majAuditPrevoyance, majAuditRemuneration, majAuditRetraite } from "classes/MajV2";
const APIURL = ApiUrl;
export const AppContext = React.createContext();
const CurrentYear = new Date().getFullYear();

function SortCarriereRetraite(a, b) {
  const sortA = a.FinSort.toUpperCase();
  const sortB = b.FinSort.toUpperCase();

  let comparison = 0;
  if (sortA > sortB) {
    comparison = 1;
  } else if (sortA < sortB) {
    comparison = -1;
  }
  return comparison;
}
class RemExpertProvider extends Component {
  //HANDLERS
  handleInputChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };
  handleValueChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };
  handleValueChangeAsync = (name, value) => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          [name]: value,
        },
        () => {
          resolve(true);
        }
      );
    });
  };
  handleValueChangeNumberForced = (name, value) => {
    this.setState({
      [name]: Number(value).toFixed(0),
    });
  };
  handleObjectChange = (Obj, Property, event) => {
    let Ob = { ...this.state[Obj] };
    Ob[Property] = event.target.value;
    this.setState({
      [Obj]: Ob,
    });
  };
  handleObjectValueChange = (Obj, Property, value) => {
    let Ob = { ...this.state[Obj] };
    Ob[Property] = value;
    this.setState({
      [Obj]: Ob,
    });
  };
  handleDialogClose = (name) => (event) => {
    this.setState({
      [name]: false,
    });
  };

  handleCheckChange = (name) => (event, checked) => {
    this.setState({ [name]: checked });
  };

  //USER

  handleSaveSettings = (Logo, LogoName, MentionLegale, Color1, Color2, Color3, Color4) => {
    this.setState(
      {
        Logo: Logo,
        LogoName: LogoName,
        MentionLegale: MentionLegale,
        Color1: Color1.replace("#", ""),
        Color2: Color2.replace("#", ""),
        Color3: Color3.replace("#", ""),
        Color4: Color4.replace("#", ""),
      },
      () => {
        User(
          {
            IdUser: this.state.IdUser,
            IdCabinet: this.state.IdCabinet,
            Logo: this.state.Logo,
            LogoName: this.state.LogoName,
            MentionLegale: this.state.MentionLegale,
            Color1: this.state.Color1,
            Color2: this.state.Color2,
            Color3: this.state.Color3,
            Color4: this.state.Color4,
          },
          "SaveSettings"
        ).then((LogoName) => {
          this.setState({ LogoName: LogoName });
        });
      }
    );
  };
  handleLogOut = () => {
    this.props.LogOut();
  };
  //CONTRATS PREVOYANCE
  handleListeUserContratPrevoyance = () => {
    ContratPrev(this.state, "ReadContratPrev").then((ListeContratPrevoyance) => {
      this.setState({
        ListeContratPrevoyance: ListeContratPrevoyance,
      });
    });
  };
  handleNewUserContratPrevoyance = () => {
    this.setState({
      IdUserContratPrevoyance: "",
      ...RemExpertPrevoyance,
    });
  };
  handleModifyUserContratPrevoyance = (UserIdContratPrevoyance) => {
    let UserContratToDisplay = this.state.ListeContratPrevoyance.filter((Contrat) => Contrat.IdContrat === UserIdContratPrevoyance)[0];
    if (Boolean(UserContratToDisplay)) {
      this.setState({
        IdUserContratPrevoyance: UserIdContratPrevoyance,
        LibelleContrat: UserContratToDisplay.LibelleContrat,
        ContratPrevoyance: UserContratToDisplay.ContratPrevoyance,
        Nature: UserContratToDisplay.Nature,
        LimiteNatureContrat: UserContratToDisplay.LimiteNatureContrat,
        LimiteNatureContratUnite: UserContratToDisplay.LimiteNatureContratUnite,
        Tarification: UserContratToDisplay.Tarification,
        MiTempsTherapeutique: UserContratToDisplay.MiTempsTherapeutique,
        MTPCarence: UserContratToDisplay.MTPCarence,
        MTPDureeIJ: UserContratToDisplay.MTPDureeIJ,
        PECDos: UserContratToDisplay.PECDos,
        PECDosCarence: UserContratToDisplay.PECDosCarence,
        PECDosDureeHospi: UserContratToDisplay.PECDosDureeHospi,
        PECDosDureeIJ: UserContratToDisplay.PECDosDureeIJ,
        PECPsy: UserContratToDisplay.PECPsy,
        PECPsyCarence: UserContratToDisplay.PECPsyCarence,
        PECPsyDureeHospi: UserContratToDisplay.PECPsyDureeHospi,
        PECPsyDureeIJ: UserContratToDisplay.PECPsyDureeIJ,
        ApplicationFranchiseHospi: UserContratToDisplay.ApplicationFranchiseHospi,
        CalculRente: UserContratToDisplay.CalculRente,
        Bareme: UserContratToDisplay.Bareme,
        TauxInvalPartielleComplementaire15: UserContratToDisplay.TauxInvalPartielleComplementaire15,
        TauxInvalPartielleComplementaire33: UserContratToDisplay.TauxInvalPartielleComplementaire33,
        TauxInvalPartielleComplementaire66: UserContratToDisplay.TauxInvalPartielleComplementaire66,
      });
    } else if (UserIdContratPrevoyance === "New") {
      this.handleResetPrevoyanceSettings();
    }
  };
  handleAddUpdateUserContratPrevoyance = () => {
    let Method = "AddContratPrevoyance";
    if (this.state.IdUserContratPrevoyance.length > 0) {
      Method = "UpdateContratPrevoyance";
    }
    ContratPrev(this.state, Method).then((ListeContratPrevoyance) => {
      if (Method === "AddContratPrevoyance") {
        this.setState({
          IdUserContratPrevoyance: ListeContratPrevoyance[0]["IdContrat"],
          ListeContratPrevoyance: ListeContratPrevoyance,
        });
      }
      if (Method === "UpdateContratPrevoyance") {
        this.setState({
          ListeContratPrevoyance: ListeContratPrevoyance,
        });
      }
    });
  };
  handlCopyUserContratPrevoyance = (IdUserContratPrevoyance) => {
    this.setState({ IdUserContratPrevoyance: IdUserContratPrevoyance }, () => {
      ContratPrev(this.state, "CopyContratPrevoyance").then((ListeContratPrevoyance) => {
        this.setState({
          ListeContratPrevoyance: ListeContratPrevoyance,
        });
      });
    });
  };
  handleDeleteUserContratPrevoyance = (IdUserContratPrevoyance) => {
    this.setState({ IdUserContratPrevoyance: IdUserContratPrevoyance }, () => {
      ContratPrev(this.state, "DeleteContratPrevoyance").then((ListeContratPrevoyance) => {
        this.setState({
          ListeContratPrevoyance: ListeContratPrevoyance,
        });
      });
    });
  };
  //CLIENT
  handleChangeNomClient = (event) => {
    this.setState({
      Nom: event.target.value,
      NomConjoint: event.target.value,
    });
  };
  handleCreationTableauEnfant = (event) => {
    let TableauEnfant = this.state.TableauEnfant;
    let Today = new Date().toISOString().split("T")[0];
    if (event.target.value < TableauEnfant.length) {
      for (var i = TableauEnfant.length - 1; i >= event.target.value; i--) {
        TableauEnfant.splice(i, 1);
      }
    } else {
      for (let i = Math.max(1, TableauEnfant.length); i <= event.target.value; i++) {
        if (i > TableauEnfant.length) {
          TableauEnfant.push({
            id: i,
            Prenom: "Enfant " + i,
            DateNaissance: Today.slice(-2) + Today.slice(5, 7) + Today.slice(0, 4),
            Charge: "exclusive",
          }); //DATE AU FORMAT JJMMAAAA
        }
      }
    }
    this.setState({
      Enfant: event.target.value,
      TableauEnfant: TableauEnfant,
    });
  };
  handleModificationEnfant = (index, name, event) => {
    let TableauEnfant = this.state.TableauEnfant;
    TableauEnfant[index][name] = event.target.value;
    if (name === "DateNaissance" && event.target.value.length === 8) {
      let AnneeNaissanceEnfant = event.target.value.slice(-4);
      let MoisDaMoisNaissanceEnfant = event.target.value.slice(2, 4);
      let JourNaissanceEnfant = event.target.value.slice(0, 2);
      let DateSaisie = new Date(AnneeNaissanceEnfant, Number(MoisDaMoisNaissanceEnfant) - 1, JourNaissanceEnfant);
      var Today = new Date();
      if (DateSaisie > Today) {
        let TodaySplit = Today.toISOString().split("T")[0];
        TableauEnfant[index][name] = TodaySplit.slice(-2) + TodaySplit.slice(5, 7) + TodaySplit.slice(0, 4);
      }
    }

    this.setState({
      TableauEnfant: TableauEnfant,
    });
  };
  handleModificationEnfantPrevTns2 = (index, name, event) => {
    let TableauEnfant = this.state.PrevTns2TableauEnfant;
    TableauEnfant[index][name] = event.target.value;
    this.setState({
      PrevTns2TableauEnfant: TableauEnfant,
    });
  };

  handleNewClient = () => {
    this.setState({
      IdClient: "",
      Civilite: "Monsieur",
      Nom: "",
      Prenom: "",
      DateNaissance: "01011970",
      CiviliteConjoint: "Madame",
      NomConjoint: "",
      PrenomConjoint: "",
      DateNaissanceConjoint: "01011970",
      StatutMarital: "celibataire",
      Enfant: "",
      Isole: false,
      TableauEnfant: [],
    });
  };
  handleCiviliteConjoint = (event) => {
    this.setState({
      Civilite: event.target.value,
      CiviliteConjoint: event.target.value === "Monsieur" ? "Madame" : "Monsieur",
    });
  };
  handleCreateUpdateClient = () => {
    let Method = "Create";
    if (this.state.IdClient !== "") {
      Method = "Update";
    }
    let EnfantACharge = this.state.TableauEnfant.filter((Tab) => Tab.Charge === "exclusive" || Tab.Charge === "partagee").length;
    let EnfantAChargeExclusive = this.state.TableauEnfant.filter((Tab) => Tab.Charge === "exclusive").length;
    let EnfantAChargePartagee = this.state.TableauEnfant.filter((Tab) => Tab.Charge === "partagee").length;
    this.setState({ EnfantACharge: EnfantACharge, EnfantAChargeExclusive: EnfantAChargeExclusive, EnfantAChargePartagee: EnfantAChargePartagee });
    Client(this.state, Method).then((ListeClient) => {
      if (Method === "Create") {
        this.setState({
          IdClient: ListeClient[0]["IdClient"],
          AccesSimulateur: true,
          SwitchCivilite: this.state.Civilite,
          SwitchCiviliteConjoint: this.state.CiviliteConjoint,
          SwitchPrenom: this.state.Prenom,
          SwitchPrenomConjoint: this.state.PrenomConjoint,
          SwitchNom: this.state.Nom,
          SwitchNomConjoint: this.state.NomConjoint,
        });
      }
      if (Method === "Update") {
        this.setState({
          AccesSimulateur: true,
          SwitchCivilite: this.state.Civilite,
          SwitchCiviliteConjoint: this.state.CiviliteConjoint,
          SwitchPrenom: this.state.Prenom,
          SwitchPrenomConjoint: this.state.PrenomConjoint,
          SwitchNom: this.state.Nom,
          SwitchNomConjoint: this.state.NomConjoint,
        });
      }
    });
  };
  HandleImportClient = (id, Token, Prospect) => {
    Client({ ...this.state, IdProspectSofraco: id, SofracoToken: Token, SofracoProspect: Prospect }, "ImportSofraco").then((ListeClient) => {
      // if (Method === "Create") {
      this.setState({
        ListeClient: ListeClient,
        // AccesSimulateur: true
      });
    });
  };

  handleDisplayClient = (IdClient) => {
    const ListeClient = this.state.ListeClient;
    let ClientToDisplay = ListeClient.filter((Client) => Client.IdClient === IdClient)[0];
    this.setState({
      IdClient: IdClient,
      Civilite: ClientToDisplay.Civilite,
      Nom: ClientToDisplay.Nom,
      Prenom: ClientToDisplay.Prenom,
      CiviliteConjoint: ClientToDisplay.CiviliteConjoint,
      NomConjoint: ClientToDisplay.NomConjoint,
      PrenomConjoint: ClientToDisplay.PrenomConjoint,
      DateNaissanceConjoint: ClientToDisplay.DateNaissanceConjoint,
      DateNaissance: ClientToDisplay.DateNaissance,
      StatutMarital: ClientToDisplay.StatutMarital,
      Isole: ClientToDisplay.Isole === "1" || ClientToDisplay.Isole === true ? true : false,
      Enfant: ClientToDisplay.Enfant,
      TableauEnfant: ClientToDisplay.TableauEnfant,
    });
  };
  handleSearchClient = (event) => {
    let ListeToDisplay = this.state.InititalListeClient;
    ListeToDisplay = ListeToDisplay.filter((Client) => Client.Nom.toUpperCase().includes(event.target.value.toUpperCase()));
    this.setState({
      ListeClient: ListeToDisplay,
    });
  };
  handleDisplaySimulation = (IdClient) => {
    const ListeClient = this.state.ListeClient;
    let ClientToDisplay = ListeClient.filter((Client) => Client.IdClient === IdClient)[0];
    let EnfantACharge = ClientToDisplay.TableauEnfant.filter((Tab) => Tab.Charge === "exclusive" || Tab.Charge === "partagee").length;
    let EnfantAChargeExclusive = ClientToDisplay.TableauEnfant.filter((Tab) => Tab.Charge === "exclusive").length;
    let EnfantAChargePartagee = ClientToDisplay.TableauEnfant.filter((Tab) => Tab.Charge === "partagee").length;
    this.setState({
      IdClient: IdClient,
      Civilite: ClientToDisplay.Civilite,
      Nom: ClientToDisplay.Nom,
      Prenom: ClientToDisplay.Prenom,
      DateNaissance: ClientToDisplay.DateNaissance,
      CiviliteConjoint: ClientToDisplay.CiviliteConjoint,
      NomConjoint: ClientToDisplay.NomConjoint,
      PrenomConjoint: ClientToDisplay.PrenomConjoint,
      SwitchCivilite: ClientToDisplay.Civilite,
      SwitchCiviliteConjoint: ClientToDisplay.CiviliteConjoint,
      SwitchPrenom: ClientToDisplay.Prenom,
      SwitchPrenomConjoint: ClientToDisplay.PrenomConjoint,
      SwitchNom: ClientToDisplay.Nom,
      SwitchNomConjoint: ClientToDisplay.NomConjoint,
      DateNaissanceConjoint: ClientToDisplay.DateNaissanceConjoint,
      StatutMarital: ClientToDisplay.StatutMarital,
      Isole: ClientToDisplay.Isole === "1" || ClientToDisplay.Isole === true,
      Enfant: ClientToDisplay.TableauEnfant.length,
      EnfantACharge: EnfantACharge,
      EnfantAChargeExclusive: EnfantAChargeExclusive,
      EnfantAChargePartagee: EnfantAChargePartagee,
      TableauEnfant: ClientToDisplay.TableauEnfant,
      AccesSimulateur: true,
    });
  };
  handleNewSimulation = (TypeSimulation) => {
    let LibelleDossier = "";
    if (TypeSimulation === "Audit complet") {
      LibelleDossier = "Etude de rémunération et de protection sociale";
    } else if (TypeSimulation === "Audit retraite") {
      LibelleDossier = "Etude de retraite";
    } else if (TypeSimulation === "Audit prévoyance") {
      LibelleDossier = "Etude de prévoyance";
    } else if (TypeSimulation === "Audit rémunération") {
      LibelleDossier = "Etude de rémunération";
    }
    this.setState(
      {
        ...RemExpertEnvironnementJuridique,
        ...RemExpertPeriodeActuelle,
        ...RemExpertEnvironnementSocial,
        ...RemExpertRevenusActivite,
        ...RemExpertForfaitSocial,
        ...RemExpertRevenusFoyer,
        ...RemExpertPeriodeActuelle,
        ...RemExpertContratEnregistrePrevoyance,
        ...RemExpertPrevoyance,
        ...RemExpertContratEnregistreSante,
        ...RemExpertSante,
        ...RemExpertDateDepartRetraite,
        ...RemExpertPeriodeRetraite,
        TableauPoints: { ...RemExpertTableauPoints },
        ...RemExpertMajorationEnfantRetraite,
        ...RemExpertParametreRetraite,
        ...RemExpertListeContratRetraite,
        ...RemExpertContratRetraite,
        ...ParamPerpContratRetraite,
        ...RemExpertListeOptimisation,
        ...RemExpress,
        ...RemExpressListeOptimisation,
        ...PrevTns,
        ...PrevTns2,
        ...CalculatriceAgircArrco,
        ...DeclaPER,
        ...CorrectionPER,
        ...StrategiePER,
        IdDossier: "",
        InclureConjoint: false,
        LibelleDossier: LibelleDossier,
        LibelleOptimisation: "",
        TypeSimulation: TypeSimulation.length === 0 ? "Audit complet" : TypeSimulation,
        OptiRemResultatRetraite: {},
        OptiRemResultatRetraiteConjoint: {},
        OptiRemResultatPrevoyance: {},
        OptiRemResultatPrevoyanceConjoint: {},
      },
      () => {
        GetPass().then((Tab) => {
          this.setState({
            TabPass: Tab,
          });
        });
        this.GetDateDepartRetraite("", this.state);
        this.CalculMajorationEnfant(this.state.Civilite, this.state.Enfant, [], this.state.TableauEnfant, "", [false, false, false, false]);
        this.GetDateDepartRetraite("Conjoint", this.state);
        this.CalculMajorationEnfant(this.state.CiviliteConjoint, this.state.Enfant, [], this.state.TableauEnfant, "Conjoint", [false, false, false, false]);
      }
    );
    // User(
    //   {
    //     IdUser: this.state.IdUser,
    //     IdCabinet: this.state.IdCabinet,
    //     TypeSimulation: TypeSimulation,
    //   },
    //   "AddConnexion"
    // );
  };
  handleSuppressionClient = (IdClient) => {
    this.setState({ IdClient: IdClient }, () => {
      Client(this.state, "Delete").then((ListeClient) => {
        this.setState({
          IdClient: "",
          ListeClient: ListeClient,
          InititalListeClient: ListeClient,
        });
      });
    });
  };
  handleListeClient = () => {
    this.setState(
      {
        IdClient: "",
        AccesSimulateur: false,
      },
      () => {
        Client(this.state, "Read").then((ListeClient) => {
          this.setState({
            ListeClient: ListeClient,
            InititalListeClient: ListeClient,
          });
        });
      }
    );
  };
  handleAccessListeClient = () => {
    this.setState({
      IdClient: "",
      AccesSimulateur: false,
    });
  };
  handleListeDossier = () => {
    this.setState({ ListeDossier: [] }, () => {
      Dossier(this.state, "GetList", "", "", "", "").then((ListeDossier) => {
        this.setState({
          ListeDossier: ListeDossier,
        });
      });
    });
  };
  handleOpenDossier = (IdDossier) => {
    const ListeDossier = this.state.ListeDossier;
    let DossierToDisplay = ListeDossier.filter((Dossier) => Dossier.IdDossier === IdDossier)[0];
    delete DossierToDisplay.InfosDossier.CodesCourtier;

    if ("TableauPoints" in DossierToDisplay.InfosDossier) {
      //FIXATION DES ANNEES AGIRC/ARRCO EN 2018
      DossierToDisplay.InfosDossier.TableauPoints.AnneeFinAgircTB = 2018;
      DossierToDisplay.InfosDossier.TableauPoints.AnneeFinAgircTC = 2018;

      //CORRECTION DE LA DATE D'ACQUISITION DES POINTS
      const dt = new Date();
      const yearMin = dt.getFullYear() - 2;

      DossierToDisplay.InfosDossier.TableauPoints.AnneeFinArrco = Math.max(yearMin, DossierToDisplay.InfosDossier.TableauPoints.AnneeFinArrco);
      DossierToDisplay.InfosDossier.TableauPoints.AnneeFinCarcdsfComp = Math.max(yearMin, DossierToDisplay.InfosDossier.TableauPoints.AnneeFinCarcdsfComp);
      DossierToDisplay.InfosDossier.TableauPoints.AnneeFinCarcdsfSupp = Math.max(yearMin, DossierToDisplay.InfosDossier.TableauPoints.AnneeFinCarcdsfSupp);
      DossierToDisplay.InfosDossier.TableauPoints.AnneeFinCarmfComp = Math.max(yearMin, DossierToDisplay.InfosDossier.TableauPoints.AnneeFinCarmfComp);
      DossierToDisplay.InfosDossier.TableauPoints.AnneeFinCarmfSupp = Math.max(yearMin, DossierToDisplay.InfosDossier.TableauPoints.AnneeFinCarmfSupp);
      DossierToDisplay.InfosDossier.TableauPoints.AnneeFinCarpimkoComp = Math.max(yearMin, DossierToDisplay.InfosDossier.TableauPoints.AnneeFinCarpimkoComp);
      DossierToDisplay.InfosDossier.TableauPoints.AnneeFinCarpimkoSupp = Math.max(yearMin, DossierToDisplay.InfosDossier.TableauPoints.AnneeFinCarpimkoSupp);
      DossierToDisplay.InfosDossier.TableauPoints.AnneeFinCarpvComp = Math.max(yearMin, DossierToDisplay.InfosDossier.TableauPoints.AnneeFinCarpvComp);
      DossierToDisplay.InfosDossier.TableauPoints.AnneeFinCavamacComp = Math.max(yearMin, DossierToDisplay.InfosDossier.TableauPoints.AnneeFinCavamacComp);
      DossierToDisplay.InfosDossier.TableauPoints.AnneeFinCavecComp = Math.max(yearMin, DossierToDisplay.InfosDossier.TableauPoints.AnneeFinCavecComp);
      DossierToDisplay.InfosDossier.TableauPoints.AnneeFinCavomComp = Math.max(yearMin, DossierToDisplay.InfosDossier.TableauPoints.AnneeFinCavomComp);
      DossierToDisplay.InfosDossier.TableauPoints.AnneeFinCavpComp = Math.max(yearMin, DossierToDisplay.InfosDossier.TableauPoints.AnneeFinCavpComp);
      DossierToDisplay.InfosDossier.TableauPoints.AnneeFinCavpSupp = Math.max(yearMin, DossierToDisplay.InfosDossier.TableauPoints.AnneeFinCavpSupp);
      DossierToDisplay.InfosDossier.TableauPoints.AnneeFinCipavComp = Math.max(yearMin, DossierToDisplay.InfosDossier.TableauPoints.AnneeFinCipavComp);
      DossierToDisplay.InfosDossier.TableauPoints.AnneeFinCnbfComp = Math.max(yearMin, DossierToDisplay.InfosDossier.TableauPoints.AnneeFinCnbfComp);
      DossierToDisplay.InfosDossier.TableauPoints.AnneeFinCrnSectionB = Math.max(yearMin, DossierToDisplay.InfosDossier.TableauPoints.AnneeFinCrnSectionB);
      DossierToDisplay.InfosDossier.TableauPoints.AnneeFinCrnSectionC = Math.max(yearMin, DossierToDisplay.InfosDossier.TableauPoints.AnneeFinCrnSectionC);
      DossierToDisplay.InfosDossier.TableauPoints.AnneeFinFonctionPubliqueComp = Math.max(yearMin, DossierToDisplay.InfosDossier.TableauPoints.AnneeFinFonctionPubliqueComp);
      DossierToDisplay.InfosDossier.TableauPoints.AnneeFinIrcantec = Math.max(yearMin, DossierToDisplay.InfosDossier.TableauPoints.AnneeFinIrcantec);
      DossierToDisplay.InfosDossier.TableauPoints.AnneeFinMsaComp = Math.max(yearMin, DossierToDisplay.InfosDossier.TableauPoints.AnneeFinMsaComp);
      DossierToDisplay.InfosDossier.TableauPoints.AnneeFinMsaProp = Math.max(yearMin, DossierToDisplay.InfosDossier.TableauPoints.AnneeFinMsaProp);
      DossierToDisplay.InfosDossier.TableauPoints.AnneeFinRsiComp = Math.max(yearMin, DossierToDisplay.InfosDossier.TableauPoints.AnneeFinRsiComp);
      DossierToDisplay.InfosDossier.TableauPoints.AnneeFinCnavpl = Math.max(yearMin, DossierToDisplay.InfosDossier.TableauPoints.AnneeFinCnavpl);
    }
    //MODIFICATIONS DES PLAFONDS PERP
    const YearAdd = Number(DossierToDisplay.DateAdd.slice(-4));
    if (CurrentYear > YearAdd) {
      // if ("IRDirigeantPlafond1" in DossierToDisplay.InfosDossier) {
      //   DossierToDisplay.InfosDossier.IRDirigeantPlafond1 = DossierToDisplay.InfosDossier.IRDirigeantPlafond2;
      //   DossierToDisplay.InfosDossier.IRDirigeantPlafond2 = DossierToDisplay.InfosDossier.IRDirigeantPlafond3;
      //   DossierToDisplay.InfosDossier.IRDirigeantPlafond3 = "";
      //   DossierToDisplay.InfosDossier.IRConjointPlafond1 = DossierToDisplay.InfosDossier.IRConjointPlafond2;
      //   DossierToDisplay.InfosDossier.IRConjointPlafond2 = DossierToDisplay.InfosDossier.IRConjointPlafond3;
      //   DossierToDisplay.InfosDossier.IRConjointPlafond3 = "";
      // }
      if (DossierToDisplay.InfosDossier.TauxIS2 === "26.5") {
        delete DossierToDisplay.InfosDossier.TauxIS2;
      }
      if (DossierToDisplay.InfosDossier.TauxIS3 === "26.5") {
        delete DossierToDisplay.InfosDossier.TauxIS3;
      }
    }
    if ("Plafond3AnneePerpContratRetraite" in DossierToDisplay.InfosDossier) {
      delete DossierToDisplay.InfosDossier.Plafond3AnneePerpContratRetraite;
      delete DossierToDisplay.InfosDossier.PlafondConjointPerpContratRetraite;
    }
    this.setState({ ...DossierToDisplay.InfosDossier }, () => {
      this.setState(
        {
          IdDossier: IdDossier,
        },
        () => {
          if (this.state.TypeSimulation === "Audit rémunération") {
            let Civilite = this.state.Civilite;
            let Prenom = this.state.Prenom;
            let Nom = this.state.SwitchNom;
            let SwitchCivilite = this.state.Civilite;
            let SwitchPrenom = this.state.Prenom;
            let SwitchNom = this.state.Nom;
            let CiviliteConjoint = this.state.SwitchCiviliteConjoint;
            let PrenomConjoint = this.state.PrenomConjoint;
            let NomConjoint = this.state.SwitchNomConjoint;

            let SwitchCiviliteConjoint = this.state.CiviliteConjoint;
            let SwitchPrenomConjoint = this.state.PrenomConjoint;
            let SwitchNomConjoint = this.state.NomConjoint;

            if (this.state.SwitchClient === "conjoint") {
              Civilite = this.state.SwitchCiviliteConjoint;
              Prenom = this.state.SwitchPrenomConjoint;
              Nom = this.state.SwitchNomConjoint;
              SwitchCivilite = this.state.SwitchCiviliteConjoint;
              SwitchPrenom = this.state.SwitchPrenomConjoint;
              SwitchNom = this.state.SwitchNomConjoint;
              CiviliteConjoint = this.state.SwitchCivilite;
              PrenomConjoint = this.state.SwitchPrenom;
              NomConjoint = this.state.SwitchNom;
              SwitchCiviliteConjoint = this.state.Civilite;
              SwitchPrenomConjoint = this.state.Prenom;
              SwitchNomConjoint = this.state.Nomf;
            }
            this.setState({
              TransfertFromV1: "",
              SwitchCivilite: SwitchCivilite,
              SwitchPrenom: SwitchPrenom,
              SwitchNom: SwitchNom,
              SwitchCiviliteConjoint: SwitchCiviliteConjoint,
              SwitchPrenomConjoint: SwitchPrenomConjoint,
              SwitchNomConjoint: SwitchNomConjoint,
              Civilite: Civilite,
              Prenom: Prenom,
              Nom: Nom,
              CiviliteConjoint: CiviliteConjoint,
              PrenomConjoint: PrenomConjoint,
              NomConjoint: NomConjoint,
            });
          }
          if (this.state.TypeSimulation === "Audit complet" || this.state.TypeSimulation === "Audit retraite") {
            const AnneeDossier = DossierToDisplay.DateAdd.split("/");
            if (Number(AnneeDossier[2]) < CurrentYear) {
              this.setState({ DisplayDateAcquisitionPoint: true });
            }
          }
          GetPass().then((Tab) => {
            this.setState({
              TabPass: Tab,
            });

            this.GetDateDepartRetraite("", this.state);
            this.CalculMajorationEnfantOpenDossier(this.state.Civilite, this.state.Enfant, DossierToDisplay.InfosDossier.Carriere, this.state.TableauEnfant, "", [
              DossierToDisplay.InfosDossier.CarriereMajorationEnfantSaisi,
              DossierToDisplay.InfosDossier.CarriereMajorationEnfantSaisiFonctionPublique,
              DossierToDisplay.InfosDossier.TrimestreBonificationEnfantSaisiFonctionPublique,
              DossierToDisplay.InfosDossier.CarriereMajorationEnfantSaisiCrpcen,
            ]);
            if (this.state.InclureConjoint) {
              this.GetDateDepartRetraite("Conjoint", this.state);
              this.CalculMajorationEnfantOpenDossier(this.state.CiviliteConjoint, this.state.Enfant, DossierToDisplay.InfosDossier.CarriereConjoint, this.state.TableauEnfant, "Conjoint", [
                DossierToDisplay.InfosDossier.CarriereMajorationEnfantSaisiConjoint,
                DossierToDisplay.InfosDossier.CarriereMajorationEnfantSaisiFonctionPubliqueConjoint,
                DossierToDisplay.InfosDossier.TrimestreBonificationEnfantSaisiFonctionPubliqueConjoint,
                DossierToDisplay.InfosDossier.CarriereMajorationEnfantSaisiCrpcenConjoint,
              ]);
            }

            let saveDossier = true;

            if (this.state.AdminAccess === true || this.state.ApiUser === true) {
              saveDossier = false;
            }
            if ("v2" in DossierToDisplay.InfosDossier) {
              if (DossierToDisplay.InfosDossier.TypeSimulation === "Audit complet") {
                majAuditComplet(this, saveDossier);
              } else if (DossierToDisplay.InfosDossier.TypeSimulation === "Audit retraite") {
                majAuditRetraite(this, saveDossier, true);
              } else if (DossierToDisplay.InfosDossier.TypeSimulation === "Audit prévoyance") {
                majAuditPrevoyance(this, saveDossier);
              } else if (DossierToDisplay.InfosDossier.TypeSimulation === "Audit rémunération") {
                majAuditRemuneration(this, saveDossier, true);
              }
            }
          });
        }
      );
    });
  };
  handleDeleteDossier = (IdDossier) => {
    Dossier(this.state, "DeleteDossier", "", IdDossier, "", "").then((ListeDossier) => {
      this.setState({
        ListeDossier: ListeDossier,
      });
    });
  };
  handleSaveDossier = () => {
    Dossier(this.state, "Save", "", "", "", "").then((res) => {});
  };
  handleSaveAsDossier = (TypeSimulation) => {
    Dossier(this.state, "SaveAs", TypeSimulation, "", "", "").then((ListeDossier) => {
      this.setState({
        IdDossier: ListeDossier[0]["IdDossier"],
      });
    });
  };
  handleSaveAsDossierPartner = () => {
    SaveAsDossierPartner(this.state).then((IdDossier) => {
      this.setState({
        IdDossier: IdDossier.data,
        IdDossierPlp: IdDossier.data,
      });
    });
  };
  handleSaveDossierRemExpress = () => {
    Dossier(this.state, "Save", "", "", this.state.RemExpressCoutEntreprise, this.state.RemExpressDividendeBrut).then((res) => {});
  };
  handleSaveAsDossierPartnerRemExpress = () => {
    SaveAsDossierPartnerRemExpress(this.state).then((IdDossier) => {
      this.setState({
        IdDossier: IdDossier.data,
        IdDossierPlp: IdDossier.data,
      });
    });
  };
  handleSaveAsDossierPartnerPrevTns = (Result) => {
    SaveAsDossierPartnerRemExpress(this.state, Result).then((IdDossier) => {
      this.setState({
        IdDossier: IdDossier.data,
      });
    });
  };
  handleSaveAsDossierRemExpress = (TypeSimulation) => {
    Dossier(this.state, "SaveAs", TypeSimulation, "", this.state.RemExpressCoutEntreprise, this.state.RemExpressDividendeBrut).then((ListeDossier) => {
      this.setState({
        IdDossier: ListeDossier[0]["IdDossier"],
      });
    });
  };
  handleInclureConjoint = (val) => {
    if (val === false) {
      let PeriodeActuelle = this.state.PeriodeActuelle;
      if (PeriodeActuelle.length > 0) {
        PeriodeActuelle[0].StatutConjoint = "Aucun";
        PeriodeActuelle[0].RemunerationConjoint = 0;
        PeriodeActuelle[0].PartSoc += PeriodeActuelle[0].PartSocConj;
        PeriodeActuelle[0].PartSocConj = 0;
      }
      this.setState({
        InclureConjoint: val,
        PeriodeActuelle: PeriodeActuelle,
        PeriodeActuelleConjoint: [],
        CarriereConjoint: [],
        ContratEnregistreConjont: [],
        ContratFraisProConjoint: [],
        ContratEnregistreSanteConjoint: [],
        ListeContratConjoint: [],
        ListeOptimisation: [],
      });
    } else {
      this.setState({ InclureConjoint: val });
    }
  };
  //SITUATION PRO
  handleFinCarriereActuelle = (ClientConjoint) => (event) => {
    let FinPeriodeCarriereActuelle = this.state.FinPeriodeCarriereActuelle;
    if (event.target.value === "DepartRetraite") {
      FinPeriodeCarriereActuelle = this.state["DateDepartRetraite" + ClientConjoint].replace(/\//g, "");
    }
    this.setState({
      FinCarriereActuelle: event.target.value,
      FinPeriodeCarriereActuelle: FinPeriodeCarriereActuelle,
    });
  };
  handleFinCarriereActuelleConjoint = (event) => {
    let FinPeriodeCarriereActuelle = this.state.FinPeriodeCarriereActuelleConjoint;
    if (event.target.value === "Client") {
      FinPeriodeCarriereActuelle = this.state.FinPeriodeCarriereActuelle.replace(/\//g, "");
    }
    if (event.target.value === "DepartRetraite") {
      FinPeriodeCarriereActuelle = this.state["DateDepartRetraiteConjoint"].replace(/\//g, "");
    }
    this.setState({
      FinCarriereActuelleConjoint: event.target.value,
      FinPeriodeCarriereActuelleConjoint: FinPeriodeCarriereActuelle,
    });
  };

  handleAddPeriodeActuelle = (id, Outil, ClientConjoint) => {
    let PeriodeActuelle = this.state[Outil + "PeriodeActuelle" + ClientConjoint];
    let IdPeriodeActuelle = id;
    if (IdPeriodeActuelle.length === 0) {
      IdPeriodeActuelle = Date.now();
    } else {
      PeriodeActuelle = PeriodeActuelle.filter((Periode) => Periode.IdPeriodeActuelle !== id);
    }
    const newPeriode = [
      {
        IdPeriodeActuelle: IdPeriodeActuelle,
        SituationPro: this.state.SituationPro,
        DebutPeriodeCarriereActuelle: this.state.DebutPeriodeCarriereActuelle,
        FinCarriereActuelle: this.state.FinCarriereActuelle,
        FinPeriodeCarriereActuelle: this.state.FinPeriodeCarriereActuelle,
        FinCarriereActuelleConjoint: this.state.FinCarriereActuelleConjoint,
        FinPeriodeCarriereActuelleConjoint: this.state.FinPeriodeCarriereActuelleConjoint,
        Statut: this.state.Statut,
        StatutConjoint: this.state.StatutConjoint,
        Caisse: this.state.Caisse,
        ConventionneCarcdsf: this.state.ConventionneCarcdsf,
        ConventionneCarmf: this.state.ConventionneCarmf,
        OptionRsi: this.state.OptionRsi,
        ConventionneCarpimko: this.state.ConventionneCarpimko,
        TauxCpam: this.state.TauxCpam,
        ClassePrevoyanceCarpv: this.state.ClassePrevoyanceCarpv,
        ClasseRetraiteCarpv: this.state.ClasseRetraiteCarpv,
        ClassePrevoyanceCavec: this.state.ClassePrevoyanceCavec,
        ClasseRetraiteCavec: this.state.ClasseRetraiteCavec,
        ConventionneCavp: this.state.ConventionneCavp,
        ClasseRetraiteCavp: this.state.ClasseRetraiteCavp,
        ClassePrevoyanceCipav: this.state.ClassePrevoyanceCipav,
        ClasseRetraiteCipav: this.state.ClasseRetraiteCipav,
        AncienneteCnbf: this.state.AncienneteCnbf,
        ClasseRetraiteCnbf: this.state.ClasseRetraiteCnbf,
        LpaMontantIncap: this.state.LpaMontantIncap,
        LpaFranchiseIncapDebut: this.state.LpaFranchiseIncapDebut,
        LpaFranchiseIncapFin: this.state.LpaFranchiseIncapFin,
        LpaInvalPartielle: this.state.LpaInvalPartielle,
        LpaInvalTotale: this.state.LpaInvalTotale,
        ClasseRetraiteCrn: this.state.ClasseRetraiteCrn,
        CrnSermentAvt2014: this.state.CrnSermentAvt2014,
        CrnColmarMetz: this.state.CrnColmarMetz,
        Atexa: this.state.Atexa,
        TauxActivite: this.state.TauxActivite,
        Effectif: this.state.Effectif,
        CavecSalarie: this.state.CnbfSalarie !== "Non" ? "Non" : this.state.CavecSalarie,
        CnbfSalarie: this.state.CnbfSalarie,
        AccidentTravail: this.state.AccidentTravail,
        AgircArrcoT1Sal: this.state.AgircArrcoT1Sal,
        AgircArrcoT1Pat: this.state.AgircArrcoT1Pat,
        AgircArrcoT2Sal: this.state.AgircArrcoT2Sal,
        AgircArrcoT2Pat: this.state.AgircArrcoT2Pat,
        Transport: this.state.Transport,
        Formation: this.state.Formation,
        Apprentissage: this.state.Apprentissage,
        Regime: this.state.Regime,
        TaxeSalaire: this.state.TaxeSalaire,
        AssuranceChomage: this.state.AssuranceChomage,
        ReductionGenerale: this.state.ReductionGenerale,
        AvantageNature: this.state.AvantageNature,
        TabCotisationsManuelle: this.state.TabCotisationsManuelle,
        ConventionneCarcdsfConjoint: this.state.ConventionneCarcdsfConjoint,
        ConventionneCarmfConjoint: this.state.ConventionneCarmfConjoint,
        OptionRsiConjoint: this.state.OptionRsiConjoint,
        ConventionneCarpimkoConjoint: this.state.ConventionneCarpimkoConjoint,
        TauxCpamConjoint: this.state.TauxCpamConjoint,
        ClassePrevoyanceCarpvConjoint: this.state.ClassePrevoyanceCarpvConjoint,
        ClasseRetraiteCarpvConjoint: this.state.ClasseRetraiteCarpvConjoint,
        ClassePrevoyanceCavecConjoint: this.state.ClassePrevoyanceCavecConjoint,
        ClasseRetraiteCavecConjoint: this.state.ClasseRetraiteCavecConjoint,
        ConventionneCavpConjoint: this.state.ConventionneCavpConjoint,
        ClasseRetraiteCavpConjoint: this.state.ClasseRetraiteCavpConjoint,
        ClassePrevoyanceCipavConjoint: this.state.ClassePrevoyanceCipavConjoint,
        ClasseRetraiteCipavConjoint: this.state.ClasseRetraiteCipavConjoint,
        AncienneteCnbfConjoint: this.state.AncienneteCnbfConjoint,
        ClasseRetraiteCnbfConjoint: this.state.ClasseRetraiteCnbfConjoint,
        LpaMontantIncapConjoint: this.state.LpaMontantIncapConjoint,
        LpaFranchiseIncapDebutConjoint: this.state.LpaFranchiseIncapDebutConjoint,
        LpaFranchiseIncapFinConjoint: this.state.LpaFranchiseIncapFinConjoint,
        LpaInvalPartielleConjoint: this.state.LpaInvalPartielleConjoint,
        LpaInvalTotaleConjoint: this.state.LpaInvalTotaleConjoint,
        ClasseRetraiteCrnConjoint: this.state.ClasseRetraiteCrnConjoint,
        CrnSermentAvt2014Conjoint: this.state.CrnSermentAvt2014Conjoint,
        CrnColmarMetzConjoint: this.state.CrnColmarMetzConjoint,
        AtexaConjoint: this.state.AtexaConjoint,
        TauxActiviteConjoint: this.state.TauxActiviteConjoint,
        EffectifConjoint: this.state.EffectifConjoint,
        CavecSalarieConjoint: this.state.CnbfSalarieConjoint !== "Non" ? "Non" : this.state.CavecSalarieConjoint,
        CnbfSalarieConjoint: this.state.CnbfSalarieConjoint,
        AccidentTravailConjoint: this.state.AccidentTravailConjoint,
        AgircArrcoT1SalConjoint: this.state.AgircArrcoT1SalConjoint,
        AgircArrcoT1PatConjoint: this.state.AgircArrcoT1PatConjoint,
        AgircArrcoT2SalConjoint: this.state.AgircArrcoT2SalConjoint,
        AgircArrcoT2PatConjoint: this.state.AgircArrcoT2PatConjoint,
        TransportConjoint: this.state.TransportConjoint,
        FormationConjoint: this.state.FormationConjoint,
        ApprentissageConjoint: this.state.ApprentissageConjoint,
        RegimeConjoint: this.state.RegimeConjoint,
        TaxeSalaireConjoint: this.state.TaxeSalaireConjoint,
        AssuranceChomageConjoint: this.state.AssuranceChomageConjoint,
        ReductionGeneraleConjoint: this.state.ReductionGeneraleConjoint,
        TabCotisationsManuelleConjoint: this.state.TabCotisationsManuelleConjoint,
        IdentiteEntreprise: this.state.IdentiteEntreprise,
        FormeJuridique: this.state.FormeJuridique,
        Fiscalite: this.state.Fiscalite,
        TauxISReduit: this.state.TauxISReduit,
        TauxISNormal: this.state.TauxISNormal,
        TauxISZero: this.state.TauxISZero,
        TypeRemuneration: this.state.TypeRemuneration,
        TypeRemunerationConjoint: this.state.TypeRemunerationConjoint,
        Remuneration: this.state.Remuneration,
        RemunerationConjoint: this.state.StatutConjoint === "Aucun" ? 0 : this.state.RemunerationConjoint,
        AvantageNatureConjoint: this.state.StatutConjoint === "Aucun" ? 0 : this.state.AvantageNatureConjoint,
        DividendeBrut: this.state.DividendeBrut,
        DividendeBrutAutres: this.state.DividendeBrutAutres,
        CapitalSocial: this.state.CapitalSocial,
        PartSoc: this.state.PartSoc,
        PartSocConj: this.state.PartSocConj,
        ResAvtIs: this.state.ResAvtIs,
        ImpotSoc: this.state.ImpotSoc,
        ResNet: this.state.ResNet,
        SeuilAssuj: this.state.SeuilAssuj,
        SeuilAssujConjoint: this.state.StatutConjoint === "Aucun" ? 0 : this.state.SeuilAssujConjoint,
        SetSeuilAssuj: this.state.SetSeuilAssuj,
        SetSeuilAssujConjoint: this.state.StatutConjoint === "Aucun" ? false : this.state.SetSeuilAssujConjoint,
        Reserve: this.state.Reserve,
      },
    ];
    PeriodeActuelle = [...PeriodeActuelle, ...newPeriode];
    this.setState(
      {
        [Outil + "PeriodeActuelle" + ClientConjoint]: PeriodeActuelle,
      },
      () => {
        this.handleCarriereActuelle(newPeriode[0], Outil, ClientConjoint).then(() => {
          if (this.state.InclureConjoint === true && ClientConjoint === "" && this.state.StatutConjoint === "Aucun" && this.state.PeriodeActuelleConjoint.length === 0) {
            // let ContratsPrevConjoint = this.state["ContratEnregistreConjoint"].filter((Contrat) => Contrat.PayeurPrimePrevoyance !== "Entreprise");
            // let ContratFraisProConjoint = [];
            // let ContratsSanteConjoint = this.state["ContratEnregistreSanteConjoint"].filter((Contrat) => Contrat.PayeurPrimeSante !== "Entreprise");
            // let ListeContratConjoint = this.state["ListeContratConjoint"].filter((Contrat) => Contrat.PayeurPrimeRetraite !== "Entreprise");
            // this.setState({
            //   ContratEnregistreConjoint: ContratsPrevConjoint,
            //   ContratFraisProConjoint: ContratFraisProConjoint,
            //   ContratEnregistreSanteConjoint: ContratsSanteConjoint,
            //   ListeContratConjoint: ListeContratConjoint,
            // });
          } else if (this.state.InclureConjoint === true && ClientConjoint === "" && this.state.StatutConjoint !== "Aucun") {
            this.handleCarriereActuelleConjointAssocie(newPeriode[0], Outil);
          }
          this.setState({
            ...RemExpertEnvironnementJuridique,
            ...RemExpertEnvironnementSocial,
            ...RemExpertRevenusActivite,
          });
        });
      }
    );
  };
  handleResetPeriodeActuelle = () => {
    this.setState({
      ...RemExpertEnvironnementJuridique,
      ...RemExpertEnvironnementSocial,
      ...RemExpertRevenusActivite,
    });
  };
  handleModifyDeletePeriodeActuelle = (id, option, Outil, ClientConjoint) => {
    let PeriodeActuelle = this.state[Outil + "PeriodeActuelle" + ClientConjoint];

    if (option === "Modifier") {
      const PeriodeToDisplay = PeriodeActuelle.filter((Periode) => Periode.IdPeriodeActuelle === id);

      PeriodeToDisplay.forEach((periode) => {
        for (var prop in periode) {
          this.setState({ [prop]: periode[prop] });
        }
      });
    } else if (option === "Supprimer") {
      if (ClientConjoint === "") {
        let ContratsPrevConjoint = this.state["ContratEnregistreConjoint"].filter((Contrat) => Contrat.PayeurPrimePrevoyance !== "Entreprise");
        let ContratFraisProConjoint = [];
        let ContratsSanteConjoint = this.state["ContratEnregistreSanteConjoint"].filter((Contrat) => Contrat.PayeurPrimeSante !== "Entreprise");
        let ListeContratConjoint = this.state["ListeContratConjoint"].filter((Contrat) => Contrat.PayeurPrimeRetraite !== "Entreprise");
        this.setState(
          {
            [Outil + "PeriodeActuelleConjoint"]: [],
            ContratEnregistreConjoint: ContratsPrevConjoint,
            ContratFraisProConjoint: ContratFraisProConjoint,
            ContratEnregistreSanteConjoint: ContratsSanteConjoint,
            ListeContratConjoint: ListeContratConjoint,
          },
          () => {}
        );
      }
      PeriodeActuelle = PeriodeActuelle.filter((Periode) => Periode.IdPeriodeActuelle !== id);
      this.setState({ [Outil + "PeriodeActuelle" + ClientConjoint]: [] }, () => {
        this.handleModifyDeletePeriodeRetraite("CarriereActuelle", option, Outil, ClientConjoint);
      });

      let ContratsPrev = this.state["ContratEnregistre" + ClientConjoint].filter((Contrat) => Contrat.PayeurPrimePrevoyance !== "Entreprise");
      let ContratFraisPro = [];
      let ContratsSante = this.state["ContratEnregistreSante" + ClientConjoint].filter((Contrat) => Contrat.PayeurPrimeSante !== "Entreprise");
      let ListeContrat = this.state["ListeContrat" + ClientConjoint].filter((Contrat) => Contrat.PayeurPrimeRetraite !== "Entreprise");
      this.setState({
        ["ContratEnregistre" + ClientConjoint]: ContratsPrev,
        ["ContratFraisPro" + ClientConjoint]: ContratFraisPro,
        ["ContratEnregistreSante" + ClientConjoint]: ContratsSante,
        ["ListeContrat" + ClientConjoint]: ListeContrat,
      });
    }
  };

  handleCarriereActuelle = (Periode, Outil, ClientConjoint) => {
    return new Promise((resolve, reject) => {
      let ParamCotisations = {
        classe_retraiteCarpv: Periode.ClasseRetraiteCarpv,
        ClasseRetr: "",
        Conventionne: "",
        BiologisteConventionne: Periode.ConventionneCavp,
        CotisationCapi: Periode.ClasseRetraiteCavp,
        ConventionneCarmf: Periode.ConventionneCarmf,
        ClasseRetraiteCnbf: Periode.ClasseRetraiteCnbf,
        TauxCpam: Periode.TauxCpam,
        // StatutIrcantec: Periode.StatutIrcantec,
        // OptionTrimSpecifique: Periode.OptionTrimSpecifique,
        CavecSalarie: Periode.CavecSalarie,
        ClasseRetraiteCrn: Periode.ClasseRetraiteCrn,
        CrnSermentAvt2014: Periode.CrnSermentAvt2014,
        CrnColmarMetz: Periode.CrnColmarMetz,
        TabMoyenneProduitEtudeCrn: {},
        AgircArrcoT1Sal: Periode.AgircArrcoT1Sal,
        AgircArrcoT1Pat: Periode.AgircArrcoT1Pat,
        AgircArrcoT2Sal: Periode.AgircArrcoT2Sal,
        AgircArrcoT2Pat: Periode.AgircArrcoT2Pat,
      };
      let RetraiteActivite = Periode.Caisse;
      if (Periode.Statut === "Assimilé salarié") {
        if (Periode.CavecSalarie !== "Non") {
          RetraiteActivite = "CavecSalarie";
        } else if (Periode.CnbfSalarie !== "Non") {
          RetraiteActivite = "CnbfSalarie";
        } else {
          RetraiteActivite = "SalarieCadre";
        }
      } else if (Periode.Statut === "Salarié cadre") {
        if (Periode.CavecSalarie !== "Non") {
          RetraiteActivite = "CavecSalarie";
        } else if (Periode.CnbfSalarie !== "Non") {
          RetraiteActivite = "CnbfSalarie";
        } else {
          RetraiteActivite = "SalarieCadre";
        }
      } else if (Periode.Statut === "Salarié non cadre") {
        if (Periode.CavecSalarie !== "Non") {
          RetraiteActivite = "CavecSalarie";
        } else if (Periode.CnbfSalarie !== "Non") {
          RetraiteActivite = "CnbfSalarie";
        } else {
          RetraiteActivite = "SalarieNonCadre";
        }
      } else {
        if (Periode.Caisse === "Carpv") {
          ParamCotisations.ClasseRetr = Periode.ClasseRetraiteCarpv;
        } else if (Periode.Caisse === "Cavec") {
          ParamCotisations.ClasseRetr = Periode.ClasseRetraiteCavec;
        } else if (Periode.Caisse === "Cipav") {
          ParamCotisations.ClasseRetr = Periode.ClasseRetraiteCipav;
        }
        if (Periode.Caisse === "Carcdsf" || Periode.Caisse === "Carcdsf2") {
          ParamCotisations.Conventionne = Periode.ConventionneCarcdsf;
        } else if (Periode.Caisse === "Carpimko") {
          ParamCotisations.Conventionne = this.state.ConventionneCarpimko;
        } else if (Periode.Caisse === "Crn") {
          const AnneeFinPeriode = Periode.FinPeriodeCarriereActuelle.replace(/\//g, "").slice(4);
          for (var Annee = CurrentYear; Annee <= Number(AnneeFinPeriode); Annee++) {
            ParamCotisations.TabMoyenneProduitEtudeCrn[Annee] = this.state.MoyenneProduitCrn;
          }
        }
      }
      CreationTableauDetailRetraite("0101" + CurrentYear, Periode.FinPeriodeCarriereActuelle.replace(/\//g, ""), Periode.Caisse, {}, {}).then((CreationDetailRetraiteFutur) => {
        const DetailRetraiteFutur = RevenusFutur(CurrentYear, CreationDetailRetraiteFutur.DetailRetraiteFutur, Periode.Remuneration, 0);
        this.setState(
          {
            RetraiteDebutActivite: "0101" + CurrentYear,
            RetraiteFinActivite: Periode.FinPeriodeCarriereActuelle,
            RetraiteActivite: RetraiteActivite,
            CheckboxDepartRetraite: Periode.FinCarriereActuelle === "DepartRetraite" ? true : false,
            IdPeriodeRetraite: "CarriereActuelle",
            DisplayDeplafondPass: false,
            DisplayTrimestre: true,
            AllCheckboxTrimestre: false,
            TypeEvolution: "EvolutionPlat",
            MontantEvolution: 0,
            TauxActivite: Periode.TauxActivite,
            DetailRetraitePasse: {},
            DetailRetraiteFutur: DetailRetraiteFutur,

            ParamCotisations: ParamCotisations,
          },
          () => {
            this.handleAddPeriodeRetraite("CarriereActuelle", Outil, ClientConjoint);
            resolve(true);
          }
        );
      });
    });
  };

  handleCarriereActuelleConjointAssocie = (Periode, Outil) => {
    return new Promise((resolve, reject) => {
      let ParamCotisations = {
        classe_retraiteCarpv: Periode.ClasseRetraiteCarpvConjoint,
        ClasseRetr: "",
        Conventionne: "",
        BiologisteConventionne: Periode.ConventionneCavpConjoint,
        CotisationCapi: Periode.ClasseRetraiteCavpConjoint,
        ConventionneCarmf: Periode.ConventionneCarmfConjoint,
        ClasseRetraiteCnbf: Periode.ClasseRetraiteCnbfConjoint,
        TauxCpam: Periode.TauxCpamConjoint,
        // StatutIrcantec: Periode.StatutIrcantec,
        // OptionTrimSpecifique: Periode.OptionTrimSpecifique,
        CavecSalarie: Periode.CavecSalarieConjoint,
        ClasseRetraiteCrn: Periode.ClasseRetraiteCrnConjoint,
        CrnSermentAvt2014: Periode.CrnSermentAvt2014Conjoint,
        CrnColmarMetz: Periode.CrnColmarMetzConjoint,
        TabMoyenneProduitEtudeCrn: {},
        AgircArrcoT1Sal: Periode.AgircArrcoT1SalConjoint,
        AgircArrcoT1Pat: Periode.AgircArrcoT1PatConjoint,
        AgircArrcoT2Sal: Periode.AgircArrcoT2SalConjoint,
        AgircArrcoT2Pat: Periode.AgircArrcoT2PatConjoint,
      };
      let RetraiteActivite = Periode.Caisse;
      if (Periode.StatutConjoint === "Assimilé salarié") {
        if (Periode.CavecSalarieConjoint !== "Non") {
          RetraiteActivite = "CavecSalarie";
        } else if (Periode.CnbfSalarieConjoint !== "Non") {
          RetraiteActivite = "CnbfSalarie";
        } else {
          RetraiteActivite = "SalarieCadre";
        }
      } else if (Periode.StatutConjoint === "Salarié cadre") {
        if (Periode.CavecSalarieConjoint !== "Non") {
          RetraiteActivite = "CavecSalarie";
        } else if (Periode.CnbfSalarieConjoint !== "Non") {
          RetraiteActivite = "CnbfSalarie";
        } else {
          RetraiteActivite = "SalarieCadre";
        }
      } else if (Periode.StatutConjoint === "Salarié non cadre") {
        if (Periode.CavecSalarieConjoint !== "Non") {
          RetraiteActivite = "CavecSalarie";
        } else if (Periode.CnbfSalarieConjoint !== "Non") {
          RetraiteActivite = "CnbfSalarie";
        } else {
          RetraiteActivite = "SalarieNonCadre";
        }
      } else {
        if (Periode.Caisse === "Carpv") {
          ParamCotisations.ClasseRetr = Periode.ClasseRetraiteCarpvConjoint;
        } else if (Periode.Caisse === "Cavec") {
          ParamCotisations.ClasseRetr = Periode.ClasseRetraiteCavecConjoint;
        } else if (Periode.Caisse === "Cipav") {
          ParamCotisations.ClasseRetr = Periode.ClasseRetraiteCipavConjoint;
        }
        if (Periode.Caisse === "Carcdsf" || Periode.Caisse === "Carcdsf2") {
          ParamCotisations.Conventionne = Periode.ConventionneCarcdsfConjoint;
        } else if (Periode.Caisse === "Carpimko") {
          ParamCotisations.Conventionne = this.state.ConventionneCarpimkoConjoint;
        } else if (Periode.Caisse === "Crn") {
          const AnneeFinPeriode = Periode.FinPeriodeCarriereActuelleConjoint.replace(/\//g, "").slice(4);
          for (var Annee = CurrentYear; Annee <= Number(AnneeFinPeriode); Annee++) {
            ParamCotisations.TabMoyenneProduitEtudeCrn[Annee] = this.state.MoyenneProduitCrnConjoint;
          }
        }
      }

      CreationTableauDetailRetraite("0101" + CurrentYear, Periode.FinPeriodeCarriereActuelleConjoint.replace(/\//g, ""), Periode.Caisse, {}, {}).then((CreationDetailRetraiteFutur) => {
        const DetailRetraiteFutur = RevenusFutur(CurrentYear, CreationDetailRetraiteFutur.DetailRetraiteFutur, Periode.RemunerationConjoint, 0);
        this.setState(
          {
            RetraiteDebutActivite: "0101" + CurrentYear,
            RetraiteFinActivite: Periode.FinPeriodeCarriereActuelleConjoint,
            RetraiteActivite: RetraiteActivite,
            CheckboxDepartRetraite: Periode.FinCarriereActuelleConjoint === "DepartRetraite" ? true : false,
            IdPeriodeRetraite: "CarriereActuelle",
            DisplayDeplafondPass: false,
            DisplayTrimestre: true,
            AllCheckboxTrimestre: false,
            TypeEvolution: "EvolutionPlat",
            MontantEvolution: 0,
            TauxActivite: Periode.TauxActiviteConjoint,
            DetailRetraitePasse: {},
            DetailRetraiteFutur: DetailRetraiteFutur,
            ParamCotisations: ParamCotisations,
          },
          () => {
            this.handleAddPeriodeRetraite("CarriereActuelle", Outil, "Conjoint");
            resolve(true);
          }
        );
      });
    });
  };

  handleTauxIS = (name) => (event) => {
    let TauxISReduit = false;
    let TauxISNormal = false;
    let TauxISZero = false;

    if (name === "TauxISReduit") {
      TauxISReduit = true;
    } else if (name === "TauxISNormal") {
      TauxISNormal = true;
    } else {
      TauxISZero = true;
    }

    this.setState(
      {
        TauxISReduit: TauxISReduit,
        TauxISNormal: TauxISNormal,
        TauxISZero: TauxISZero,
      },
      () => {
        CalculResultatEntreprise(this.state, name).then((ResultatEntreprise) => {
          this.setState({
            ResAvtIs: ResultatEntreprise.Rai,
            ImpotSoc: ResultatEntreprise.ImpotSoc,
            DividendeBrutAutres: ResultatEntreprise.DividendeBrutAutres,
            SeuilAssuj: this.state.SetSeuilAssuj === false ? ResultatEntreprise.SeuilAssuj : this.state.SeuilAssuj,
            SeuilAssujConjoint: this.state.SetSeuilAssujConjoint === false ? ResultatEntreprise.SeuilAssujConjoint : this.state.SeuilAssujConjoint,
            ResNet: ResultatEntreprise.ResNet,
            Reserve: ResultatEntreprise.Reserve,
          });
        });
      }
    );
  };

  handleCalculResultatEntreprise = (outil, name, event) => {
    let value = Number(event.target.value);

    let totPartCouple = Number(this.state[outil + "PartSoc"]) + Number(this.state[outil + "PartSocConj"]);
    if (name === outil + "PartSoc") {
      totPartCouple = Number(value) + Number(this.state[outil + "PartSocConj"]);
    } else if (name === outil + "PartSocConj") {
      totPartCouple = Number(this.state[outil + "PartSoc"]) + Number(value);
    }

    let PeriodeActuelle = _.cloneDeep(this.state[outil + "PeriodeActuelle"]);
    if (outil === "OptiRem") {
      if (name === "OptiRemCapitalSocial") {
        PeriodeActuelle[0]["CapitalSocial"] = value;
      } else if (name === "OptiRemPartSoc") {
        PeriodeActuelle[0]["PartSoc"] = value;
      } else if (name === "OptiRemPartSocConj") {
        PeriodeActuelle[0]["PartSocConj"] = value;
      }
    }
    if (totPartCouple <= 100) {
      this.setState({ [name]: value }, () => {
        CalculResultatEntreprise(this.state, outil, name).then((ResultatEntreprise) => {
          this.setState({
            ResAvtIs: ResultatEntreprise.Rai,
            ImpotSoc: ResultatEntreprise.ImpotSoc,
            DividendeBrutAutres: ResultatEntreprise.DividendeBrutAutres,
            [outil + "SeuilAssuj"]: this.state[outil + "SetSeuilAssuj"] === false ? ResultatEntreprise.SeuilAssuj : this.state[outil + "SeuilAssuj"],
            [outil + "SeuilAssujConjoint"]: this.state[outil + "SetSeuilAssujConjoint"] === false ? ResultatEntreprise.SeuilAssujConjoint : this.state[outil + "SeuilAssujConjoint"],
            ResNet: ResultatEntreprise.ResNet,
            Reserve: ResultatEntreprise.Reserve,
            [outil + "PeriodeActuelle"]: PeriodeActuelle,
          });
        });
      });
    }
  };
  handleCalculResultatEntrepriseConjointOptiRem = (outil, name, event) => {
    console.log("YOUPI", name);
    let value = this.state[name];
    if (event !== undefined) {
      value = Number(event.target.value);
    }

    let totPartCouple = Number(this.state[outil + "PartSoc"]) + Number(this.state[outil + "PartSocConj"]);
    if (name === outil + "PartSoc") {
      totPartCouple = Number(value) + Number(this.state[outil + "PartSocConj"]);
    } else if (name === outil + "PartSocConj") {
      totPartCouple = Number(this.state[outil + "PartSoc"]) + Number(value);
    }

    let PeriodeActuelleConjoint = _.cloneDeep(this.state["OptiRemPeriodeActuelleConjoint"]);
    if (outil === "OptiRemPeriodeConjoint") {
      if (name === "OptiRemCapitalSocialConjoint") {
        PeriodeActuelleConjoint[0]["CapitalSocial"] = value;
      } else if (name === "OptiRemPeriodeConjointPartSoc") {
        PeriodeActuelleConjoint[0]["PartSoc"] = value;
      } else if (name === "OptiRemPeriodeConjointPartSocConj") {
        PeriodeActuelleConjoint[0]["PartSocConj"] = value;
      }
    }
    if (totPartCouple <= 100) {
      this.setState({ [name]: value }, () => {
        CalculResultatEntreprise(this.state, outil, name).then((ResultatEntreprise) => {
          this.setState({
            ResAvtIs: ResultatEntreprise.Rai,
            ImpotSoc: ResultatEntreprise.ImpotSoc,
            DividendeBrutAutres: ResultatEntreprise.DividendeBrutAutres,
            OptiRemSeuilAssuj: this.state.OptiRemSetSeuilAssujConjoint === false ? ResultatEntreprise.SeuilAssujConjoint : this.state.OptiremSeuilAssujConjoint,
            OptiRemSeuilAssujConjoint: this.state.OptiRemSetSeuilAssuj === false ? ResultatEntreprise.SeuilAssuj : this.state.OptiRemSeuilAssuj,
            ResNet: ResultatEntreprise.ResNet,
            Reserve: ResultatEntreprise.Reserve,
            OptiRemPeriodeActuelleConjoint: PeriodeActuelleConjoint,
          });
        });
      });
    }
  };
  //REVENUS
  handleChoixCaisse = (event) => {
    let TNSIndividuelRegimeApplicable = this.state.TNSIndividuelRegimeApplicable;
    if (this.state.Statut === "TNS individuel") {
      if (event.target.value === "RsiCommercant" || event.target.value === "RsiArtisan" || event.target.value === "Cavp") {
        TNSIndividuelRegimeApplicable = "BICReel";
      } else if (event.target.value === "Msa") {
        TNSIndividuelRegimeApplicable = "BAReel";
      } else {
        TNSIndividuelRegimeApplicable = "BNCControlle";
      }
    }
    this.setState({ Caisse: event.target.value, TNSIndividuelRegimeApplicable: TNSIndividuelRegimeApplicable }, () => {
      // this.handleCarriereActuelle(this.state.Remuneration);
    });
  };
  handleRemuneration = (event) => {
    this.setState({ Remuneration: Number(event.target.value) }, () => {
      // this.handleCarriereActuelle(this.state.Remuneration);
    });
  };

  handleOptionPfu = (outil) => (event) => {
    if (event.target.value === "oui") {
      this.setState({
        [outil + "OptionPfu"]: event.target.value,
        [outil + "AbattementPYL"]: "non",
      });
    } else {
      this.setState({
        [outil + "OptionPfu"]: event.target.value,
      });
    }
  };

  handleChoixStatut = (name, ClientConjoint, event) => {
    this.setState(
      {
        [name]: name !== "Statut" ? event.target.value : this.state.Statut,
      },
      () => {
        let Statut = this.state.Statut;
        let Caisse = this.state.Caisse;
        let TypeRemuneration = "";
        let Fiscalite = this.state.Fiscalite;
        let FiscaliteContratRetraite = this.state.FiscaliteContratRetraite;
        let ContratEnregistre = this.state["ContratEnregistre" + ClientConjoint];
        let ContratFraisPro = this.state["ContratFraisPro" + ClientConjoint];
        let ContratEnregistreSante = this.state["ContratEnregistreSante" + ClientConjoint];
        let ListeContrat = this.state["ListeContrat" + ClientConjoint];

        //CHOIX DE LA FISCALITE
        const fiscaliteOnlyIS = ["SARL", "SAS", "SASU", "SA", "SELARL", "SELAS", "SELASU", "SCEA"];
        if (fiscaliteOnlyIS.indexOf(this.state.FormeJuridique) > -1) {
          Fiscalite = "IS";
        }
        // if (Fiscalite === "IR") {
        //   Statut = "TNS individuel";
        // }

        //CHOIX DU STATUT
        if (name === "SituationPro" || name === "Fiscalite") {
          switch (this.state.SituationPro) {
            case "SalarieCadre":
            case "ECSalarie":
            case "AvocatSalarie":
              Statut = "Salarié cadre";
              break;
            case "SalarieNonCadre":
              Statut = "Salarié non cadre";
              break;
            default:
              if (Fiscalite === "IR") {
                Statut = "TNS individuel";
              } else {
                Statut = "TNS Article 62";
              }
          }
        } else if (name === "Statut") {
          Statut = event.target.value;
        }
        if (name !== "Statut") {
          switch (this.state.FormeJuridique) {
            case "SA":
            case "SAS":
            case "SASU":
              if (this.state.SituationPro === "Tns") {
                Statut = "Assimilé salarié";
              }
              break;
            case "EI":
              if (this.state.SituationPro === "Tns") {
                Statut = "TNS individuel";
                Fiscalite = "IR";
              }
              break;
            case "EIRL":
            case "EURL":
            case "SELARL":
            case "SELURL":
            case "SELAS":
            case "SELASU":
              if (this.state.SituationPro === "Tns") {
                if (Fiscalite === "IR") {
                  Statut = "TNS individuel";
                } else {
                  Statut = "TNS Article 62";
                }
              }
              break;
            default:
              if (this.state.SituationPro === "Tns") {
                if (Fiscalite === "IR") {
                  Statut = "TNS individuel";
                } else {
                  Statut = this.state.Statut === "Assimilé salarié" ? "Assimilé salarié" : "TNS Article 62";
                }
              }
          }
        }
        //CHOIX DU TYPE DE REMUNERATION
        switch (Statut) {
          case "Salarié cadre":
          case "Salarié non cadre":
          case "Assimilé salarié":
            TypeRemuneration = "salaire_brut";
            break;
          case "TNS individuel":
            TypeRemuneration = "brut_imposable";
            break;
          default:
            TypeRemuneration = "remuneration_nette";
        }
        if (this.state.FormeJuridique === "EARL" || this.state.FormeJuridique === "SCEA") {
          Caisse = "Msa";
        }

        if ((Statut === "Assimilé salarié" || Statut === "Salarié cadre" || Statut === "Salarié non cadre") && this.state.Statut !== "Assimilé salarié") {
          ContratEnregistre = [];
          ContratFraisPro = [];
          ContratEnregistreSante = [];
          ListeContrat = [];
          FiscaliteContratRetraite = "Per";
        }
        if (
          (Statut === "TNS Article 62" || Statut === "TNS individuel") &&
          (this.state.Statut === "Assimilé salarié" || this.state.Statut === "Salarié cadre" || this.state.Statut === "Salarié non cadre")
        ) {
          ContratEnregistre = [];
          ContratFraisPro = [];
          ContratEnregistreSante = [];
          ListeContrat = [];
          FiscaliteContratRetraite = "Per";
        }
        this.setState(
          {
            Statut: Statut,
            Caisse: Caisse,
            Fiscalite: Fiscalite,
            TypeRemuneration: TypeRemuneration,
            ["ContratEnregistre" + ClientConjoint]: ContratEnregistre,
            ["ContratFraisPro" + ClientConjoint]: ContratFraisPro,
            ["ContratEnregistreSante" + ClientConjoint]: ContratEnregistreSante,
            ["ListeContrat" + ClientConjoint]: ListeContrat,
            FiscaliteContratRetraite: FiscaliteContratRetraite,
          },
          () => {
            // this.handleCarriereActuelle(this.state.Remuneration);
          }
        );
      }
    );
  };
  handleChoixStatutConjoint = (name, event) => {
    let Statut = event.target.value;
    let TypeRemuneration = "";
    let FiscaliteContratRetraite = this.state.FiscaliteContratRetraite;
    let ContratEnregistre = this.state.ContratEnregistreConjoint;
    let ContratFraisPro = this.state.ContratFraisProConjoint;
    let ContratEnregistreSante = this.state.ContratEnregistreSanteConjoint;
    let ListeContrat = this.state.ListeContratConjoint;

    //CHOIX DU TYPE DE REMUNERATION
    switch (Statut) {
      case "Salarié cadre":
      case "Salarié non cadre":
      case "Assimilé salarié":
        TypeRemuneration = "salaire_brut";
        break;
      case "TNS individuel":
        TypeRemuneration = "brut_imposable";
        break;
      default:
        TypeRemuneration = "remuneration_nette";
    }

    if (Statut === "Aucun") {
      ContratEnregistre = [];
      ContratFraisPro = [];
      ContratEnregistreSante = [];
      ListeContrat = [];
      FiscaliteContratRetraite = "Per";
    }
    if ((Statut === "Assimilé salarié" || Statut === "Salarié cadre" || Statut === "Salarié non cadre") && this.state.StatutConjoint !== "Assimilé salarié") {
      ContratEnregistre = [];
      ContratFraisPro = [];
      ContratEnregistreSante = [];
      ListeContrat = [];
      FiscaliteContratRetraite = "Per";
    }
    if (
      (Statut === "TNS Article 62" || Statut === "TNS individuel") &&
      (this.state.StatutConjoint === "Assimilé salarié" || this.state.StatutConjoint === "Salarié cadre" || this.state.StatutConjoint === "Salarié non cadre")
    ) {
      ContratEnregistre = [];
      ContratFraisPro = [];
      ContratEnregistreSante = [];
      ListeContrat = [];
      FiscaliteContratRetraite = "Per";
    }
    this.setState(
      {
        StatutConjoint: Statut,
        TypeRemunerationConjoint: TypeRemuneration,
        ContratEnregistreConjoint: ContratEnregistre,
        ContratFraisProConjoint: ContratFraisPro,
        ContratEnregistreSanteConjoint: ContratEnregistreSante,
        ListeContratConjoint: ListeContrat,
        FiscaliteContratRetraite: FiscaliteContratRetraite,
      },
      () => {
        // this.handleCarriereActuelle(this.state.Remuneration);
      }
    );
  };
  handleAddCotisationManuelle = (Outil, ClientConjoint) => {
    let TabCotisationsManuelle = this.state[Outil + "TabCotisationsManuelle" + ClientConjoint];
    const Cotis = {
      id: Date.now(),
      Libelle: this.state[Outil + "LibelleCotisationManuelle"],
      Assiette: this.state[Outil + "AssietteCotisationManuelle"],
      TypeCotisation: this.state[Outil + "TypeCotisationManuelle"],
      TauxSalariale: this.state[Outil + "TauxSalarieCotisationManuelle"],
      TauxPatronale: this.state[Outil + "TauxPatronaleCotisationManuelle"],
    };
    TabCotisationsManuelle = [...TabCotisationsManuelle, Cotis];
    this.setState({
      [Outil + "TabCotisationsManuelle" + ClientConjoint]: TabCotisationsManuelle,
      [Outil + "LibelleCotisationManuelle"]: "",
      [Outil + "AssietteCotisationManuelle"]: "Salaire brut",
      [Outil + "TypeCotisationManuelle"]: "proportionnelle",
      [Outil + "TauxSalarieCotisationManuelle"]: 0.0,
      [Outil + "TauxPatronaleCotisationManuelle"]: 0.0,
    });
  };
  handleDeleteCotisationManuelle = (IdToRemove, Outil, ClientConjoint) => {
    let TabCotisationsManuelle = [...this.state[Outil + "TabCotisationsManuelle" + ClientConjoint]];
    TabCotisationsManuelle = TabCotisationsManuelle.filter((Cotis) => Cotis.id !== IdToRemove);
    this.setState({
      [Outil + "TabCotisationsManuelle" + ClientConjoint]: TabCotisationsManuelle,
    });
  };
  //PREVOYANCE
  handleAssietteGarantie = (name) => (event) => {
    let AssietteGarantie = event.target.value;
    let MontantIJ = Number((AssietteGarantie * this.state.TauxIJ) / Number(100) / Number(365)).toFixed(0);

    let MontantInvaliditeTotale = Number((AssietteGarantie * this.state.TauxInvaliditeTotale) / Number(100)).toFixed(0);
    let MontantDecesTouteCause = Number((AssietteGarantie * this.state.TauxDecesTouteCause) / Number(100)).toFixed(0);
    let MontantDecesAccidentel = Number((AssietteGarantie * this.state.TauxDecesAccidentel) / Number(100)).toFixed(0);
    let MontantRenteConjoint = Number((AssietteGarantie * this.state.TauxRenteConjoint) / Number(100)).toFixed(0);
    let MontantRenteEducation0_11 = Number((AssietteGarantie * this.state.TauxRenteEducation0_11) / Number(100)).toFixed(0);
    let MontantRenteEducation12_17 = Number((AssietteGarantie * this.state.TauxRenteEducation12_17) / Number(100)).toFixed(0);
    let MontantRenteEducation18_25 = Number((AssietteGarantie * this.state.TauxRenteEducation18_25) / Number(100)).toFixed(0);
    this.setState({
      [name]: event.target.value,
      MaladieComplementaire: MontantIJ,
      InvaliditeTotaleComplementaire: MontantInvaliditeTotale,
      DecesTouteCause: MontantDecesTouteCause,
      DecesAccidentel: MontantDecesAccidentel,
      RenteConjoint: MontantRenteConjoint,
      RenteEducation0_11: MontantRenteEducation0_11,
      RenteEducation12_17: MontantRenteEducation12_17,
      RenteEducation18_25: MontantRenteEducation18_25,
    });
  };
  handleGestionIJ1 = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
    let NewtTabij2 = [...Tabij2];
    NewtTabij2.forEach(function (elem, i) {
      if (Number(elem.value) < Number(event.target.value)) {
        delete NewtTabij2[i];
      }
    });
    NewtTabij2 = NewtTabij2.filter(Boolean);
    let NewtTabij4 = [...NewtTabij2];
    NewtTabij4.shift();
    if (NewtTabij4.length === 0) {
      NewtTabij4 = [
        {
          value: "1095",
          label: "1095 jours",
        },
      ];
    }
    let NewtTabij6 = [...NewtTabij4];
    NewtTabij6.shift();
    if (NewtTabij6.length === 0) {
      NewtTabij6 = [
        {
          value: "1095",
          label: "1095 jours",
        },
      ];
    }
    this.setState({
      Tabij2: NewtTabij2,
    });
    if (Number(event.target.value) >= Number(this.state.IJ2)) {
      this.setState({
        IJ2: NewtTabij2[0].value,
        IJ3: Math.min(1095, Number(NewtTabij2[0].value) + 1),
        Tabij4: NewtTabij4,
        IJ4: NewtTabij4[0].value,
        IJ5: Math.min(1095, Number(NewtTabij4[0].value) + 1),
        Tabij6: NewtTabij6,
        IJ6: NewtTabij6[0].value,
      });
    }
  };
  handleGestionIJ2 = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
    let NewtTabij4 = [...Tabij2];
    NewtTabij4.forEach(function (elem, i) {
      if (Number(elem.value) <= Number(event.target.value)) {
        delete NewtTabij4[i];
      }
    });
    NewtTabij4 = NewtTabij4.filter(Boolean);
    if (NewtTabij4.length === 0) {
      NewtTabij4 = [
        {
          value: "1095",
          label: "1095 jours",
        },
      ];
    }
    let NewtTabij6 = [...NewtTabij4];
    NewtTabij6.shift();
    if (NewtTabij6.length === 0) {
      NewtTabij6 = [
        {
          value: "1095",
          label: "1095 jours",
        },
      ];
    }
    this.setState({
      IJ3: Math.min(1095, Number(event.target.value) + 1),
      Tabij4: NewtTabij4,
    });
    if (Number(event.target.value) >= Number(this.state.IJ4)) {
      this.setState({
        IJ4: NewtTabij4[0].value,
        IJ5: Math.min(1095, Number(NewtTabij4[0].value) + 1),
        Tabij6: NewtTabij6,
        IJ6: NewtTabij6[0].value,
      });
    }
  };
  handleGestionIJ = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
    if (name === "IJ4") {
      let NewtTabij6 = [...Tabij2];
      NewtTabij6.forEach(function (elem, i) {
        if (Number(elem.value) <= Number(event.target.value)) {
          delete NewtTabij6[i];
        }
      });
      NewtTabij6 = NewtTabij6.filter(Boolean);
      if (NewtTabij6.length > 0) {
        this.setState({
          IJ5: Number(event.target.value) + 1,
          Tabij6: NewtTabij6,
        });
        if (Number(event.target.value) >= Number(this.state.IJ6)) {
          this.setState({
            IJ6: NewtTabij6[0].value,
          });
        }
      }
    }
  };
  handleTauxToMontant = (name) => (event) => {
    const Assiette = this.state.AssietteGarantie;
    const Correspondance = {
      TauxIJ: "MaladieComplementaire",
      TauxInvaliditeTotale: "InvaliditeTotaleComplementaire",
      TauxDecesTouteCause: "DecesTouteCause",
      TauxDecesAccidentel: "DecesAccidentel",
      TauxRenteConjoint: "RenteConjoint",
      TauxRenteEducation0_11: "RenteEducation0_11",
      TauxRenteEducation12_17: "RenteEducation12_17",
      TauxRenteEducation18_25: "RenteEducation18_25",
    };

    const Taux = event.target.value.replace(/\D/g, "");

    if (name === "TauxIJ") {
      const Montant = Number((Assiette * Taux) / Number(100) / Number(365)).toFixed(0);
      this.setState({
        [name]: Taux,
        [Correspondance[name]]: Montant,
      });
    } else {
      const Montant = Number((Assiette * Taux) / Number(100)).toFixed(0);
      this.setState({
        [name]: Taux,
        [Correspondance[name]]: Montant,
      });
    }
  };
  handleMontantToTaux = (name) => (event) => {
    const Assiette = this.state.AssietteGarantie;
    const Correspondance = {
      MaladieComplementaire: "TauxIJ",
      InvaliditeTotaleComplementaire: "TauxInvaliditeTotale",
      DecesTouteCause: "TauxDecesTouteCause",
      DecesAccidentel: "TauxDecesAccidentel",
      RenteConjoint: "TauxRenteConjoint",
      RenteEducation0_11: "TauxRenteEducation0_11",
      RenteEducation12_17: "TauxRenteEducation12_17",
      RenteEducation18_25: "TauxRenteEducation18_25",
    };

    let Montant = Number(event.target.value.replace(/\D/g, "")).toFixed(0);
    if (name === "MaladieComplementaire") {
      Montant = Number(Montant * 365).toFixed(0);
    }
    let Taux = Number((Montant / Assiette) * Number(100)).toFixed(0);
    if (isNaN(Taux) || Taux === "Infinity") {
      Taux = 0;
    }
    this.setState({
      [name]: Number(event.target.value.replace(/\D/g, "")).toFixed(0),
      [Correspondance[name]]: Taux,
    });
  };
  handleResetPrevoyanceSettings = () => {
    this.setState({
      ...RemExpertPrevoyance,
    });
  };
  handleAddContratPrevoyance = (id, Outil, ClientConjoint) => {
    GetCotisation83(this.state, ClientConjoint).then((Resultat) => {
      let ContratEnregistre = this.state[Outil + "ContratEnregistre" + ClientConjoint];
      let IdTarificateur = "";
      let IdContratPrevoyance = id;
      if (IdContratPrevoyance.length === 0) {
        IdContratPrevoyance = Date.now();
      } else {
        if (this.state.AccesTarificateur === "Oui") {
          IdTarificateur = ContratEnregistre.filter((Contrat) => Contrat.id === id)[0].IdTarificateur;
        }
        ContratEnregistre = ContratEnregistre.filter((Contrat) => Contrat.id !== id);
      }

      let Cotisation83 = 0;
      if (Outil === "" && this.state["Statut" + ClientConjoint] !== "TNS Article 62" && this.state["Statut" + ClientConjoint] !== "TNS individuel") {
        Cotisation83 = Resultat.CotisationPrev83;
      }
      if (Outil === "OptiRem" && this.state["OptiRemStatut" + ClientConjoint] !== "TNS Article 62" && this.state["OptiRemStatut" + ClientConjoint] !== "TNS individuel") {
        Cotisation83 = Resultat.CotisationPrev83;
      }
      const newContrat = [
        {
          //PREVOYANCE
          IdTarificateur: IdTarificateur,
          id: IdContratPrevoyance,
          SelectContrat: this.state.SelectContrat,
          LibelleContrat: this.state.LibelleContrat,
          PayeurPrimePrevoyance: this.state.PayeurPrimePrevoyance,
          PrevoyanceMadelin: this.state.PrevoyanceMadelin,
          PrevoyanceNonMadelin: this.state.PrevoyanceNonMadelin,
          Cotisation83: Cotisation83,
          Taux83Prevoyance: {
            taux_prevoyance_trancheA_salarie83: this.state.Taux83Prevoyance.taux_prevoyance_trancheA_salarie83,
            taux_prevoyance_trancheA_patronale83: this.state.Taux83Prevoyance.taux_prevoyance_trancheA_patronale83,
            taux_prevoyance_trancheB_salarie83: this.state.Taux83Prevoyance.taux_prevoyance_trancheB_salarie83,
            taux_prevoyance_trancheB_patronale83: this.state.Taux83Prevoyance.taux_prevoyance_trancheB_patronale83,
            taux_prevoyance_trancheC_salarie83: this.state.Taux83Prevoyance.taux_prevoyance_trancheC_salarie83,
            taux_prevoyance_trancheC_patronale83: this.state.Taux83Prevoyance.taux_prevoyance_trancheC_patronale83,
            taux_prevoyance_trancheD_salarie83: this.state.Taux83Prevoyance.taux_prevoyance_trancheD_salarie83,
            taux_prevoyance_trancheD_patronale83: this.state.Taux83Prevoyance.taux_prevoyance_trancheD_patronale83,
          },
          Tarification: this.state.Tarification,
          ContratPrevoyance: this.state.ContratPrevoyance,
          Nature: this.state.Nature,
          LimiteNatureContrat: this.state.LimiteNatureContrat,
          LimiteNatureContratUnite: this.state.LimiteNatureContratUnite,
          AssietteGarantie: this.state.AssietteGarantie,
          //OPTION/EXCLUSION
          MiTempsTherapeutique: this.state.MiTempsTherapeutique,
          MTPCarence: this.state.MTPCarence,
          MTPDureeIJ: this.state.MTPDureeIJ,
          RachatPsy: this.state.RachatPsy,
          RachatPsyCarence: this.state.RachatPsyCarence,
          RachatPsyDureeHospi: this.state.RachatPsyDureeHospi,
          RachatPsyDureeIJ: this.state.RachatPsyDureeIJ,
          RachatDos: this.state.RachatDos,
          RachatDosCarence: this.state.RachatDosCarence,
          RachatDosDureeHospi: this.state.RachatDosDureeHospi,
          RachatDosDureeIJ: this.state.RachatDosDureeIJ,
          PECDos: this.state.PECDos,
          PECDosCarence: this.state.PECDosCarence,
          PECDosDureeHospi: this.state.PECDosDureeHospi,
          PECDosDureeIJ: this.state.PECDosDureeIJ,
          PECPsy: this.state.PECPsy,
          PECPsyCarence: this.state.PECPsyCarence,
          PECPsyDureeHospi: this.state.PECPsyDureeHospi,
          PECPsyDureeIJ: this.state.PECPsyDureeIJ,
          //INCAP
          TauxIJ: this.state.TauxIJ,
          MaladieComplementaire: this.state.MaladieComplementaire,
          IJ1: this.state.IJ1,
          IJ2: this.state.IJ2,
          MaladieComplementaire2: this.state.MaladieComplementaire2,
          IJ3: this.state.IJ3,
          IJ4: this.state.IJ4,
          MaladieComplementaire3: this.state.MaladieComplementaire3,
          IJ5: this.state.IJ5,
          IJ6: this.state.IJ6,
          FranchiseAccidentComplementaire: this.state.FranchiseAccidentComplementaire,
          FranchiseHospitalisationComplementaire: this.state.FranchiseHospitalisationComplementaire,
          ApplicationFranchiseHospi: this.state.ApplicationFranchiseHospi,
          CapitalMaladieRedoutee: this.state.CapitalMaladieRedoutee,

          BaseMaladieComplementaireSalarie: this.state.BaseMaladieComplementaireSalarie,
          PourcentageMaladieComplementaireSalarie: this.state.PourcentageMaladieComplementaireSalarie,

          //INVAL
          InvaliditeTotaleComplementaire: this.state.InvaliditeTotaleComplementaire,
          TauxInvaliditeTotale: this.state.TauxInvaliditeTotale,
          CapitalInvalTotale: this.state.CapitalInvalTotale,
          InvaliditePartielleComplementaire: this.state.InvaliditePartielleComplementaire,
          CalculRente: this.state.CalculRente,
          TauxInvalPartielleComplementaire15: this.state.TauxInvalPartielleComplementaire15,
          TauxInvalPartielleComplementaire33: this.state.TauxInvalPartielleComplementaire33,
          TauxInvalPartielleComplementaire66: this.state.TauxInvalPartielleComplementaire66,
          Bareme: this.state.Bareme,
          InvaliditeCategorie1: this.state.InvaliditeCategorie1,
          InvaliditeCategorie2: this.state.InvaliditeCategorie2,
          InvaliditeCategorie3: this.state.InvaliditeCategorie3,

          BaseInvaliditeComplementaireSalarie: this.state.BaseInvaliditeComplementaireSalarie,
          PourcentageInvaliditeCategorie1: this.state.PourcentageInvaliditeCategorie1,
          PourcentageInvaliditeCategorie2: this.state.PourcentageInvaliditeCategorie2,
          PourcentageInvaliditeCategorie3: this.state.PourcentageInvaliditeCategorie3,

          //DECES
          DecesTouteCause: this.state.DecesTouteCause,
          TauxDecesTouteCause: this.state.TauxDecesTouteCause,
          DecesAccidentel: this.state.DecesAccidentel,
          TauxDecesAccidentel: this.state.TauxDecesAccidentel,
          DecesDoubleEffet: this.state.DecesDoubleEffet,
          FraisObseque: this.state.FraisObseque,
          RenteConjoint: this.state.RenteConjoint,
          TauxRenteConjoint: this.state.TauxRenteConjoint,
          DecesAgeDebut1: this.state.DecesAgeDebut1,
          DecesAgeFin1: this.state.DecesAgeFin1,
          DecesAgeDebut2: this.state.DecesAgeDebut2,
          DecesAgeFin2: this.state.DecesAgeFin2,
          DecesAgeDebut3: this.state.DecesAgeDebut3,
          DecesAgeFin3: this.state.DecesAgeFin3,
          RenteEducation0_11: this.state.RenteEducation0_11,
          TauxRenteEducation0_11: this.state.TauxRenteEducation0_11,
          RenteEducation12_17: this.state.RenteEducation12_17,
          TauxRenteEducation12_17: this.state.TauxRenteEducation12_17,
          RenteEducation18_25: this.state.RenteEducation18_25,
          TauxRenteEducation18_25: this.state.TauxRenteEducation18_25,

          BaseDecesComplementaireSalarie: this.state.BaseDecesComplementaireSalarie,
          PourcentageDecesTouteCause: this.state.PourcentageDecesTouteCause,
          PourcentageDecesAccidentel: this.state.PourcentageDecesAccidentel,
          BaseRenteConjointComplementaireSalarie: this.state.BaseRenteConjointComplementaireSalarie,
          PourcentageRenteConjoint: this.state.PourcentageRenteConjoint,
          BaseRenteEducationComplementaireSalarie: this.state.BaseRenteEducationComplementaireSalarie,
          PourcentageRenteEducation0_11: this.state.PourcentageRenteEducation0_11,
          PourcentageRenteEducation12_17: this.state.PourcentageRenteEducation12_17,
          PourcentageRenteEducation18_25: this.state.PourcentageRenteEducation18_25,
        },
      ];
      ContratEnregistre = [...ContratEnregistre, ...newContrat];
      this.setState(
        {
          [Outil + "ContratEnregistre" + ClientConjoint]: ContratEnregistre,
        },
        () => {
          this.handleResetPrevoyanceSettings();
        }
      );
    });
  };
  handleAddContratFraisPro = (id, Outil, ClientConjoint) => {
    let ContratFraisPro = this.state[Outil + "ContratFraisPro" + ClientConjoint];
    let IdContratPrevoyance = id;
    if (IdContratPrevoyance.length === 0) {
      IdContratPrevoyance = Date.now();
    } else {
      ContratFraisPro = ContratFraisPro.filter((Contrat) => Contrat.id !== id);
    }
    const newContrat = [
      {
        //PREVOYANCE
        id: IdContratPrevoyance,

        //FGP
        LibelleContrat: this.state.LibelleContrat,
        FGPMontant: this.state.FGPMontant,
        FGPIJ: this.state.FGPIJ,
        FGPFranchiseMaladie: this.state.FGPFranchiseMaladie,
        FGPFranchiseAccident: this.state.FGPFranchiseAccident,
        FGPFranchiseHospitalisation: this.state.FGPFranchiseHospitalisation,
        FGPDureeVersement: this.state.FGPDureeVersement,
      },
    ];
    ContratFraisPro = [...ContratFraisPro, ...newContrat];
    this.setState(
      {
        [Outil + "ContratFraisPro" + ClientConjoint]: ContratFraisPro,
      },
      () => {
        this.handleResetPrevoyanceSettings();
      }
    );
  };

  handleModifyDeleteContratPrevoyance = (id, option, TypeContrat, Outil, ClientConjoint) => {
    let ContratEnregistre = this.state[Outil + "ContratEnregistre" + ClientConjoint];

    if (TypeContrat === "Frais professionnels") {
      ContratEnregistre = this.state[Outil + "ContratFraisPro" + ClientConjoint];
    }

    if (option === "Modifier") {
      const ContratToDisplay = ContratEnregistre.filter((Contrat) => Contrat.id === id);
      this.setState({ IdContratPrevoyance: id });
      ContratToDisplay.forEach((contrat) => {
        for (var prop in contrat) {
          this.setState({ [prop]: contrat[prop] });
        }
      });
    } else if (option === "Supprimer") {
      ContratEnregistre = ContratEnregistre.filter((Contrat) => Contrat.id !== id);
      if (TypeContrat === "Frais professionnels") {
        this.setState({ [Outil + "ContratFraisPro" + ClientConjoint]: ContratEnregistre });
      } else {
        this.setState({ [Outil + "ContratEnregistre" + ClientConjoint]: ContratEnregistre });
      }
    }
  };
  //SANTE
  handleResetSanteSettings = () => {
    this.setState({
      ...RemExpertSante,
    });
  };
  handleAddContratSante = (id, Outil, ClientConjoint) => {
    GetCotisation83(this.state, ClientConjoint).then((Resultat) => {
      let ContratEnregistreSante = this.state[Outil + "ContratEnregistreSante" + ClientConjoint];
      let IdContratSante = id;
      if (IdContratSante.length === 0) {
        IdContratSante = Date.now();
      } else {
        ContratEnregistreSante = ContratEnregistreSante.filter((Contrat) => Contrat.id !== id);
      }

      let Cotisation83 = Resultat.CotisationSante83;

      const newContrat = [
        {
          //PREVOYANCE
          id: IdContratSante,
          LibelleContratSante: this.state.LibelleContratSante,
          PayeurPrimeSante: this.state.PayeurPrimeSante,
          SanteMadelin: this.state.SanteMadelin,
          SanteNonMadelin: this.state.SanteNonMadelin,
          Cotisation83: Cotisation83,
          Taux83Sante: {
            taux_sante_salarie83: this.state.Taux83Sante.taux_sante_salarie83,
            taux_sante_patronale83: this.state.Taux83Sante.taux_sante_patronale83,
          },
          RemboursementSante: this.state.RemboursementSante,
          FraisSejour: this.state.FraisSejour,
          RemboursementFraisSejour: this.state.RemboursementFraisSejour,
          ForfaitJournalier: this.state.ForfaitJournalier,
          ChambreParticuliere: this.state.ChambreParticuliere,
          GeneralisteConventionne: this.state.GeneralisteConventionne,
          RemboursementGeneralisteConventionne: this.state.RemboursementGeneralisteConventionne,
          SpecialisteConventionne: this.state.SpecialisteConventionne,
          RemboursementSpecialisteConventionne: this.state.RemboursementSpecialisteConventionne,
          Radiologie: this.state.Radiologie,
          RemboursementRadiologie: this.state.RemboursementRadiologie,
          Dentaire: this.state.Dentaire,
          RemboursementDentaire: this.state.RemboursementDentaire,
          Prothese: this.state.Prothese,
          RemboursementProthese: this.state.RemboursementProthese,
          Orthodontie: this.state.Orthodontie,
          RemboursementOrthodontie: this.state.RemboursementOrthodontie,
          MontureAdulte: this.state.MontureAdulte,
          VerresAdulteUnifocaux: this.state.VerresAdulteUnifocaux,
          VerresAdulteMultifocaux: this.state.VerresAdulteMultifocaux,
          Lentilles: this.state.Lentilles,
          MedecineDouce: this.state.MedecineDouce,
          MedecineDouceConsultation: this.state.MedecineDouceConsultation,
          Medicaments: this.state.Medicaments,
          ProtheseAuditive: this.state.ProtheseAuditive,
          RemboursementProtheseAuditive: this.state.RemboursementProtheseAuditive,
        },
      ];
      ContratEnregistreSante = [...ContratEnregistreSante, ...newContrat];
      this.setState(
        {
          [Outil + "ContratEnregistreSante" + ClientConjoint]: ContratEnregistreSante,
        },
        () => {
          this.handleResetSanteSettings();
        }
      );
    });
  };

  handleModifyDeleteContratSante = (id, option, Outil, ClientConjoint) => {
    let ContratEnregistreSante = this.state[Outil + "ContratEnregistreSante" + ClientConjoint];
    if (option === "Modifier") {
      const ContratToDisplay = ContratEnregistreSante.filter((Contrat) => Contrat.id === id);
      this.setState({ IdContratSante: id });
      ContratToDisplay.forEach((contrat) => {
        for (var prop in contrat) {
          this.setState({ [prop]: contrat[prop] });
        }
      });
    } else if (option === "Supprimer") {
      ContratEnregistreSante = ContratEnregistreSante.filter((Contrat) => Contrat.id !== id);
      this.setState({ [Outil + "ContratEnregistreSante" + ClientConjoint]: ContratEnregistreSante });
    }
  };

  //RETRAITE
  //DATE DE DEPART RETRAITE
  handleDepartRetraite = (ClientConjoint, name) => (event) => {
    let ChoixDepartRetraite = name;
    let DepartAgeLegal = false;
    let DepartAgeChoisi = false;
    let DepartDateChoisi = false;
    let DepartAgeTauxPlein = false;
    let DepartAgeChoisiSelect = this.state["DepartAgeChoisiSelect" + ClientConjoint];
    let DepartMoisChoisiSelect = this.state["DepartMoisChoisiSelect" + ClientConjoint];
    let MoisDepartDateChoisiInput = this.state["MoisDepartDateChoisiInput" + ClientConjoint];
    let AnneeDepartDateChoisiInput = this.state["AnneeDepartDateChoisiInput" + ClientConjoint];

    if (name === "DepartAgeLegal" || event.target.value === "") {
      ChoixDepartRetraite = "DepartAgeLegal";
      DepartAgeLegal = true;
    } else if (name === "DepartAgeChoisi") {
      ChoixDepartRetraite = "DepartAgeChoisi";
      DepartAgeChoisi = true;
    } else if (name === "DepartAgeChoisiSelect") {
      ChoixDepartRetraite = "DepartAgeChoisi";
      DepartAgeChoisiSelect = event.target.value;
      DepartAgeChoisi = true;
    } else if (name === "DepartMoisChoisiSelect") {
      ChoixDepartRetraite = "DepartAgeChoisi";
      DepartMoisChoisiSelect = event.target.value;
      DepartAgeChoisi = true;
    } else if (name === "DepartDateChoisi") {
      ChoixDepartRetraite = "DepartDateChoisi";
      DepartDateChoisi = true;
    } else if (name === "MoisDepartDateChoisiInput") {
      ChoixDepartRetraite = "DepartDateChoisi";
      DepartDateChoisi = true;
      MoisDepartDateChoisiInput = ("0" + event.target.value).slice(-2);
    } else if (name === "AnneeDepartDateChoisiInput") {
      ChoixDepartRetraite = "DepartDateChoisi";
      DepartDateChoisi = true;
      AnneeDepartDateChoisiInput = event.target.value;
    } else if (name === "DepartAgeTauxPlein") {
      ChoixDepartRetraite = "DepartAgeTauxPlein";
      DepartAgeTauxPlein = true;
    }

    this.setState(
      {
        ["ChoixDepartRetraite" + ClientConjoint]: ChoixDepartRetraite,
        ["DepartAgeLegal" + ClientConjoint]: DepartAgeLegal,
        ["DepartAgeChoisi" + ClientConjoint]: DepartAgeChoisi,
        ["DepartDateChoisi" + ClientConjoint]: DepartDateChoisi,
        ["DepartAgeTauxPlein" + ClientConjoint]: DepartAgeTauxPlein,
        ["DepartAgeChoisiSelect" + ClientConjoint]: DepartAgeChoisiSelect,
        ["DepartMoisChoisiSelect" + ClientConjoint]: DepartMoisChoisiSelect,
        ["MoisDepartDateChoisiInput" + ClientConjoint]: MoisDepartDateChoisiInput,
        ["AnneeDepartDateChoisiInput" + ClientConjoint]: AnneeDepartDateChoisiInput,
      },
      () => {
        if (ChoixDepartRetraite === "DepartDateChoisi") {
          if (MoisDepartDateChoisiInput.length === 2 && AnneeDepartDateChoisiInput.length === 4) {
            this.GetDateDepartRetraite(ClientConjoint, this.state);
          }
        } else {
          this.GetDateDepartRetraite(ClientConjoint, this.state);
        }
      }
    );
  };

  handleReformeRetraite = (ClientConjoint, name) => (event, checked) => {
    this.setState({ [name]: checked }, () => {
      this.GetDateDepartRetraite(ClientConjoint, this.state);
    });
  };
  GetDateDepartRetraite = (ClientConjoint, Data) => {
    let CleanData = RemoveUnecessaryData(Data);
    var formData = new FormData();
    formData.append("Data", JSON.stringify(CleanData));
    formData.append("ClientConjoint", ClientConjoint);
    axios({
      method: "post",
      url: `${APIURL}DateDepartRetraite.php`,
      data: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      withCredentials: true,
    })
      .catch(function (error) {
        if (parseInt(error.response.request.status) === 401) {
          var event = new CustomEvent("session-expired", {
            detail: {
              logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
            },
          });
          window.dispatchEvent(event);
        }
      })
      .then((res) => {
        let ChoixDepartRetraite = this.state["ChoixDepartRetraite" + ClientConjoint];
        let DepartAgeLegal = this.state["DepartAgeLegal" + ClientConjoint];
        let DepartAgeChoisi = this.state["DepartAgeChoisi" + ClientConjoint];
        let DepartDateChoisi = this.state["DepartDateChoisi" + ClientConjoint];
        let DepartAgeTauxPlein = this.state["DepartAgeTauxPlein" + ClientConjoint];
        let MoisDepartDateChoisiInput = this.state["MoisDepartDateChoisiInput" + ClientConjoint];
        let AnneeDepartDateChoisiInput = this.state["AnneeDepartDateChoisiInput" + ClientConjoint];
        let DateDepartRetraite = res.data.Date;
        var d = DateDepartRetraite.split("/");
        var DateVerif = new Date(d[2] + "-" + d[1] + "-" + d[0]);

        //Si date de depart inferieur a aujourd'hui+1 mois alors on force
        var DateAffich = res.data.DateAffichage;
        var now = new Date();
        var DateMini = new Date(now.getFullYear(), now.getMonth() + 1, 1);
        // if (this.state.TypeSimulation === "Audit retraite") {
        //   DateMini = new Date(now.getFullYear() - 1, now.getMonth(), 1);
        // }

        if (ChoixDepartRetraite === "DepartDateChoisi") {
          var ddd = DateAffich.split("/");
          MoisDepartDateChoisiInput = ddd[1];
          AnneeDepartDateChoisiInput = ddd[2];
        }
        // if (window.location.href.indexOf("test") === -1 && window.location.href.indexOf("localhost") === -1) {
        // console.log('STAT',this.state.IdUser)
        if (this.state.IdUser !== 2000 && this.state.IdUser !== 2158) {
          if (DateVerif.setHours(0, 0, 0, 0) <= DateMini.setHours(0, 0, 0, 0)) {
            DateDepartRetraite = DateMini;

            ChoixDepartRetraite = "DepartDateChoisi";
            DepartDateChoisi = true;
            DepartAgeLegal = false;
            DepartAgeChoisi = false;
            DepartAgeTauxPlein = false;
            DateAffich = new Date(DateMini);
            DateAffich = GetFormattedDate(DateAffich);
            var dd = DateAffich.split("/");
            MoisDepartDateChoisiInput = dd[1];
            AnneeDepartDateChoisiInput = dd[2];

            DateMini.setDate(DateMini.getDate() - 1);
            DateDepartRetraite = GetFormattedDate(DateMini);
          }
        }
        let PeriodeActuelle = this.state.PeriodeActuelle;
        let PeriodeActuelleConjoint = this.state.PeriodeActuelleConjoint;
        if (PeriodeActuelle.length > 0) {
          if (ClientConjoint === "") {
            if (PeriodeActuelle[0]["FinCarriereActuelle"] === "DepartRetraite") {
              PeriodeActuelle[0]["FinPeriodeCarriereActuelle"] = DateDepartRetraite.replaceAll("/", "");
            }
            if (PeriodeActuelle[0]["FinCarriereActuelle"] === "DepartRetraite" && PeriodeActuelle[0]["FinCarriereActuelleConjoint"] === "Client") {
              PeriodeActuelle[0]["FinPeriodeCarriereActuelleConjoint"] = DateDepartRetraite.replaceAll("/", "");
            }
          } else {
            if (PeriodeActuelle[0]["FinCarriereActuelleConjoint"] === "DepartRetraite") {
              PeriodeActuelle[0]["FinPeriodeCarriereActuelleConjoint"] = DateDepartRetraite.replaceAll("/", "");
            }
          }
        }
        if (PeriodeActuelleConjoint.length > 0 && ClientConjoint === "Conjoint") {
          if (PeriodeActuelleConjoint[0]["FinCarriereActuelle"] === "DepartRetraite") {
            PeriodeActuelleConjoint[0]["FinPeriodeCarriereActuelle"] = DateDepartRetraite.replaceAll("/", "");
          }
        }

        this.setState(
          {
            ["ChoixDepartRetraite" + ClientConjoint]: ChoixDepartRetraite,
            ["DateDepartRetraite" + ClientConjoint]: DateDepartRetraite,
            ["DateDepartRetraiteAffichage" + ClientConjoint]: DateAffich,
            PeriodeActuelle: PeriodeActuelle,
            PeriodeActuelleConjoint: PeriodeActuelleConjoint,
            ["FinPeriodeCarriereActuelle" + ClientConjoint]: DateDepartRetraite.replaceAll("/", ""),
            RetraiteFinActivite: DateDepartRetraite.replaceAll("/", ""),
            ["AgeDepartRetraite" + ClientConjoint]: res.data.Age,
            ["AgeDepartProjection" + ClientConjoint]: Number(res.data.Age),
            ["DepartAgeLegal" + ClientConjoint]: DepartAgeLegal,
            ["DepartAgeChoisi" + ClientConjoint]: DepartAgeChoisi,
            ["DepartAgeTauxPlein" + ClientConjoint]: DepartAgeTauxPlein,
            ["DepartDateChoisi" + ClientConjoint]: DepartDateChoisi,
            ["MoisDepartDateChoisiInput" + ClientConjoint]: MoisDepartDateChoisiInput,
            ["AnneeDepartDateChoisiInput" + ClientConjoint]: AnneeDepartDateChoisiInput,
          },
          () => {
            CalculRetraite(this.state, "", 0, ClientConjoint).then((ResultatRetraite) => {
              this.setState(
                {
                  ["DisplayRetraiteProgressive" + ClientConjoint]: ResultatRetraite.DisplayRetraiteProgressive,
                  ["Carriere" + ClientConjoint]: ResultatRetraite.RetraiteChoixDepartRetraite.Carriere,
                  ["AgeDepartRetraite" + ClientConjoint]: ResultatRetraite.AgeDepartRetraite,
                  ["TotalTrimestre" + ClientConjoint]: ResultatRetraite.TotalTrimestre,
                },
                () => {
                  if (this.state["ChoixDepartRetraite" + ClientConjoint] === "DepartAgeTauxPlein") {
                    var DateDepartRetraiteTauxPlein = ResultatRetraite.RetraiteChoixDepartRetraite.DateDepartTauxPlein;
                    DateAffich = ResultatRetraite.RetraiteChoixDepartRetraite.DateDepartTauxPlein;
                    let d = ResultatRetraite.RetraiteChoixDepartRetraite.DateDepartTauxPlein.split("/");
                    var DateVerif = new Date(d[2] + "-" + d[1] + "-" + d[0]);
                    var DateMini = new Date(now.getFullYear(), now.getMonth() + 1, 1);
                    if (this.state.IdUser !== 2000 && this.state.IdUser !== 2158) {
                      if (DateVerif.setHours(0, 0, 0, 0) <= DateMini.setHours(0, 0, 0, 0)) {
                        DateDepartRetraiteTauxPlein = DateMini;
                        ChoixDepartRetraite = "DepartDateChoisi";
                        DepartDateChoisi = true;
                        DepartAgeLegal = false;
                        DepartAgeChoisi = false;
                        DepartAgeTauxPlein = false;
                        DateAffich = new Date(DateMini);
                        DateAffich = GetFormattedDate(DateAffich);
                        var dd = DateAffich.split("/");
                        MoisDepartDateChoisiInput = dd[1];
                        AnneeDepartDateChoisiInput = dd[2];
                        DateMini.setDate(DateMini.getDate() - 1);
                        DateDepartRetraiteTauxPlein = GetFormattedDate(DateMini);
                      } else {
                        DateVerif.setDate(DateVerif.getDate() - 1);
                        DateDepartRetraiteTauxPlein = GetFormattedDate(DateVerif);
                      }
                    } else {
                      DateVerif.setDate(DateVerif.getDate() - 1);
                      DateDepartRetraiteTauxPlein = GetFormattedDate(DateVerif);
                    }
                    let PeriodeActuelle = this.state.PeriodeActuelle;
                    let PeriodeActuelleConjoint = this.state.PeriodeActuelleConjoint;
                    if (PeriodeActuelle.length > 0) {
                      if (ClientConjoint === "") {
                        PeriodeActuelle[0]["FinPeriodeCarriereActuelle"] = DateDepartRetraiteTauxPlein.replaceAll("/", "");
                        if (PeriodeActuelle[0]["FinCarriereActuelle"] === "DepartRetraite") {
                          PeriodeActuelle[0]["FinPeriodeCarriereActuelle"] = DateDepartRetraiteTauxPlein.replaceAll("/", "");
                        }
                        if (PeriodeActuelle[0]["FinCarriereActuelle"] === "DepartRetraite" && PeriodeActuelle[0]["FinCarriereActuelleConjoint"] === "Client") {
                          PeriodeActuelle[0]["FinPeriodeCarriereActuelleConjoint"] = DateDepartRetraiteTauxPlein.replaceAll("/", "");
                        }
                      } else {
                        if (PeriodeActuelle[0]["FinCarriereActuelleConjoint"] === "DepartRetraite") {
                          PeriodeActuelle[0]["FinPeriodeCarriereActuelleConjoint"] = DateDepartRetraiteTauxPlein.replaceAll("/", "");
                        }
                      }
                    }
                    if (PeriodeActuelleConjoint.length > 0 && ClientConjoint === "Conjoint") {
                      if (PeriodeActuelleConjoint[0]["FinCarriereActuelle"] === "DepartRetraite") {
                        PeriodeActuelleConjoint[0]["FinPeriodeCarriereActuelle"] = DateDepartRetraiteTauxPlein.replaceAll("/", "");
                      }
                    }
                    this.setState(
                      {
                        ["ChoixDepartRetraite" + ClientConjoint]: ChoixDepartRetraite,
                        ["DateDepartRetraite" + ClientConjoint]: DateDepartRetraiteTauxPlein,
                        ["DateDepartRetraiteAffichage" + ClientConjoint]: DateAffich,
                        PeriodeActuelle: PeriodeActuelle,
                        PeriodeActuelleConjoint: PeriodeActuelleConjoint,
                        ["FinPeriodeCarriereActuelle" + ClientConjoint]: DateDepartRetraiteTauxPlein.replaceAll("/", ""),
                        ["AgeDepartProjection" + ClientConjoint]: Number(ResultatRetraite.RetraiteChoixDepartRetraite.Infos.AgeDepartRetraite2),
                        ["AgeDepartRetraite" + ClientConjoint]: res.data.Age,
                        ["DepartAgeLegal" + ClientConjoint]: DepartAgeLegal,
                        ["DepartAgeChoisi" + ClientConjoint]: DepartAgeChoisi,
                        ["DepartAgeTauxPlein" + ClientConjoint]: DepartAgeTauxPlein,
                        ["DepartDateChoisi" + ClientConjoint]: DepartDateChoisi,
                        ["MoisDepartDateChoisiInput" + ClientConjoint]: MoisDepartDateChoisiInput,
                        ["AnneeDepartDateChoisiInput" + ClientConjoint]: AnneeDepartDateChoisiInput,
                      },
                      () => {
                        CalculRetraite(this.state, "", 0, ClientConjoint).then((ResultatRetraite) => {
                          this.setState({
                            ["Carriere" + ClientConjoint]: ResultatRetraite.RetraiteChoixDepartRetraite.Carriere,
                            ["AgeDepartProjection" + ClientConjoint]: Number(ResultatRetraite.RetraiteChoixDepartRetraite.Infos.AgeDepartRetraite2),
                            ["AgeDepartRetraite" + ClientConjoint]: ResultatRetraite.AgeDepartRetraite,
                            ["TotalTrimestre" + ClientConjoint]: ResultatRetraite.TotalTrimestre,
                          });
                        });
                      }
                    );
                  }
                  if (PeriodeActuelle.length > 0 && ClientConjoint === "" && PeriodeActuelle[0]["FinCarriereActuelleConjoint"] === "Client") {
                    this.handleCarriereActuelleConjointAssocie(PeriodeActuelle[0], "");
                  }
                }
              );
            });
          }
        );
      });
  };

  CarriereDateApresModif = (Carriere) => {
    return new Promise((resolve, reject) => {
      let DateDepartRetraite = this.state.DateDepartRetraite;

      const ModifCarriere = Carriere.filter((Periode) => Periode.CaseDepartRetraite === true);
      if (ModifCarriere.length === 0) {
        resolve(true);
      }
      ModificationCarriere(ModifCarriere, CurrentYear, DateDepartRetraite).then((CarriereModifie) => {
        const ResteCarriere = Carriere.filter((Periode) => Periode.CaseDepartRetraite === false);
        this.setState(
          {
            Carriere: [...ResteCarriere, ...CarriereModifie],
          },
          () => {
            resolve(true);
          }
        );
      });
    });
  };
  //CARRIERE RETRAITE

  handleResetPeriodeRetraite = (ClientConjoint, Outil) => {
    let RetraiteDebutActivite = this.state.RetraiteDebutActivite;
    let PremierePeriodeManuelle = this.state.PremierePeriodeManuelle;

    let Annee = this.state.DateNaissance.slice(-4);
    let Mois = this.state.DateNaissance.slice(2, 4);
    let Jour = this.state.DateNaissance.slice(0, 2);
    Annee = Number(Annee) + Number(20);
    RetraiteDebutActivite = Jour + Mois + Annee;

    if (this.state.PremierePeriodeManuelle === true) {
      PremierePeriodeManuelle = false;
    } else {
      if (Boolean(this.state.DateDernierePeriodeSaisi)) {
        Annee = this.state.DateDernierePeriodeSaisi.slice(-4);
        Mois = this.state.DateDernierePeriodeSaisi.slice(2, 4);
        Jour = this.state.DateDernierePeriodeSaisi.slice(0, 2);

        let date = new Date();
        date.setFullYear(Annee, Mois - 1, Jour);
        if (!isNaN(date)) {
          var NextDay = new Date(date.setTime(date.getTime() + 86400000)).toISOString().slice(0, 10);

          var dtDepartRetr = this.state["DateDepartRetraite" + ClientConjoint].replace(/\//g, "");
          let AnneeDepartRetr = dtDepartRetr.slice(-4);
          let MoisDepartRetr = dtDepartRetr.slice(2, 4);
          let JourDepartRetr = dtDepartRetr.slice(0, 2);
          let dateEnd = new Date();
          dateEnd.setFullYear(AnneeDepartRetr, MoisDepartRetr - 1, JourDepartRetr);
          var LAstDayPossible = new Date(dateEnd.setTime(dateEnd.getTime())).toISOString().slice(0, 10);
          if (NextDay > LAstDayPossible) {
            RetraiteDebutActivite = LAstDayPossible.slice(-2) + LAstDayPossible.slice(5, 7) + LAstDayPossible.slice(0, 4); //DATE AU FORMAT JJMMAAAA
          } else {
            RetraiteDebutActivite = NextDay.slice(-2) + NextDay.slice(5, 7) + NextDay.slice(0, 4); //DATE AU FORMAT JJMMAAAA
          }
        }
      }
    }

    if (InitialListeActiviteRetraite[0].options[0].value === "ActiviteSalarie") {
      InitialListeActiviteRetraite[0].options.shift();
    }
    this.setState({
      ListeActiviteRetraite: InitialListeActiviteRetraite,
      PremierePeriodeManuelle: PremierePeriodeManuelle,
      // RetraiteDebutActivite: RetraiteDebutActivite,
      RetraiteDebutActivite: "",
      RetraiteFinActivite: this.state[Outil + "DateDepartRetraite" + ClientConjoint].replace(/\//g, ""),
      RetraiteActivite: "SalarieNonCadre",
      RetraiteActiviteLabel: "Salarié non cadre du secteur privé",
      CheckboxDepartRetraite: true,
      IdPeriodeRetraite: "",
      RISAuto: "",
      DetailRetraitePasse: {},
      DetailRetraiteFutur: {},
      DisplayDeplafondPass: false,
      DisplayTrimestre: true,
      AllCheckboxTrimestre: false,
      TypeEvolution: "EvolutionPlat",
      MontantEvolution: 0,

      TauxActivite: 100,
      CFEAgircArrco: "Oui",
      ParamCotisations: RemExpertPeriodeRetraite.ParamCotisations,
    });
  };
  handleSelectActiviteRetraite = (name) => (event) => {
    let DisplayDeplafondPass = false;
    let DisplayTrimestre = true;
    if (event.target.value === "FonctionPublique" || event.target.value === "Crpcen") {
      DisplayTrimestre = false;
    }

    Object.keys(this.state.DetailRetraitePasse).forEach(function (Annee) {
      if (
        (event.target.value === "ActiviteSalarie" ||
          event.target.value === "SalarieNonCadre" ||
          event.target.value === "SalarieCadre" ||
          event.target.value === "SalarieNonCadreCFE" ||
          event.target.value === "SalarieCadreCFE" ||
          event.target.value === "SalarieNonCadreAgricole" ||
          event.target.value === "SalarieCadreAgricole" ||
          event.target.value === "CavecSalarie" ||
          event.target.value === "SalarieIrcantec") &&
        Annee < 2005
      ) {
        DisplayDeplafondPass = true;
      }
    });

    this.setState(
      {
        [name]: event.target.value,
        [name + "Label"]: event.label,
        DisplayDeplafondPass: DisplayDeplafondPass,
        DisplayTrimestre: DisplayTrimestre,
      },
      () => {
        this.handleCreationRevenusRetraite("RetraiteDebutActivite", this.state.RetraiteDebutActivite);
      }
    );
  };
  handleCreationRevenusRetraite = (name, value) => {
    // if (event.target.value.length === 8) {
    this.setState(
      {
        [name]: value,
        ValidatePeriode: false,
      },
      () => {
        let CheckboxDepartRetraite = this.state.CheckboxDepartRetraite;

        let { DebutPeriode, FinPeriode } = 0;
        if (name === "RetraiteDebutActivite") {
          DebutPeriode = value;
          FinPeriode = this.state.RetraiteFinActivite;
        } else if (name === "RetraiteFinActivite") {
          DebutPeriode = this.state.RetraiteDebutActivite;
          FinPeriode = value;
        } else {
          DebutPeriode = this.state.RetraiteDebutActivite;
          FinPeriode = this.state.RetraiteFinActivite;
        }

        if (DebutPeriode.length === 8) {
          let IsGoodDebutPeriode = "Oui";
          let MoisDebutPeriode = DebutPeriode.slice(2, 4) - 1;
          let JourDebutPeriode = DebutPeriode.slice(0, 2);

          if (Number(MoisDebutPeriode) > 11 || Number(JourDebutPeriode) > 31) {
            IsGoodDebutPeriode = "Non";
          }
          this.setState({
            IsGoodDebutPeriode: IsGoodDebutPeriode,
          });
        }
        if (FinPeriode.length === 8) {
          let IsGoodFinPeriode = "Oui";
          let MoisFinPeriode = FinPeriode.slice(2, 4) - 1;
          let JourFinPeriode = FinPeriode.slice(0, 2);

          if (Number(MoisFinPeriode) > 11 || Number(JourFinPeriode) > 31) {
            IsGoodFinPeriode = "Non";
          }
          this.setState({
            IsGoodFinPeriode: IsGoodFinPeriode,
          });
        }
        // console.log(DebutPeriode.length === 8, FinPeriode.length === 8, DebutPeriode);
        if (DebutPeriode.length === 8 && FinPeriode.length === 8) {
          let AnneeDebutPeriode = DebutPeriode.slice(4);
          let MoisDebutPeriode = DebutPeriode.slice(2, 4) - 1;
          let JourDebutPeriode = DebutPeriode.slice(0, 2);

          let DateDebutPeriode = new Date();
          DateDebutPeriode.setFullYear(AnneeDebutPeriode, MoisDebutPeriode, JourDebutPeriode);

          let DateFinPeriode = new Date();
          DateFinPeriode.setFullYear(FinPeriode.slice(4), FinPeriode.slice(2, 4) - 1, FinPeriode.slice(0, 2));

          DateDebutPeriode.setHours(0, 0, 0, 0);
          DateFinPeriode.setHours(24, 0, 0, 0);

          if (DateFinPeriode.getTime() >= DateDebutPeriode.getTime()) {
            console.log("DateFinPeriode is greater than DateDebutPeriode");
            CreationTableauDetailRetraite(DebutPeriode, FinPeriode, this.state.RetraiteActivite, this.state.DetailRetraitePasse, this.state.DetailRetraiteFutur).then((DetailRetraite) => {
              let DetailRetraiteFutur = DetailRetraite.DetailRetraiteFutur;

              if (Object.keys(DetailRetraite.DetailRetraiteFutur).length > 0) {
                const PremiereAnnee = Object.keys(DetailRetraite.DetailRetraiteFutur)[0];
                DetailRetraiteFutur = RevenusFutur(
                  PremiereAnnee,
                  DetailRetraite.DetailRetraiteFutur,
                  DetailRetraite.DetailRetraiteFutur[PremiereAnnee]["Salaire"],
                  this.state.MontantEvolution
                );
              }
              this.setState(
                {
                  DetailRetraitePasse: DetailRetraite.DetailRetraitePasse,
                  DetailRetraiteFutur: DetailRetraiteFutur,
                  DisplayDeplafondPass: DetailRetraite.DisplayDeplafondPass,
                  CheckboxDepartRetraite: CheckboxDepartRetraite,
                  ValidatePeriode: true,
                },
                () => {
                  CalculTrimestre(this.state).then((ListeTrimestre) => {
                    let NewDetailRetraitePasse = this.state.DetailRetraitePasse;
                    Object.keys(NewDetailRetraitePasse).forEach(function (Annee) {
                      if (NewDetailRetraitePasse[Annee]["CheckboxTrimestre"] === false) {
                        NewDetailRetraitePasse[Annee]["Trimestre"] = ListeTrimestre.ListeTrimestrePasse[Annee];
                      }
                    });

                    let NewDetailRetraite = this.state.DetailRetraiteFutur;
                    Object.keys(NewDetailRetraite).forEach(function (Annee) {
                      if (NewDetailRetraite[Annee]["CheckboxTrimestre"] === false) {
                        NewDetailRetraite[Annee]["Trimestre"] = ListeTrimestre.ListeTrimestreFutur[Annee];
                      }
                    });
                  });
                }
              );
            });
          } else {
            console.log("DateDebutPeriode is greater than DateFinPeriode", DateDebutPeriode, DateFinPeriode);
            this.setState({ OpenAlertDialogPeriodeRetraite: true });
          }
        }
      }
    );
    // }
  };

  handleResetCheckboxDepartRetraite = (event) => {
    this.setState({
      CheckboxDepartRetraite: true,
      RetraiteFinActivite: event.target.value.replace(/\//g, ""),
      DateDernierePeriodeSaisi: event.target.value.replace(/\//g, ""),
    });
  };
  handleCheckboxDepartRetraite = (ClientConjoint) => (event) => {
    let RetraiteFinActivite = this.state.RetraiteFinActivite;
    if (event.target.value === true) {
      RetraiteFinActivite = this.state["DateDepartRetraite" + ClientConjoint].replace(/\//g, "");
    }
    this.setState(
      {
        CheckboxDepartRetraite: event.target.value,
        RetraiteFinActivite: RetraiteFinActivite,
      },
      () => this.handleCreationRevenusRetraite("")
    );
  };
  handleCheckboxDetailRetraite = (Annee, check, CheckboxConcerne, DetailRetraiteConcerne) => {
    let NewDetailRetraite = this.state[DetailRetraiteConcerne];
    NewDetailRetraite[Annee][CheckboxConcerne] = check;
    this.setState({
      [DetailRetraiteConcerne]: NewDetailRetraite,
    });
  };
  handleSaisieTrimestre = (Annee, Value, DetailRetraiteConcerne) => {
    let NewDetailRetraite = this.state[DetailRetraiteConcerne];
    NewDetailRetraite[Annee]["Trimestre"] = Value;
    this.setState({
      [DetailRetraiteConcerne]: NewDetailRetraite,
    });
  };
  handleRevenusAuPASS = () => {
    let NewDetailRetraitePasse = this.state.DetailRetraitePasse;
    let NewDetailRetraiteFutur = this.state.DetailRetraiteFutur;
    const TabPass = this.state.TabPass;
    Object.keys(NewDetailRetraitePasse).forEach(function (Annee) {
      NewDetailRetraitePasse[Annee]["Salaire"] = Number((TabPass[Annee] / 365) * NewDetailRetraitePasse[Annee]["DureePeriode"]).toFixed(0);
    });
    Object.keys(NewDetailRetraiteFutur).forEach(function (Annee) {
      const Today = new Date();
      NewDetailRetraiteFutur[Annee]["Salaire"] = Number((TabPass[Math.min(Today.getFullYear(), Annee)] / 365) * NewDetailRetraiteFutur[Annee]["DureePeriode"]).toFixed(0);
    });
    this.setState(
      {
        DetailRetraitePasse: NewDetailRetraitePasse,
        DetailRetraiteFutur: NewDetailRetraiteFutur,
      },
      () => {
        CalculTrimestre(this.state).then((ListeTrimestre) => {
          let NewDetailRetraitePasse = this.state.DetailRetraitePasse;
          let NewDetailRetraiteFutur = this.state.DetailRetraiteFutur;
          Object.keys(NewDetailRetraitePasse).forEach(function (Annee) {
            if (NewDetailRetraitePasse[Annee]["CheckboxTrimestre"] === false) {
              NewDetailRetraitePasse[Annee]["Trimestre"] = ListeTrimestre.ListeTrimestrePasse[Annee];
            }
          });
          Object.keys(NewDetailRetraiteFutur).forEach(function (Annee) {
            if (NewDetailRetraiteFutur[Annee]["CheckboxTrimestre"] === false) {
              NewDetailRetraiteFutur[Annee]["Trimestre"] = ListeTrimestre.ListeTrimestreFutur[Annee];
            }
          });
          this.setState({
            DetailRetraitePasse: NewDetailRetraitePasse,
            DetailRetraiteFutur: NewDetailRetraiteFutur,
          });
        });
      }
    );
  };
  handleRevenusRetraitePasse = (Annee, event) => {
    let NewDetailRetraite = this.state.DetailRetraitePasse;
    NewDetailRetraite[Annee]["Salaire"] = Number(event.target.value).toFixed(0);
    this.setState({
      DetailRetraitePasse: NewDetailRetraite,
    });
  };
  handleCalculTrimestrePasse = (Annee, event) => {
    if (this.state.DetailRetraitePasse[Annee].CheckboxTrimestre === false) {
      CalculTrimestreAnnee(Annee, this.state.DetailRetraitePasse[Annee], this.state.RetraiteActivite, this.state.DetailRetraitePasse, this.state.TauxActivite).then((Trim) => {
        let DetailRetraite = this.state.DetailRetraitePasse;
        DetailRetraite[Annee].Trimestre = Trim;
        DetailRetraite[Annee].Trimestre = Trim;

        this.setState({
          DetailRetraitePasse: DetailRetraite,
        });
      });
    }
  };
  handleRevenusRetraiteFutur = (Annee, event) => {
    let NewDetailRetraite = this.state.DetailRetraiteFutur;
    NewDetailRetraite[Annee]["Salaire"] = Number(event.target.value.replace(/\D/g, "")).toFixed(0);
    const DetailRetraite = RevenusFutur(Annee, NewDetailRetraite, Number(event.target.value.replace(/\D/g, "")).toFixed(0), this.state.MontantEvolution);
    this.setState(
      {
        DetailRetraiteFutur: DetailRetraite,
      },
      () => {
        // this.handleCalculTrimestreFutur();
      }
    );
  };

  handleCalculTrimestreFutur = () => {
    // if (this.state.DetailRetraiteFutur[Annee].CheckboxTrimestre === false) {
    CalculTrimestre(this.state).then((ListeTrimestre) => {
      let NewDetailRetraite = this.state.DetailRetraiteFutur;
      Object.keys(NewDetailRetraite).forEach(function (Annee) {
        if (NewDetailRetraite[Annee]["CheckboxTrimestre"] === false) {
          NewDetailRetraite[Annee]["Trimestre"] = ListeTrimestre.ListeTrimestreFutur[Annee];
        }
      });
      this.setState({
        DetailRetraiteFutur: NewDetailRetraite,
      });
    });
    // }
  };
  handleChangeTypeEvolution = (TypeEvolution) => {
    let MontantEvolution = 0;
    switch (TypeEvolution) {
      case "EvolutionUp":
        MontantEvolution = 2;
        break;
      case "EvolutionDown":
        MontantEvolution = -2;
        break;
      default:
    }
    this.setState({
      TypeEvolution: TypeEvolution,
      MontantEvolution: MontantEvolution,
    });
  };

  handleCalculEvolutionRetraite = (event) => {
    let NewDetailRetraite = this.state.DetailRetraiteFutur;

    const FirstKey = Object.keys(NewDetailRetraite)[0];
    let NewRevenus = (Number(NewDetailRetraite[FirstKey]["Salaire"]) / NewDetailRetraite[FirstKey]["DureePeriode"]) * 365;
    NewRevenus = Number(NewRevenus).toFixed(0);
    const DetailRetraite = RevenusFutur(FirstKey, NewDetailRetraite, NewRevenus, event.target.value);

    let TypeEvolution = "EvolutionPlat";
    if (event.target.value > 0) {
      TypeEvolution = "EvolutionUp";
    } else if (event.target.value < 0) {
      TypeEvolution = "EvolutionDown";
    }

    this.setState(
      {
        TypeEvolution: TypeEvolution,
        MontantEvolution: event.target.value,
        DetailRetraiteFutur: DetailRetraite,
      },
      () => {
        CalculTrimestre(this.state).then((ListeTrimestre) => {
          let NewDetailRetraite = this.state.DetailRetraiteFutur;
          Object.keys(NewDetailRetraite).forEach(function (Annee) {
            if (NewDetailRetraite[Annee]["CheckboxTrimestre"] === false) {
              NewDetailRetraite[Annee]["Trimestre"] = ListeTrimestre.ListeTrimestreFutur[Annee];
            }
          });
          this.setState({
            DetailRetraiteFutur: NewDetailRetraite,
          });
        });
      }
    );
  };

  handleParametrePeriode = (name) => (event) => {
    let ParamPeriode = this.state.ParamCotisations;
    ParamPeriode[name] = event.target.value;
    this.setState({
      ParamCotisations: ParamPeriode,
    });
  };

  handleParametrePeriodeCheck = (name) => (event, checked) => {
    let ParamPeriode = this.state.ParamCotisations;
    ParamPeriode[name] = checked;
    this.setState({
      ParamCotisations: ParamPeriode,
    });
  };

  handleTableauMoyenneProduit = (index, Annee, event) => {
    let ParamPeriode = this.state.ParamCotisations;
    let TabMoyenneProduitEtudeCrn = _.cloneDeep(this.state.ParamCotisations["TabMoyenneProduitEtudeCrn"]);

    //CREATION DU TABLEAU
    if (Object.keys(TabMoyenneProduitEtudeCrn).length === 0) {
      const TabAnnee = Object.keys(this.state.DetailRetraiteFutur);
      TabAnnee.forEach((Annee) => {
        TabMoyenneProduitEtudeCrn[Annee] = {};
        TabMoyenneProduitEtudeCrn[Annee]["MoyenneProduit"] = 0;
      });
    }

    //MODIFICATION DU TABLEAU
    const TabDetailRetraiteFutur = Object.keys(this.state.DetailRetraiteFutur);
    const TabAnneeModifTableau = Object.keys(TabMoyenneProduitEtudeCrn);
    //PERIODE PLUS COURTE
    if (TabDetailRetraiteFutur.length < TabAnneeModifTableau.length) {
      TabAnneeModifTableau.forEach((AnneeTab) => {
        if (!TabDetailRetraiteFutur.includes(AnneeTab)) {
          delete TabMoyenneProduitEtudeCrn[AnneeTab];
        }
      });
    }
    //PERIODE PLUS LONGUE
    if (TabDetailRetraiteFutur.length > TabAnneeModifTableau.length) {
      TabDetailRetraiteFutur.forEach((AnneeTab) => {
        if (!TabAnneeModifTableau.includes(AnneeTab)) {
          TabMoyenneProduitEtudeCrn[AnneeTab] = {};
          TabMoyenneProduitEtudeCrn[AnneeTab]["MoyenneProduit"] = 0;
        }
      });
    }

    const TabAnnee = Object.keys(TabMoyenneProduitEtudeCrn);
    TabAnnee.forEach((AnneeTab) => {
      if (AnneeTab >= Annee) {
        TabMoyenneProduitEtudeCrn[AnneeTab]["MoyenneProduit"] = event.target.value;
      }
    });

    ParamPeriode["TabMoyenneProduitEtudeCrn"] = TabMoyenneProduitEtudeCrn;
    this.setState({
      ParamPeriode: ParamPeriode,
    });
  };

  handleTableauCommission = (index, Annee, event) => {
    let ParamPeriode = this.state.ParamCotisations;
    let TabCommissionBruteCavamac = _.cloneDeep(this.state.ParamCotisations["TabCommissionBruteCavamac"]);

    //CREATION DU TABLEAU
    if (Object.keys(TabCommissionBruteCavamac).length === 0) {
      const TabAnnee = Object.keys(this.state.DetailRetraiteFutur);
      TabAnnee.forEach((Annee) => {
        TabCommissionBruteCavamac[Annee] = {};
        TabCommissionBruteCavamac[Annee]["CommissionBrute"] = 0;
      });
    }
console.log('TAB',TabCommissionBruteCavamac)
    //MODIFICATION DU TABLEAU
    const TabDetailRetraiteFutur = Object.keys(this.state.DetailRetraiteFutur);
    const TabAnneeModifTableau = Object.keys(TabCommissionBruteCavamac);
    //PERIODE PLUS COURTE
    if (TabDetailRetraiteFutur.length < TabAnneeModifTableau.length) {
      TabAnneeModifTableau.forEach((AnneeTab) => {
        if (!TabDetailRetraiteFutur.includes(AnneeTab)) {
          delete TabCommissionBruteCavamac[AnneeTab];
        }
      });
    }
    //PERIODE PLUS LONGUE
    if (TabDetailRetraiteFutur.length > TabAnneeModifTableau.length) {
      TabDetailRetraiteFutur.forEach((AnneeTab) => {
        if (!TabAnneeModifTableau.includes(AnneeTab)) {
          TabCommissionBruteCavamac[AnneeTab] = {};
          TabCommissionBruteCavamac[AnneeTab]["CommissionBrute"] = 0;
        }
      });
    }

    const TabAnnee = Object.keys(TabCommissionBruteCavamac);
    TabAnnee.forEach((AnneeTab) => {
      if (AnneeTab >= Annee) {
        TabCommissionBruteCavamac[AnneeTab]["CommissionBrute"] = event.target.value;
      }
    });

    ParamPeriode["TabCommissionBruteCavamac"] = TabCommissionBruteCavamac;
    this.setState({
      ParamPeriode: ParamPeriode,
    });
  };
  handleAddPeriodeRetraite = (id, Outil, ClientConjoint) => {
    let Carriere = this.state[Outil + "Carriere" + ClientConjoint];
    let IdPeriodeRetraite = id;
    if (IdPeriodeRetraite.length === 0) {
      IdPeriodeRetraite = Date.now();
    } else {
      Carriere = Carriere.filter((Periode) => Periode.id !== id);
    }

    const newPeriodeRetraite = [
      {
        id: IdPeriodeRetraite,
        Debut: this.state.RetraiteDebutActivite,
        Fin: this.state.RetraiteFinActivite,
        FinSort: this.state.RetraiteFinActivite.slice(4) + this.state.RetraiteFinActivite.slice(2, 4) + this.state.RetraiteFinActivite.slice(0, 2),
        Nature: this.state.RetraiteActivite,
        TauxActivite: this.state.TauxActivite,
        CFEAgircArrco: this.state.CFEAgircArrco,
        RISAuto: this.state.RISAuto,
        ParamCotisations: this.state.ParamCotisations,
        DetailRetraitePasse: this.state.DetailRetraitePasse,
        DetailRetraiteFutur: this.state.DetailRetraiteFutur,
        Detail: {
          ...this.state.DetailRetraitePasse,
          ...this.state.DetailRetraiteFutur,
        },
        CaseDepartRetraite: this.state.CheckboxDepartRetraite,
        TypeEvolution: this.state.TypeEvolution,
        MontantEvolution: this.state.MontantEvolution,
        DisplayDeplafondPass: this.state.DisplayDeplafondPass,
        DisplayTrimestre: this.state.DisplayTrimestre,
        AllCheckboxTrimestre: this.state.AllCheckboxTrimestre,
      },
    ];
    Carriere = [...Carriere, ...newPeriodeRetraite];
    Carriere.sort(SortCarriereRetraite);
    this.setState(
      {
        DateDernierePeriodeSaisi: this.state.RetraiteFinActivite,
        [Outil + "Carriere" + ClientConjoint]: Carriere,
      },
      () => {
        this.handleResetPeriodeRetraite(ClientConjoint, Outil);
        if (Outil === "CumulEmploi") {
          this.handleCalculCumulEmploiRetraite(ClientConjoint, this.state);
        } else {
          this.VerifPointRetraite();
          this.CalculMajorationEnfant(this.state["Civilite" + ClientConjoint], this.state.Enfant, this.state["Carriere" + ClientConjoint], this.state.TableauEnfant, ClientConjoint, [
            this.state["CarriereMajorationEnfantSaisi" + ClientConjoint],
            this.state["CarriereMajorationEnfantSaisiFonctionPublique" + ClientConjoint],
            this.state["TrimestreBonificationEnfantSaisiFonctionPublique" + ClientConjoint],
            this.state["CarriereMajorationEnfantSaisiCrpcen" + ClientConjoint],
          ]);
          // if (id !== "CarriereActuelle") {
          this.GetDateDepartRetraite(ClientConjoint, this.state);
          // }
        }
      }
    );
  };

  handleModifyDeletePeriodeRetraite = (id, option, Outil, ClientConjoint) => {
    let Carriere = this.state[Outil + "Carriere" + ClientConjoint];
    if (option === "Modifier") {
      const PeriodeToDisplay = Carriere.filter((Periode) => Periode.id === id);
      let ListeActiviteRetraite = _.cloneDeep(InitialListeActiviteRetraite);

      if (PeriodeToDisplay[0].Nature === "ActiviteSalarie") {
        ListeActiviteRetraite[0].options.unshift({
          label: "Activité salariée",
          value: "ActiviteSalarie",
        });
      }
      PeriodeToDisplay.forEach((Periode) => {
        this.setState(
          {
            ValidatePeriode: true,
            ListeActiviteRetraite: ListeActiviteRetraite,
            IdPeriodeRetraite: Periode.id,
            RetraiteDebutActivite: Periode.Debut,
            RetraiteFinActivite: Periode.Fin,
            RetraiteActivite: Periode.Nature,
            RetraiteActiviteLabel: InitialListeActiviteRetraite2.filter((Liste) => Liste.value === Periode.Nature)[0].label,
            CheckboxDepartRetraite: Periode.CaseDepartRetraite,
            DetailRetraitePasse: Periode.DetailRetraitePasse,
            DetailRetraiteFutur: Periode.DetailRetraiteFutur,
            DisplayDeplafondPass: Periode.DisplayDeplafondPass,
            DisplayTrimestre: Periode.DisplayTrimestre,
            AllCheckboxTrimestre: Periode.AllCheckboxTrimestre,
            TypeEvolution: Periode.TypeEvolution,
            MontantEvolution: Periode.MontantEvolution,
            RISAuto: Periode.RISAuto,

            TauxActivite: Periode.TauxActivite,
            CFEAgircArrco: Periode.CFEAgircArrco,
            ParamCotisations: {
              classe_retraiteCarpv: Periode.ParamCotisations.classe_retraiteCarpv,
              ClasseRetr: Periode.ParamCotisations.ClasseRetr,
              ClasseRetraiteCrn: Periode.ParamCotisations.ClasseRetraiteCrn,
              TabMoyenneProduitEtudeCrn: Periode.ParamCotisations.TabMoyenneProduitEtudeCrn,
              CrnSermentAvt2014: Periode.ParamCotisations.CrnSermentAvt2014,
              CrnColmarMetz: Periode.ParamCotisations.CrnColmarMetz,
              Conventionne: Periode.ParamCotisations.Conventionne,
              BiologisteConventionne: Periode.ParamCotisations.BiologisteConventionne,
              TauxCpam: Periode.ParamCotisations.TauxCpam,
              CotisationCapi: Periode.ParamCotisations.CotisationCapi,
              ConventionneCarmf: Periode.ParamCotisations.ConventionneCarmf,
              ClasseRetraiteCnbfAvant2021: Periode.ParamCotisations.ClasseRetraiteCnbfAvant2021,
              ClasseRetraiteCnbf: Periode.ParamCotisations.ClasseRetraiteCnbf,
              StatutIrcantec: Periode.ParamCotisations.StatutIrcantec,
              OptionTrimSpecifique: Periode.ParamCotisations.OptionTrimSpecifique,
              TypeTrimSpecifique: Periode.ParamCotisations.TypeTrimSpecifique,
              CavecSalarie: Periode.ParamCotisations.CavecSalarie,
              ChomageAgirArrco: Periode.ParamCotisations.ChomageAgirArrco,
              ChomageSJR: Periode.ParamCotisations.ChomageSJR,
              Ravgdt: Periode.ParamCotisations.Ravgdt,
              RavgdtRemises: Periode.ParamCotisations.RavgdtRemises,
              MaladieInvalAgircArrco: Periode.ParamCotisations.MaladieInvalAgircArrco,
              MaladieInvalPoints: Periode.ParamCotisations.MaladieInvalPoints,
              TabCommissionBruteCavamac: Periode.ParamCotisations.TabCommissionBruteCavamac,
              AgircArrcoT1Sal: Periode.ParamCotisations.AgircArrcoT1Sal,
              AgircArrcoT1Pat: Periode.ParamCotisations.AgircArrcoT1Pat,
              AgircArrcoT2Sal: Periode.ParamCotisations.AgircArrcoT2Sal,
              AgircArrcoT2Pat: Periode.ParamCotisations.AgircArrcoT2Pat,
              IrcantecT1Sal: Periode.ParamCotisations.IrcantecT1Sal,
              IrcantecT1Pat: Periode.ParamCotisations.IrcantecT1Pat,
              IrcantecT2Sal: Periode.ParamCotisations.IrcantecT2Sal,
              IrcantecT2Pat: Periode.ParamCotisations.IrcantecT2Pat,
            },
          },
          () => {
            CalculTrimestre(this.state).then((ListeTrimestre) => {
              let NewDetailRetraiteFutur = this.state.DetailRetraiteFutur;
              let NewDetailRetraitePasse = this.state.DetailRetraitePasse;
              Object.keys(NewDetailRetraiteFutur).forEach(function (Annee) {
                if (NewDetailRetraiteFutur[Annee]["CheckboxTrimestre"] === false) {
                  NewDetailRetraiteFutur[Annee]["Trimestre"] = ListeTrimestre.ListeTrimestreFutur[Annee];
                }
              });
              Object.keys(NewDetailRetraitePasse).forEach(function (Annee) {
                if (NewDetailRetraitePasse[Annee]["CheckboxTrimestre"] === false) {
                  NewDetailRetraitePasse[Annee]["Trimestre"] = ListeTrimestre.ListeTrimestrePasse[Annee];
                }
              });
              this.setState({
                DetailRetraitePasse: NewDetailRetraitePasse,
                DetailRetraiteFutur: NewDetailRetraiteFutur,
              });
            });
          }
        );
      });
    } else if (option === "Supprimer") {
      Carriere = Carriere.filter((Periode) => Periode.id !== id);
      this.setState({ [Outil + "Carriere" + ClientConjoint]: Carriere }, () => {
        if (Outil === "CumulEmploi") {
          this.handleCalculCumulEmploiRetraite(ClientConjoint, this.state);
        } else {
          this.VerifPointRetraite();
          this.CalculMajorationEnfant(this.state["Civilite" + ClientConjoint], this.state.Enfant, this.state["Carriere" + ClientConjoint], this.state.TableauEnfant, ClientConjoint, [
            this.state["CarriereMajorationEnfantSaisi" + ClientConjoint],
            this.state["CarriereMajorationEnfantSaisiFonctionPublique" + ClientConjoint],
            this.state["TrimestreBonificationEnfantSaisiFonctionPublique" + ClientConjoint],
            this.state["CarriereMajorationEnfantSaisiCrpcen" + ClientConjoint],
          ]);
          this.GetDateDepartRetraite(ClientConjoint, this.state);
        }
      });
    }
  };

  handleAddRIS = (RIS, ClientConjoint) => {
    let Carriere = [...this.state["Carriere" + ClientConjoint]];
    Carriere = Carriere.filter((Periode) => Periode.id === "CarriereActuelle");
    Carriere = [...Carriere, ...RIS.Carriere];
    // Object.entries(RIS.Carriere).map((data, index) => {
    //   Carriere = [...Carriere, ...[data[1]]];
    // });
    Carriere.sort(SortCarriereRetraite);
    let IndiceMajorePerso = this.state["IndiceMajorePerso" + ClientConjoint];
    let IndiceMajoreValeur = this.state["IndiceMajoreValeur" + ClientConjoint];

    if (Boolean(RIS.IFC)) {
      IndiceMajorePerso = true;
      IndiceMajoreValeur = RIS.IFC;
    }

    let ChoixDepartRetraite = this.state["ChoixDepartRetraite" + ClientConjoint];
    let DepartAgeLegal = this.state["DepartAgeLegal" + ClientConjoint];
    let DepartAgeChoisi = this.state["DepartAgeChoisi" + ClientConjoint];
    let DepartDateChoisi = this.state["DepartDateChoisi" + ClientConjoint];
    let DepartAgeTauxPlein = this.state["DepartAgeTauxPlein" + ClientConjoint];
    let MoisDepartDateChoisiInput = this.state["MoisDepartDateChoisiInput" + ClientConjoint];
    let AnneeDepartDateChoisiInput = this.state["AnneeDepartDateChoisiInput" + ClientConjoint];

    if (RIS.ForceDateDepartTrimestreCivil === true) {
      ChoixDepartRetraite = "DepartDateChoisi";
      DepartAgeLegal = false;
      DepartAgeChoisi = false;
      DepartDateChoisi = true;
      DepartAgeTauxPlein = false;
      MoisDepartDateChoisiInput = RIS.MoisDepartRetraite;
      AnneeDepartDateChoisiInput = RIS.AnneeDepartRetraite;
    }

    this.setState(
      {
        ["Carriere" + ClientConjoint]: Carriere,
        ["TableauPoints" + ClientConjoint]: RIS.TableauPoints,
        ["IndiceMajorePerso" + ClientConjoint]: IndiceMajorePerso,
        ["IndiceMajoreValeur" + ClientConjoint]: IndiceMajoreValeur,
        ["ChoixDepartRetraite" + ClientConjoint]: ChoixDepartRetraite,
        ["DepartAgeLegal" + ClientConjoint]: DepartAgeLegal,
        ["DepartAgeChoisi" + ClientConjoint]: DepartAgeChoisi,
        ["DepartDateChoisi" + ClientConjoint]: DepartDateChoisi,
        ["DepartAgeTauxPlein" + ClientConjoint]: DepartAgeTauxPlein,
        ["MoisDepartDateChoisiInput" + ClientConjoint]: MoisDepartDateChoisiInput,
        ["AnneeDepartDateChoisiInput" + ClientConjoint]: AnneeDepartDateChoisiInput,
      },
      () => {
        this.CalculMajorationEnfant(this.state["Civilite" + ClientConjoint], this.state.Enfant, this.state["Carriere" + ClientConjoint], this.state.TableauEnfant, ClientConjoint, [
          this.state.CarriereMajorationEnfantSaisi,
          this.state.CarriereMajorationEnfantSaisiFonctionPublique,
          this.state.TrimestreBonificationEnfantSaisiFonctionPublique,
          this.state.CarriereMajorationEnfantSaisiCrpcen,
        ]);
        // this.CalculMajorationEnfant(this.state['Civilite'+ClientConjoint], this.state.Enfant, this.state['Carriere'+ClientConjoint], this.state.TableauEnfant,ClientConjoint, [
        //   this.state['CarriereMajorationEnfantSaisi'+ClientConjoint],
        //   this.state["CarriereMajorationEnfantSaisiFonctionPublique"+ClientConjoint],
        //   this.state["TrimestreBonificationEnfantSaisiFonctionPublique"+ClientConjoint],
        //   this.state["CarriereMajorationEnfantSaisiCrpcen"+ClientConjoint],
        // ]);
        this.GetDateDepartRetraite(ClientConjoint, this.state);
      }
    );
  };
  handleAddReleveRG = (Carriere, ClientConjoint) => {
    this.setState(
      {
        ["Carriere" + ClientConjoint]: Carriere,
      },
      () => {
        this.CalculMajorationEnfant(this.state["Civilite" + ClientConjoint], this.state.Enfant, this.state["Carriere" + ClientConjoint], this.state.TableauEnfant, ClientConjoint, [
          this.state.CarriereMajorationEnfantSaisi,
          this.state.CarriereMajorationEnfantSaisiFonctionPublique,
          this.state.TrimestreBonificationEnfantSaisiFonctionPublique,
          this.state.CarriereMajorationEnfantSaisiCrpcen,
        ]);
        this.GetDateDepartRetraite(ClientConjoint, this.state);
      }
    );
  };
  //POINTS RETRAITE
  VerifPointRetraite = () => {
    const TableauPoints = HandlerVerifPointRetraite({ ...this.state.TableauPoints }, this.state.Carriere);
    this.setState({ TableauPoints: TableauPoints });
  };
  handlePointRetraite = (ClientConjoint, name, event) => {
    let TableauPoints = this.state["TableauPoints" + ClientConjoint];
    TableauPoints[name] = event.target.value;
    if (name === "AnneeFinCavpSupp" && event.target.value === 2022) {
      TableauPoints["RetraiteSuppCavp"] = TableauPoints["RetraiteSuppCavp"] - TableauPoints["RetraiteSuppCavp2023"];
      TableauPoints["RetraiteSuppCavp2023"] = 0;
    }
    this.setState({
      ["TableauPoints" + ClientConjoint]: TableauPoints,
    });
  };
  handleModalPoint = (ClientConjoint, name) => (event) => {
    let TableauPoints = this.state["TableauPoints" + ClientConjoint];
    TableauPoints[name] = event.target.value;
    this.setState(
      {
        ["TableauPoints" + ClientConjoint]: TableauPoints,
      },
      () => {
        if (name.includes("RetraiteCompArrco")) {
          let TotalArrco = Number(TableauPoints.RetraiteCompArrcoAvant1999) + Number(TableauPoints.RetraiteCompArrco19992011) + Number(TableauPoints.RetraiteCompArrcoApres2012);
          TableauPoints["RetraiteCompArrco"] = Number(TotalArrco).toFixed(2);
        }
        if (name.includes("RetraiteCompAgircTB")) {
          let TotalAgircTB = Number(TableauPoints.RetraiteCompAgircTBAvant2012) + Number(TableauPoints.RetraiteCompAgircTBApres2012);
          TableauPoints["RetraiteCompAgircTB"] = Number(TotalAgircTB).toFixed(2);
        }
        if (name.includes("RetraiteCompAgircTC")) {
          let TotalAgircTC = Number(TableauPoints.RetraiteCompAgircTCAvant2012) + Number(TableauPoints.RetraiteCompAgircTC20122015) + Number(TableauPoints.RetraiteCompAgircTC2016);
          TableauPoints["RetraiteCompAgircTC"] = Number(TotalAgircTC).toFixed(2);
        }
        if (name.includes("RetraiteCompCavec")) {
          let TotalCavec = Number(TableauPoints.RetraiteCompCavecAvant2009) + Number(TableauPoints.RetraiteCompCavecApres2009);
          TableauPoints["RetraiteCompCavec"] = Number(TotalCavec).toFixed(2);
        }
        if (name.includes("RetraiteSuppCarcdsf")) {
          let TotalCarcdsf = Number(TableauPoints.RetraiteSuppCarcdsfAvant1995) + Number(TableauPoints.RetraiteSuppCarcdsf19952005) + Number(TableauPoints.RetraiteSuppCarcdsfApres2006);
          TableauPoints["RetraiteSuppCarcdsf"] = Number(TotalCarcdsf).toFixed(2);
        }
        if (name.includes("RetraiteSuppCarcdsf2")) {
          let TotalCarcdsf2 = Number(TableauPoints.RetraiteSuppCarcdsf2Avant1995) + Number(TableauPoints.RetraiteSuppCarcdsf219952005) + Number(TableauPoints.RetraiteSuppCarcdsf2Apres2006);
          TableauPoints["RetraiteSuppCarcdsf2"] = Number(TotalCarcdsf2).toFixed(2);
        }
        if (name.includes("RetraiteSuppCarpimko")) {
          let TotalCarpimko =
            Number(TableauPoints.RetraiteSuppCarpimko19601975) +
            Number(TableauPoints.RetraiteSuppCarpimko19761987) +
            Number(TableauPoints.RetraiteSuppCarpimko19881997) +
            Number(TableauPoints.RetraiteSuppCarpimko19982005) +
            Number(TableauPoints.RetraiteSuppCarpimkoApres2005);
          TableauPoints["RetraiteSuppCarpimko"] = Number(TotalCarpimko).toFixed(2);
        }
        if (name.includes("RetraiteSuppCavp")) {
          let TotalCavp =
            Number(TableauPoints.RetraiteSuppCavp19771981) +
            Number(TableauPoints.RetraiteSuppCavp19821986) +
            Number(TableauPoints.RetraiteSuppCavp19871991) +
            Number(TableauPoints.RetraiteSuppCavp19921996) +
            Number(TableauPoints.RetraiteSuppCavp19972001) +
            Number(TableauPoints.RetraiteSuppCavp20022005) +
            Number(TableauPoints.RetraiteSuppCavpApres2006);
          TableauPoints["RetraiteSuppCavp"] = Number(TotalCavp).toFixed(2);
        }
        if (name.includes("RetraiteCompRsiArtisan")) {
          let TotalRsiArtisan =
            Number(TableauPoints.RetraiteCompRsiArtisanAvant1979) + Number(TableauPoints.RetraiteCompRsiArtisanAvant1997) + Number(TableauPoints.RetraiteCompRsiArtisanApres1997);
          TableauPoints["RetraiteCompRsiArtisan"] = Number(TotalRsiArtisan).toFixed(2);
        }
        if (name.includes("RetraiteCompRsiArtisan") || name.includes("RetraiteCompRsiCommercant") || name.includes("RetraiteCompRCI")) {
          let TotalRCI = Number(TableauPoints.RetraiteCompRsiArtisan) + Number(TableauPoints.PointRetraiteCompRsiCommercantAvant2013) + Number(TableauPoints.RetraiteCompRCI);
          TableauPoints["RetraiteTotalRCI"] = Number(TotalRCI).toFixed(2);
        }
        this.setState({
          ["TableauPoints" + ClientConjoint]: TableauPoints,
        });
      }
    );
  };

  //MAJORATION RETRAITE
  handleCarriereMajorationEnfantSaisi = (name, ClientConjoint, value) => {
    let MajorationEnfantSaisi = true;
    if (value === "") {
      MajorationEnfantSaisi = false;
    }
    this.setState({ [name]: MajorationEnfantSaisi }, () => {
      this.CalculMajorationEnfant(this.state["Civilite" + ClientConjoint], this.state.Enfant, this.state["Carriere" + ClientConjoint], this.state.TableauEnfant, ClientConjoint, [
        this.state["CarriereMajorationEnfantSaisi" + ClientConjoint],
        this.state["CarriereMajorationEnfantSaisiFonctionPublique" + ClientConjoint],
        this.state["TrimestreBonificationEnfantSaisiFonctionPublique" + ClientConjoint],
        this.state["CarriereMajorationEnfantSaisiCrpcen" + ClientConjoint],
      ]);
    });
  };
  CalculMajorationEnfant = (Civilite, Enfant, Carriere, TableauEnfant, ClientConjoint, MajorationEnfantSaisi) => {
    if (Civilite === "Madame" && Number(Enfant) > 0) {
      const MajorationEnfantSaisiRegimeGeneral = MajorationEnfantSaisi[0];
      const MajorationEnfantSaisiFonctionPublique = MajorationEnfantSaisi[1];
      const BonificationEnfantSaisiFonctionPublique = MajorationEnfantSaisi[2];
      const MajorationEnfantSaisiCrpcen = MajorationEnfantSaisi[3];
      if (
        MajorationEnfantSaisiRegimeGeneral === false &&
        MajorationEnfantSaisiFonctionPublique === false &&
        BonificationEnfantSaisiFonctionPublique === false &&
        MajorationEnfantSaisiCrpcen === false
      ) {
        var CarriereRegimeGeneral = "Non";
        if (Carriere.length === 0 || Carriere.filter((Carriere) => Carriere.Nature !== "FonctionPublique" && Carriere.Nature !== "Crpcen").length > 0) {
          CarriereRegimeGeneral = "Oui";
        }
        var CarriereFonctionPublique = "Non";
        if (Carriere.filter((Carriere) => Carriere.Nature === "FonctionPublique").length > 0) {
          CarriereFonctionPublique = "Oui";
        }
        var CarriereCrpcen = "Non";
        if (Carriere.filter((Carriere) => Carriere.Nature === "Crpcen").length > 0) {
          CarriereCrpcen = "Oui";
        }

        let MajoEnfantRegimeGeneral = this.state["CarriereMajorationEnfant" + ClientConjoint];
        let MajoEnfantFonctionPublique = this.state["CarriereMajorationEnfantFonctionPublique" + ClientConjoint];
        let TrimestreBonificationEnfantFonctionPublique = this.state["TrimestreBonificationEnfantFonctionPublique" + ClientConjoint];
        let MajoEnfantCrpcen = this.state["CarriereMajorationEnfantCrpcen" + ClientConjoint];

        if (MajorationEnfantSaisiRegimeGeneral === false && CarriereRegimeGeneral === "Oui") {
          MajoEnfantFonctionPublique = 0;
          TrimestreBonificationEnfantFonctionPublique = 0;
          MajoEnfantCrpcen = 0;
          MajoEnfantRegimeGeneral = Number(8) * Number(Enfant);
        } else if (MajorationEnfantSaisiFonctionPublique === false && BonificationEnfantSaisiFonctionPublique === false && CarriereFonctionPublique === "Oui" && CarriereCrpcen === "Non") {
          MajoEnfantRegimeGeneral = 0;
          MajoEnfantCrpcen = 0;

          let NbEnfantsAvant2004 = 0;
          let NbEnfantsApres2004 = 0;
          TableauEnfant.forEach((Enfant) => {
            let AnneeNaissanceEnfant = Enfant.DateNaissance.slice(-4);
            if (AnneeNaissanceEnfant < 2004) {
              NbEnfantsAvant2004++;
            } else {
              NbEnfantsApres2004++;
            }
          });
          MajoEnfantFonctionPublique = Number(2) * Number(NbEnfantsApres2004);
          TrimestreBonificationEnfantFonctionPublique = Number(4) * Number(NbEnfantsAvant2004);
        } else if (MajorationEnfantSaisiCrpcen === false && CarriereCrpcen === "Oui" && CarriereFonctionPublique === "Non") {
          MajoEnfantRegimeGeneral = 0;
          MajoEnfantFonctionPublique = 0;
          TrimestreBonificationEnfantFonctionPublique = 0;

          let NbEnfantsAvant2006 = 0;
          TableauEnfant.forEach((Enfant) => {
            let AnneeNaissanceEnfant = Enfant.DateNaissance.slice(-4);
            let MoisNaissanceEnfant = Number(Enfant.DateNaissance.slice(2, 4));
            if (AnneeNaissanceEnfant < 2006) {
              NbEnfantsAvant2006++;
            } else if (AnneeNaissanceEnfant === 2006 && MoisNaissanceEnfant < 7) {
              NbEnfantsAvant2006++;
            }
          });
          MajoEnfantCrpcen = Number(4) * Number(NbEnfantsAvant2006);
        }

        // console.log("MAJO", Civilite, Enfant, MajoEnfantRegimeGeneral, MajoEnfantFonctionPublique, TrimestreBonificationEnfantFonctionPublique, MajoEnfantCrpcen);

        this.setState({
          ["CarriereMajorationEnfant" + ClientConjoint]: MajoEnfantRegimeGeneral,
          ["CarriereMajorationEnfantFonctionPublique" + ClientConjoint]: MajoEnfantFonctionPublique,
          ["TrimestreBonificationEnfantFonctionPublique" + ClientConjoint]: TrimestreBonificationEnfantFonctionPublique,
          ["CarriereMajorationEnfantCrpcen" + ClientConjoint]: MajoEnfantCrpcen,
        });
      }
    } else if (Civilite === "Madame" && Number(Enfant) === 0) {
      this.setState({
        ["CarriereMajorationEnfant" + ClientConjoint]: 0,
        ["CarriereMajorationEnfantFonctionPublique" + ClientConjoint]: 0,
        ["TrimestreBonificationEnfantFonctionPublique" + ClientConjoint]: 0,
        ["CarriereMajorationEnfantCrpcen" + ClientConjoint]: 0,
      });
    }
  };
  CalculMajorationEnfantOpenDossier = (Civilite, Enfant, Carriere, TableauEnfant, ClientConjoint, MajorationEnfantSaisi) => {
    if (Civilite === "Madame" && Number(Enfant) > 0) {
      const MajorationEnfantSaisiRegimeGeneral = MajorationEnfantSaisi[0];
      const MajorationEnfantSaisiFonctionPublique = MajorationEnfantSaisi[1];
      const BonificationEnfantSaisiFonctionPublique = MajorationEnfantSaisi[2];
      const MajorationEnfantSaisiCrpcen = MajorationEnfantSaisi[3];
      if (
        MajorationEnfantSaisiRegimeGeneral === false &&
        MajorationEnfantSaisiFonctionPublique === false &&
        BonificationEnfantSaisiFonctionPublique === false &&
        MajorationEnfantSaisiCrpcen === false
      ) {
        var CarriereRegimeGeneral = "Non";
        if (Carriere.length === 0 || Carriere.filter((Carriere) => Carriere.Nature !== "FonctionPublique" && Carriere.Nature !== "Crpcen").length > 0) {
          CarriereRegimeGeneral = "Oui";
        }
        var CarriereFonctionPublique = "Non";
        if (Carriere.filter((Carriere) => Carriere.Nature === "FonctionPublique").length > 0) {
          CarriereFonctionPublique = "Oui";
        }
        var CarriereCrpcen = "Non";
        if (Carriere.filter((Carriere) => Carriere.Nature === "Crpcen").length > 0) {
          CarriereCrpcen = "Oui";
        }

        let MajoEnfantRegimeGeneral = this.state["CarriereMajorationEnfant" + ClientConjoint];
        let MajoEnfantFonctionPublique = this.state["CarriereMajorationEnfantFonctionPublique" + ClientConjoint];
        let TrimestreBonificationEnfantFonctionPublique = this.state["TrimestreBonificationEnfantFonctionPublique" + ClientConjoint];
        let MajoEnfantCrpcen = this.state["CarriereMajorationEnfantCrpcen" + ClientConjoint];
        if (MajorationEnfantSaisiRegimeGeneral === false && CarriereRegimeGeneral === "Oui") {
          MajoEnfantRegimeGeneral = Number(8) * Number(Enfant);
        } else if (MajorationEnfantSaisiFonctionPublique === false && BonificationEnfantSaisiFonctionPublique === false && CarriereFonctionPublique === "Oui" && CarriereCrpcen === "Non") {
          let NbEnfantsAvant2004 = 0;
          let NbEnfantsApres2004 = 0;
          TableauEnfant.forEach((Enfant) => {
            let AnneeNaissanceEnfant = Enfant.DateNaissance.slice(-4);
            if (AnneeNaissanceEnfant < 2004) {
              NbEnfantsAvant2004++;
            } else {
              NbEnfantsApres2004++;
            }
          });
          MajoEnfantFonctionPublique = Number(2) * Number(NbEnfantsApres2004);
          TrimestreBonificationEnfantFonctionPublique = Number(4) * Number(NbEnfantsAvant2004);
        } else if (MajorationEnfantSaisiCrpcen === false && CarriereCrpcen === "Oui" && CarriereFonctionPublique === "Non") {
          let NbEnfantsAvant2006 = 0;
          TableauEnfant.forEach((Enfant) => {
            let AnneeNaissanceEnfant = Enfant.DateNaissance.slice(-4);
            let MoisNaissanceEnfant = Number(Enfant.DateNaissance.slice(2, 4));
            if (AnneeNaissanceEnfant < 2006) {
              NbEnfantsAvant2006++;
            } else if (AnneeNaissanceEnfant === 2006 && MoisNaissanceEnfant < 7) {
              NbEnfantsAvant2006++;
            }
          });
          MajoEnfantCrpcen = Number(4) * Number(NbEnfantsAvant2006);
        }
        this.setState({
          ["CarriereMajorationEnfant" + ClientConjoint]: MajoEnfantRegimeGeneral,
          ["CarriereMajorationEnfantFonctionPublique" + ClientConjoint]: MajoEnfantFonctionPublique,
          ["TrimestreBonificationEnfantFonctionPublique" + ClientConjoint]: TrimestreBonificationEnfantFonctionPublique,
          ["CarriereMajorationEnfantCrpcen" + ClientConjoint]: MajoEnfantCrpcen,
        });
      }
    }
  };

  //TAUX DE REMPLACEMENT RETRAITE
  handleCarriereSalaireReferenceSaisi = (name, ClientConjoint, value) => {
    let MajorationEnfantSaisi = true;
    let SalaireReference = value;
    if (value === "") {
      MajorationEnfantSaisi = false;
      SalaireReference = this.state["CarriereSalaireReferenceCalcule" + ClientConjoint];
    }
    this.setState(
      {
        [name]: MajorationEnfantSaisi,
        ["CarriereSalaireReference" + ClientConjoint]: SalaireReference,
      },
      () => {}
    );
  };
  //CONTRATS RETRAITE
  handleAddContratRetraite = (id, Outil, ClientConjoint, SalaireBrut83PERO) => {
    CalculRetraite(this.state, "", 0, ClientConjoint).then((ResultatRetraite) => {
      CalculPrestationContrat(this.state, ClientConjoint, ResultatRetraite, SalaireBrut83PERO, "").then((Resultat) => {
        let TableauSaisieManuelleRente = [];
        // if (Array.isArray(Resultat.TabPrestations)) {
        TableauSaisieManuelleRente = Resultat.TabPrestations;
        let ListeContrat = this.state[Outil + "ListeContrat" + ClientConjoint];
        let IdContratRetraite = id;
        if (IdContratRetraite.length === 0) {
          IdContratRetraite = Date.now();
        } else {
          ListeContrat = ListeContrat.filter((Contrat) => Contrat.id !== id);
        }
        let CotisationContrat = Resultat.TabCotisations;
        if (this.state.FiscaliteContratRetraite === "PEE" || this.state.FiscaliteContratRetraite === "PERCO") {
          CotisationContrat =
            Number(this.state.InteressementContratRetraite) +
            Number(this.state.ParticipationContratRetraite) +
            Number(this.state.AbondementContratRetraite) +
            Number(this.state.VolontaireContratRetraite);
        } else if (this.state.FiscaliteContratRetraite === "Article83" || this.state.FiscaliteContratRetraite === "Pero") {
          CotisationContrat = Resultat.Cotisation83;
        }
        const newContratRetraite = [
          {
            id: IdContratRetraite,
            Fiscalite: this.state.FiscaliteContratRetraite,
            FiscalitePer: this.state.FiscaliteContratPer,
            ForfaitSocialArticle83: this.state.ForfaitSocialArticle83,
            AssietteCotisation83: this.state.AssietteCotisation83,
            Compagnie: this.state.CompagnieContratRetraite,
            Statut: this.state.StatutContratRetraite,
            Cotisation: CotisationContrat,
            Salaire: this.state["SalaireBrutContratRetraite" + ClientConjoint],
            Indexation: this.state.TauxIndexationContratRetraite,
            Periodicite: this.state.PeriodiciteContratRetraite,
            Tableau83: {
              taux_retraite_trancheA_salarie83: this.state.taux_retraite_trancheA_salarie83,
              taux_retraite_trancheA_patronale83: this.state.taux_retraite_trancheA_patronale83,
              taux_retraite_trancheB_salarie83: this.state.taux_retraite_trancheB_salarie83,
              taux_retraite_trancheB_patronale83: this.state.taux_retraite_trancheB_patronale83,
              taux_retraite_trancheC_salarie83: this.state.taux_retraite_trancheC_salarie83,
              taux_retraite_trancheC_patronale83: this.state.taux_retraite_trancheC_patronale83,
              taux_retraite_trancheD_salarie83: this.state.taux_retraite_trancheD_salarie83,
              taux_retraite_trancheD_patronale83: this.state.taux_retraite_trancheD_patronale83,
            },
            DetailPEE: {
              Interessement: this.state.InteressementContratRetraite,
              Participation: this.state.ParticipationContratRetraite,
              Abondement: this.state.AbondementContratRetraite,
              Volontaire: this.state.VolontaireContratRetraite,
            },
            DetailPERCO: {
              Interessement: this.state.InteressementContratRetraite,
              Participation: this.state.ParticipationContratRetraite,
              Abondement: this.state.AbondementContratRetraite,
              Volontaire: this.state.VolontaireContratRetraite,
            },
            CSGPERCO: this.state.CSGPERCOContratRetraite,
            Epargne: this.state.EpargneAcquiseContratRetraite,
            Rendement: this.state.RendementContratRetraite,
            FraisVersement: this.state.FraisVersementContratRetraite,
            SetConversion: this.state.SetTauxConversionContratRetraite,
            Conversion: this.state.TauxConversionContratRetraite,
            FraisArrerage: this.state.FraisArrerageContratRetraite,
            Sortie: this.state.SortieContratRetraite,
            Reversion: this.state.ReversionContratRetraite,
            TauxReversion: this.state.TauxReversionContratRetraite,
            VersementInitialPerp: this.state.VersementInitialPerpContratRetraite,
            VersementProgrammePerp: this.state.VersementProgrammePerpContratRetraite,
            PrimePerTP: this.state.PrimePerTP,
            PrimePerp: this.state.PrimePerp,
            PayeurPrimeRetraite: this.state.PayeurPrimeRetraite,
            DeductionFiscaleVersementPER: this.state.DeductionFiscaleVersementContratRetraite,
            EpargneAcquiseVersement: this.state.EpargneAcquiseVersementContratRetraite,
            EpargneAcquiseProduit: this.state.EpargneAcquiseProduitContratRetraite,
            DureeFractionnement: this.state.DureeFractionnementContratRetraite,
            PartCapitalPER: this.state.PartCapitalPERContratRetraite,
            SaisieContratRetraite: this.state.SaisieContratRetraite,
            TableauSaisieManuelleRente: TableauSaisieManuelleRente,
          },
        ];
        ListeContrat = [...ListeContrat, ...newContratRetraite];
        this.setState({ [Outil + "ListeContrat" + ClientConjoint]: ListeContrat }, () => {
          this.handleResetContratRetraite();
        });
        // }
      });
    });
  };
  handleResetContratRetraite = () => {
    let ContratRetraite = { ...RemExpertContratRetraite };
    this.setState(ContratRetraite);
  };

  handleCalculEvolutionContrat = (event) => {
    let NewVersementContrat = this.state.CotisationContratRetraite;

    const FirstKey = new Date().getFullYear();
    let NewRevenus = Number(NewVersementContrat[FirstKey]["Versement"]);
    NewRevenus = Number(NewRevenus).toFixed(0);
    const VrstContrat = VersementContrat(FirstKey, NewVersementContrat, NewRevenus, event.target.value);

    this.setState({
      TauxIndexationContratRetraite: event.target.value,
      CotisationContratRetraite: VrstContrat,
    });
  };

  handleVersementContrat = (Annee, value) => {
    let NewVersementContrat = _.cloneDeep(this.state.CotisationContratRetraite);
    NewVersementContrat[Annee]["Versement"] = value;

    const VrstContrat = VersementContrat(Annee, NewVersementContrat, value, this.state.TauxIndexationContratRetraite);
    this.setState({
      CotisationContratRetraite: VrstContrat,
    });
  };
  handleMisEnReductionContrat = (event) => {
    this.setState({
      CotisationContratRetraite: {},
      StatutContratRetraite: event.target.value,
    });
  };

  handleModifyDeleteContratRetraite = (id, option, Outil, ClientConjoint) => {
    let ListeContrat = this.state[Outil + "ListeContrat" + ClientConjoint];
    if (option === "Modifier") {
      const ContratToDisplay = ListeContrat.filter((Contrat) => Contrat.id === id);
      ContratToDisplay.forEach((Contrat) => {
        this.setState({
          IdContratRetraite: Contrat.id,
          FiscaliteContratRetraite: Contrat.Fiscalite,
          FiscaliteContratPer: Contrat.FiscalitePer,
          ForfaitSocialArticle83: Contrat.ForfaitSocialArticle83,
          AssietteCotisation83: Contrat.AssietteCotisation83,
          CompagnieContratRetraite: Contrat.Compagnie,
          StatutContratRetraite: Contrat.Statut,
          CotisationContratRetraite: Contrat.Cotisation,
          ["SalaireBrutContratRetraite" + ClientConjoint]: Contrat.Salaire,
          TauxIndexationContratRetraite: Contrat.Indexation,
          PeriodiciteContratRetraite: Contrat.Periodicite,
          taux_retraite_trancheA_salarie83: Contrat.Tableau83?.taux_retraite_trancheA_salarie83,
          taux_retraite_trancheA_patronale83: Contrat.Tableau83?.taux_retraite_trancheA_patronale83,
          taux_retraite_trancheB_salarie83: Contrat.Tableau83?.taux_retraite_trancheB_salarie83,
          taux_retraite_trancheB_patronale83: Contrat.Tableau83?.taux_retraite_trancheB_patronale83,
          taux_retraite_trancheC_salarie83: Contrat.Tableau83?.taux_retraite_trancheC_salarie83,
          taux_retraite_trancheC_patronale83: Contrat.Tableau83?.taux_retraite_trancheC_patronale83,
          taux_retraite_trancheD_salarie83: Contrat.Tableau83?.taux_retraite_trancheD_salarie83,
          taux_retraite_trancheD_patronale83: Contrat.Tableau83?.taux_retraite_trancheD_patronale83,
          EpargneAcquiseContratRetraite: Contrat.Epargne,
          RendementContratRetraite: Contrat.Rendement,
          FraisVersementContratRetraite: Contrat.FraisVersement,
          TauxConversionContratRetraite: Contrat.Conversion,
          SetTauxConversionContratRetraite: Contrat.SetConversion,
          FraisArrerageContratRetraite: Contrat.FraisArrerage,
          SortieContratRetraite: Contrat.Sortie,
          ReversionContratRetraite: Contrat.Reversion,
          TauxReversionContratRetraite: Contrat.TauxReversion,
          VersementInitialPerpContratRetraite: Contrat.VersementInitialPerp,
          VersementProgrammePerpContratRetraite: Contrat.VersementProgrammePerp,
          PrimePerTP: Contrat.PrimePerTP,
          PrimePerp: Contrat.PrimePerp,
          PayeurPrimeRetraite: Contrat.PayeurPrimeRetraite,
          DeductionFiscaleVersementContratRetraite: Contrat.DeductionFiscaleVersementPER,
          EpargneAcquiseVersementContratRetraite: Contrat.EpargneAcquiseVersement,
          EpargneAcquiseProduitContratRetraite: Contrat.EpargneAcquiseProduit,
          DureeFractionnementContratRetraite: Contrat.DureeFractionnement,
          PartCapitalPERContratRetraite: Contrat.PartCapitalPER,
          SaisieContratRetraite: Contrat.SaisieContratRetraite,
          TableauSaisieManuelleRente: Contrat.TableauSaisieManuelleRente,
          CSGPERCOContratRetraite: Contrat.CSGPERCO,
        });
        if (Contrat.Fiscalite === "PEE") {
          this.setState({
            InteressementContratRetraite: Contrat.DetailPEE.Interessement,
            ParticipationContratRetraite: Contrat.DetailPEE.Participation,
            AbondementContratRetraite: Contrat.DetailPEE.Abondement,
            VolontaireContratRetraite: Contrat.DetailPEE.Volontaire,
          });
        } else if (Contrat.Fiscalite === "PERCO") {
          this.setState({
            InteressementContratRetraite: Contrat.DetailPERCO.Interessement,
            ParticipationContratRetraite: Contrat.DetailPERCO.Participation,
            AbondementContratRetraite: Contrat.DetailPERCO.Abondement,
            VolontaireContratRetraite: Contrat.DetailPERCO.Volontaire,
          });
        }
      });
    } else if (option === "Supprimer") {
      ListeContrat = ListeContrat.filter((Contrat) => Contrat.id !== id);
      this.setState({ [Outil + "ListeContrat" + ClientConjoint]: ListeContrat });
    }
  };
  handleChangeFiscalite = (name) => (event) => {
    let SortieContratRetraite = "RenteSimple";
    if (event.target.value === "Article62" || event.target.value === "PEE" || event.target.value === "PERCO") {
      SortieContratRetraite = "Capital";
    }
    this.setState({
      [name]: event.target.value,
      SortieContratRetraite: SortieContratRetraite,
    });
  };
  handleChangeAssietteCotisation83 = (name, Outil, event) => {
    this.setState({ [name]: event.target.value }, () => {
      if (Outil === "") {
        this.setState({
          taux_retraite_trancheB_salarie83: 0.0,
          taux_retraite_trancheB_patronale83: 0.0,
          taux_retraite_trancheC_salarie83: 0.0,
          taux_retraite_trancheC_patronale83: 0.0,
          taux_retraite_trancheD_salarie83: 0.0,
          taux_retraite_trancheD_patronale83: 0.0,
        });
      }
    });

    // let SortieContratRetraite = "RenteSimple";
    // if (event.target.value === "Article62" || event.target.value === "PEE" || event.target.value === "PERCO") {
    //   SortieContratRetraite = "Capital";
    // }
    // this.setState({
    //   [name]: event.target.value,
    //   SortieContratRetraite: SortieContratRetraite
    // });
  };

  handleSaisieManuelleRente = (index, type, event) => {
    let TableauSaisieManuelleRente = _.cloneDeep(this.state.TableauSaisieManuelleRente);
    if (type === "RenteSaisi") {
      TableauSaisieManuelleRente[index]["Rente"] = TableauSaisieManuelleRente[index]["RenteCalcule"];
      TableauSaisieManuelleRente[index]["RenteSaisi"] = false;
    } else if (type === "PartVersementSaisi") {
      TableauSaisieManuelleRente[index]["PartVersement"] = TableauSaisieManuelleRente[index]["PartVersementCalcule"];
      TableauSaisieManuelleRente[index]["PartVersementSaisi"] = false;
    } else if (type === "PartProduitSaisi") {
      TableauSaisieManuelleRente[index]["PartProduit"] = TableauSaisieManuelleRente[index]["PartProduitCalcule"];
      TableauSaisieManuelleRente[index]["PartProduitSaisi"] = false;
    } else {
      TableauSaisieManuelleRente[index][type] = event.target.value;
      if (type === "Rente") {
        TableauSaisieManuelleRente[index]["RenteSaisi"] = true;
      } else if (type === "PartVersement") {
        TableauSaisieManuelleRente[index]["PartVersementSaisi"] = true;
      } else if (type === "PartProduit") {
        TableauSaisieManuelleRente[index]["PartProduitSaisi"] = true;
      }
    }
    this.setState({
      TableauSaisieManuelleRente: TableauSaisieManuelleRente,
    });
  };

  //CUMUL EMPLOI RETRAITE
  handleDepartRetraiteCumulEmploi = (ClientConjoint, name) => (event) => {
    console.log('DATE CUMUL')
    let ChoixDepartRetraite = name;
    let DepartAgeChoisi = false;
    let DepartDateChoisi = false;
    let DepartAgeChoisiSelect = this.state["CumulEmploiDepartAgeChoisiSelect" + ClientConjoint];
    let DepartMoisChoisiSelect = this.state["CumulEmploiDepartMoisChoisiSelect" + ClientConjoint];
    let MoisDepartDateChoisiInput = this.state["CumulEmploiMoisDepartDateChoisiInput" + ClientConjoint];
    let AnneeDepartDateChoisiInput = this.state["CumulEmploiAnneeDepartDateChoisiInput" + ClientConjoint];
    if (event.target.value === "") {
      ChoixDepartRetraite = "DepartDateChoisi";
      DepartDateChoisi = true;
    } else if (name === "CumulEmploiDepartAgeChoisi") {
      ChoixDepartRetraite = "DepartAgeChoisi";
      DepartAgeChoisi = true;
    } else if (name === "CumulEmploiDepartAgeChoisiSelect") {
      ChoixDepartRetraite = "DepartAgeChoisi";
      DepartAgeChoisiSelect = event.target.value;
      DepartAgeChoisi = true;
    } else if (name === "CumulEmploiDepartMoisChoisiSelect") {
      ChoixDepartRetraite = "DepartAgeChoisi";
      DepartMoisChoisiSelect = event.target.value;
      DepartAgeChoisi = true;
    } else if (name === "CumulEmploiDepartDateChoisi") {
      ChoixDepartRetraite = "DepartDateChoisi";
      DepartDateChoisi = true;
    } else if (name === "CumulEmploiMoisDepartDateChoisiInput") {
      ChoixDepartRetraite = "DepartDateChoisi";
      DepartDateChoisi = true;
      MoisDepartDateChoisiInput = ("0" + event.target.value).slice(-2);
    } else if (name === "CumulEmploiAnneeDepartDateChoisiInput") {
      ChoixDepartRetraite = "DepartDateChoisi";
      DepartDateChoisi = true;
      AnneeDepartDateChoisiInput = event.target.value;
    }

    this.setState(
      {
        ["CumulEmploiChoixDepartRetraite" + ClientConjoint]: ChoixDepartRetraite,
        ["CumulEmploiDepartAgeChoisi" + ClientConjoint]: DepartAgeChoisi,
        ["CumulEmploiDepartDateChoisi" + ClientConjoint]: DepartDateChoisi,
        ["CumulEmploiDepartAgeChoisiSelect" + ClientConjoint]: DepartAgeChoisiSelect,
        ["CumulEmploiDepartMoisChoisiSelect" + ClientConjoint]: DepartMoisChoisiSelect,
        ["CumulEmploiMoisDepartDateChoisiInput" + ClientConjoint]: MoisDepartDateChoisiInput,
        ["CumulEmploiAnneeDepartDateChoisiInput" + ClientConjoint]: AnneeDepartDateChoisiInput,
      },
      () => {
        if (ChoixDepartRetraite === "DepartDateChoisi") {
          if (MoisDepartDateChoisiInput.length === 2 && AnneeDepartDateChoisiInput.length === 4) {
            this.handleCalculCumulEmploiRetraite(ClientConjoint, this.state);
          }
        } else {
          this.handleCalculCumulEmploiRetraite(ClientConjoint, this.state);
        }
      }
    );
  };

  handleCalculCumulEmploiRetraite = (ClientConjoint, Data) => {
    let CleanData = RemoveUnecessaryData(Data);
    var formData = new FormData();
    formData.append("Data", JSON.stringify(CleanData));
    formData.append("ClientConjoint", ClientConjoint);
    axios({
      method: "post",
      url: `${APIURL}DateDepartRetraiteCumulEmploi.php`,
      data: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      withCredentials: true,
    })
      .catch(function (error) {
        if (parseInt(error.response.request.status) === 401) {
          var event = new CustomEvent("session-expired", {
            detail: {
              logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
            },
          });
          window.dispatchEvent(event);
        }
      })
      .then((res) => {
        let ChoixDepartRetraite =
          this.state["CumulEmploiChoixDepartRetraite" + ClientConjoint] === "" || res.data.ForcetoDate === true
            ? "DepartDateChoisi"
            : this.state["CumulEmploiChoixDepartRetraite" + ClientConjoint];
        let DepartAgeChoisi = this.state["CumulEmploiDepartAgeChoisi" + ClientConjoint];
        let DepartDateChoisi = this.state["CumulEmploiDepartDateChoisi" + ClientConjoint];
        let MoisDepartDateChoisiInput = this.state["CumulEmploiMoisDepartDateChoisiInput" + ClientConjoint];
        let AnneeDepartDateChoisiInput = this.state["CumulEmploiAnneeDepartDateChoisiInput" + ClientConjoint];
        let DateDepartRetraite = res.data.Date;
        var d = DateDepartRetraite.split("/");
        var DateVerif = new Date(d[2] + "-" + d[1] + "-" + d[0]);

        //Si date de depart inferieur a aujourd'hui+1 mois alors on force
        var DateAffich = res.data.DateAffichage;
        var now = new Date();
        var DateMini = new Date(now.getFullYear(), now.getMonth() + 1, 1);
        // if (this.state.TypeSimulation === "Audit retraite") {
        //   DateMini = new Date(now.getFullYear() - 1, now.getMonth(), 1);
        // }

        if (ChoixDepartRetraite === "DepartDateChoisi") {
          DepartDateChoisi = true;
          DepartAgeChoisi = false;
          var ddd = DateAffich.split("/");
          MoisDepartDateChoisiInput = ddd[1];
          AnneeDepartDateChoisiInput = ddd[2];
        }
        // if (window.location.href.indexOf("test") === -1 && window.location.href.indexOf("localhost") === -1) {
        // console.log('STAT',this.state.IdUser)
        // if (this.state.IdUser !== 2000 && this.state.IdUser !== 2158) {
        //   if (DateVerif.setHours(0, 0, 0, 0) <= DateMini.setHours(0, 0, 0, 0)) {
        //     DateDepartRetraite = DateMini;

        //     ChoixDepartRetraite = "DepartDateChoisi";
        //     DepartDateChoisi = true;
        //     DepartAgeLegal = false;
        //     DepartAgeChoisi = false;
        //     DepartAgeTauxPlein = false;
        //     DateAffich = new Date(DateMini);
        //     DateAffich = GetFormattedDate(DateAffich);
        //     var dd = DateAffich.split("/");
        //     MoisDepartDateChoisiInput = dd[1];
        //     AnneeDepartDateChoisiInput = dd[2];

        //     DateMini.setDate(DateMini.getDate() - 1);
        //     DateDepartRetraite = GetFormattedDate(DateMini);
        //   }
        // }

        this.setState(
          {
            ["CumulEmploiChoixDepartRetraite" + ClientConjoint]: ChoixDepartRetraite,
            ["CumulEmploiDateDepartRetraite" + ClientConjoint]: DateDepartRetraite,
            ["CumulEmploiDateDepartRetraiteAffichage" + ClientConjoint]: DateAffich,
            RetraiteFinActivite: DateDepartRetraite.replaceAll("/", ""),
            // ["AgeDepartRetraite" + ClientConjoint]: res.data.Age,
            ["CumulEmploiDepartAgeChoisi" + ClientConjoint]: DepartAgeChoisi,
            ["CumulEmploiDepartDateChoisi" + ClientConjoint]: DepartDateChoisi,
            ["CumulEmploiMoisDepartDateChoisiInput" + ClientConjoint]: MoisDepartDateChoisiInput,
            ["CumulEmploiAnneeDepartDateChoisiInput" + ClientConjoint]: AnneeDepartDateChoisiInput,
          },
          () => {
            this.handleCalculCER(ClientConjoint,this.state);
          }
        );
      });
  };

  handleCalculCER=(ClientConjoint,Data)=>{
    CalculCumulEmploiRetraite(Data, "", 0, ClientConjoint).then((ResultatRetraite) => {
      this.setState({
        //       ["DisplayRetraiteProgressive" + ClientConjoint]: ResultatRetraite.DisplayRetraiteProgressive,
        CumulEmploiAlertLURA: ResultatRetraite.AlertLURA,
        CumulEmploisIdsLURA: ResultatRetraite.IdsLURA,
        ["CumulEmploiCarriere" + ClientConjoint]: ResultatRetraite.CarriereCumulEmploiRetraite,
        ["CumulEmploiResult" + ClientConjoint]: ResultatRetraite.RetraiteCER,
        ["CumulEmploiAgeDepartRetraite" + ClientConjoint]: ResultatRetraite.AgeDepartRetraite,
        ["CumulEmploiPensionRenteDateCER" + ClientConjoint]: ResultatRetraite.PensionRenteDateCER,
        //       ["TotalTrimestre" + ClientConjoint]: ResultatRetraite.TotalTrimestre,
        //     },
        //     () => {
        //       if (this.state["ChoixDepartRetraite" + ClientConjoint] === "DepartAgeTauxPlein") {
        //         var DateDepartRetraiteTauxPlein = ResultatRetraite.RetraiteChoixDepartRetraite.DateDepartTauxPlein;
        //         DateAffich = ResultatRetraite.RetraiteChoixDepartRetraite.DateDepartTauxPlein;
        //         let d = ResultatRetraite.RetraiteChoixDepartRetraite.DateDepartTauxPlein.split("/");
        //         var DateVerif = new Date(d[2] + "-" + d[1] + "-" + d[0]);
        //         var DateMini = new Date(now.getFullYear(), now.getMonth() + 1, 1);
        //         if (this.state.IdUser !== 2000 && this.state.IdUser !== 2158) {
        //           if (DateVerif.setHours(0, 0, 0, 0) <= DateMini.setHours(0, 0, 0, 0)) {
        //             DateDepartRetraiteTauxPlein = DateMini;
        //             ChoixDepartRetraite = "DepartDateChoisi";
        //             DepartDateChoisi = true;
        //             DepartAgeLegal = false;
        //             DepartAgeChoisi = false;
        //             DepartAgeTauxPlein = false;
        //             DateAffich = new Date(DateMini);
        //             DateAffich = GetFormattedDate(DateAffich);
        //             var dd = DateAffich.split("/");
        //             MoisDepartDateChoisiInput = dd[1];
        //             AnneeDepartDateChoisiInput = dd[2];
        //             DateMini.setDate(DateMini.getDate() - 1);
        //             DateDepartRetraiteTauxPlein = GetFormattedDate(DateMini);
        //           } else {
        //             DateVerif.setDate(DateVerif.getDate() - 1);
        //             DateDepartRetraiteTauxPlein = GetFormattedDate(DateVerif);
        //           }
        //         } else {
        //           DateVerif.setDate(DateVerif.getDate() - 1);
        //           DateDepartRetraiteTauxPlein = GetFormattedDate(DateVerif);
        //         }
        //         let PeriodeActuelle = this.state.PeriodeActuelle;
        //         let PeriodeActuelleConjoint = this.state.PeriodeActuelleConjoint;
        //         if (PeriodeActuelle.length > 0) {
        //           if (ClientConjoint === "") {
        //             PeriodeActuelle[0]["FinPeriodeCarriereActuelle"] = DateDepartRetraiteTauxPlein.replaceAll("/", "");
        //             if (PeriodeActuelle[0]["FinCarriereActuelle"] === "DepartRetraite") {
        //               PeriodeActuelle[0]["FinPeriodeCarriereActuelle"] = DateDepartRetraiteTauxPlein.replaceAll("/", "");
        //             }
        //             if (PeriodeActuelle[0]["FinCarriereActuelle"] === "DepartRetraite" && PeriodeActuelle[0]["FinCarriereActuelleConjoint"] === "Client") {
        //               PeriodeActuelle[0]["FinPeriodeCarriereActuelleConjoint"] = DateDepartRetraiteTauxPlein.replaceAll("/", "");
        //             }
        //           } else {
        //             if (PeriodeActuelle[0]["FinCarriereActuelleConjoint"] === "DepartRetraite") {
        //               PeriodeActuelle[0]["FinPeriodeCarriereActuelleConjoint"] = DateDepartRetraiteTauxPlein.replaceAll("/", "");
        //             }
        //           }
        //         }
        //         if (PeriodeActuelleConjoint.length > 0 && ClientConjoint === "Conjoint") {
        //           if (PeriodeActuelleConjoint[0]["FinCarriereActuelle"] === "DepartRetraite") {
        //             PeriodeActuelleConjoint[0]["FinPeriodeCarriereActuelle"] = DateDepartRetraiteTauxPlein.replaceAll("/", "");
        //           }
        //         }
        //         this.setState(
        //           {
        //             ["ChoixDepartRetraite" + ClientConjoint]: ChoixDepartRetraite,
        //             ["DateDepartRetraite" + ClientConjoint]: DateDepartRetraiteTauxPlein,
        //             ["DateDepartRetraiteAffichage" + ClientConjoint]: DateAffich,
        //             PeriodeActuelle: PeriodeActuelle,
        //             PeriodeActuelleConjoint: PeriodeActuelleConjoint,
        //             ["FinPeriodeCarriereActuelle" + ClientConjoint]: DateDepartRetraiteTauxPlein.replaceAll("/", ""),
        //             ["AgeDepartProjection" + ClientConjoint]: Number(ResultatRetraite.RetraiteChoixDepartRetraite.Infos.AgeDepartRetraite2),
        //             ["AgeDepartRetraite" + ClientConjoint]: res.data.Age,
        //             ["DepartAgeLegal" + ClientConjoint]: DepartAgeLegal,
        //             ["DepartAgeChoisi" + ClientConjoint]: DepartAgeChoisi,
        //             ["DepartAgeTauxPlein" + ClientConjoint]: DepartAgeTauxPlein,
        //             ["DepartDateChoisi" + ClientConjoint]: DepartDateChoisi,
        //             ["MoisDepartDateChoisiInput" + ClientConjoint]: MoisDepartDateChoisiInput,
        //             ["AnneeDepartDateChoisiInput" + ClientConjoint]: AnneeDepartDateChoisiInput,
        //           },
        //           () => {
        //             CalculRetraite(this.state, "", 0, ClientConjoint).then((ResultatRetraite) => {
        //               this.setState({
        //                 ["Carriere" + ClientConjoint]: ResultatRetraite.RetraiteChoixDepartRetraite.Carriere,
        //                 ["AgeDepartProjection" + ClientConjoint]: Number(ResultatRetraite.RetraiteChoixDepartRetraite.Infos.AgeDepartRetraite2),
        //                 ["AgeDepartRetraite" + ClientConjoint]: ResultatRetraite.AgeDepartRetraite,
        //                 ["TotalTrimestre" + ClientConjoint]: ResultatRetraite.TotalTrimestre,
        //               });
        //             });
        //           }
        //         );
        //       }
        //       if (PeriodeActuelle.length > 0 && ClientConjoint === "" && PeriodeActuelle[0]["FinCarriereActuelleConjoint"] === "Client") {
        //         this.handleCarriereActuelleConjointAssocie(PeriodeActuelle[0], "");
        //       }
      });
    });
  }

  //IMPOT
  handleRecetteBrute = (Outil, name, event) => {
    let Regime = event.target.value <= 15000 ? this.state[Outil + "IRDirigeantRegimeRevenuFoncier"] : "Reel";
    this.setState({
      [name]: event.target.value,
      [Outil + "IRDirigeantRegimeRevenuFoncier"]: Regime,
    });
  };
  //OPTIMISATION
  handleResetOptimisation = () => {
    this.setState({
      LibelleOptimisation: "",
      IdOptimisation: "",
    });
  };
  //OPTIREM TNS
  handleMontantOptiRem = (name, value) => {
    this.setState({
      [name]: value.replace(/\D/g, ""),
      OptiRemMontantSaisi: value.replace(/\D/g, ""),
    });
  };
  handleCalculOptiRem = (TypeCalcul, name, ClientConjoint, RemExpertCalcul, CalculCDS, event) => {
    return new Promise((resolve, reject) => {
      let OptiRemMontantSaisi = typeof event === "object" ? parseInt(event.target.value.replace(/\D/g, ""), 10) : event;
      let OptiRemTypeCalcul = TypeCalcul;
      let OptiRemUnlockCoutEntreprise = TypeCalcul === "revenu_dispo" ? true : this.state["OptiRemUnlockCoutEntreprise" + ClientConjoint];
      this.setState(
        {
          // OptiRemLoading: true,
          [name]: OptiRemMontantSaisi,
          ["OptiRemUnlockCoutEntreprise" + ClientConjoint]: OptiRemUnlockCoutEntreprise,
          OptiRemTypeCalcul: OptiRemTypeCalcul,
          OptiRemMontantSaisi: OptiRemMontantSaisi,
        },
        () => {
          RemExpertCalcul.GetResultOptiRem(
            this.state,
            ClientConjoint,
            CalculCDS,
            this.state.OptiRemTnsRemunerationNette,
            this.state.OptiRemTnsDividendeBrut,
            this.state.OptiRemOptionPfu
          ).then((res) => {
            let OptiRemCoutEntreprise = this.state.OptiRemCoutEntreprise;
            let OptiRemCoutRemuneration = this.state.OptiRemCoutRemuneration;
            let OptiRemCotisations = this.state.OptiRemCotisations;
            let OptiRemCotisationsPatronale = this.state.OptiRemCotisationsPatronale;
            let OptiRemCotisationsSalariale = this.state.OptiRemCotisationsSalariale;
            let OptiRemRemBrute = this.state.OptiRemRemBrute;
            let OptiRemRemNette = this.state.OptiRemRemNette;
            let OptiRemRemImposable = this.state.OptiRemRemImposable;
            let OptiRemResAvtIs = this.state.OptiRemResAvtIs;
            let OptiRemImpotSoc = this.state.OptiRemImpotSoc;
            let OptiRemDivAutres = this.state.OptiRemDivAutres;
            let OptiRemSeuilAssuj = this.state.OptiRemSeuilAssuj;
            let OptiRemDividendeBrut = this.state.OptiRemDividendeBrut;
            let OptiRemCotFacPerso = this.state.OptiRemCotFacPerso;

            let OptiRemCoutEntrepriseConjoint = this.state.OptiRemCoutEntrepriseConjoint;
            let OptiRemCoutRemunerationConjoint = this.state.OptiRemCoutRemunerationConjoint;
            let OptiRemCotisationsConjoint = this.state.OptiRemCotisationsConjoint;
            let OptiRemCotisationsPatronaleConjoint = this.state.OptiRemCotisationsPatronaleConjoint;
            let OptiRemCotisationsSalarialeConjoint = this.state.OptiRemCotisationsSalarialeConjoint;
            let OptiRemRemBruteConjoint = this.state.OptiRemRemBruteConjoint;
            let OptiRemRemNetteConjoint = this.state.OptiRemRemNetteConjoint;
            let OptiRemRemImposableConjoint = this.state.OptiRemRemImposableConjoint;
            let OptiRemResAvtIsConjoint = this.state.OptiRemResAvtIsConjoint;
            let OptiRemImpotSocConjoint = this.state.OptiRemImpotSocConjoint;
            let OptiRemDivAutresConjoint = this.state.OptiRemDivAutresConjoint;
            let OptiRemDividendeBrutConjoint = this.state.OptiRemDividendeBrutConjoint;
            let OptiRemSeuilAssujConjoint = this.state.OptiRemSeuilAssujConjoint;
            let OptiRemCotFacPersoConjoint = this.state.OptiRemCotFacPersoConjoint;

            if (ClientConjoint === "" || ClientConjoint === "ConjointAssocie") {
              OptiRemCoutRemuneration = res.RemunerationClient.CoutRemuneration;
              OptiRemCotisations = Number(res.RemunerationClient.TotalChargesSociales) + Number(res.RemunerationClient.CotisationsFacultativesEntreprise);
              if ("TotalChargesPatronale" in res.RemunerationClient.AffichageResultat) {
                OptiRemCotisationsPatronale = res.RemunerationClient.AffichageResultat.TotalChargesPatronale;
                OptiRemCotisationsSalariale = res.RemunerationClient.AffichageResultat.TotalChargesSalariale;
              }
              OptiRemRemBrute = res.RemunerationClient.RemunerationBrute;
              OptiRemRemNette = res.RemunerationClient.RemunerationNette;
              OptiRemRemImposable = res.RemunerationClient.BrutImposable;
              OptiRemResAvtIs = res.ResAvtIs;
              OptiRemImpotSoc = res.ImpotSoc;
              OptiRemDivAutres = res.DivBrutsAutres;
              OptiRemDividendeBrut = res.DivBruts;
              OptiRemSeuilAssuj = this.state.OptiRemSetSeuilAssuj === false ? res.SeuilAssuj : this.state.OptiRemSeuilAssuj;
              OptiRemCoutEntreprise = OptiRemCoutRemuneration + res.RemunerationClient.AffichageResultat.CotisationsFacultativesFraisPro + OptiRemResAvtIs;
              OptiRemCotFacPerso = res.RemunerationClient.VersementFacultatifPerso;
            } else if (ClientConjoint === "Conjoint") {
              // console.log(res);
              OptiRemCoutRemunerationConjoint = res.RemunerationConjoint.CoutRemuneration;
              OptiRemCotisationsConjoint = Number(res.RemunerationConjoint.TotalChargesSociales) + Number(res.RemunerationConjoint.CotisationsFacultativesEntreprise);
              if ("TotalChargesPatronale" in res.RemunerationConjoint.AffichageResultat) {
                OptiRemCotisationsPatronaleConjoint = res.RemunerationConjoint.AffichageResultat.TotalChargesPatronale;
                OptiRemCotisationsSalarialeConjoint = res.RemunerationConjoint.AffichageResultat.TotalChargesSalariale;
              }
              OptiRemRemBruteConjoint = res.RemunerationConjoint.RemunerationBrute;
              OptiRemRemNetteConjoint = res.RemunerationConjoint.RemunerationNette;
              OptiRemRemImposableConjoint = res.RemunerationConjoint.BrutImposable;
              OptiRemResAvtIsConjoint = res.ResAvtIs;
              OptiRemImpotSocConjoint = res.ImpotSoc;
              OptiRemDivAutresConjoint = res.DivBrutsAutres;
              OptiRemDividendeBrutConjoint = res.DivBruts;
              OptiRemSeuilAssujConjoint = this.state.OptiRemSetSeuilAssujConjoint === false ? res.SeuilAssuj : this.state.OptiRemSeuilAssujConjoint;
              OptiRemCoutEntrepriseConjoint = OptiRemCoutRemunerationConjoint + res.RemunerationConjoint.AffichageResultat.CotisationsFacultativesFraisPro + OptiRemResAvtIsConjoint;
              OptiRemCotFacPersoConjoint = res.RemunerationConjoint.VersementFacultatifPerso;
            }
            if (this.state["OptiRemPeriodeActuelle"][0]?.StatutConjoint !== "Aucun") {
              OptiRemCoutRemunerationConjoint = res.RemunerationConjoint.CoutRemuneration;
              OptiRemCotisationsConjoint = Number(res.RemunerationConjoint.TotalChargesSociales) + Number(res.RemunerationConjoint.CotisationsFacultativesEntreprise);
              if ("TotalChargesPatronale" in res.RemunerationConjoint.AffichageResultat) {
                OptiRemCotisationsPatronaleConjoint = res.RemunerationConjoint.AffichageResultat.TotalChargesPatronale;
                OptiRemCotisationsSalarialeConjoint = res.RemunerationConjoint.AffichageResultat.TotalChargesSalariale;
              }
              OptiRemSeuilAssujConjoint = this.state.OptiRemSetSeuilAssujConjoint === false ? res.SeuilAssujConjoint : this.state.OptiRemSeuilAssujConjoint;
              OptiRemRemBruteConjoint = res.RemunerationConjoint.RemunerationBrute;
              OptiRemRemNetteConjoint = res.RemunerationConjoint.RemunerationNette;
              OptiRemRemImposableConjoint = res.RemunerationConjoint.BrutImposable;
              OptiRemCoutEntreprise += OptiRemCoutRemunerationConjoint + res.RemunerationConjoint.AffichageResultat.CotisationsFacultativesFraisPro;
              OptiRemCotFacPerso += res.RemunerationConjoint.VersementFacultatifPerso;
            }
            this.setState(
              {
                OptiRemCoutEntreprise: OptiRemCoutEntreprise,
                OptiRemCoutRemuneration: OptiRemCoutRemuneration,
                OptiRemCotisations: OptiRemCotisations,
                OptiRemCotisationsPatronale: OptiRemCotisationsPatronale,
                OptiRemCotisationsSalariale: OptiRemCotisationsSalariale,
                OptiRemCotisationsPatronaleConjoint: OptiRemCotisationsPatronaleConjoint,
                OptiRemCotisationsSalarialeConjoint: OptiRemCotisationsSalarialeConjoint,
                OptiRemRemNette: OptiRemRemNette,
                OptiRemRemBrute: OptiRemRemBrute,
                OptiRemRemImposable: OptiRemRemImposable,
                OptiRemOptionPfu: res.OptionPfu,
                OptiRemResAvtIs: OptiRemResAvtIs,
                OptiRemImpotSoc: OptiRemImpotSoc,
                OptiRemDividendeBrut: OptiRemDividendeBrut,
                OptiRemDivAutres: OptiRemDivAutres,
                OptiRemSeuilAssuj: OptiRemSeuilAssuj,
                OptiRemSeuilAssujConjoint: OptiRemSeuilAssujConjoint,

                // OptiRemTnsRevenuImmediat: res.Remuneration.RevenuImmediat,

                OptiRemCoutEntrepriseConjoint: OptiRemCoutEntrepriseConjoint,
                OptiRemCoutRemunerationConjoint: OptiRemCoutRemunerationConjoint,
                OptiRemCotisationsConjoint: OptiRemCotisationsConjoint,
                OptiRemRemBruteConjoint: OptiRemRemBruteConjoint,
                OptiRemRemNetteConjoint: OptiRemRemNetteConjoint,
                OptiRemRemImposableConjoint: OptiRemRemImposableConjoint,
                OptiRemResAvtIsConjoint: OptiRemResAvtIsConjoint,
                OptiRemImpotSocConjoint: OptiRemImpotSocConjoint,
                OptiRemDividendeBrutConjoint: OptiRemDividendeBrutConjoint,
                OptiRemDivAutresConjoint: OptiRemDivAutresConjoint,

                OptiRemRevPercus: OptiRemRemNette + OptiRemRemNetteConjoint + OptiRemDividendeBrut + OptiRemDividendeBrutConjoint,
                OptiRemCotFacPerso: OptiRemCotFacPerso,
                OptiRemCotFacPersoConjoint: OptiRemCotFacPersoConjoint,
              },
              () => {
                RemExpertCalcul.GetImpot(
                  "OptiRem",
                  this.state,
                  this.state.OptiRemRemImposable,
                  this.state.OptiRemRemImposableConjoint,
                  this.state.OptiRemDividendeBrut + this.state.OptiRemDividendeBrutConjoint
                ).then((resImpot) => {
                  this.setState(
                    {
                      OptiRemRevenusFoyer: resImpot.AutresRevenusFoyer,
                      OptiRemIRTotal: resImpot.IRTotal,
                      OptiRemRevDispo:
                        Number(this.state.OptiRemRevPercus) +
                        Number(resImpot.AutresRevenusFoyer) -
                        Number(this.state.OptiRemCotFacPerso) -
                        Number(this.state.OptiRemCotFacPersoConjoint) -
                        Number(resImpot.IRTotal),
                    },
                    () => {
                      //PRESTATIONS
                      // if (ClientConjoint === "" || ClientConjoint === "Conjoint") {
                      let CliConj = ClientConjoint !== "Conjoint" ? "" : "Conjoint";
                      let result = res.RemunerationClient;
                      if (CliConj === "Conjoint") {
                        result = res.RemunerationConjoint;
                      }

                      if (result !== undefined) {
                        const PeriodeToDisplay = this.state["OptiRemCarriere" + CliConj].filter((Periode) => Periode.id === "CarriereActuelle");
                        if (PeriodeToDisplay.length > 0) {
                          PeriodeToDisplay.forEach((Periode) => {
                            var CurrentYear = new Date().getFullYear();
                            const NewDetailRetraiteFutur = RevenusFutur(CurrentYear, Periode.DetailRetraiteFutur, result.AssietteRetraite, Periode.MontantEvolution);
                            Periode.Detail = NewDetailRetraiteFutur;
                            Periode.DetailRetraiteFutur = NewDetailRetraiteFutur;
                          });
                          const NewCarriere = PeriodeToDisplay[0];
                          let OptiRemCarriere = this.state["OptiRemCarriere" + CliConj].filter((Periode) => Periode.id !== "CarriereActuelle");
                          OptiRemCarriere = [...OptiRemCarriere, NewCarriere];
                          this.setState({ ["OptiRemCarriere" + CliConj]: OptiRemCarriere }, () => {
                            let SalaireReference = 0;
                            const annee = new Date().getFullYear();
                            for (const periode of this.state["OptiRemCarriere" + CliConj]) {
                              if (annee in periode.Detail) {
                                SalaireReference += Number(periode.Detail[annee]["Salaire"]);
                              }
                            }
                            CalculRetraite(this.state, "OptiRem", SalaireReference, CliConj).then((ResultatRetraiteClient) => {
                              this.setState({ ["OptiRemResultatRetraite" + CliConj]: ResultatRetraiteClient }, () => {
                                if (
                                  this.state["OptiRemPeriodeActuelle" + CliConj][0]?.Statut === "TNS Article 62" ||
                                  this.state["OptiRemPeriodeActuelle" + CliConj][0]?.Statut === "TNS individuel"
                                ) {
                                  RemExpertCalcul.CalculPrevoyanceTNS(
                                    this.state,
                                    "OptiRem",
                                    CliConj,
                                    result.DetailAssiette.DetailAssietteTotale,
                                    result.RemunerationNette,
                                    OptiRemRemImposable
                                  ).then((resPrevoyance) => {
                                    this.setState(
                                      {
                                        OptiRemLoading: false,
                                        ["OptiRemResultatPrevoyance" + CliConj]: resPrevoyance,
                                      },
                                      () => {
                                        if (this.state["OptiRemPeriodeActuelle"][0]?.StatutConjoint === "Aucun") {
                                          resolve(true);
                                        }
                                      }
                                    );
                                  });
                                } else {
                                  RemExpertCalcul.CalculPrevoyanceSalarie(
                                    this.state,
                                    "OptiRem",
                                    CliConj,
                                    result.InfosRemuneration.Prevoyance83Salariale,
                                    result.InfosRemuneration.Prevoyance83Patronale,
                                    result.DetailFicheDePaie.TabRecap.DepassementSocial83,
                                    result.DetailFicheDePaie.AssietteFicheDePaie.AssietteSbDepassement,
                                    result.AffichageResultat.SalaireBrut,
                                    result.AffichageResultat.SalaireNet,
                                    result.AffichageResultat.BrutImposable
                                  ).then((resPrevoyance) => {
                                    this.setState(
                                      {
                                        OptiRemLoading: false,
                                        ["OptiRemResultatPrevoyance" + CliConj]: resPrevoyance,
                                      },
                                      () => {
                                        if (this.state["OptiRemPeriodeActuelle"][0]?.StatutConjoint !== "Aucun") {
                                          resolve(true);
                                        }
                                      }
                                    );
                                  });
                                }
                              });
                            });
                          });
                        } else {
                          //UTILE POUR L'ARBITRAGE
                          resolve({ Remuneration: res, Impot: resImpot });
                        }
                      }
                      // }
                      if (this.state["OptiRemPeriodeActuelle"][0]?.StatutConjoint !== "Aucun") {
                        // if (ClientConjoint === "ConjointAssocie") {
                        const PeriodeToDisplayConjoint = this.state.OptiRemCarriereConjoint.filter((PeriodeConjoint) => PeriodeConjoint.id === "CarriereActuelle");
                        if (PeriodeToDisplayConjoint.length > 0) {
                          PeriodeToDisplayConjoint.forEach((PeriodeConjoint) => {
                            var CurrentYear = new Date().getFullYear();
                            const NewDetailRetraiteFuturConjoint = RevenusFutur(
                              CurrentYear,
                              PeriodeConjoint.DetailRetraiteFutur,
                              res.RemunerationConjoint.AssietteRetraite,
                              PeriodeConjoint.MontantEvolution
                            );
                            PeriodeConjoint.Detail = NewDetailRetraiteFuturConjoint;
                            PeriodeConjoint.DetailRetraiteFutur = NewDetailRetraiteFuturConjoint;
                          });

                          const NewCarriereConjoint = PeriodeToDisplayConjoint[0];

                          let OptiRemCarriereConjoint = this.state.OptiRemCarriereConjoint.filter((Periode) => Periode.id !== "CarriereActuelle");
                          OptiRemCarriereConjoint = [...OptiRemCarriereConjoint, NewCarriereConjoint];
                          this.setState(
                            {
                              OptiRemLoading: true,
                              OptiRemCarriereConjoint: OptiRemCarriereConjoint,
                            },
                            () => {
                              let SalaireReferenceConjoint = 0;
                              const annee = new Date().getFullYear();
                              for (const periode of this.state.OptiRemCarriereConjoint) {
                                if (annee in periode.Detail) {
                                  SalaireReferenceConjoint += Number(periode.Detail[annee]["Salaire"]);
                                }
                              }
                              CalculRetraite(this.state, "OptiRem", SalaireReferenceConjoint, "Conjoint").then((ResultatRetraiteConjoint) => {
                                this.setState({ OptiRemResultatRetraiteConjoint: ResultatRetraiteConjoint }, () => {
                                  if (
                                    this.state["OptiRemPeriodeActuelle"][0]?.StatutConjoint === "TNS Article 62" ||
                                    this.state["OptiRemPeriodeActuelle"][0]?.StatutConjoint === "TNS individuel" ||
                                    this.state["OptiRemPeriodeActuelleConjoint"][0]?.Statut === "TNS Article 62" ||
                                    this.state["OptiRemPeriodeActuelleConjoint"][0]?.Statut === "TNS individuel"
                                  ) {
                                    RemExpertCalcul.CalculPrevoyanceTNS(
                                      this.state,
                                      "OptiRem",
                                      "Conjoint",
                                      res.RemunerationConjoint.DetailAssiette.DetailAssietteTotale,
                                      res.RemunerationConjoint.RemunerationNette,
                                      res.RemunerationConjoint.BrutImposable
                                    ).then((resPrevoyance) => {
                                      this.setState({ OptiRemLoading: false, OptiRemResultatPrevoyanceConjoint: resPrevoyance }, () => {
                                        resolve(true);
                                      });
                                    });
                                  } else {
                                    RemExpertCalcul.CalculPrevoyanceSalarie(
                                      this.state,
                                      "OptiRem",
                                      "Conjoint",
                                      res.RemunerationConjoint.InfosRemuneration.Prevoyance83Salariale,
                                      res.RemunerationConjoint.InfosRemuneration.Prevoyance83Patronale,
                                      res.RemunerationConjoint.DetailFicheDePaie.TabRecap.DepassementSocial83,
                                      res.RemunerationConjoint.DetailFicheDePaie.AssietteFicheDePaie.AssietteSbDepassement,
                                      res.RemunerationConjoint.RemunerationBrute,
                                      res.RemunerationConjoint.RemunerationNette,
                                      res.RemunerationConjoint.BrutImposable
                                    ).then((resPrevoyance) => {
                                      this.setState({ OptiRemLoading: false, OptiRemResultatPrevoyanceConjoint: resPrevoyance }, () => {
                                        resolve(true);
                                      });
                                    });
                                  }
                                });
                              });
                            }
                          );
                        }
                      }
                    }
                  );
                });
              }
            );
          });
        }
      );
    });
  };
  handleOpenRemiseDonneeOptirem = (ClientConjoint, ResultatTNS, ResultatSalarie) => {
    return new Promise((resolve, reject) => {
      let EnvEntr = 0;
      let CoutRemuneration = 0;
      let Cotisations = 0;
      let RemBrute = 0;
      let RemNette = 0;
      let AvantageNature = 0;
      let RemImposable = 0;
      let ResAvtIs = 0;
      let ImpotSoc = 0;
      let Reserve = 0;
      let CapitalSocial = 0;
      let SeuilAssuj = 0;
      let SetSeuilAssuj = false;
      let DivAutres = 0;
      let DividendeBrut = 0;
      let PartSoc = 100;
      let PartSocConj = 100;
      let PeriodeConjointPartSoc = 100;
      let PeriodeConjointPartSocConj = 0;
      let OptiRemStatut = this.state.PeriodeActuelle[0]?.Statut;
      let EnvEntrConjoint = 0;
      let CoutRemunerationConjoint = 0;
      let CotisationsConjoint = 0;
      let RemBruteConjoint = 0;
      let AvantageNatureConjoint = 0;
      let RemNetteConjoint = 0;
      let RemImposableConjoint = 0;
      let CapitalSocialConjoint = 0;
      let SeuilAssujConjoint = 0;
      let SetSeuilAssujConjoint = false;
      let ResAvtIsConjoint = 0;
      let ImpotSocConjoint = 0;
      let ReserveConjoint = 0;
      let DivAutresConjoint = 0;
      let DividendeBrutConjoint = 0;
      let RevPercus = 0;
      let CotFacPerso = 0;
      let CotFacPersoConjoint = 0;
      let CDSStatutConjoint = "";
      let OptiRemStatutConjoint = "";

      //CLIENT
      if (this.state.PeriodeActuelle[0]?.Statut === "TNS Article 62" || this.state.PeriodeActuelle[0]?.Statut === "TNS individuel") {
        CoutRemuneration = ResultatTNS.state.RemunerationTNS.CoutRemuneration;
        Cotisations = Number(ResultatTNS.state.RemunerationTNS.TotalChargesSociales) + Number(ResultatTNS.state.RemunerationTNS.CotisationsFacultativesEntreprise);
        RemNette = ResultatTNS.state.RemunerationTNS.RemunerationNette;
        AvantageNature = this.state.PeriodeActuelle[0]?.AvantageNature;
        RemImposable = ResultatTNS.state.RemunerationTNS.BrutImposable;

        EnvEntr += CoutRemuneration + ResultatTNS.state.RemunerationTNS.CotisationsFacultativesFraisPro;
        RevPercus += RemNette;
        CotFacPerso += ResultatTNS.state.RemunerationTNS.VersementFacultatifPerso;
      } else {
        CoutRemuneration = ResultatSalarie.state.RemunerationSalarie.CoutRemuneration;
        Cotisations = Number(ResultatSalarie.state.RemunerationSalarie.TotalChargesPatronale) + Number(ResultatSalarie.state.RemunerationSalarie.TotalChargesSalariale);
        RemBrute = ResultatSalarie.state.RemunerationSalarie.SalaireBrut;
        RemNette = ResultatSalarie.state.RemunerationSalarie.SalaireNet;
        AvantageNature = this.state.PeriodeActuelle[0]?.AvantageNature;
        RemImposable = ResultatSalarie.state.RemunerationSalarie.BrutImposable;
        EnvEntr += CoutRemuneration;
        RevPercus += RemNette;
        CotFacPerso += ResultatSalarie.state.RemunerationSalarie.VersementFacultatifPerso;
      }
      let OptiRemConventionneCarcdsf = this.state.PeriodeActuelle[0].ConventionneCarcdsf;
      let OptiRemConventionneCarmf = this.state.PeriodeActuelle[0].ConventionneCarmf;
      let OptiRemOptionRsi = this.state.PeriodeActuelle[0].OptionRsi;
      let OptiRemConventionneCarpimko = this.state.PeriodeActuelle[0].ConventionneCarpimko;
      let OptiRemTauxCpam = this.state.PeriodeActuelle[0].TauxCpam;
      let OptiRemClassePrevoyanceCarpv = this.state.PeriodeActuelle[0].ClassePrevoyanceCarpv;
      let OptiRemClasseRetraiteCarpv = this.state.PeriodeActuelle[0].ClasseRetraiteCarpv;
      let OptiRemClassePrevoyanceCavec = this.state.PeriodeActuelle[0].ClassePrevoyanceCavec;
      let OptiRemClasseRetraiteCavec = this.state.PeriodeActuelle[0].ClasseRetraiteCavec;
      let OptiRemConventionneCavp = this.state.PeriodeActuelle[0].ConventionneCavp;
      let OptiRemClasseRetraiteCavp = this.state.PeriodeActuelle[0].ClasseRetraiteCavp;
      let OptiRemClassePrevoyanceCipav = this.state.PeriodeActuelle[0].ClassePrevoyanceCipav;
      let OptiRemClasseRetraiteCipav = this.state.PeriodeActuelle[0].ClasseRetraiteCipav;
      let OptiRemAncienneteCnbf = this.state.PeriodeActuelle[0].AncienneteCnbf;
      let OptiRemClasseRetraiteCnbf = this.state.PeriodeActuelle[0].ClasseRetraiteCnbf;
      let OptiRemLpaMontantIncap = this.state.PeriodeActuelle[0].LpaMontantIncap;
      let OptiRemLpaFranchiseIncapDebut = this.state.PeriodeActuelle[0].LpaFranchiseIncapDebut;
      let OptiRemLpaFranchiseIncapFin = this.state.PeriodeActuelle[0].LpaFranchiseIncapFin;
      let OptiRemLpaInvalPartielle = this.state.PeriodeActuelle[0].LpaInvalPartielle;
      let OptiRemLpaInvalTotale = this.state.PeriodeActuelle[0].LpaInvalTotale;
      let OptiRemClasseRetraiteCrn = this.state.PeriodeActuelle[0].ClasseRetraiteCrn;
      let OptiRemCrnSermentAvt2014 = this.state.PeriodeActuelle[0].CrnSermentAvt2014;
      let OptiRemCrnColmarMetz = this.state.PeriodeActuelle[0].CrnColmarMetz;
      let OptiRemAtexa = this.state.PeriodeActuelle[0].Atexa;
      let OptiRemTauxActivite = this.state.PeriodeActuelle[0].TauxActivite;
      let OptiRemEffectif = this.state.PeriodeActuelle[0].Effectif;
      let OptiRemCavecSalarie = this.state.PeriodeActuelle[0].CnbfSalarie !== "Non" ? "Non" : this.state.PeriodeActuelle[0].CavecSalarie;
      let OptiRemCnbfSalarie = this.state.PeriodeActuelle[0].CnbfSalarie;
      let OptiRemAccidentTravail = this.state.PeriodeActuelle[0].AccidentTravail;
      let OptiRemAgircArrcoT1Sal = this.state.PeriodeActuelle[0].AgircArrcoT1Sal;
      let OptiRemAgircArrcoT1Pat = this.state.PeriodeActuelle[0].AgircArrcoT1Pat;
      let OptiRemAgircArrcoT2Sal = this.state.PeriodeActuelle[0].AgircArrcoT2Sal;
      let OptiRemAgircArrcoT2Pat = this.state.PeriodeActuelle[0].AgircArrcoT2Pat;
      let OptiRemTransport = this.state.PeriodeActuelle[0].Transport;
      let OptiRemFormation = this.state.PeriodeActuelle[0].Formation;
      let OptiRemApprentissage = this.state.PeriodeActuelle[0].Apprentissage;
      let OptiRemRegime = this.state.PeriodeActuelle[0].Regime;
      let OptiRemTaxeSalaire = this.state.PeriodeActuelle[0].TaxeSalaire;
      let OptiRemAssuranceChomage = this.state.PeriodeActuelle[0].AssuranceChomage;
      let OptiRemReductionGenerale = this.state.PeriodeActuelle[0].ReductionGenerale;
      let OptiRemTabCotisationsManuelle = this.state.PeriodeActuelle[0].TabCotisationsManuelle;

      let OptiRemConventionneCarcdsfConjoint = this.state.PeriodeActuelle[0].ConventionneCarcdsfConjoint;
      let OptiRemConventionneCarmfConjoint = this.state.PeriodeActuelle[0].ConventionneCarmfConjoint;
      let OptiRemOptionRsiConjoint = this.state.PeriodeActuelle[0].OptionRsiConjoint;
      let OptiRemConventionneCarpimkoConjoint = this.state.PeriodeActuelle[0].ConventionneCarpimkoConjoint;
      let OptiRemTauxCpamConjoint = this.state.PeriodeActuelle[0].TauxCpamConjoint;
      let OptiRemClassePrevoyanceCarpvConjoint = this.state.PeriodeActuelle[0].ClassePrevoyanceCarpvConjoint;
      let OptiRemClasseRetraiteCarpvConjoint = this.state.PeriodeActuelle[0].ClasseRetraiteCarpvConjoint;
      let OptiRemClassePrevoyanceCavecConjoint = this.state.PeriodeActuelle[0].ClassePrevoyanceCavecConjoint;
      let OptiRemClasseRetraiteCavecConjoint = this.state.PeriodeActuelle[0].ClasseRetraiteCavecConjoint;
      let OptiRemConventionneCavpConjoint = this.state.PeriodeActuelle[0].ConventionneCavpConjoint;
      let OptiRemClasseRetraiteCavpConjoint = this.state.PeriodeActuelle[0].ClasseRetraiteCavpConjoint;
      let OptiRemClassePrevoyanceCipavConjoint = this.state.PeriodeActuelle[0].ClassePrevoyanceCipavConjoint;
      let OptiRemClasseRetraiteCipavConjoint = this.state.PeriodeActuelle[0].ClasseRetraiteCipavConjoint;
      let OptiRemAncienneteCnbfConjoint = this.state.PeriodeActuelle[0].AncienneteCnbfConjoint;
      let OptiRemClasseRetraiteCnbfConjoint = this.state.PeriodeActuelle[0].ClasseRetraiteCnbfConjoint;
      let OptiRemLpaMontantIncapConjoint = this.state.PeriodeActuelle[0].LpaMontantIncapConjoint;
      let OptiRemLpaFranchiseIncapDebutConjoint = this.state.PeriodeActuelle[0].LpaFranchiseIncapDebutConjoint;
      let OptiRemLpaFranchiseIncapFinConjoint = this.state.PeriodeActuelle[0].LpaFranchiseIncapFinConjoint;
      let OptiRemLpaInvalPartielleConjoint = this.state.PeriodeActuelle[0].LpaInvalPartielleConjoint;
      let OptiRemLpaInvalTotaleConjoint = this.state.PeriodeActuelle[0].LpaInvalTotaleConjoint;
      let OptiRemClasseRetraiteCrnConjoint = this.state.PeriodeActuelle[0].ClasseRetraiteCrnConjoint;
      let OptiRemCrnSermentAvt2014Conjoint = this.state.PeriodeActuelle[0].CrnSermentAvt2014Conjoint;
      let OptiRemCrnColmarMetzConjoint = this.state.PeriodeActuelle[0].CrnColmarMetzConjoint;
      let OptiRemAtexaConjoint = this.state.PeriodeActuelle[0].AtexaConjoint;
      let OptiRemTauxActiviteConjoint = this.state.PeriodeActuelle[0].TauxActiviteConjoint;
      let OptiRemEffectifConjoint = this.state.PeriodeActuelle[0].EffectifConjoint;
      let OptiRemCavecSalarieConjoint = this.state.PeriodeActuelle[0].CnbfSalarieConjoint !== "Non" ? "Non" : this.state.PeriodeActuelle[0].CavecSalarieConjoint;
      let OptiRemCnbfSalarieConjoint = this.state.PeriodeActuelle[0].CnbfSalarieConjoint;
      let OptiRemAccidentTravailConjoint = this.state.PeriodeActuelle[0].AccidentTravailConjoint;
      let OptiRemAgircArrcoT1SalConjoint = this.state.PeriodeActuelle[0].AgircArrcoT1SalConjoint;
      let OptiRemAgircArrcoT1PatConjoint = this.state.PeriodeActuelle[0].AgircArrcoT1PatConjoint;
      let OptiRemAgircArrcoT2SalConjoint = this.state.PeriodeActuelle[0].AgircArrcoT2SalConjoint;
      let OptiRemAgircArrcoT2PatConjoint = this.state.PeriodeActuelle[0].AgircArrcoT2PatConjoint;
      let OptiRemTransportConjoint = this.state.PeriodeActuelle[0].TransportConjoint;
      let OptiRemFormationConjoint = this.state.PeriodeActuelle[0].FormationConjoint;
      let OptiRemApprentissageConjoint = this.state.PeriodeActuelle[0].ApprentissageConjoint;
      let OptiRemRegimeConjoint = this.state.PeriodeActuelle[0].RegimeConjoint;
      let OptiRemTaxeSalaireConjoint = this.state.PeriodeActuelle[0].TaxeSalaireConjoint;
      let OptiRemAssuranceChomageConjoint = this.state.PeriodeActuelle[0].AssuranceChomageConjoint;
      let OptiRemReductionGeneraleConjoint = this.state.PeriodeActuelle[0].ReductionGeneraleConjoint;
      let OptiRemTabCotisationsManuelleConjoint = this.state.PeriodeActuelle[0].TabCotisationsManuelleConjoint;
      //CONJOINT DANS MEME SOCIETE
      if (this.state.InclureConjoint === true && this.state["PeriodeActuelle"][0]?.StatutConjoint !== "Aucun") {
        OptiRemStatutConjoint = this.state["PeriodeActuelle"][0]?.StatutConjoint;
        if (this.state["PeriodeActuelle"][0]?.StatutConjoint === "TNS Article 62" || this.state["PeriodeActuelle"][0]?.StatutConjoint === "TNS individuel") {
          CDSStatutConjoint = "Assimilé salarié";
          CoutRemunerationConjoint = ResultatTNS.state.RemunerationTNSConjoint.CoutRemuneration;
          CotisationsConjoint = Number(ResultatTNS.state.RemunerationTNSConjoint.TotalChargesSociales) + Number(ResultatTNS.state.RemunerationTNSConjoint.CotisationsFacultativesEntreprise);
          RemBruteConjoint = ResultatTNS.state.RemunerationTNSConjoint.RemunerationBrute;
          RemNetteConjoint = ResultatTNS.state.RemunerationTNSConjoint.RemunerationNette;
          AvantageNatureConjoint = this.state.PeriodeActuelle[0]?.AvantageNatureConjoint;
          RemImposableConjoint = ResultatTNS.state.RemunerationTNSConjoint.BrutImposable;
          EnvEntr += ResultatTNS.state.RemunerationTNSConjoint.CoutRemuneration + ResultatTNS.state.RemunerationTNSConjoint.CotisationsFacultativesFraisPro;
          RevPercus += RemNetteConjoint;
          CotFacPerso += ResultatTNS.state.RemunerationTNSConjoint.VersementFacultatifPerso;
        } else {
          CDSStatutConjoint = "TNS Article 62";
          CoutRemunerationConjoint = ResultatSalarie.state.RemunerationSalarieConjoint.CoutRemuneration;
          CotisationsConjoint =
            Number(ResultatSalarie.state.RemunerationSalarieConjoint.TotalChargesPatronale) + Number(ResultatSalarie.state.RemunerationSalarieConjoint.TotalChargesSalariale);
          RemBruteConjoint = ResultatSalarie.state.RemunerationSalarieConjoint.SalaireBrut;
          RemNetteConjoint = ResultatSalarie.state.RemunerationSalarieConjoint.SalaireNet;
          AvantageNatureConjoint = this.state.PeriodeActuelle[0]?.AvantageNatureConjoint;
          RemImposableConjoint = ResultatSalarie.state.RemunerationSalarieConjoint.BrutImposable;
          EnvEntr += CoutRemunerationConjoint;
          RevPercus += RemNetteConjoint;
          CotFacPerso += ResultatSalarie.state.RemunerationSalarieConjoint.VersementFacultatifPerso;
        }
      }
      if (this.state.PeriodeActuelle[0]?.Statut !== "TNS individuel") {
        CapitalSocial = this.state.PeriodeActuelle[0].CapitalSocial;
        SeuilAssuj = this.state.PeriodeActuelle[0].SeuilAssuj;
        SetSeuilAssuj = this.state.PeriodeActuelle[0].SetSeuilAssuj;
        SeuilAssujConjoint = this.state.PeriodeActuelle[0].SeuilAssujConjoint;
        SetSeuilAssujConjoint = this.state.PeriodeActuelle[0].SetSeuilAssujConjoint;
        ResAvtIs = this.state.PeriodeActuelle[0].ResAvtIs;
        PartSoc = this.state.PeriodeActuelle[0].PartSoc;
        PartSocConj = this.state.PeriodeActuelle[0].PartSocConj;
        ImpotSoc = this.state.PeriodeActuelle[0].ImpotSoc;
        Reserve = Number(this.state.PeriodeActuelle[0].Reserve);
        DivAutres = Number(this.state.PeriodeActuelle[0].DividendeBrutAutres);
        DividendeBrut = this.state.PeriodeActuelle[0].DividendeBrut;

        EnvEntr += ResAvtIs;
        RevPercus += DividendeBrut;
      }
      let CaisseCDSConjoint = "RsiCommercant";
      //CONJOINT
      if (this.state.PeriodeActuelleConjoint[0] !== undefined) {
        OptiRemStatutConjoint = this.state.PeriodeActuelleConjoint[0]?.Statut;
        if (this.state.PeriodeActuelleConjoint[0]?.Statut === "TNS Article 62" || this.state.PeriodeActuelleConjoint[0]?.Statut === "TNS individuel") {
          CaisseCDSConjoint = this.state.PeriodeActuelleConjoint[0]?.Caisse;
          CDSStatutConjoint = "Assimilé salarié";
          CoutRemunerationConjoint = ResultatTNS.state.RemunerationTNSConjoint.CoutRemuneration;
          CotisationsConjoint = Number(ResultatTNS.state.RemunerationTNSConjoint.TotalChargesSociales) + Number(ResultatTNS.state.RemunerationTNSConjoint.CotisationsFacultativesEntreprise);
          RemNetteConjoint = ResultatTNS.state.RemunerationTNSConjoint.RemunerationNette;
          AvantageNatureConjoint = this.state.PeriodeActuelleConjoint[0]?.AvantageNature;
          RemImposableConjoint = ResultatTNS.state.RemunerationTNSConjoint.BrutImposable;

          EnvEntrConjoint += ResultatTNS.state.RemunerationTNSConjoint.CoutRemuneration + ResultatTNS.state.RemunerationTNSConjoint.CotisationsFacultativesFraisPro;
          RevPercus += RemNetteConjoint;
          CotFacPersoConjoint = ResultatTNS.state.RemunerationTNSConjoint.VersementFacultatifPerso;
        } else {
          CDSStatutConjoint = "TNS Article 62";
          CoutRemunerationConjoint = ResultatSalarie.state.RemunerationSalarieConjoint.CoutRemuneration;
          CotisationsConjoint =
            Number(ResultatSalarie.state.RemunerationSalarieConjoint.TotalChargesPatronale) + Number(ResultatSalarie.state.RemunerationSalarieConjoint.TotalChargesSalariale);
          RemBruteConjoint = ResultatSalarie.state.RemunerationSalarieConjoint.SalaireBrut;
          RemNetteConjoint = ResultatSalarie.state.RemunerationSalarieConjoint.SalaireNet;
          AvantageNatureConjoint = this.state.PeriodeActuelleConjoint[0]?.AvantageNature;
          RemImposableConjoint = ResultatSalarie.state.RemunerationSalarieConjoint.BrutImposable;
          EnvEntrConjoint += CoutRemunerationConjoint;
          RevPercus += RemNetteConjoint;
          CotFacPersoConjoint = ResultatSalarie.state.RemunerationSalarieConjoint.VersementFacultatifPerso;
        }
        OptiRemConventionneCarcdsfConjoint = this.state.PeriodeActuelleConjoint[0].ConventionneCarcdsf;
        OptiRemConventionneCarmfConjoint = this.state.PeriodeActuelleConjoint[0].ConventionneCarmf;
        OptiRemOptionRsiConjoint = this.state.PeriodeActuelleConjoint[0].OptionRsi;
        OptiRemConventionneCarpimkoConjoint = this.state.PeriodeActuelleConjoint[0].ConventionneCarpimko;
        OptiRemTauxCpamConjoint = this.state.PeriodeActuelleConjoint[0].TauxCpam;
        OptiRemClassePrevoyanceCarpvConjoint = this.state.PeriodeActuelleConjoint[0].ClassePrevoyanceCarpv;
        OptiRemClasseRetraiteCarpvConjoint = this.state.PeriodeActuelleConjoint[0].ClasseRetraiteCarpv;
        OptiRemClassePrevoyanceCavecConjoint = this.state.PeriodeActuelleConjoint[0].ClassePrevoyanceCavec;
        OptiRemClasseRetraiteCavecConjoint = this.state.PeriodeActuelleConjoint[0].ClasseRetraiteCavec;
        OptiRemConventionneCavpConjoint = this.state.PeriodeActuelleConjoint[0].ConventionneCavp;
        OptiRemClasseRetraiteCavpConjoint = this.state.PeriodeActuelleConjoint[0].ClasseRetraiteCavp;
        OptiRemClassePrevoyanceCipavConjoint = this.state.PeriodeActuelleConjoint[0].ClassePrevoyanceCipav;
        OptiRemClasseRetraiteCipavConjoint = this.state.PeriodeActuelleConjoint[0].ClasseRetraiteCipav;
        OptiRemAncienneteCnbfConjoint = this.state.PeriodeActuelleConjoint[0].AncienneteCnbf;
        OptiRemClasseRetraiteCnbfConjoint = this.state.PeriodeActuelleConjoint[0].ClasseRetraiteCnbf;
        OptiRemLpaMontantIncapConjoint = this.state.PeriodeActuelleConjoint[0].LpaMontantIncap;
        OptiRemLpaFranchiseIncapDebutConjoint = this.state.PeriodeActuelleConjoint[0].LpaFranchiseIncapDebut;
        OptiRemLpaFranchiseIncapFinConjoint = this.state.PeriodeActuelleConjoint[0].LpaFranchiseIncapFin;
        OptiRemLpaInvalPartielleConjoint = this.state.PeriodeActuelleConjoint[0].LpaInvalPartielle;
        OptiRemLpaInvalTotaleConjoint = this.state.PeriodeActuelleConjoint[0].LpaInvalTotale;
        OptiRemClasseRetraiteCrnConjoint = this.state.PeriodeActuelleConjoint[0].ClasseRetraiteCrn;
        OptiRemCrnSermentAvt2014Conjoint = this.state.PeriodeActuelleConjoint[0].CrnSermentAvt2014;
        OptiRemCrnColmarMetzConjoint = this.state.PeriodeActuelleConjoint[0].CrnColmarMetz;
        OptiRemAtexaConjoint = this.state.PeriodeActuelleConjoint[0].Atexa;
        OptiRemTauxActiviteConjoint = this.state.PeriodeActuelleConjoint[0].TauxActivite;
        OptiRemEffectifConjoint = this.state.PeriodeActuelleConjoint[0].Effectif;
        OptiRemCavecSalarieConjoint = this.state.PeriodeActuelleConjoint[0].CnbfSalarieConjoint !== "Non" ? "Non" : this.state.PeriodeActuelleConjoint[0].CavecSalarie;
        OptiRemCnbfSalarieConjoint = this.state.PeriodeActuelleConjoint[0].CnbfSalarie;
        OptiRemAccidentTravailConjoint = this.state.PeriodeActuelleConjoint[0].AccidentTravail;
        OptiRemAgircArrcoT1SalConjoint = this.state.PeriodeActuelleConjoint[0].AgircArrcoT1Sal;
        OptiRemAgircArrcoT1PatConjoint = this.state.PeriodeActuelleConjoint[0].AgircArrcoT1Pat;
        OptiRemAgircArrcoT2SalConjoint = this.state.PeriodeActuelleConjoint[0].AgircArrcoT2Sal;
        OptiRemAgircArrcoT2PatConjoint = this.state.PeriodeActuelleConjoint[0].AgircArrcoT2Pat;
        OptiRemTransportConjoint = this.state.PeriodeActuelleConjoint[0].Transport;
        OptiRemFormationConjoint = this.state.PeriodeActuelleConjoint[0].Formation;
        OptiRemApprentissageConjoint = this.state.PeriodeActuelleConjoint[0].Apprentissage;
        OptiRemRegimeConjoint = this.state.PeriodeActuelleConjoint[0].Regime;
        OptiRemTaxeSalaireConjoint = this.state.PeriodeActuelleConjoint[0].TaxeSalaire;
        OptiRemAssuranceChomageConjoint = this.state.PeriodeActuelleConjoint[0].AssuranceChomage;
        OptiRemReductionGeneraleConjoint = this.state.PeriodeActuelleConjoint[0].ReductionGenerale;
        OptiRemTabCotisationsManuelleConjoint = this.state.PeriodeActuelleConjoint[0].TabCotisationsManuelle;

        if (this.state.PeriodeActuelleConjoint[0]?.Statut !== "TNS individuel") {
          CapitalSocialConjoint = this.state.PeriodeActuelleConjoint[0].CapitalSocial;
          SeuilAssujConjoint = this.state.PeriodeActuelleConjoint[0].SeuilAssuj;
          SetSeuilAssujConjoint = this.state.PeriodeActuelleConjoint[0].SetSeuilAssuj;
          ResAvtIsConjoint = this.state.PeriodeActuelleConjoint[0].ResAvtIs;
          PeriodeConjointPartSoc = this.state.PeriodeActuelleConjoint[0].PartSoc;
          PeriodeConjointPartSocConj = this.state.PeriodeActuelleConjoint[0].PartSocConj;
          ImpotSocConjoint = this.state.PeriodeActuelleConjoint[0].ImpotSoc;
          ReserveConjoint = Number(this.state.PeriodeActuelleConjoint[0].Reserve);
          DivAutresConjoint = Number(this.state.PeriodeActuelleConjoint[0].DividendeBrutAutres);
          DividendeBrutConjoint = this.state.PeriodeActuelleConjoint[0].DividendeBrut;

          EnvEntrConjoint += ResAvtIsConjoint;
          RevPercus += DividendeBrut;
        }
      }
      this.setState(
        {
          OptiRemTypeCalcul: "res_avant_is",
          OptiRemChoixOptimum: "automatique",
          OptiRemOptimumRemMini: 0,
          // OptiRemTnsMontantSaisi: ResultatRemExpertProvider.state.RemunerationTNS.CoutEntreprise,
          // OptiRemTnsCoutEntreprise: ResultatRemExpertProvider.state.RemunerationTNS.CoutEntreprise,
          OptiRemUnlockCoutEntreprise: false,
          OptiRemPeriodeActuelle: _.cloneDeep(this.state.PeriodeActuelle),
          OptiRemPeriodeActuelleConjoint: _.cloneDeep(this.state.PeriodeActuelleConjoint),
          OptiRemCoutEntreprise: EnvEntr,
          OptiRemCoutRemuneration: CoutRemuneration,
          OptiRemCotisations: Cotisations,
          OptiRemRemBrute: RemBrute,
          OptiRemRemNette: RemNette,
          OptiRemAvantageNature: AvantageNature,
          OptiRemRemImposable: RemImposable,
          OptiRemResAvtIs: ResAvtIs,
          OptiRemImpotSoc: ImpotSoc,
          OptiRemReserve: Reserve,
          OptiRemCapitalSocial: CapitalSocial,
          OptiRemSeuilAssuj: SeuilAssuj,
          OptiRemPartSoc: PartSoc,
          OptiRemPartSocConj: PartSocConj,
          OptiRemPeriodeConjointPartSoc: PeriodeConjointPartSoc,
          OptiRemPeriodeConjointPartSocConj: PeriodeConjointPartSocConj,

          OptiRemSeuilAssujConjoint: SeuilAssujConjoint,
          OptiRemSetSeuilAssuj: SetSeuilAssuj,
          OptiRemSetSeuilAssujConjoint: SetSeuilAssujConjoint,
          OptiRemDivAutres: DivAutres,
          OptiRemDividendeBrut: DividendeBrut,

          OptiRemUnlockCoutEntrepriseConjoint: false,
          OptiRemCoutEntrepriseConjoint: EnvEntrConjoint,
          OptiRemCoutRemunerationConjoint: CoutRemunerationConjoint,
          OptiRemCotisationsConjoint: CotisationsConjoint,
          OptiRemRemBruteConjoint: RemBruteConjoint,
          OptiRemAvantageNatureConjoint: AvantageNatureConjoint,
          OptiRemRemNetteConjoint: RemNetteConjoint,
          OptiRemRemImposableConjoint: RemImposableConjoint,
          OptiRemCapitalSocialConjoint: CapitalSocialConjoint,
          OptiRemResAvtIsConjoint: ResAvtIsConjoint,
          OptiRemImpotSocConjoint: ImpotSocConjoint,
          OptiRemReserveConjoint: ReserveConjoint,
          OptiRemDivAutresConjoint: DivAutresConjoint,
          OptiRemDividendeBrutConjoint: DividendeBrutConjoint,

          OptiRemRevPercus: RevPercus,
          OptiRemRevenusFoyer: ResultatTNS.state.DetailImpot?.AutresRevenusFoyer,
          OptiRemIRTotal: ResultatTNS.state.DetailImpot?.IRTotal,
          OptiRemCotFacPerso: CotFacPerso,
          OptiRemCotFacPersoConjoint: CotFacPersoConjoint,
          OptiRemRevDispo: Number(RevPercus) + Number(ResultatTNS.state.DetailImpot?.AutresRevenusFoyer) - Number(CotFacPerso) - Number(ResultatTNS.state.DetailImpot?.IRTotal),
          // OptiRemDividendeBrutConjoint: DividendeBrutConjoint,
          OptiRemTauxISReduit: this.state.TauxISReduit,
          OptiRemTauxISNormal: this.state.TauxISNormal,
          OptiRemTauxISZero: this.state.TauxISZero,

          OptiRemAutresRevenus: this.state.AutresRevenus,
          OptiRemDeductionFiscale: this.state.DeductionFiscale,
          OptiRemReductionImpot: this.state.ReductionImpot,
          OptiRemIRDirigeantTraitement: this.state.IRDirigeantTraitement,
          OptiRemIRConjointTraitement: this.state.IRConjointTraitement,
          OptiRemIRDirigeantAutresRevenus: this.state.IRDirigeantAutresRevenus,
          OptiRemIRConjointAutresRevenus: this.state.IRConjointAutresRevenus,
          OptiRemIRDirigeantAbattement: this.state.IRDirigeantAbattement,
          OptiRemIRConjointAbattement: this.state.IRConjointAbattement,
          OptiRemIRDirigeantFraisReel: this.state.IRDirigeantFraisReel,
          OptiRemIRConjointFraisReel: this.state.IRConjointFraisReel,
          OptiRemIRDirigeantBNCMicro: this.state.IRDirigeantBNCMicro,
          OptiRemIRConjointBNCMicro: this.state.IRConjointBNCMicro,
          OptiRemIRDirigeantBNCControlle: this.state.IRDirigeantBNCControlle,
          OptiRemIRConjointBNCControlle: this.state.IRConjointBNCControlle,
          OptiRemIRDirigeantBICMicroAchatRevente: this.state.IRDirigeantBICMicroAchatRevente,
          OptiRemIRConjointBICMicroAchatRevente: this.state.IRConjointBICMicroAchatRevente,
          OptiRemIRDirigeantBICMicroService: this.state.IRDirigeantBICMicroService,
          OptiRemIRConjointBICMicroService: this.state.IRConjointBICMicroService,
          OptiRemIRDirigeantBICReel: this.state.IRDirigeantBICReel,
          OptiRemIRConjointBICReel: this.state.IRConjointBICReel,
          OptiRemIRDirigeantBAMicro: this.state.IRDirigeantBAMicro,
          OptiRemIRConjointBAMicro: this.state.IRConjointBAMicro,
          OptiRemIRDirigeantBAReel: this.state.IRDirigeantBAReel,
          OptiRemIRConjointBAReel: this.state.IRConjointBAReel,
          OptiRemIRDirigeantRetraite: this.state.IRDirigeantRetraite,
          OptiRemIRConjointRetraite: this.state.IRConjointRetraite,
          OptiRemIRDirigeantInvalidite: this.state.IRDirigeantInvalidite,
          OptiRemIRConjointInvalidite: this.state.IRConjointInvalidite,
          OptiRemIRDirigeantAlimentaire: this.state.IRDirigeantAlimentaire,
          OptiRemIRConjointAlimentaire: this.state.IRConjointAlimentaire,
          OptiRemIRDirigeantCapital: this.state.IRDirigeantCapital,
          OptiRemIRConjointCapital: this.state.IRConjointCapital,
          OptiRemIRDirigeantRenteMoins50: this.state.IRDirigeantRenteMoins50,
          OptiRemIRDirigeantRente5059: this.state.IRDirigeantRente5059,
          OptiRemIRDirigeantRente6069: this.state.IRDirigeantRente6069,
          OptiRemIRDirigeantRente70: this.state.IRDirigeantRente70,
          OptiRemOptionPfu: this.state.OptionPfu,
          OptiRemIRDirigeantAutreDividendeSoumisPS: this.state.IRDirigeantAutreDividendeSoumisPS,
          OptiRemIRDirigeantAutreDividendeNonSoumisPS: this.state.IRDirigeantAutreDividendeNonSoumisPS,
          OptiRemIRDirigeantPlusValue: this.state.IRDirigeantPlusValue,
          OptiRemIRDirigeantMoinsValue: this.state.IRDirigeantMoinsValue,
          OptiRemIRDirigeantAbattementDureeDetention: this.state.IRDirigeantAbattementDureeDetention,
          OptiRemIRDirigeantAbattementDepartRetraite: this.state.IRDirigeantAbattementDepartRetraite,
          OptiRemIRDirigeantRecette: this.state.IRDirigeantRecette,
          OptiRemIRDirigeantRegimeRevenuFoncier: this.state.IRDirigeantRegimeRevenuFoncier,
          OptiRemIRDirigeantChargeRevenuFoncier: this.state.IRDirigeantChargeRevenuFoncier,
          OptiRemIRConjointCotisationPerp: this.state.IRConjointCotisationPerp,
          OptiRemIRDirigeantPlafond1: this.state.IRDirigeantPlafond1,
          OptiRemIRConjointPlafond1: this.state.IRConjointPlafond1,
          OptiRemIRDirigeantPlafond2: this.state.IRDirigeantPlafond2,
          OptiRemIRConjointPlafond2: this.state.IRConjointPlafond2,
          OptiRemIRDirigeantPlafond3: this.state.IRDirigeantPlafond3,
          OptiRemIRConjointPlafond3: this.state.IRConjointPlafond3,
          OptiRemIRDirigeantPlafondNM1: this.state.IRDirigeantPlafondNM1,
          OptiRemSetIRDirigeantPlafondNM1: this.state.SetIRDirigeantPlafondNM1,
          OptiRemIRConjointPlafondNM1: this.state.IRConjointPlafondNM1,
          OptiRemSetIRConjointPlafondNM1: this.state.SetIRConjointPlafondNM1,
          OptiRemIRDirigeantAutresRevenusSoumisPSAvecCSGDed: this.state.IRDirigeantAutresRevenusSoumisPSAvecCSGDed,
          OptiRemIRDirigeantAutresRevenusSoumisPSSansCSGDed: this.state.IRDirigeantAutresRevenusSoumisPSSansCSGDed,
          OptiRemIRCSGDedRevenuPatrimoine: this.state.IRCSGDedRevenuPatrimoine,
          OptiRemIRPensionAlimEnfant1: this.state.IRPensionAlimEnfant1,
          OptiRemIRPensionAlimEnfant2: this.state.IRPensionAlimEnfant2,
          OptiRemIRAutrePensionAlimEnfant1: this.state.IRAutrePensionAlimEnfant1,
          OptiRemIRAutrePensionAlimEnfant2: this.state.IRAutrePensionAlimEnfant2,
          OptiRemIRPensionAlim: this.state.IRPensionAlim,
          OptiRemIRAutrePensionAlim: this.state.IRAutrePensionAlim,
          OptiRemIRDonPersonne: this.state.IRDonPersonne,
          OptiRemIRDonAssociation: this.state.IRDonAssociation,
          OptiRemIREmploiDomicile: this.state.IREmploiDomicile,
          OptiRemIRFraisGardeEnfantExclusive1: this.state.IRFraisGardeEnfantExclusive1,
          OptiRemIRFraisGardeEnfantPartagee1: this.state.IRFraisGardeEnfantPartagee1,
          OptiRemIRFraisGardeEnfantExclusive2: this.state.IRFraisGardeEnfantExclusive2,
          OptiRemIRFraisGardeEnfantPartagee2: this.state.IRFraisGardeEnfantPartagee2,
          OptiRemIRFraisGardeEnfantExclusive3: this.state.IRFraisGardeEnfantExclusive3,
          OptiRemIRFraisGardeEnfantPartagee3: this.state.IRFraisGardeEnfantPartagee3,
          OptiRemIREtudeEnfantCollegeExclusive: this.state.IREtudeEnfantCollegeExclusive,
          OptiRemIREtudeEnfantCollegePartagee: this.state.IREtudeEnfantCollegePartagee,
          OptiRemIREtudeEnfantLyceeExclusive: this.state.IREtudeEnfantLyceeExclusive,
          OptiRemIREtudeEnfantLyceePartagee: this.state.IREtudeEnfantLyceePartagee,
          OptiRemIREtudeEnfantFacExclusive: this.state.IREtudeEnfantFacExclusive,
          OptiRemIREtudeEnfantFacPartagee: this.state.IREtudeEnfantFacPartagee,
          OptiRemContratEnregistre: this.state.ContratEnregistre.slice(0),
          OptiRemContratEnregistreConjoint: this.state.ContratEnregistreConjoint.slice(0),
          OptiRemContratFraisPro: this.state.ContratFraisPro.slice(0),
          OptiRemContratFraisProConjoint: this.state.ContratFraisProConjoint.slice(0),
          OptiRemContratEnregistreSante: this.state.ContratEnregistreSante.slice(0),
          OptiRemContratEnregistreSanteConjoint: this.state.ContratEnregistreSanteConjoint.slice(0),
          OptiRemListeContrat: this.state.ListeContrat.slice(0),
          OptiRemListeContratConjoint: this.state.ListeContratConjoint.slice(0),
          OptiRemCarriere: this.state.Carriere.slice(0),
          OptiRemCarriereConjoint: this.state.CarriereConjoint.slice(0),
          OptiRemForfaitSocialArticle83: this.state.ForfaitSocialArticle83,
          OptiRemAssietteCotisation83: this.state.AssietteCotisation83,
          OptiRemInteressementCash: this.state.InteressementCash,
          OptiRemParticipationCash: this.state.ParticipationCash,
          OptiRemForfaitSocialInteressement: this.state.ForfaitSocialInteressement,
          OptiRemForfaitSocialParticipation: this.state.ForfaitSocialParticipation,
          OptiRemForfaitSocialAbondementPee: this.state.ForfaitSocialAbondementPee,
          OptiRemForfaitSocialAbondementPerco: this.state.ForfaitSocialAbondementPerco,

          OptiRemStatut: OptiRemStatut,
          OptiRemCDSEtude: "client",
          OptiRemCDSStatut: this.state.PeriodeActuelle[0]?.Statut === "TNS Article 62" ? "Assimilé salarié" : "TNS Article 62",
          OptiRemCDSCaisse: this.state.PeriodeActuelle[0].Caisse,
          OptiRemCDSRaisonnement: "cout_remuneration",
          OptiRemStatutConjoint: OptiRemStatutConjoint,
          OptiRemCDSStatutConjoint: CDSStatutConjoint,
          OptiRemCDSCaisseConjoint: CaisseCDSConjoint,
          OptiRemCDSRaisonnementConjoint: "cout_remuneration",
          OptiRemConventionneCarcdsf: OptiRemConventionneCarcdsf,
          OptiRemConventionneCarmf: OptiRemConventionneCarmf,
          OptiRemOptionRsi: OptiRemOptionRsi,
          OptiRemConventionneCarpimko: OptiRemConventionneCarpimko,
          OptiRemTauxCpam: OptiRemTauxCpam,
          OptiRemClassePrevoyanceCarpv: OptiRemClassePrevoyanceCarpv,
          OptiRemClasseRetraiteCarpv: OptiRemClasseRetraiteCarpv,
          OptiRemClassePrevoyanceCavec: OptiRemClassePrevoyanceCavec,
          OptiRemClasseRetraiteCavec: OptiRemClasseRetraiteCavec,
          OptiRemConventionneCavp: OptiRemConventionneCavp,
          OptiRemClasseRetraiteCavp: OptiRemClasseRetraiteCavp,
          OptiRemClassePrevoyanceCipav: OptiRemClassePrevoyanceCipav,
          OptiRemClasseRetraiteCipav: OptiRemClasseRetraiteCipav,
          OptiRemAncienneteCnbf: OptiRemAncienneteCnbf,
          OptiRemClasseRetraiteCnbf: OptiRemClasseRetraiteCnbf,
          OptiRemLpaMontantIncap: OptiRemLpaMontantIncap,
          OptiRemLpaFranchiseIncapDebut: OptiRemLpaFranchiseIncapDebut,
          OptiRemLpaFranchiseIncapFin: OptiRemLpaFranchiseIncapFin,
          OptiRemLpaInvalPartielle: OptiRemLpaInvalPartielle,
          OptiRemLpaInvalTotale: OptiRemLpaInvalTotale,
          OptiRemClasseRetraiteCrn: OptiRemClasseRetraiteCrn,
          OptiRemCrnSermentAvt2014: OptiRemCrnSermentAvt2014,
          OptiRemCrnColmarMetz: OptiRemCrnColmarMetz,
          OptiRemAtexa: OptiRemAtexa,
          OptiRemTauxActivite: OptiRemTauxActivite,
          OptiRemEffectif: OptiRemEffectif,
          OptiRemCavecSalarie: OptiRemCavecSalarie,
          OptiRemCnbfSalarie: OptiRemCnbfSalarie,
          OptiRemAccidentTravail: OptiRemAccidentTravail,
          OptiRemAgircArrcoT1Sal: OptiRemAgircArrcoT1Sal,
          OptiRemAgircArrcoT1Pat: OptiRemAgircArrcoT1Pat,
          OptiRemAgircArrcoT2Sal: OptiRemAgircArrcoT2Sal,
          OptiRemAgircArrcoT2Pat: OptiRemAgircArrcoT2Pat,
          OptiRemTransport: OptiRemTransport,
          OptiRemFormation: OptiRemFormation,
          OptiRemApprentissage: OptiRemApprentissage,
          OptiRemRegime: OptiRemRegime,
          OptiRemTaxeSalaire: OptiRemTaxeSalaire,
          OptiRemAssuranceChomage: OptiRemAssuranceChomage,
          OptiRemReductionGenerale: OptiRemReductionGenerale,
          OptiRemTabCotisationsManuelle: OptiRemTabCotisationsManuelle,
          OptiRemConventionneCarcdsfConjoint: OptiRemConventionneCarcdsfConjoint,
          OptiRemConventionneCarmfConjoint: OptiRemConventionneCarmfConjoint,
          OptiRemOptionRsiConjoint: OptiRemOptionRsiConjoint,
          OptiRemConventionneCarpimkoConjoint: OptiRemConventionneCarpimkoConjoint,
          OptiRemTauxCpamConjoint: OptiRemTauxCpamConjoint,
          OptiRemClassePrevoyanceCarpvConjoint: OptiRemClassePrevoyanceCarpvConjoint,
          OptiRemClasseRetraiteCarpvConjoint: OptiRemClasseRetraiteCarpvConjoint,
          OptiRemClassePrevoyanceCavecConjoint: OptiRemClassePrevoyanceCavecConjoint,
          OptiRemClasseRetraiteCavecConjoint: OptiRemClasseRetraiteCavecConjoint,
          OptiRemConventionneCavpConjoint: OptiRemConventionneCavpConjoint,
          OptiRemClasseRetraiteCavpConjoint: OptiRemClasseRetraiteCavpConjoint,
          OptiRemClassePrevoyanceCipavConjoint: OptiRemClassePrevoyanceCipavConjoint,
          OptiRemClasseRetraiteCipavConjoint: OptiRemClasseRetraiteCipavConjoint,
          OptiRemAncienneteCnbfConjoint: OptiRemAncienneteCnbfConjoint,
          OptiRemClasseRetraiteCnbfConjoint: OptiRemClasseRetraiteCnbfConjoint,
          OptiRemLpaMontantIncapConjoint: OptiRemLpaMontantIncapConjoint,
          OptiRemLpaFranchiseIncapDebutConjoint: OptiRemLpaFranchiseIncapDebutConjoint,
          OptiRemLpaFranchiseIncapFinConjoint: OptiRemLpaFranchiseIncapFinConjoint,
          OptiRemLpaInvalPartielleConjoint: OptiRemLpaInvalPartielleConjoint,
          OptiRemLpaInvalTotaleConjoint: OptiRemLpaInvalTotaleConjoint,
          OptiRemClasseRetraiteCrnConjoint: OptiRemClasseRetraiteCrnConjoint,
          OptiRemCrnSermentAvt2014Conjoint: OptiRemCrnSermentAvt2014Conjoint,
          OptiRemCrnColmarMetzConjoint: OptiRemCrnColmarMetzConjoint,
          OptiRemAtexaConjoint: OptiRemAtexaConjoint,
          OptiRemTauxActiviteConjoint: OptiRemTauxActiviteConjoint,
          OptiRemEffectifConjoint: OptiRemEffectifConjoint,
          OptiRemCavecSalarieConjoint: OptiRemCavecSalarieConjoint,
          OptiRemCnbfSalarieConjoint: OptiRemCnbfSalarieConjoint,
          OptiRemAccidentTravailConjoint: OptiRemAccidentTravailConjoint,
          OptiRemAgircArrcoT1SalConjoint: OptiRemAgircArrcoT1SalConjoint,
          OptiRemAgircArrcoT1PatConjoint: OptiRemAgircArrcoT1PatConjoint,
          OptiRemAgircArrcoT2SalConjoint: OptiRemAgircArrcoT2SalConjoint,
          OptiRemAgircArrcoT2PatConjoint: OptiRemAgircArrcoT2PatConjoint,
          OptiRemTransportConjoint: OptiRemTransportConjoint,
          OptiRemFormationConjoint: OptiRemFormationConjoint,
          OptiRemApprentissageConjoint: OptiRemApprentissageConjoint,
          OptiRemRegimeConjoint: OptiRemRegimeConjoint,
          OptiRemTaxeSalaireConjoint: OptiRemTaxeSalaireConjoint,
          OptiRemAssuranceChomageConjoint: OptiRemAssuranceChomageConjoint,
          OptiRemReductionGeneraleConjoint: OptiRemReductionGeneraleConjoint,
          OptiRemTabCotisationsManuelleConjoint: OptiRemTabCotisationsManuelleConjoint,
        },
        () => {
          // if (this.state.PeriodeActuelleConjoint[0] !== undefined) {
          //   this.handleCalculOptiRem(this.state.OptiRemTypeCalcul, "", "Conjoint", ResultatTNS, false, "").then(() => {
          //     resolve(true);
          //   });
          // } else {
          this.handleCalculOptiRem(this.state.OptiRemTypeCalcul, "", "", ResultatTNS, false, "").then(() => {
            resolve(true);
          });
          // }
        }
      );
    });
  };

  handleChangementStatut = (ClientConjoint, ResultatTNS) => {
    let OptiRemPeriodeActuelle = _.cloneDeep(this.state["OptiRemPeriodeActuelle" + ClientConjoint]);

    let OptiRemStatut = this.state["OptiRemCDSStatut" + ClientConjoint];
    let OptiRemPartSoc = this.state.OptiRemPartSoc;
    let OptiRemPartSocConj = this.state.OptiRemPartSocConj;
    let OptiRemStatutConjoint = this.state["OptiRemCDSStatutConjoint"];
    let ListeContratClient = _.cloneDeep(this.state.OptiRemListeContrat);
    let ContratsPEEPERCO = ListeContratClient.filter((Contrat) => Contrat.Fiscalite === "PEE" || Contrat.Fiscalite === "PERCO");
    let ContratsAModifier = ListeContratClient.filter((Contrat) => Contrat.Fiscalite === "Per" || Contrat.Fiscalite === "Article83" || Contrat.Fiscalite === "Pero");
    let ContratsRestants = ListeContratClient.filter(
      (Contrat) => Contrat.Fiscalite !== "Per" && Contrat.Fiscalite !== "Article83" && Contrat.Fiscalite !== "Pero" && Contrat.Fiscalite !== "PEE" && Contrat.Fiscalite !== "PERCO"
    );

    let ListeContratConjoint = _.cloneDeep(this.state.OptiRemListeContratConjoint);
    let ContratsPEEPERCOConjoint = ListeContratConjoint.filter((Contrat) => Contrat.Fiscalite === "PEE" || Contrat.Fiscalite === "PERCO");
    let ContratsAModifierConjoint = ListeContratConjoint.filter((Contrat) => Contrat.Fiscalite === "Per" || Contrat.Fiscalite === "Article83" || Contrat.Fiscalite === "Pero");
    let ContratsRestantsConjoint = ListeContratConjoint.filter(
      (Contrat) => Contrat.Fiscalite !== "Per" && Contrat.Fiscalite !== "Article83" && Contrat.Fiscalite !== "Pero" && Contrat.Fiscalite !== "PEE" && Contrat.Fiscalite !== "PERCO"
    );

    let OptiRemContratEnregistre = [];
    let OptiRemContratEnregistreConjoint = [];
    let OptiRemContratFraisPro = [];
    let OptiRemContratFraisProConjoint = [];
    let OptiRemContratEnregistreSante = [];
    let OptiRemContratEnregistreSanteConjoint = [];

    if (this.state.OptiRemCDSEtude !== "conjoint") {
      OptiRemPeriodeActuelle[0].Statut = this.state["OptiRemCDSStatut" + ClientConjoint];

      if (this.state["PeriodeActuelle"][0]?.Statut === "TNS individuel") {
        OptiRemPartSoc = 100;
        OptiRemPartSocConj = 0;
        OptiRemContratEnregistre = this.state.OptiRemContratEnregistre;
        OptiRemContratFraisPro = this.state.OptiRemContratFraisPro;
        OptiRemContratEnregistreSante = this.state.OptiRemContratEnregistreSante;
      }
      OptiRemPeriodeActuelle[0].Caisse = this.state["OptiRemCDSCaisse" + ClientConjoint];
      if (ClientConjoint === "") {
        OptiRemPeriodeActuelle[0].ConventionneCarcdsf = this.state.OptiRemConventionneCarcdsf;
        OptiRemPeriodeActuelle[0].ConventionneCarmf = this.state.OptiRemConventionneCarmf;
        OptiRemPeriodeActuelle[0].OptionRsi = this.state.OptiRemOptionRsi;
        OptiRemPeriodeActuelle[0].ConventionneCarpimko = this.state.OptiRemConventionneCarpimko;
        OptiRemPeriodeActuelle[0].TauxCpam = this.state.OptiRemTauxCpam;
        OptiRemPeriodeActuelle[0].ClassePrevoyanceCarpv = this.state.OptiRemClassePrevoyanceCarpv;
        OptiRemPeriodeActuelle[0].ClasseRetraiteCarpv = this.state.OptiRemClasseRetraiteCarpv;
        OptiRemPeriodeActuelle[0].ClassePrevoyanceCavec = this.state.OptiRemClassePrevoyanceCavec;
        OptiRemPeriodeActuelle[0].ClasseRetraiteCavec = this.state.OptiRemClasseRetraiteCavec;
        OptiRemPeriodeActuelle[0].ConventionneCavp = this.state.OptiRemConventionneCavp;
        OptiRemPeriodeActuelle[0].ClasseRetraiteCavp = this.state.OptiRemClasseRetraiteCavp;
        OptiRemPeriodeActuelle[0].ClassePrevoyanceCipav = this.state.OptiRemClassePrevoyanceCipav;
        OptiRemPeriodeActuelle[0].ClasseRetraiteCipav = this.state.OptiRemClasseRetraiteCipav;
        OptiRemPeriodeActuelle[0].AncienneteCnbf = this.state.OptiRemAncienneteCnbf;
        OptiRemPeriodeActuelle[0].ClasseRetraiteCnbf = this.state.OptiRemClasseRetraiteCnbf;
        OptiRemPeriodeActuelle[0].LpaMontantIncap = this.state.OptiRemLpaMontantIncap;
        OptiRemPeriodeActuelle[0].LpaFranchiseIncapDebut = this.state.OptiRemLpaFranchiseIncapDebut;
        OptiRemPeriodeActuelle[0].LpaFranchiseIncapFin = this.state.OptiRemLpaFranchiseIncapFin;
        OptiRemPeriodeActuelle[0].LpaInvalPartielle = this.state.OptiRemLpaInvalPartielle;
        OptiRemPeriodeActuelle[0].LpaInvalTotale = this.state.OptiRemLpaInvalTotale;
        OptiRemPeriodeActuelle[0].ClasseRetraiteCrn = this.state.OptiRemClasseRetraiteCrn;
        OptiRemPeriodeActuelle[0].CrnSermentAvt2014 = this.state.OptiRemCrnSermentAvt2014;
        OptiRemPeriodeActuelle[0].CrnColmarMetz = this.state.OptiRemCrnColmarMetz;
        OptiRemPeriodeActuelle[0].Atexa = this.state.OptiRemAtexa;
        OptiRemPeriodeActuelle[0].TauxActivite = this.state.OptiRemTauxActivite;
        OptiRemPeriodeActuelle[0].Effectif = this.state.OptiRemEffectif;
        OptiRemPeriodeActuelle[0].CavecSalarie = this.state.OptiRemCnbfSalarie !== "Non" ? "Non" : this.state.OptiRemCavecSalarie;
        OptiRemPeriodeActuelle[0].CnbfSalarie = this.state.OptiRemCnbfSalarie;
        OptiRemPeriodeActuelle[0].AccidentTravail = this.state.OptiRemAccidentTravail;
        OptiRemPeriodeActuelle[0].AgircArrcoT1Sal = this.state.OptiRemAgircArrcoT1Sal;
        OptiRemPeriodeActuelle[0].AgircArrcoT1Pat = this.state.OptiRemAgircArrcoT1Pat;
        OptiRemPeriodeActuelle[0].AgircArrcoT2Sal = this.state.OptiRemAgircArrcoT2Sal;
        OptiRemPeriodeActuelle[0].AgircArrcoT2Pat = this.state.OptiRemAgircArrcoT2Pat;
        OptiRemPeriodeActuelle[0].Transport = this.state.OptiRemTransport;
        OptiRemPeriodeActuelle[0].Formation = this.state.OptiRemFormation;
        OptiRemPeriodeActuelle[0].Apprentissage = this.state.OptiRemApprentissage;
        OptiRemPeriodeActuelle[0].Regime = this.state.OptiRemRegime;
        OptiRemPeriodeActuelle[0].TaxeSalaire = this.state.OptiRemTaxeSalaire;
        OptiRemPeriodeActuelle[0].AssuranceChomage = this.state.OptiRemAssuranceChomage;
        OptiRemPeriodeActuelle[0].ReductionGenerale = this.state.OptiRemReductionGenerale;
        OptiRemPeriodeActuelle[0].TabCotisationsManuelle = this.state.OptiRemTabCotisationsManuelle;
        OptiRemPeriodeActuelle[0].AvantageNature = this.state.OptiRemAvantageNature;
      } else {
        OptiRemPeriodeActuelle[0].ConventionneCarcdsf = this.state.OptiRemConventionneCarcdsfConjoint;
        OptiRemPeriodeActuelle[0].ConventionneCarmf = this.state.OptiRemConventionneCarmfConjoint;
        OptiRemPeriodeActuelle[0].OptionRsi = this.state.OptiRemOptionRsiConjoint;
        OptiRemPeriodeActuelle[0].ConventionneCarpimko = this.state.OptiRemConventionneCarpimkoConjoint;
        OptiRemPeriodeActuelle[0].TauxCpam = this.state.OptiRemTauxCpamConjoint;
        OptiRemPeriodeActuelle[0].ClassePrevoyanceCarpv = this.state.OptiRemClassePrevoyanceCarpvConjoint;
        OptiRemPeriodeActuelle[0].ClasseRetraiteCarpv = this.state.OptiRemClasseRetraiteCarpvConjoint;
        OptiRemPeriodeActuelle[0].ClassePrevoyanceCavec = this.state.OptiRemClassePrevoyanceCavecConjoint;
        OptiRemPeriodeActuelle[0].ClasseRetraiteCavec = this.state.OptiRemClasseRetraiteCavecConjoint;
        OptiRemPeriodeActuelle[0].ConventionneCavp = this.state.OptiRemConventionneCavpConjoint;
        OptiRemPeriodeActuelle[0].ClasseRetraiteCavp = this.state.OptiRemClasseRetraiteCavpConjoint;
        OptiRemPeriodeActuelle[0].ClassePrevoyanceCipav = this.state.OptiRemClassePrevoyanceCipavConjoint;
        OptiRemPeriodeActuelle[0].ClasseRetraiteCipav = this.state.OptiRemClasseRetraiteCipavConjoint;
        OptiRemPeriodeActuelle[0].AncienneteCnbf = this.state.OptiRemAncienneteCnbfConjoint;
        OptiRemPeriodeActuelle[0].ClasseRetraiteCnbf = this.state.OptiRemClasseRetraiteCnbfConjoint;
        OptiRemPeriodeActuelle[0].LpaMontantIncap = this.state.OptiRemLpaMontantIncapConjoint;
        OptiRemPeriodeActuelle[0].LpaFranchiseIncapDebut = this.state.OptiRemLpaFranchiseIncapDebutConjoint;
        OptiRemPeriodeActuelle[0].LpaFranchiseIncapFin = this.state.OptiRemLpaFranchiseIncapFinConjoint;
        OptiRemPeriodeActuelle[0].LpaInvalPartielle = this.state.OptiRemLpaInvalPartielleConjoint;
        OptiRemPeriodeActuelle[0].LpaInvalTotale = this.state.OptiRemLpaInvalTotaleConjoint;
        OptiRemPeriodeActuelle[0].ClasseRetraiteCrn = this.state.OptiRemClasseRetraiteCrnConjoint;
        OptiRemPeriodeActuelle[0].CrnSermentAvt2014 = this.state.OptiRemCrnSermentAvt2014Conjoint;
        OptiRemPeriodeActuelle[0].CrnColmarMetz = this.state.OptiRemCrnColmarMetzConjoint;
        OptiRemPeriodeActuelle[0].Atexa = this.state.OptiRemAtexaConjoint;
        OptiRemPeriodeActuelle[0].TauxActivite = this.state.OptiRemTauxActiviteConjoint;
        OptiRemPeriodeActuelle[0].Effectif = this.state.OptiRemEffectifConjoint;
        OptiRemPeriodeActuelle[0].CavecSalarie = this.state.OptiRemCnbfSalarieConjoint !== "Non" ? "Non" : this.state.OptiRemCavecSalarieConjoint;
        OptiRemPeriodeActuelle[0].CnbfSalarie = this.state.OptiRemCnbfSalarieConjoint;
        OptiRemPeriodeActuelle[0].AccidentTravail = this.state.OptiRemAccidentTravailConjoint;
        OptiRemPeriodeActuelle[0].AgircArrcoT1Sal = this.state.OptiRemAgircArrcoT1SalConjoint;
        OptiRemPeriodeActuelle[0].AgircArrcoT1Pat = this.state.OptiRemAgircArrcoT1PatConjoint;
        OptiRemPeriodeActuelle[0].AgircArrcoT2Sal = this.state.OptiRemAgircArrcoT2SalConjoint;
        OptiRemPeriodeActuelle[0].AgircArrcoT2Pat = this.state.OptiRemAgircArrcoT2PatConjoint;
        OptiRemPeriodeActuelle[0].Transport = this.state.OptiRemTransportConjoint;
        OptiRemPeriodeActuelle[0].Formation = this.state.OptiRemFormationConjoint;
        OptiRemPeriodeActuelle[0].Apprentissage = this.state.OptiRemApprentissageConjoint;
        OptiRemPeriodeActuelle[0].Regime = this.state.OptiRemRegimeConjoint;
        OptiRemPeriodeActuelle[0].TaxeSalaire = this.state.OptiRemTaxeSalaireConjoint;
        OptiRemPeriodeActuelle[0].AssuranceChomage = this.state.OptiRemAssuranceChomageConjoint;
        OptiRemPeriodeActuelle[0].ReductionGenerale = this.state.OptiRemReductionGeneraleConjoint;
        OptiRemPeriodeActuelle[0].TabCotisationsManuelle = this.state.OptiRemTabCotisationsManuelleConjoint;
        OptiRemPeriodeActuelle[0].AvantageNature = this.state.OptiRemAvantageNatureConjoint;
      }

      if (this.state["OptiRemCDSStatut" + ClientConjoint] === "TNS Article 62" || this.state["OptiRemCDSStatut" + ClientConjoint] === "Assimilé salarié") {
        const SituationOrigine = this.state["PeriodeActuelle" + ClientConjoint][0].Statut;
        if (SituationOrigine === "TNS individuel" && this.state["OptiRemCDSStatut" + ClientConjoint] === "TNS Article 62") {
        } else {
          for (let i = 0; i < ContratsAModifier.length; i++) {
            ContratsAModifier[i]["Statut"] = "Mis en réduction";
            if (this.state["OptiRemCDSStatut" + ClientConjoint] === "Assimilé salarié") {
              ContratsAModifier[i]["FiscalitePer"] = "163";
            }
            ContratsAModifier[i]["Cotisation"] = [];
            ContratsAModifier[i]["Tableau83"]["taux_retraite_trancheA_salarie83"] = 0.0;
            ContratsAModifier[i]["Tableau83"]["taux_retraite_trancheA_patronale83"] = 0.0;
            ContratsAModifier[i]["Tableau83"]["taux_retraite_trancheB_salarie83"] = 0.0;
            ContratsAModifier[i]["Tableau83"]["taux_retraite_trancheB_patronale83"] = 0.0;
            ContratsAModifier[i]["Tableau83"]["taux_retraite_trancheC_salarie83"] = 0.0;
            ContratsAModifier[i]["Tableau83"]["taux_retraite_trancheC_patronale83"] = 0.0;
            ContratsAModifier[i]["Tableau83"]["taux_retraite_trancheD_salarie83"] = 0.0;
            ContratsAModifier[i]["Tableau83"]["taux_retraite_trancheD_patronale83"] = 0.0;
            if (this.state.TypeSimulation === "Audit complet") {
              CalculPrestationContrat(this.state, ClientConjoint, this.state["OptiRemResultatRetraite" + ClientConjoint], 0, ContratsAModifier[i]).then((Resultat) => {
                ContratsAModifier[i]["TableauSaisieManuelleRente"] = Resultat.TabPrestations;
              });
            }
          }
          for (let i = 0; i < ContratsPEEPERCO.length; i++) {
            if (this.state["OptiRemCDSStatut" + ClientConjoint] === "Assimilé salarié") {
              ContratsPEEPERCO[i]["CSGPERCO"] = "oui";
            } else {
              ContratsPEEPERCO[i]["CSGPERCO"] = "non";
            }
            if (this.state.TypeSimulation === "Audit complet") {
              CalculPrestationContrat(this.state, ClientConjoint, this.state["OptiRemResultatRetraite" + ClientConjoint], 0, ContratsPEEPERCO[i]).then((Resultat) => {
                // console.log("PEEE", Resultat.TabPrestations);
                ContratsPEEPERCO[i]["TableauSaisieManuelleRente"] = Resultat.TabPrestations;
              });
            }
          }
        }
      }
    }
    if (this.state.OptiRemCDSEtude !== "client") {
      OptiRemPeriodeActuelle[0].StatutConjoint = this.state.OptiRemCDSStatutConjoint;
      OptiRemPeriodeActuelle[0].ConventionneCarcdsfConjoint = this.state.OptiRemConventionneCarcdsfConjoint;
      OptiRemPeriodeActuelle[0].ConventionneCarmfConjoint = this.state.OptiRemConventionneCarmfConjoint;
      OptiRemPeriodeActuelle[0].OptionRsiConjoint = this.state.OptiRemOptionRsiConjoint;
      OptiRemPeriodeActuelle[0].ConventionneCarpimkoConjoint = this.state.OptiRemConventionneCarpimkoConjoint;
      OptiRemPeriodeActuelle[0].TauxCpamConjoint = this.state.OptiRemTauxCpamConjoint;
      OptiRemPeriodeActuelle[0].ClassePrevoyanceCarpvConjoint = this.state.OptiRemClassePrevoyanceCarpvConjoint;
      OptiRemPeriodeActuelle[0].ClasseRetraiteCarpvConjoint = this.state.OptiRemClasseRetraiteCarpvConjoint;
      OptiRemPeriodeActuelle[0].ClassePrevoyanceCavecConjoint = this.state.OptiRemClassePrevoyanceCavecConjoint;
      OptiRemPeriodeActuelle[0].ClasseRetraiteCavecConjoint = this.state.OptiRemClasseRetraiteCavecConjoint;
      OptiRemPeriodeActuelle[0].ConventionneCavpConjoint = this.state.OptiRemConventionneCavpConjoint;
      OptiRemPeriodeActuelle[0].ClasseRetraiteCavpConjoint = this.state.OptiRemClasseRetraiteCavpConjoint;
      OptiRemPeriodeActuelle[0].ClassePrevoyanceCipavConjoint = this.state.OptiRemClassePrevoyanceCipavConjoint;
      OptiRemPeriodeActuelle[0].ClasseRetraiteCipavConjoint = this.state.OptiRemClasseRetraiteCipavConjoint;
      OptiRemPeriodeActuelle[0].AncienneteCnbfConjoint = this.state.OptiRemAncienneteCnbfConjoint;
      OptiRemPeriodeActuelle[0].ClasseRetraiteCnbfConjoint = this.state.OptiRemClasseRetraiteCnbfConjoint;
      OptiRemPeriodeActuelle[0].LpaMontantIncapConjoint = this.state.OptiRemLpaMontantIncapConjoint;
      OptiRemPeriodeActuelle[0].LpaFranchiseIncapDebutConjoint = this.state.OptiRemLpaFranchiseIncapDebutConjoint;
      OptiRemPeriodeActuelle[0].LpaFranchiseIncapFinConjoint = this.state.OptiRemLpaFranchiseIncapFinConjoint;
      OptiRemPeriodeActuelle[0].LpaInvalPartielleConjoint = this.state.OptiRemLpaInvalPartielleConjoint;
      OptiRemPeriodeActuelle[0].LpaInvalTotaleConjoint = this.state.OptiRemLpaInvalTotaleConjoint;
      OptiRemPeriodeActuelle[0].ClasseRetraiteCrnConjoint = this.state.OptiRemClasseRetraiteCrnConjoint;
      OptiRemPeriodeActuelle[0].CrnSermentAvt2014Conjoint = this.state.OptiRemCrnSermentAvt2014Conjoint;
      OptiRemPeriodeActuelle[0].CrnColmarMetzConjoint = this.state.OptiRemCrnColmarMetzConjoint;
      OptiRemPeriodeActuelle[0].AtexaConjoint = this.state.OptiRemAtexaConjoint;
      OptiRemPeriodeActuelle[0].TauxActiviteConjoint = this.state.OptiRemTauxActiviteConjoint;
      OptiRemPeriodeActuelle[0].EffectifConjoint = this.state.OptiRemEffectifConjoint;
      OptiRemPeriodeActuelle[0].CavecSalarieConjoint = this.state.OptiRemCnbfSalarieConjoint !== "Non" ? "Non" : this.state.OptiRemCavecSalarieConjoint;
      OptiRemPeriodeActuelle[0].CnbfSalarieConjoint = this.state.OptiRemCnbfSalarieConjoint;
      OptiRemPeriodeActuelle[0].AccidentTravailConjoint = this.state.OptiRemAccidentTravailConjoint;
      OptiRemPeriodeActuelle[0].AgircArrcoT1SalConjoint = this.state.OptiRemAgircArrcoT1SalConjoint;
      OptiRemPeriodeActuelle[0].AgircArrcoT1PatConjoint = this.state.OptiRemAgircArrcoT1PatConjoint;
      OptiRemPeriodeActuelle[0].AgircArrcoT2SalConjoint = this.state.OptiRemAgircArrcoT2SalConjoint;
      OptiRemPeriodeActuelle[0].AgircArrcoT2PatConjoint = this.state.OptiRemAgircArrcoT2PatConjoint;
      OptiRemPeriodeActuelle[0].TransportConjoint = this.state.OptiRemTransportConjoint;
      OptiRemPeriodeActuelle[0].FormationConjoint = this.state.OptiRemFormationConjoint;
      OptiRemPeriodeActuelle[0].ApprentissageConjoint = this.state.OptiRemApprentissageConjoint;
      OptiRemPeriodeActuelle[0].RegimeConjoint = this.state.OptiRemRegimeConjoint;
      OptiRemPeriodeActuelle[0].TaxeSalaireConjoint = this.state.OptiRemTaxeSalaireConjoint;
      OptiRemPeriodeActuelle[0].AssuranceChomageConjoint = this.state.OptiRemAssuranceChomageConjoint;
      OptiRemPeriodeActuelle[0].ReductionGeneraleConjoint = this.state.OptiRemReductionGeneraleConjoint;
      OptiRemPeriodeActuelle[0].TabCotisationsManuelleConjoint = this.state.OptiRemTabCotisationsManuelleConjoint;
      OptiRemPeriodeActuelle[0].AvantageNatureConjoint = this.state.OptiRemAvantageNatureConjoint;

      if (this.state.OptiRemCDSStatutConjoint === "TNS Article 62" || this.state.OptiRemCDSStatutConjoint === "Assimilé salarié") {
        const SituationOrigine = this.state["PeriodeActuelle" + ClientConjoint][0].StatutConjoint;
        if (SituationOrigine === "TNS individuel" && this.state.OptiRemCDSStatutConjoint === "TNS Article 62") {
        } else {
          for (let i = 0; i < ContratsAModifierConjoint.length; i++) {
            ContratsAModifierConjoint[i]["Statut"] = "Mis en réduction";
            if (this.state.OptiRemCDSStatutConjoint === "Assimilé salarié") {
              ContratsAModifierConjoint[i]["FiscalitePer"] = "163";
            }
            ContratsAModifierConjoint[i]["Cotisation"] = [];
            ContratsAModifierConjoint[i]["Tableau83"]["taux_retraite_trancheA_salarie83"] = 0.0;
            ContratsAModifierConjoint[i]["Tableau83"]["taux_retraite_trancheA_patronale83"] = 0.0;
            ContratsAModifierConjoint[i]["Tableau83"]["taux_retraite_trancheB_salarie83"] = 0.0;
            ContratsAModifierConjoint[i]["Tableau83"]["taux_retraite_trancheB_patronale83"] = 0.0;
            ContratsAModifierConjoint[i]["Tableau83"]["taux_retraite_trancheC_salarie83"] = 0.0;
            ContratsAModifierConjoint[i]["Tableau83"]["taux_retraite_trancheC_patronale83"] = 0.0;
            ContratsAModifierConjoint[i]["Tableau83"]["taux_retraite_trancheD_salarie83"] = 0.0;
            ContratsAModifierConjoint[i]["Tableau83"]["taux_retraite_trancheD_patronale83"] = 0.0;
            if (this.state.TypeSimulation === "Audit complet") {
              CalculPrestationContrat(this.state, "Conjoint", this.state.OptiRemResultatRetraiteConjoint, 0, ContratsAModifierConjoint[i]).then((Resultat) => {
                ContratsAModifierConjoint[i]["TableauSaisieManuelleRente"] = Resultat.TabPrestations;
              });
            }
          }
          for (let i = 0; i < ContratsPEEPERCOConjoint.length; i++) {
            if (this.state.OptiRemCDSStatutConjoint === "Assimilé salarié") {
              ContratsPEEPERCOConjoint[i]["CSGPERCO"] = "oui";
            } else {
              ContratsPEEPERCOConjoint[i]["CSGPERCO"] = "non";
            }
            if (this.state.TypeSimulation === "Audit complet") {
              CalculPrestationContrat(this.state, "Conjoint", this.state.OptiRemResultatRetraiteConjoint, 0, ContratsPEEPERCOConjoint[i]).then((Resultat) => {
                ContratsPEEPERCOConjoint[i]["TableauSaisieManuelleRente"] = Resultat.TabPrestations;
              });
            }
          }
        }
      }

      this.handleCarriereActuelleConjointAssocie(OptiRemPeriodeActuelle[0], "OptiRem");
    }

    let MontantSaisi = this.state["OptiRemCoutRemuneration" + ClientConjoint];
    let Raisonnement = this.state.OptiRemCDSRaisonnement;
    if (this.state.OptiRemCDSRaisonnement === "remuneration_nette") {
      MontantSaisi = this.state["OptiRemRemNette" + ClientConjoint];
      Raisonnement = OptiRemStatut === "Assimilé salarié" ? "salaire_net" : this.state.OptiRemCDSRaisonnement;
    }

    if (this.state.OptiRemCarriere.length > 0) {
      this.handleCarriereActuelle(OptiRemPeriodeActuelle[0], "OptiRem", ClientConjoint).then(() => {
        this.setState(
          {
            ["OptiRemPeriodeActuelle" + ClientConjoint]: OptiRemPeriodeActuelle,
            OptiRemStatut: OptiRemStatut,
            OptiRemPartSoc: OptiRemPartSoc,
            OptiRemPartSocConj: OptiRemPartSocConj,
            OptiRemStatutConjoint: OptiRemStatutConjoint,
            OptiRemTypeCalcul: Raisonnement,
            // OptiRemUnlockCoutEntreprise:true,
            OptiRemContratEnregistre: OptiRemContratEnregistre,
            OptiRemContratEnregistreConjoint: [],
            OptiRemContratFraisPro: OptiRemContratFraisPro,
            OptiRemContratFraisProConjoint: [],
            OptiRemContratEnregistreSante: OptiRemContratEnregistreSante,
            OptiRemContratEnregistreSanteConjoint: [],
            OptiRemListeContrat: [...ContratsAModifier, ...ContratsPEEPERCO, ...ContratsRestants],
            OptiRemListeContratConjoint: [...ContratsAModifierConjoint, ...ContratsPEEPERCOConjoint, ...ContratsRestantsConjoint],
          },
          () => {
            this.handleCalculOptiRem(this.state.OptiRemTypeCalcul, "", ClientConjoint, ResultatTNS, true, MontantSaisi);
          }
        );
      });
    } else {
      //POUR LE CALCUL DE L'OPTIREM PAR REMEXPRESS
      this.setState(
        {
          OptiRemPeriodeActuelle: OptiRemPeriodeActuelle,
          OptiRemPartSoc: OptiRemPartSoc,
          OptiRemPartSocConj: OptiRemPartSocConj,
          OptiRemTypeCalcul: Raisonnement,
          // OptiRemUnlockCoutEntreprise:true,
          OptiRemStatut: OptiRemStatut,
          OptiRemContratEnregistre: OptiRemContratEnregistre,
          OptiRemContratEnregistreConjoint: [],
          OptiRemContratFraisPro: OptiRemContratFraisPro,
          OptiRemContratFraisProConjoint: [],
          OptiRemContratEnregistreSante: OptiRemContratEnregistreSante,
          OptiRemContratEnregistreSanteConjoint: [],
          OptiRemListeContrat: [...ContratsAModifier, ...ContratsPEEPERCO, ...ContratsRestants],
          OptiRemListeContratConjoint: [...ContratsAModifierConjoint, ...ContratsPEEPERCOConjoint, ...ContratsRestantsConjoint],
        },
        () => {
          this.handleCalculOptiRem(this.state.OptiRemTypeCalcul, "", ClientConjoint, ResultatTNS, true, MontantSaisi);
        }
      );
    }
  };

  handleOpenRemiseDonneeRemExpressOptirem = (ResultatTNS) => {
    let EnvEntr = 0;
    let CoutRemuneration = 0;
    let Cotisations = 0;
    let RemBrute = 0;
    let RemNette = 0;
    let RemImposable = 0;
    let ResAvtIs = 0;
    let ImpotSoc = 0;
    let Reserve = 0;
    let DivAutres = 0;
    let DividendeBrut = 0;
    let PartSoc = 100;
    let PartSocConj = 100;

    let RevPercus = 0;
    let CotFacPerso = 0;

    const newPeriode = [
      {
        IdPeriodeActuelle: Date.now(),
        Statut: this.state.Statut,
        StatutConjoint: "Aucun",
        Caisse: this.state.Caisse,
        ConventionneCarcdsf: this.state.ConventionneCarcdsf,
        ConventionneCarmf: this.state.ConventionneCarmf,
        OptionRsi: this.state.OptionRsi,
        ConventionneCarpimko: this.state.ConventionneCarpimko,
        TauxCpam: this.state.TauxCpam,
        ClassePrevoyanceCarpv: this.state.ClassePrevoyanceCarpv,
        ClasseRetraiteCarpv: this.state.ClasseRetraiteCarpv,
        ClassePrevoyanceCavec: this.state.ClassePrevoyanceCavec,
        ClasseRetraiteCavec: this.state.ClasseRetraiteCavec,
        ConventionneCavp: this.state.ConventionneCavp,
        ClasseRetraiteCavp: this.state.ClasseRetraiteCavp,
        ClassePrevoyanceCipav: this.state.ClassePrevoyanceCipav,
        ClasseRetraiteCipav: this.state.ClasseRetraiteCipav,
        AncienneteCnbf: this.state.AncienneteCnbf,
        ClasseRetraiteCnbf: this.state.ClasseRetraiteCnbf,
        LpaMontantIncap: this.state.LpaMontantIncap,
        LpaFranchiseIncapDebut: this.state.LpaFranchiseIncapDebut,
        LpaFranchiseIncapFin: this.state.LpaFranchiseIncapFin,
        LpaInvalPartielle: this.state.LpaInvalPartielle,
        LpaInvalTotale: this.state.LpaInvalTotale,
        ClasseRetraiteCrn: this.state.ClasseRetraiteCrn,
        CrnSermentAvt2014: this.state.CrnSermentAvt2014,
        CrnColmarMetz: this.state.CrnColmarMetz,
        Atexa: this.state.Atexa,
        TauxActivite: this.state.TauxActivite,
        Effectif: this.state.Effectif,
        CavecSalarie: this.state.CnbfSalarie !== "Non" ? "Non" : this.state.CavecSalarie,
        CnbfSalarie: this.state.CnbfSalarie,
        AccidentTravail: this.state.AccidentTravail,
        AgircArrcoT1Sal: this.state.AgircArrcoT1Sal,
        AgircArrcoT1Pat: this.state.AgircArrcoT1Pat,
        AgircArrcoT2Sal: this.state.AgircArrcoT2Sal,
        AgircArrcoT2Pat: this.state.AgircArrcoT2Pat,
        Transport: this.state.Transport,
        Formation: this.state.Formation,
        Apprentissage: this.state.Apprentissage,
        Regime: this.state.Regime,
        TaxeSalaire: this.state.TaxeSalaire,
        AssuranceChomage: this.state.AssuranceChomage,
        ReductionGenerale: this.state.ReductionGenerale,
        AvantageNature: this.state.AvantageNature,
        TabCotisationsManuelle: this.state.TabCotisationsManuelle,
        Fiscalite: this.state.Fiscalite,
        TauxISReduit: this.state.TauxISReduit,
        TauxISNormal: this.state.TauxISNormal,
        TauxISZero: this.state.TauxISZero,

        DividendeBrut: this.state.RemExpressDividendeBrut,
        DividendeBrutAutres: this.state.RemExpressDivAutres,
        CapitalSocial: this.state.CapitalSocial,
        PartSoc: this.state.PartSoc,
        PartSocConj: this.state.PartSocConj,
        ResAvtIs: this.state.RemExpressResAvtIs,
        ImpotSoc: this.state.RemExpressImpotSoc,
        SeuilAssuj: this.state.SeuilAssuj,
        SetSeuilAssuj: this.state.SetSeuilAssuj,
        Reserve: this.state.RemExpressReserve,
        // DividendeBrutAutres: 0,
      },
    ];
    this.setState(
      {
        PeriodeActuelle: newPeriode,
        OptiRemConventionneCarcdsf: this.state.ConventionneCarcdsf,
        OptiRemConventionneCarmf: this.state.ConventionneCarmf,
        OptiRemOptionRsi: this.state.OptionRsi,
        OptiRemConventionneCarpimko: this.state.ConventionneCarpimko,
        OptiRemTauxCpam: this.state.TauxCpam,
        OptiRemClassePrevoyanceCarpv: this.state.ClassePrevoyanceCarpv,
        OptiRemClasseRetraiteCarpv: this.state.ClasseRetraiteCarpv,
        OptiRemClassePrevoyanceCavec: this.state.ClassePrevoyanceCavec,
        OptiRemClasseRetraiteCavec: this.state.ClasseRetraiteCavec,
        OptiRemConventionneCavp: this.state.ConventionneCavp,
        OptiRemClasseRetraiteCavp: this.state.ClasseRetraiteCavp,
        OptiRemClassePrevoyanceCipav: this.state.ClassePrevoyanceCipav,
        OptiRemClasseRetraiteCipav: this.state.ClasseRetraiteCipav,
        OptiRemAncienneteCnbf: this.state.AncienneteCnbf,
        OptiRemClasseRetraiteCnbf: this.state.ClasseRetraiteCnbf,
        OptiRemLpaMontantIncap: this.state.LpaMontantIncap,
        OptiRemLpaFranchiseIncapDebut: this.state.LpaFranchiseIncapDebut,
        OptiRemLpaFranchiseIncapFin: this.state.LpaFranchiseIncapFin,
        OptiRemLpaInvalPartielle: this.state.LpaInvalPartielle,
        OptiRemLpaInvalTotale: this.state.LpaInvalTotale,
        OptiRemClasseRetraiteCrn: this.state.ClasseRetraiteCrn,
        OptiRemCrnSermentAvt2014: this.state.CrnSermentAvt2014,
        OptiRemCrnColmarMetz: this.state.CrnColmarMetz,
        OptiRemAtexa: this.state.Atexa,
        OptiRemTauxActivite: this.state.TauxActivite,
        OptiRemEffectif: this.state.Effectif,
        OptiRemCavecSalarie: this.state.CnbfSalarie !== "Non" ? "Non" : this.state.CavecSalarie,
        OptiRemCnbfSalarie: this.state.CnbfSalarie,
        OptiRemAccidentTravail: this.state.AccidentTravail,
        OptiRemAgircArrcoT1Sal: this.state.AgircArrcoT1Sal,
        OptiRemAgircArrcoT1Pat: this.state.AgircArrcoT1Pat,
        OptiRemAgircArrcoT2Sal: this.state.AgircArrcoT2Sal,
        OptiRemAgircArrcoT2Pat: this.state.AgircArrcoT2Pat,
        OptiRemTransport: this.state.Transport,
        OptiRemFormation: this.state.Formation,
        OptiRemApprentissage: this.state.Apprentissage,
        OptiRemRegime: this.state.Regime,
        OptiRemTaxeSalaire: this.state.TaxeSalaire,
        OptiRemAssuranceChomage: this.state.AssuranceChomage,
        OptiRemReductionGenerale: this.state.ReductionGenerale,
      },
      () => {
        //CLIENT
        CoutRemuneration = this.state.RemExpressCoutRemuneration;
        Cotisations = this.state.RemExpressCotisations;
        RemNette = this.state.RemExpressRemNette;
        RemBrute = this.state.RemExpressRemBrute;
        RemImposable = this.state.RemExpressRemImposable;

        EnvEntr += CoutRemuneration;
        this.state.ContratFraisPro.forEach((contrat) => {
          EnvEntr += Number(contrat.FGPMontant);
        });

        RevPercus += RemNette;
        CotFacPerso += this.state.RemExpressCotFacPerso;

        if (this.state.PeriodeActuelle[0]?.Statut !== "TNS individuel") {
          ResAvtIs = this.state.PeriodeActuelle[0].ResAvtIs;
          PartSoc = this.state.PeriodeActuelle[0].PartSoc;
          PartSocConj = this.state.PeriodeActuelle[0].PartSocConj;
          ImpotSoc = this.state.PeriodeActuelle[0].ImpotSoc;
          Reserve = Number(this.state.PeriodeActuelle[0].Reserve);
          DivAutres = Number(this.state.PeriodeActuelle[0].DividendeBrutAutres);
          DividendeBrut = this.state.PeriodeActuelle[0].DividendeBrut;

          EnvEntr += ResAvtIs;
          RevPercus += DividendeBrut;
        }

        this.setState(
          {
            ArbitrageRai: 0,
            ArbitrageReserve: 0,
            OptiRemTypeCalcul: "cout_entreprise",
            OptiRemChoixOptimum: "automatique",
            OptiRemOptimumRemMini: 0,
            OptiRemTnsMontantSaisi: this.state.RemExpressCoutEntreprise,
            OptiRemTnsCoutEntreprise: this.state.RemExpressCoutEntreprise,
            OptiRemUnlockCoutEntreprise: false,
            OptiRemPeriodeActuelle: _.cloneDeep(this.state.PeriodeActuelle),
            //     OptiRemPeriodeActuelleConjoint: _.cloneDeep(this.state.PeriodeActuelleConjoint),
            OptiRemCoutEntreprise: EnvEntr,
            OptiRemCoutRemuneration: CoutRemuneration,
            OptiRemCotisations: Cotisations,
            OptiRemRemBrute: RemBrute,
            OptiRemRemNette: RemNette,
            OptiRemRemImposable: RemImposable,
            OptiRemResAvtIs: ResAvtIs,
            OptiRemImpotSoc: ImpotSoc,
            OptiRemReserve: Reserve,
            OptiRemCapitalSocial: this.state.CapitalSocial,
            OptiRemPartSoc: PartSoc,
            OptiRemPartSocConj: PartSocConj,
            OptiRemSeuilAssuj: this.state.SeuilAssuj,
            //     OptiRemSeuilAssujConjoint: this.state.SeuilAssujConjoint,
            OptiRemSetSeuilAssuj: this.state.SetSeuilAssuj,
            //     OptiRemSetSeuilAssujConjoint: this.state.SetSeuilAssujConjoint,
            OptiRemDivAutres: DivAutres,
            OptiRemDividendeBrut: DividendeBrut,

            //     OptiRemUnlockCoutEntrepriseConjoint: false,
            //     OptiRemCoutEntrepriseConjoint: EnvEntrConjoint,
            //     OptiRemCoutRemunerationConjoint: CoutRemunerationConjoint,
            //     OptiRemCotisationsConjoint: CotisationsConjoint,
            //     OptiRemRemBruteConjoint: RemBruteConjoint,
            OptiRemRemNetteConjoint: 0,
            OptiRemRemImposableConjoint: 0,
            //     OptiRemResAvtIsConjoint: ResAvtIsConjoint,
            //     OptiRemImpotSocConjoint: ImpotSocConjoint,
            //     OptiRemReserveConjoint: ReserveConjoint,
            //     OptiRemDivAutresConjoint: DivAutresConjoint,
            OptiRemDividendeBrutConjoint: 0,

            OptiRemRevPercus: RevPercus,
            OptiRemRevenusFoyer: this.state.RemExpressRevenusFoyer,
            OptiRemIRTotal: this.state.RemExpressIRTotal,
            OptiRemCotFacPerso: CotFacPerso,
            OptiRemRevDispo: this.state.RemExpressRevDispo,
            OptiRemCotFacPersoConjoint: 0,
            //     // OptiRemDividendeBrutConjoint: DividendeBrutConjoint,
            OptiRemTauxISReduit: this.state.TauxISReduit,
            OptiRemTauxISNormal: this.state.TauxISNormal,
            OptiRemTauxISZero: this.state.TauxISZero,

            OptiRemAutresRevenus: this.state.AutresRevenus,
            OptiRemDeductionFiscale: this.state.DeductionFiscale,
            OptiRemReductionImpot: this.state.ReductionImpot,
            OptiRemIRDirigeantTraitement: this.state.IRDirigeantTraitement,
            OptiRemIRConjointTraitement: this.state.IRConjointTraitement,
            OptiRemIRDirigeantAutresRevenus: this.state.IRDirigeantAutresRevenus,
            OptiRemIRConjointAutresRevenus: this.state.IRConjointAutresRevenus,
            OptiRemIRDirigeantAbattement: this.state.IRDirigeantAbattement,
            OptiRemIRConjointAbattement: this.state.IRConjointAbattement,
            OptiRemIRDirigeantFraisReel: this.state.IRDirigeantFraisReel,
            OptiRemIRConjointFraisReel: this.state.IRConjointFraisReel,
            OptiRemIRDirigeantBNCMicro: this.state.IRDirigeantBNCMicro,
            OptiRemIRConjointBNCMicro: this.state.IRConjointBNCMicro,
            OptiRemIRDirigeantBNCControlle: this.state.IRDirigeantBNCControlle,
            OptiRemIRConjointBNCControlle: this.state.IRConjointBNCControlle,
            OptiRemIRDirigeantBICMicroAchatRevente: this.state.IRDirigeantBICMicroAchatRevente,
            OptiRemIRConjointBICMicroAchatRevente: this.state.IRConjointBICMicroAchatRevente,
            OptiRemIRDirigeantBICMicroService: this.state.IRDirigeantBICMicroService,
            OptiRemIRConjointBICMicroService: this.state.IRConjointBICMicroService,
            OptiRemIRDirigeantBICReel: this.state.IRDirigeantBICReel,
            OptiRemIRConjointBICReel: this.state.IRConjointBICReel,
            OptiRemIRDirigeantBAMicro: this.state.IRDirigeantBAMicro,
            OptiRemIRConjointBAMicro: this.state.IRConjointBAMicro,
            OptiRemIRDirigeantBAReel: this.state.IRDirigeantBAReel,
            OptiRemIRConjointBAReel: this.state.IRConjointBAReel,
            OptiRemIRDirigeantRetraite: this.state.IRDirigeantRetraite,
            OptiRemIRConjointRetraite: this.state.IRConjointRetraite,
            OptiRemIRDirigeantInvalidite: this.state.IRDirigeantInvalidite,
            OptiRemIRConjointInvalidite: this.state.IRConjointInvalidite,
            OptiRemIRDirigeantAlimentaire: this.state.IRDirigeantAlimentaire,
            OptiRemIRConjointAlimentaire: this.state.IRConjointAlimentaire,
            OptiRemIRDirigeantCapital: this.state.IRDirigeantCapital,
            OptiRemIRConjointCapital: this.state.IRConjointCapital,
            OptiRemIRDirigeantRenteMoins50: this.state.IRDirigeantRenteMoins50,
            OptiRemIRDirigeantRente5059: this.state.IRDirigeantRente5059,
            OptiRemIRDirigeantRente6069: this.state.IRDirigeantRente6069,
            OptiRemIRDirigeantRente70: this.state.IRDirigeantRente70,
            OptiRemOptionPfu: this.state.OptionPfu,
            OptiRemIRDirigeantAutreDividendeSoumisPS: this.state.IRDirigeantAutreDividendeSoumisPS,
            OptiRemIRDirigeantAutreDividendeNonSoumisPS: this.state.IRDirigeantAutreDividendeNonSoumisPS,
            OptiRemIRDirigeantPlusValue: this.state.IRDirigeantPlusValue,
            OptiRemIRDirigeantMoinsValue: this.state.IRDirigeantMoinsValue,
            OptiRemIRDirigeantAbattementDureeDetention: this.state.IRDirigeantAbattementDureeDetention,
            OptiRemIRDirigeantAbattementDepartRetraite: this.state.IRDirigeantAbattementDepartRetraite,
            OptiRemIRDirigeantRecette: this.state.IRDirigeantRecette,
            OptiRemIRDirigeantRegimeRevenuFoncier: this.state.IRDirigeantRegimeRevenuFoncier,
            OptiRemIRDirigeantChargeRevenuFoncier: this.state.IRDirigeantChargeRevenuFoncier,
            OptiRemIRConjointCotisationPerp: this.state.IRConjointCotisationPerp,
            OptiRemIRDirigeantPlafond1: this.state.IRDirigeantPlafond1,
            OptiRemIRConjointPlafond1: this.state.IRConjointPlafond1,
            OptiRemIRDirigeantPlafond2: this.state.IRDirigeantPlafond2,
            OptiRemIRConjointPlafond2: this.state.IRConjointPlafond2,
            OptiRemIRDirigeantPlafond3: this.state.IRDirigeantPlafond3,
            OptiRemIRConjointPlafond3: this.state.IRConjointPlafond3,
            OptiRemIRDirigeantPlafondNM1: this.state.IRDirigeantPlafondNM1,
            OptiRemSetIRDirigeantPlafondNM1: this.state.SetIRDirigeantPlafondNM1,
            OptiRemIRConjointPlafondNM1: this.state.IRConjointPlafondNM1,
            OptiRemSetIRConjointPlafondNM1: this.state.SetIRConjointPlafondNM1,
            OptiRemIRDirigeantAutresRevenusSoumisPSAvecCSGDed: this.state.IRDirigeantAutresRevenusSoumisPSAvecCSGDed,
            OptiRemIRDirigeantAutresRevenusSoumisPSSansCSGDed: this.state.IRDirigeantAutresRevenusSoumisPSSansCSGDed,
            OptiRemIRCSGDedRevenuPatrimoine: this.state.IRCSGDedRevenuPatrimoine,
            OptiRemIRPensionAlimEnfant1: this.state.IRPensionAlimEnfant1,
            OptiRemIRPensionAlimEnfant2: this.state.IRPensionAlimEnfant2,
            OptiRemIRAutrePensionAlimEnfant1: this.state.IRAutrePensionAlimEnfant1,
            OptiRemIRAutrePensionAlimEnfant2: this.state.IRAutrePensionAlimEnfant2,
            OptiRemIRPensionAlim: this.state.IRPensionAlim,
            OptiRemIRAutrePensionAlim: this.state.IRAutrePensionAlim,
            OptiRemIRDonPersonne: this.state.IRDonPersonne,
            OptiRemIRDonAssociation: this.state.IRDonAssociation,
            OptiRemIREmploiDomicile: this.state.IREmploiDomicile,
            OptiRemIRFraisGardeEnfantExclusive1: this.state.IRFraisGardeEnfantExclusive1,
            OptiRemIRFraisGardeEnfantPartagee1: this.state.IRFraisGardeEnfantPartagee1,
            OptiRemIRFraisGardeEnfantExclusive2: this.state.IRFraisGardeEnfantExclusive2,
            OptiRemIRFraisGardeEnfantPartagee2: this.state.IRFraisGardeEnfantPartagee2,
            OptiRemIRFraisGardeEnfantExclusive3: this.state.IRFraisGardeEnfantExclusive3,
            OptiRemIRFraisGardeEnfantPartagee3: this.state.IRFraisGardeEnfantPartagee3,
            OptiRemIREtudeEnfantCollegeExclusive: this.state.IREtudeEnfantCollegeExclusive,
            OptiRemIREtudeEnfantCollegePartagee: this.state.IREtudeEnfantCollegePartagee,
            OptiRemIREtudeEnfantLyceeExclusive: this.state.IREtudeEnfantLyceeExclusive,
            OptiRemIREtudeEnfantLyceePartagee: this.state.IREtudeEnfantLyceePartagee,
            OptiRemIREtudeEnfantFacExclusive: this.state.IREtudeEnfantFacExclusive,
            OptiRemIREtudeEnfantFacPartagee: this.state.IREtudeEnfantFacPartagee,
            OptiRemContratEnregistre: this.state.ContratEnregistre.slice(0),
            OptiRemContratEnregistreConjoint: this.state.ContratEnregistreConjoint.slice(0),
            OptiRemContratFraisPro: this.state.ContratFraisPro.slice(0),
            OptiRemContratFraisProConjoint: this.state.ContratFraisProConjoint.slice(0),
            OptiRemContratEnregistreSante: this.state.ContratEnregistreSante.slice(0),
            OptiRemContratEnregistreSanteConjoint: this.state.ContratEnregistreSanteConjoint.slice(0),
            OptiRemListeContrat: this.state.ListeContrat.slice(0),
            OptiRemListeContratConjoint: this.state.ListeContratConjoint.slice(0),
            OptiRemCarriere: this.state.Carriere.slice(0),
            OptiRemCarriereConjoint: this.state.CarriereConjoint.slice(0),
            OptiRemForfaitSocialArticle83: this.state.ForfaitSocialArticle83,
            OptiRemAssietteCotisation83: this.state.AssietteCotisation83,
            OptiRemInteressementCash: this.state.InteressementCash,
            OptiRemParticipationCash: this.state.ParticipationCash,
            OptiRemForfaitSocialInteressement: this.state.ForfaitSocialInteressement,
            OptiRemForfaitSocialParticipation: this.state.ForfaitSocialParticipation,
            OptiRemForfaitSocialAbondementPee: this.state.ForfaitSocialAbondementPee,
            OptiRemForfaitSocialAbondementPerco: this.state.ForfaitSocialAbondementPerco,
            OptiRemAvantageNature: this.state.AvantageNature,
            OptiRemStatut: this.state.Statut,
            OptiRemCDSEtude: "client",
            OptiRemCDSStatut: this.state.PeriodeActuelle[0]?.Statut === "TNS Article 62" ? "Assimilé salarié" : "TNS Article 62",
            OptiRemCDSCaisse: this.state.PeriodeActuelle[0].Caisse,
            OptiRemCDSRaisonnement: "cout_remuneration",

            //     OptiRemCDSStatutConjoint: CDSStatutConjoint,
            //     OptiRemCDSCaisseConjoint: "RsiCommercant",
            //     OptiRemCDSRaisonnementConjoint: "cout_remuneration",
          },
          () => {
            this.handleCalculOptiRem(this.state.OptiRemTypeCalcul, "", "", ResultatTNS, false, "");
          }
        );
      }
    );
  };

  //LISTE OPTIMISATION
  handleSaveAsOptimisation = (id, NatureOptimisation) => {
    let ListeOptimisation = this.state.ListeOptimisation;
    let IdOptimisation = id;
    if (IdOptimisation.length === 0) {
      IdOptimisation = Date.now();
    } else {
      ListeOptimisation = ListeOptimisation.filter((Optimisation) => Optimisation.IdOptimisation !== id);
    }
    let newOptimisation = [];
    if (NatureOptimisation === "OptiRem") {
      newOptimisation = [
        {
          DateAdd: Date.now(),
          NatureOptimisation: NatureOptimisation,
          IdOptimisation: IdOptimisation,
          LibelleOptimisation: this.state.LibelleOptimisation,
          OptiRemStatut: this.state.OptiRemStatut,
          OptiRemStatutConjoint: this.state.OptiRemStatutConjoint,
          OptiRemTypeCalcul: this.state.OptiRemTypeCalcul,
          OptiRemChoixOptimum: this.state.OptiRemChoixOptimum,
          OptiRemOptimumRemMini: this.state.OptiRemOptimumRemMini,
          OptiRemUnlockCoutEntreprise: this.state.OptiRemUnlockCoutEntreprise,
          OptiRemPeriodeActuelle: this.state.OptiRemPeriodeActuelle,
          OptiRemPeriodeActuelleConjoint: this.state.OptiRemPeriodeActuelleConjoint,
          OptiRemCoutEntreprise: this.state.OptiRemCoutEntreprise,
          OptiRemCoutRemuneration: this.state.OptiRemCoutRemuneration,
          OptiRemCotisations: this.state.OptiRemCotisations,
          OptiRemCotisationsPatronale: 0,
          OptiRemCotisationsSalariale: 0,
          OptiRemRemBrute: this.state.OptiRemRemBrute,
          OptiRemRemNette: this.state.OptiRemRemNette,
          OptiRemRemImposable: this.state.OptiRemRemImposable,
          OptiRemResAvtIs: this.state.OptiRemResAvtIs,
          OptiRemImpotSoc: this.state.OptiRemImpotSoc,
          OptiRemCapitalSocial: this.state.OptiRemCapitalSocial,
          OptiRemPartSoc: this.state.OptiRemPartSoc,
          OptiRemPartSocConj: this.state.OptiRemPartSocConj,
          OptiRemSetSeuilAssuj: this.state.OptiRemSetSeuilAssuj,
          OptiRemSeuilAssuj: this.state.OptiRemSeuilAssuj,

          OptiRemReserve: this.state.OptiRemReserve,
          OptiRemDivAutres: this.state.OptiRemDivAutres,
          OptiRemDividendeBrut: this.state.OptiRemDividendeBrut,
          OptiRemUnlockCoutEntrepriseConjoint: this.state.OptiRemUnlockCoutEntrepriseConjoint,
          OptiRemCoutEntrepriseConjoint: this.state.OptiRemCoutEntrepriseConjoint,
          OptiRemCoutRemunerationConjoint: this.state.OptiRemCoutRemunerationConjoint,
          OptiRemCotisationsConjoint: this.state.OptiRemCotisationsConjoint,
          OptiRemCotisationsPatronaleConjoint: 0,
          OptiRemCotisationsSalarialeConjoint: 0,
          OptiRemRemBruteConjoint: this.state.OptiRemRemBruteConjoint,
          OptiRemRemNetteConjoint: this.state.OptiRemRemNetteConjoint,
          OptiRemRemImposableConjoint: this.state.OptiRemRemImposableConjoint,
          OptiRemResAvtIsConjoint: this.state.OptiRemResAvtIsConjoint,

          OptiRemCapitalSocialConjoint: this.state.OptiRemCapitalSocialConjoint,
          OptiRemPeriodeConjointPartSoc: this.state.OptiRemPeriodeConjointPartSoc,
          OptiRemPeriodeConjointPartSocConj: this.state.OptiRemPeriodeConjointPartSocConj,
          OptiRemSetSeuilAssujConjoint: this.state.OptiRemSetSeuilAssujConjoint,
          OptiRemSeuilAssujConjoint: this.state.OptiRemSeuilAssujConjoint,

          OptiRemImpotSocConjoint: this.state.OptiRemImpotSocConjoint,
          OptiRemReserveConjoint: this.state.OptiRemReserveConjoint,
          OptiRemDivAutresConjoint: this.state.OptiRemDivAutresConjoint,
          OptiRemDividendeBrutConjoint: this.state.OptiRemDividendeBrutConjoint,
          OptiRemRevPercus: this.state.OptiRemRevPercus,
          OptiRemRevenusFoyer: this.state.OptiRemRevenusFoyer,
          OptiRemIRTotal: this.state.OptiRemIRTotal,
          OptiRemCotFacPerso: this.state.OptiRemCotFacPerso,
          OptiRemCotFacPersoConjoint: this.state.OptiRemCotFacPersoConjoint,
          OptiRemRevDispo: this.state.OptiRemRevDispo,
          OptiRemTauxISReduit: this.state.OptiRemTauxISReduit,
          OptiRemTauxISNormal: this.state.OptiRemTauxISNormal,
          OptiRemTauxISZero: this.state.OptiRemTauxISZero,
          OptiRemAutresRevenus: this.state.OptiRemAutresRevenus,
          OptiRemDeductionFiscale: this.state.OptiRemDeductionFiscale,
          OptiRemReductionImpot: this.state.OptiRemReductionImpot,
          OptiRemIRDirigeantTraitement: this.state.OptiRemIRDirigeantTraitement,
          OptiRemIRConjointTraitement: this.state.OptiRemIRConjointTraitement,
          OptiRemIRDirigeantAutresRevenus: this.state.OptiRemIRDirigeantAutresRevenus,
          OptiRemIRConjointAutresRevenus: this.state.OptiRemIRConjointAutresRevenus,
          OptiRemIRDirigeantAbattement: this.state.OptiRemIRDirigeantAbattement,
          OptiRemIRConjointAbattement: this.state.OptiRemIRConjointAbattement,
          OptiRemIRDirigeantFraisReel: this.state.OptiRemIRDirigeantFraisReel,
          OptiRemIRConjointFraisReel: this.state.OptiRemIRConjointFraisReel,
          OptiRemIRDirigeantBNCMicro: this.state.OptiRemIRDirigeantBNCMicro,
          OptiRemIRConjointBNCMicro: this.state.OptiRemIRConjointBNCMicro,
          OptiRemIRDirigeantBNCControlle: this.state.OptiRemIRDirigeantBNCControlle,
          OptiRemIRConjointBNCControlle: this.state.OptiRemIRConjointBNCControlle,
          OptiRemIRDirigeantBICMicroAchatRevente: this.state.OptiRemIRDirigeantBICMicroAchatRevente,
          OptiRemIRConjointBICMicroAchatRevente: this.state.OptiRemIRConjointBICMicroAchatRevente,
          OptiRemIRDirigeantBICMicroService: this.state.OptiRemIRDirigeantBICMicroService,
          OptiRemIRConjointBICMicroService: this.state.OptiRemIRConjointBICMicroService,
          OptiRemIRDirigeantBICReel: this.state.OptiRemIRDirigeantBICReel,
          OptiRemIRConjointBICReel: this.state.OptiRemIRConjointBICReel,
          OptiRemIRDirigeantBAMicro: this.state.OptiRemIRDirigeantBAMicro,
          OptiRemIRConjointBAMicro: this.state.OptiRemIRConjointBAMicro,
          OptiRemIRDirigeantBAReel: this.state.OptiRemIRDirigeantBAReel,
          OptiRemIRConjointBAReel: this.state.OptiRemIRConjointBAReel,
          OptiRemIRDirigeantRetraite: this.state.OptiRemIRDirigeantRetraite,
          OptiRemIRConjointRetraite: this.state.OptiRemIRConjointRetraite,
          OptiRemIRDirigeantInvalidite: this.state.OptiRemIRDirigeantInvalidite,
          OptiRemIRConjointInvalidite: this.state.OptiRemIRConjointInvalidite,
          OptiRemIRDirigeantAlimentaire: this.state.OptiRemIRDirigeantAlimentaire,
          OptiRemIRConjointAlimentaire: this.state.OptiRemIRConjointAlimentaire,
          OptiRemIRDirigeantCapital: this.state.OptiRemIRDirigeantCapital,
          OptiRemIRConjointCapital: this.state.OptiRemIRConjointCapital,
          OptiRemIRDirigeantRenteMoins50: this.state.OptiRemIRDirigeantRenteMoins50,
          OptiRemIRDirigeantRente5059: this.state.OptiRemIRDirigeantRente5059,
          OptiRemIRDirigeantRente6069: this.state.OptiRemIRDirigeantRente6069,
          OptiRemIRDirigeantRente70: this.state.OptiRemIRDirigeantRente70,
          OptiRemOptionPfu: this.state.OptiRemOptionPfu,
          OptiRemIRDirigeantAutreDividendeSoumisPS: this.state.OptiRemIRDirigeantAutreDividendeSoumisPS,
          OptiRemIRDirigeantAutreDividendeNonSoumisPS: this.state.OptiRemIRDirigeantAutreDividendeNonSoumisPS,
          OptiRemIRDirigeantPlusValue: this.state.OptiRemIRDirigeantPlusValue,
          OptiRemIRDirigeantMoinsValue: this.state.OptiRemIRDirigeantMoinsValue,
          OptiRemIRDirigeantAbattementDureeDetention: this.state.OptiRemIRDirigeantAbattementDureeDetention,
          OptiRemIRDirigeantAbattementDepartRetraite: this.state.OptiRemIRDirigeantAbattementDepartRetraite,
          OptiRemIRDirigeantRecette: this.state.OptiRemIRDirigeantRecette,
          OptiRemIRDirigeantRegimeRevenuFoncier: this.state.OptiRemIRDirigeantRegimeRevenuFoncier,
          OptiRemIRDirigeantChargeRevenuFoncier: this.state.OptiRemIRDirigeantChargeRevenuFoncier,
          OptiRemIRConjointCotisationPerp: this.state.OptiRemIRConjointCotisationPerp,
          OptiRemIRDirigeantPlafond1: this.state.OptiRemIRDirigeantPlafond1,
          OptiRemIRConjointPlafond1: this.state.OptiRemIRConjointPlafond1,
          OptiRemIRDirigeantPlafond2: this.state.OptiRemIRDirigeantPlafond2,
          OptiRemIRConjointPlafond2: this.state.OptiRemIRConjointPlafond2,
          OptiRemIRDirigeantPlafond3: this.state.OptiRemIRDirigeantPlafond3,
          OptiRemIRConjointPlafond3: this.state.OptiRemIRConjointPlafond3,
          OptiRemIRDirigeantPlafondNM1: this.state.OptiRemIRDirigeantPlafondNM1,
          OptiRemSetIRDirigeantPlafondNM1: this.state.OptiRemSetIRDirigeantPlafondNM1,
          OptiRemIRConjointPlafondNM1: this.state.OptiRemIRConjointPlafondNM1,
          OptiRemSetIRConjointPlafondNM1: this.state.OptiRemSetIRConjointPlafondNM1,
          OptiRemIRDirigeantAutresRevenusSoumisPSAvecCSGDed: this.state.OptiRemIRDirigeantAutresRevenusSoumisPSAvecCSGDed,
          OptiRemIRDirigeantAutresRevenusSoumisPSSansCSGDed: this.state.OptiRemIRDirigeantAutresRevenusSoumisPSSansCSGDed,
          OptiRemIRCSGDedRevenuPatrimoine: this.state.OptiRemIRCSGDedRevenuPatrimoine,
          OptiRemIRPensionAlimEnfant1: this.state.OptiRemIRPensionAlimEnfant1,
          OptiRemIRPensionAlimEnfant2: this.state.OptiRemIRPensionAlimEnfant2,
          OptiRemIRAutrePensionAlimEnfant1: this.state.OptiRemIRAutrePensionAlimEnfant1,
          OptiRemIRAutrePensionAlimEnfant2: this.state.OptiRemIRAutrePensionAlimEnfant2,
          OptiRemIRPensionAlim: this.state.OptiRemIRPensionAlim,
          OptiRemIRAutrePensionAlim: this.state.OptiRemIRAutrePensionAlim,
          OptiRemIRDonPersonne: this.state.OptiRemIRDonPersonne,
          OptiRemIRDonAssociation: this.state.OptiRemIRDonAssociation,
          OptiRemIREmploiDomicile: this.state.OptiRemIREmploiDomicile,
          OptiRemIRFraisGardeEnfantExclusive1: this.state.OptiRemIRFraisGardeEnfantExclusive1,
          OptiRemIRFraisGardeEnfantPartagee1: this.state.OptiRemIRFraisGardeEnfantPartagee1,
          OptiRemIRFraisGardeEnfantExclusive2: this.state.OptiRemIRFraisGardeEnfantExclusive2,
          OptiRemIRFraisGardeEnfantPartagee2: this.state.OptiRemIRFraisGardeEnfantPartagee2,
          OptiRemIRFraisGardeEnfantExclusive3: this.state.OptiRemIRFraisGardeEnfantExclusive3,
          OptiRemIRFraisGardeEnfantPartagee3: this.state.OptiRemIRFraisGardeEnfantPartagee3,
          OptiRemIREtudeEnfantCollegeExclusive: this.state.OptiRemIREtudeEnfantCollegeExclusive,
          OptiRemIREtudeEnfantCollegePartagee: this.state.OptiRemIREtudeEnfantCollegePartagee,
          OptiRemIREtudeEnfantLyceeExclusive: this.state.OptiRemIREtudeEnfantLyceeExclusive,
          OptiRemIREtudeEnfantLyceePartagee: this.state.OptiRemIREtudeEnfantLyceePartagee,
          OptiRemIREtudeEnfantFacExclusive: this.state.OptiRemIREtudeEnfantFacExclusive,
          OptiRemIREtudeEnfantFacPartagee: this.state.OptiRemIREtudeEnfantFacPartagee,
          OptiRemContratEnregistre: this.state.OptiRemContratEnregistre,
          OptiRemContratEnregistreConjoint: this.state.OptiRemContratEnregistreConjoint,
          OptiRemContratFraisPro: this.state.OptiRemContratFraisPro,
          OptiRemContratFraisProConjoint: this.state.OptiRemContratFraisProConjoint,
          OptiRemContratEnregistreSante: this.state.OptiRemContratEnregistreSante,
          OptiRemContratEnregistreSanteConjoint: this.state.OptiRemContratEnregistreSanteConjoint,
          OptiRemListeContrat: this.state.OptiRemListeContrat,
          OptiRemListeContratConjoint: this.state.OptiRemListeContratConjoint,
          OptiRemCarriere: this.state.OptiRemCarriere,
          OptiRemCarriereConjoint: this.state.OptiRemCarriereConjoint,
          OptiRemForfaitSocialArticle83: this.state.OptiRemForfaitSocialArticle83,
          OptiRemAssietteCotisation83: this.state.OptiRemAssietteCotisation83,
          OptiRemInteressementCash: this.state.OptiRemInteressementCash,
          OptiRemParticipationCash: this.state.OptiRemParticipationCash,
          OptiRemForfaitSocialInteressement: this.state.OptiRemForfaitSocialInteressement,
          OptiRemForfaitSocialParticipation: this.state.OptiRemForfaitSocialParticipation,
          OptiRemForfaitSocialAbondementPee: this.state.OptiRemForfaitSocialAbondementPee,
          OptiRemForfaitSocialAbondementPerco: this.state.OptiRemForfaitSocialAbondementPerco,
          OptiRemCDSEtude: this.state.OptiRemCDSEtude,
          OptiRemCDSStatut: this.state.OptiRemCDSStatut,
          OptiRemCDSCaisse: this.state.OptiRemCDSCaisse,
          OptiRemCDSRaisonnement: this.state.OptiRemCDSRaisonnement,
          OptiRemCDSStatutConjoint: this.state.OptiRemCDSStatutConjoint,
          OptiRemCDSCaisseConjoint: this.state.OptiRemCDSCaisseConjoint,
          OptiRemCDSRaisonnementConjoint: this.state.OptiRemCDSRaisonnementConjoint,
          OptiRemConventionneCarcdsf: this.state.OptiRemConventionneCarcdsf,
          OptiRemConventionneCarmf: this.state.OptiRemConventionneCarmf,
          OptiRemOptionRsi: this.state.OptiRemOptionRsi,
          OptiRemConventionneCarpimko: this.state.OptiRemConventionneCarpimko,
          OptiRemTauxCpam: this.state.OptiRemTauxCpam,
          OptiRemClassePrevoyanceCarpv: this.state.OptiRemClassePrevoyanceCarpv,
          OptiRemClasseRetraiteCarpv: this.state.OptiRemClasseRetraiteCarpv,
          OptiRemClassePrevoyanceCavec: this.state.OptiRemClassePrevoyanceCavec,
          OptiRemClasseRetraiteCavec: this.state.OptiRemClasseRetraiteCavec,
          OptiRemConventionneCavp: this.state.OptiRemConventionneCavp,
          OptiRemClasseRetraiteCavp: this.state.OptiRemClasseRetraiteCavp,
          OptiRemClassePrevoyanceCipav: this.state.OptiRemClassePrevoyanceCipav,
          OptiRemClasseRetraiteCipav: this.state.OptiRemClasseRetraiteCipav,
          OptiRemAncienneteCnbf: this.state.OptiRemAncienneteCnbf,
          OptiRemClasseRetraiteCnbf: this.state.OptiRemClasseRetraiteCnbf,
          OptiRemLpaMontantIncap: this.state.OptiRemLpaMontantIncap,
          OptiRemLpaFranchiseIncapDebut: this.state.OptiRemLpaFranchiseIncapDebut,
          OptiRemLpaFranchiseIncapFin: this.state.OptiRemLpaFranchiseIncapFin,
          OptiRemLpaInvalPartielle: this.state.OptiRemLpaInvalPartielle,
          OptiRemLpaInvalTotale: this.state.OptiRemLpaInvalTotale,
          OptiRemClasseRetraiteCrn: this.state.OptiRemClasseRetraiteCrn,
          OptiRemCrnSermentAvt2014: this.state.OptiRemCrnSermentAvt2014,
          OptiRemCrnColmarMetz: this.state.OptiRemCrnColmarMetz,
          OptiRemAtexa: this.state.OptiRemAtexa,
          OptiRemTauxActivite: this.state.OptiRemTauxActivite,
          OptiRemEffectif: this.state.OptiRemEffectif,
          OptiRemCavecSalarie: this.state.OptiRemCavecSalarie,
          OptiRemCnbfSalarie: this.state.OptiRemCnbfSalarie,
          OptiRemAccidentTravail: this.state.OptiRemAccidentTravail,
          OptiRemAgircArrcoT1Sal: this.state.OptiRemAgircArrcoT1Sal,
          OptiRemAgircArrcoT1Pat: this.state.OptiRemAgircArrcoT1Pat,
          OptiRemAgircArrcoT2Sal: this.state.OptiRemAgircArrcoT2Sal,
          OptiRemAgircArrcoT2Pat: this.state.OptiRemAgircArrcoT2Pat,
          OptiRemTransport: this.state.OptiRemTransport,
          OptiRemFormation: this.state.OptiRemFormation,
          OptiRemApprentissage: this.state.OptiRemApprentissage,
          OptiRemRegime: this.state.OptiRemRegime,
          OptiRemTaxeSalaire: this.state.OptiRemTaxeSalaire,
          OptiRemAssuranceChomage: this.state.OptiRemAssuranceChomage,
          OptiRemReductionGenerale: this.state.OptiRemReductionGenerale,
          OptiRemTabCotisationsManuelle: this.state.OptiRemTabCotisationsManuelle,
          OptiRemConventionneCarcdsfConjoint: this.state.OptiRemConventionneCarcdsfConjoint,
          OptiRemConventionneCarmfConjoint: this.state.OptiRemConventionneCarmfConjoint,
          OptiRemOptionRsiConjoint: this.state.OptiRemOptionRsiConjoint,
          OptiRemConventionneCarpimkoConjoint: this.state.OptiRemConventionneCarpimkoConjoint,
          OptiRemTauxCpamConjoint: this.state.OptiRemTauxCpamConjoint,
          OptiRemClassePrevoyanceCarpvConjoint: this.state.OptiRemClassePrevoyanceCarpvConjoint,
          OptiRemClasseRetraiteCarpvConjoint: this.state.OptiRemClasseRetraiteCarpvConjoint,
          OptiRemClassePrevoyanceCavecConjoint: this.state.OptiRemClassePrevoyanceCavecConjoint,
          OptiRemClasseRetraiteCavecConjoint: this.state.OptiRemClasseRetraiteCavecConjoint,
          OptiRemConventionneCavpConjoint: this.state.OptiRemConventionneCavpConjoint,
          OptiRemClasseRetraiteCavpConjoint: this.state.OptiRemClasseRetraiteCavpConjoint,
          OptiRemClassePrevoyanceCipavConjoint: this.state.OptiRemClassePrevoyanceCipavConjoint,
          OptiRemClasseRetraiteCipavConjoint: this.state.OptiRemClasseRetraiteCipavConjoint,
          OptiRemAncienneteCnbfConjoint: this.state.OptiRemAncienneteCnbfConjoint,
          OptiRemClasseRetraiteCnbfConjoint: this.state.OptiRemClasseRetraiteCnbfConjoint,
          OptiRemLpaMontantIncapConjoint: this.state.OptiRemLpaMontantIncapConjoint,
          OptiRemLpaFranchiseIncapDebutConjoint: this.state.OptiRemLpaFranchiseIncapDebutConjoint,
          OptiRemLpaFranchiseIncapFinConjoint: this.state.OptiRemLpaFranchiseIncapFinConjoint,
          OptiRemLpaInvalPartielleConjoint: this.state.OptiRemLpaInvalPartielleConjoint,
          OptiRemLpaInvalTotaleConjoint: this.state.OptiRemLpaInvalTotaleConjoint,
          OptiRemClasseRetraiteCrnConjoint: this.state.OptiRemClasseRetraiteCrnConjoint,
          OptiRemCrnSermentAvt2014Conjoint: this.state.OptiRemCrnSermentAvt2014Conjoint,
          OptiRemCrnColmarMetzConjoint: this.state.OptiRemCrnColmarMetzConjoint,
          OptiRemAtexaConjoint: this.state.OptiRemAtexaConjoint,
          OptiRemTauxActiviteConjoint: this.state.OptiRemTauxActiviteConjoint,
          OptiRemEffectifConjoint: this.state.OptiRemEffectifConjoint,
          OptiRemCavecSalarieConjoint: this.state.OptiRemCavecSalarieConjoint,
          OptiRemCnbfSalarieConjoint: this.state.OptiRemCnbfSalarieConjoint,
          OptiRemAccidentTravailConjoint: this.state.OptiRemAccidentTravailConjoint,
          OptiRemAgircArrcoT1SalConjoint: this.state.OptiRemAgircArrcoT1SalConjoint,
          OptiRemAgircArrcoT1PatConjoint: this.state.OptiRemAgircArrcoT1PatConjoint,
          OptiRemAgircArrcoT2SalConjoint: this.state.OptiRemAgircArrcoT2SalConjoint,
          OptiRemAgircArrcoT2PatConjoint: this.state.OptiRemAgircArrcoT2PatConjoint,
          OptiRemTransportConjoint: this.state.OptiRemTransportConjoint,
          OptiRemFormationConjoint: this.state.OptiRemFormationConjoint,
          OptiRemApprentissageConjoint: this.state.OptiRemApprentissageConjoint,
          OptiRemRegimeConjoint: this.state.OptiRemRegimeConjoint,
          OptiRemTaxeSalaireConjoint: this.state.OptiRemTaxeSalaireConjoint,
          OptiRemAssuranceChomageConjoint: this.state.OptiRemAssuranceChomageConjoint,
          OptiRemReductionGeneraleConjoint: this.state.OptiRemReductionGeneraleConjoint,
          OptiRemTabCotisationsManuelleConjoint: this.state.OptiRemTabCotisationsManuelleConjoint,
        },
      ];
    }

    ListeOptimisation = [...ListeOptimisation, ...newOptimisation];
    this.setState(
      {
        ListeOptimisation: ListeOptimisation,
      },
      () => {
        if (this.state.ApiUser !== true) {
          if (this.state.IdDossier === "") {
            this.handleSaveAsDossier(this.state.TypeSimulation);
          } else {
            this.handleSaveDossier();
          }
        }
      }
    );
  };

  handleAccesOptimisation = (id, ResultatTNS) => {
    let ListeOptimisation = this.state.ListeOptimisation;
    const OptimisationToDisplay = ListeOptimisation.filter((Optimisation) => Optimisation.IdOptimisation === id);
    //  OptimisationToDisplay[0]["OptiRemCarriere"]= _.cloneDeep(this.state.Carriere);
    this.setState(OptimisationToDisplay[0], () => {
      this.handleCalculOptiRem("res_avant_is", "", "", ResultatTNS, false, "");
      if (this.state.PeriodeActuelleConjoint[0] !== undefined) {
        this.handleCalculOptiRem("res_avant_is", "", "Conjoint", ResultatTNS, false, "");
      }
    });
  };
  handleDeleteOptimisation = (id) => {
    let ListeOptimisation = this.state["ListeOptimisation"];
    ListeOptimisation = ListeOptimisation.filter((ListeOptimisation) => ListeOptimisation.IdOptimisation !== id);
    this.setState({ ListeOptimisation: ListeOptimisation });
  };
  //REMEXPRESS

  handleChoixStatutRemExpress = (event) => {
    const OldStatut = this.state.Statut;

    this.setState(
      {
        Statut: event.target.value,
        RemExpressResAvtIs: event.target.value === "TNS individuel" ? 0 : this.state.RemExpressResAvtIs,
        RemExpressImpotSoc: event.target.value === "TNS individuel" ? 0 : this.state.RemExpressImpotSoc,
        RemExpressReserve: event.target.value === "TNS individuel" ? 0 : this.state.RemExpressReserve,
        RemExpressDivAutres: event.target.value === "TNS individuel" ? 0 : this.state.RemExpressDivAutres,
        RemExpressDividendeBrut: event.target.value === "TNS individuel" ? 0 : this.state.RemExpressDividendeBrut,
      },
      () => {
        let Statut = this.state.Statut;
        let ContratEnregistre = this.state.ContratEnregistre;
        let ContratFraisPro = this.state.ContratFraisPro;
        let ContratEnregistreSante = this.state.ContratEnregistreSante;
        let ListeContrat = this.state.ListeContrat;
        if (OldStatut === "Assimilé salarié" && this.state.Statut !== "Assimilé salarié") {
          ContratEnregistre = [];
          ContratFraisPro = [];
          ContratEnregistreSante = [];
          ListeContrat = [];
        }
        if ((OldStatut === "TNS Article 62" || OldStatut === "TNS individuel") && this.state.Statut === "Assimilé salarié") {
          ContratEnregistre = [];
          ContratFraisPro = [];
          ContratEnregistreSante = [];
          ListeContrat = [];
        }
        this.setState({
          Statut: Statut,
          ContratEnregistre: ContratEnregistre,
          ContratFraisPro: ContratFraisPro,
          ContratEnregistreSante: ContratEnregistreSante,
          ListeContrat: ListeContrat,
        });
      }
    );
  };
  handleSwitchClient = (event) => {
    let Civilite = this.state.SwitchCivilite;
    let Prenom = this.state.SwitchPrenom;
    let Nom = this.state.SwitchNom;
    let CiviliteConjoint = this.state.SwitchCiviliteConjoint;
    let PrenomConjoint = this.state.SwitchPrenomConjoint;
    let NomConjoint = this.state.SwitchNomConjoint;
    if (event.target.value === "conjoint") {
      Civilite = this.state.SwitchCiviliteConjoint;
      Prenom = this.state.SwitchPrenomConjoint;
      Nom = this.state.SwitchNomConjoint;
      CiviliteConjoint = this.state.SwitchCivilite;
      PrenomConjoint = this.state.SwitchPrenom;
      NomConjoint = this.state.SwitchNom;
    }
    this.setState({
      SwitchClient: event.target.value,
      Civilite: Civilite,
      Prenom: Prenom,
      Nom: Nom,
      CiviliteConjoint: CiviliteConjoint,
      PrenomConjoint: PrenomConjoint,
      NomConjoint: NomConjoint,
    });
  };
  handleRemExpressMontantSaisi = (name) => (event) => {
    this.setState({
      [name]: parseInt(event.target.value.replace(/\D/g, ""), 10),
      RemExpressMontantSaisi: parseInt(event.target.value.replace(/\D/g, ""), 10),
    });
  };

  handleCalculRemExpress = (TypeCalcul, name, RemExpertCalcul, event) => {
    let RemExpressMontantSaisi = Boolean(event) ? (typeof event === "object" ? parseInt(event.target.value.replace(/\D/g, ""), 10) : event) : this.state.RemExpressMontantSaisi;

    this.setState(
      {
        [name]: RemExpressMontantSaisi,
        RemExpressTypeCalcul: TypeCalcul,
        RemExpressMontantSaisi: RemExpressMontantSaisi,
      },
      () => {
        const newPeriode = [
          {
            IdPeriodeActuelle: Date.now(),
            Statut: this.state.Statut,
            Remuneration: this.state.RemExpressRemNette,
            TypeRemuneration: this.state.Statut === "Assimilé salarié" ? "salaire_net" : "remuneration_nette",
            StatutConjoint: "Aucun",
            Caisse: this.state.Caisse,
            ConventionneCarcdsf: this.state.ConventionneCarcdsf,
            ConventionneCarmf: this.state.ConventionneCarmf,
            OptionRsi: this.state.OptionRsi,
            ConventionneCarpimko: this.state.ConventionneCarpimko,
            TauxCpam: this.state.TauxCpam,
            ClassePrevoyanceCarpv: this.state.ClassePrevoyanceCarpv,
            ClasseRetraiteCarpv: this.state.ClasseRetraiteCarpv,
            ClassePrevoyanceCavec: this.state.ClassePrevoyanceCavec,
            ClasseRetraiteCavec: this.state.ClasseRetraiteCavec,
            ConventionneCavp: this.state.ConventionneCavp,
            ClasseRetraiteCavp: this.state.ClasseRetraiteCavp,
            ClassePrevoyanceCipav: this.state.ClassePrevoyanceCipav,
            ClasseRetraiteCipav: this.state.ClasseRetraiteCipav,
            AncienneteCnbf: this.state.AncienneteCnbf,
            ClasseRetraiteCnbf: this.state.ClasseRetraiteCnbf,
            LpaMontantIncap: this.state.LpaMontantIncap,
            LpaFranchiseIncapDebut: this.state.LpaFranchiseIncapDebut,
            LpaFranchiseIncapFin: this.state.LpaFranchiseIncapFin,
            LpaInvalPartielle: this.state.LpaInvalPartielle,
            LpaInvalTotale: this.state.LpaInvalTotale,
            ClasseRetraiteCrn: this.state.ClasseRetraiteCrn,
            CrnSermentAvt2014: this.state.CrnSermentAvt2014,
            CrnColmarMetz: this.state.CrnColmarMetz,
            Atexa: this.state.Atexa,
            TauxActivite: this.state.TauxActivite,
            Effectif: this.state.Effectif,
            CavecSalarie: this.state.CnbfSalarie !== "Non" ? "Non" : this.state.CavecSalarie,
            CnbfSalarie: this.state.CnbfSalarie,
            AccidentTravail: this.state.AccidentTravail,
            AgircArrcoT1Sal: this.state.AgircArrcoT1Sal,
            AgircArrcoT1Pat: this.state.AgircArrcoT1Pat,
            AgircArrcoT2Sal: this.state.AgircArrcoT2Sal,
            AgircArrcoT2Pat: this.state.AgircArrcoT2Pat,
            Transport: this.state.Transport,
            Formation: this.state.Formation,
            Apprentissage: this.state.Apprentissage,
            Regime: this.state.Regime,
            TaxeSalaire: this.state.TaxeSalaire,
            AssuranceChomage: this.state.AssuranceChomage,
            ReductionGenerale: this.state.ReductionGenerale,
            AvantageNature: this.state.AvantageNature,
            TabCotisationsManuelle: this.state.TabCotisationsManuelle,
            Fiscalite: this.state.Fiscalite,
            TauxISReduit: this.state.TauxISReduit,
            TauxISNormal: this.state.TauxISNormal,
            TauxISZero: this.state.TauxISZero,

            DividendeBrut: this.state.RemExpressDividendeBrut,
            DividendeBrutAutres: this.state.RemExpressDivAutres,
            CapitalSocial: this.state.CapitalSocial,
            PartSoc: this.state.PartSoc,
            PartSocConj: this.state.PartSocConj,
            ResAvtIs: this.state.RemExpressResAvtIs,
            ImpotSoc: this.state.RemExpressImpotSoc,
            SeuilAssuj: this.state.SeuilAssuj,
            SetSeuilAssuj: this.state.SetSeuilAssuj,
            Reserve: this.state.RemExpressReserve,
            // DividendeBrutAutres: 0,
          },
        ];
        this.setState(
          {
            PeriodeActuelle: newPeriode,
          },
          () => {
            RemExpertCalcul.GetResultRemExpress(this.state, this.state.OptiRemTnsRemunerationNette, this.state.OptiRemTnsDividendeBrut).then((res) => {
              let RemExpressCoutRemuneration = res.RemunerationClient.CoutRemuneration;
              let RemExpressCotisations = Number(res.RemunerationClient.TotalChargesSociales) + Number(res.RemunerationClient.CotisationsFacultativesEntreprise);
              let RemExpressRemBrute = res.RemunerationClient.RemunerationBrute;
              let RemExpressRemNette = res.RemunerationClient.RemunerationNette;
              let RemExpressRemImposable = res.RemunerationClient.BrutImposable;
              let RemExpressResAvtIs = res.ResAvtIs;
              let RemExpressImpotSoc = res.ImpotSoc;
              let RemExpressDivAutres = res.DivBrutsAutres;
              let RemExpressDividendeBrut = res.DivBruts;
              let PeriodeActuelle = this.state.PeriodeActuelle;
              PeriodeActuelle[0].ResAvtIs = res.ResAvtIs;
              PeriodeActuelle[0].DividendeBrut = RemExpressDividendeBrut;
              PeriodeActuelle[0].DividendeBrutAutres = res.DivBrutsAutres;
              PeriodeActuelle[0].ImpotSoc = res.ImpotSoc;

              let RemExpressCoutEntreprise = RemExpressCoutRemuneration + res.RemunerationClient.CotisationsFacultativesFraisPro + RemExpressResAvtIs;
              let RemExpressCotFacPerso = res.RemunerationClient.VersementFacultatifPerso;
              let RemExpressCotisationsPatronale = 0;
              let RemExpressCotisationsSalariale = 0;
              // let SalaireBrutContratRetraite = 0;
              if (this.state.Statut === "Assimilé salarié") {
                RemExpressCotisationsPatronale = res.RemunerationClient.AffichageResultat.TotalChargesPatronale;
                RemExpressCotisationsSalariale = res.RemunerationClient.AffichageResultat.TotalChargesSalariale;
                // SalaireBrutContratRetraite=res.RemunerationClient.RemunerationBrute;
              }

              this.setState(
                {
                  PeriodeActuelle: PeriodeActuelle,
                  RemExpressCoutEntreprise: RemExpressCoutEntreprise,
                  RemExpressCoutRemuneration: RemExpressCoutRemuneration,
                  RemExpressCotisations: RemExpressCotisations,
                  RemExpressCotisationsPatronale: RemExpressCotisationsPatronale,
                  RemExpressCotisationsSalariale: RemExpressCotisationsSalariale,
                  RemExpressRemNette: RemExpressRemNette,
                  RemExpressRemBrute: RemExpressRemBrute,
                  RemExpressRemImposable: RemExpressRemImposable,
                  RemExpressResAvtIs: RemExpressResAvtIs,
                  RemExpressImpotSoc: RemExpressImpotSoc,
                  RemExpressDividendeBrut: RemExpressDividendeBrut,
                  RemExpressDivAutres: RemExpressDivAutres,
                  RemExpressRevPercus: RemExpressRemNette + RemExpressDividendeBrut,
                  RemExpressCotFacPerso: RemExpressCotFacPerso,
                  // SalaireBrutContratRetraite: SalaireBrutContratRetraite,
                },
                () => {
                  RemExpertCalcul.GetImpot("", this.state, this.state.RemExpressRemImposable, 0, this.state.RemExpressDividendeBrut).then((resImpot) => {
                    this.setState(
                      {
                        OptiRemLoading: false,
                        RemExpressRevenusFoyer: resImpot.AutresRevenusFoyer,
                        RemExpressIRTotal: resImpot.IRTotal,
                        RemExpressRevDispo:
                          Number(this.state.RemExpressRevPercus) + Number(resImpot.AutresRevenusFoyer) - Number(this.state.RemExpressCotFacPerso) - Number(resImpot.IRTotal),
                      },
                      () => {}
                    );
                  });
                }
              );
            });
          }
        );
      }
    );
  };
  handleMontantRemExpress = (TypeCalcul) => (event) => {
    this.setState({
      RemExpressTypeCalcul: TypeCalcul,
      RemExpressMontantSaisi: parseInt(event.target.value.replace(/\D/g, ""), 10),
    });
  };
  handleValidationParamRemExpress = (RemExpertCalcul) => {
    this.setState(
      {
        RemExpressTypeCalcul: "cout_entreprise",
        RemExpressMontantSaisi: this.state.RemExpressCoutEntreprise,
        RemExpressDividendeBrut: this.state.RemExpressDividendeBrut,
      },
      () => {
        this.handleCalculRemExpress(this.state.RemExpressTypeCalcul, RemExpertCalcul, "");
      }
    );
  };

  //PREVTNS
  handlePrevTnsAddToPreco = (ContratPreco) => {
    this.setState({
      [ContratPreco + "ContratEnregistre"]: this.state.ContratEnregistre,
      [ContratPreco + "ContratFraisPro"]: this.state.ContratFraisPro,
    });
  };
  handlePropals = (event) => {
    let PrevTNSPreco1ContratEnregistre = [];
    let PrevTNSPreco2ContratEnregistre = [];
    let PrevTNSPreco3ContratEnregistre = [];
    let PrevTNSPreco1ContratFraisPro = [];
    let PrevTNSPreco2ContratFraisPro = [];
    let PrevTNSPreco3ContratFraisPro = [];

    if (event.target.value >= 1) {
      PrevTNSPreco1ContratEnregistre = this.state.PrevTNSPreco1ContratEnregistre;
      PrevTNSPreco1ContratFraisPro = this.state.PrevTNSPreco1ContratFraisPro;
    }
    if (event.target.value >= 2) {
      PrevTNSPreco2ContratEnregistre = this.state.PrevTNSPreco2ContratEnregistre;
      PrevTNSPreco2ContratFraisPro = this.state.PrevTNSPreco2ContratFraisPro;
    }
    if (event.target.value >= 3) {
      PrevTNSPreco3ContratEnregistre = this.state.PrevTNSPreco3ContratEnregistre;
      PrevTNSPreco3ContratFraisPro = this.state.PrevTNSPreco3ContratFraisPro;
    }

    this.setState({
      AffichContratPreco: event.target.value,
      PrevTNSPreco1ContratEnregistre: PrevTNSPreco1ContratEnregistre,
      PrevTNSPreco2ContratEnregistre: PrevTNSPreco2ContratEnregistre,
      PrevTNSPreco3ContratEnregistre: PrevTNSPreco3ContratEnregistre,
      PrevTNSPreco1ContratFraisPro: PrevTNSPreco1ContratFraisPro,
      PrevTNSPreco2ContratFraisPro: PrevTNSPreco2ContratFraisPro,
      PrevTNSPreco3ContratFraisPro: PrevTNSPreco3ContratFraisPro,
    });
  };
  //PREVTNS2

  handlePrevTnsAddToPreco2 = () => {
    this.setState({
      PrevTNSPreco1ContratEnregistre: this.state.ContratEnregistre,
      PrevTNSPreco2ContratEnregistre: this.state.ContratEnregistre,
      PrevTNSPreco3ContratEnregistre: this.state.ContratEnregistre,
      PrevTNSPreco1ContratFraisPro: this.state.ContratFraisPro,
      PrevTNSPreco2ContratFraisPro: this.state.ContratFraisPro,
      PrevTNSPreco3ContratFraisPro: this.state.ContratFraisPro,
    });
  };

  handleChoixCaissePrevTns2 = (event) => {
    this.setState({ Caisse: event.target.value, PrevTns2Profession: "", PrevTns2SecteurActivite: "0" }, () => {});
  };
  handleChoixSecteurActivitePrevTns2 = (event) => {
    this.setState({ PrevTns2SecteurActivite: event.target.value, PrevTns2Profession: "" }, () => {});
  };

  //SIMULATEURS
  handleRemiseDonneeDeclarationPER = () => {
    this.setState({ ...DeclaPER });
  };
  componentWillMount() {
    // console.log("PROPS", this.props.InfosUser);
    // this.setState(this.props.InfosUser);
    if (this.props?.InfosUser?.ApiUser === true) {
      if (this.props.InfosDossier !== false) {
        this.setState(this.props.InfosDossier, () => {
          GetPass().then((Tab) => {
            this.setState({
              TabPass: Tab,
            });
            this.GetDateDepartRetraite("", this.state);
            this.GetDateDepartRetraite("Conjoint", this.state);

            this.CalculMajorationEnfant(this.state.Civilite, this.state.Enfant, this.state.Carriere, this.state.TableauEnfant, "", [
              this.state.CarriereMajorationEnfantSaisi,
              this.state.CarriereMajorationEnfantSaisiFonctionPublique,
              this.state.TrimestreBonificationEnfantSaisiFonctionPublique,
              this.state.CarriereMajorationEnfantSaisiCrpcen,
            ]);
            this.CalculMajorationEnfant(this.state.CiviliteConjoint, this.state.Enfant, this.state.CarriereConjoint, this.state.TableauEnfant, "Conjoint", [
              this.state.CarriereMajorationEnfantSaisiConjoint,
              this.state.CarriereMajorationEnfantSaisiFonctionPubliqueConjoint,
              this.state.TrimestreBonificationEnfantSaisiFonctionPubliqueConjoint,
              this.state.CarriereMajorationEnfantSaisiCrpcenConjoint,
            ]);
          });
          if ("v2" in this.props.InfosDossier) {
            if (this.props.InfosDossier.TypeSimulation === "Audit complet") {
              majAuditComplet(this, false);
            } else if (this.props.InfosDossier.TypeSimulation === "Audit retraite") {
              majAuditRetraite(this, false, false);
            } else if (this.props.InfosDossier.TypeSimulation === "Audit prévoyance") {
              majAuditPrevoyance(this, false);
            } else if (this.props.InfosDossier.TypeSimulation === "Audit rémunération") {
              majAuditRemuneration(this, false, false);
            }
          }
        });
      } else {
        GetPass().then((Tab) => {
          this.setState({
            TabPass: Tab,
          });
          this.GetDateDepartRetraite("", this.state);
          this.GetDateDepartRetraite("Conjoint", this.state);
        });
      }
    }
    //  else {
    // if (window.performance) {
    //   // console.info("window.performance works fine on this browser");
    // }
    // // console.log("NAV2", performance.navigation.type);
    // if (performance.navigation.type === 1) {
    //   console.info("This page is reloaded");
    //   const LocalState = JSON.parse(localStorage.getItem("State"));
    //   this.setState(LocalState);
    //   GetPass().then((Tab) => {
    //     this.setState({
    //       TabPass: Tab,
    //     });
    //     this.GetDateDepartRetraite("", this.state);
    //     this.GetDateDepartRetraite("Conjoint", this.state);
    //   });
    // } else {
    //   // console.info("This page is not reloaded");
    //   this.setState({ IsLogged: false });
    //   localStorage.clear();
    // }
    // }
  }
  componentDidMount() {
    // this.handleListeUserContratPrevoyance();
  }
  componentWillReceiveProps() {
    // localStorage.setItem("State", JSON.stringify(this.state));
  }
  //VARIABLES
  constructor(props) {
    super(props);
    this.state = {
      //VARIABLES GENERAL
      IdUser: "",
      IdCabinet: "",
      Conseiller: "",
      LogoName: "",
      MentionLegale: "",
      Color1: "",
      Color2: "",
      Color3: "",
      Color4: "",
      IsLogged: false,
      IdClient: "",
      IdUserContratPrevoyance: "",
      DisplayDateAcquisitionPoint: false,
      ListeClient: [],
      InititalListeClient: [],
      IdDossier: "",
      ListeDossier: [],
      LibelleDossier: "",
      LibelleSimulation: "",
      AccesSimulateur: false,
      TypeSimulation: "",
      ListeContratPrevoyance: [],
      Civilite: "Monsieur",
      CiviliteConjoint: "Madame",
      Nom: "",
      Prenom: "",
      NomConjoint: "",
      PrenomConjoint: "",
      InclureConjoint: false,
      PartFiscale: 0,
      SetPartFiscale: false,
      DateNaissance: "01011970",
      DateNaissanceConjoint: "01011970",
      StatutMarital: "celibataire",
      Isole: false,
      Enfant: 0,
      EnfantACharge: 0,
      EnfantAChargeExclusive: 0,
      EnfantAChargePartagee: 0,
      TableauEnfant: [],
      TabPass: {},

      // TypeSimulation: "Audit retraite",
      // Color1: "#1E3C72",
      // Color2: "#77B6E8",
      // Color3: "#7878de",
      // Color4: "#DBE5F1",
      // Civilite: "Monsieur",
      // IdUser: 1,
      // IdCabinet: 1,
      // Nom: "Bibibi",
      // NomConjoint: "Bibi",
      // Prenom: "Sam",
      // DateNaissance: "01011970",
      // PrenomConjoint: "Lili",
      // DateNaissanceConjoint: "10101980",
      // StatutMarital: "marie",
      // TableauEnfant: [
      //   { id: 1, Prenom: "Gabriel", DateNaissance: "11072018", Charge: "exclusive" },
      //   { id: 2, Prenom: "Gabriel", DateNaissance: "11072007", Charge: "partagee" },
      //   { id: 3, Prenom: "Gabriel", DateNaissance: "11072003", Charge: "none" },
      //   { id: 4, Prenom: "Gabriel", DateNaissance: "11071990", Charge: "exclusive" },
      // ],
      // Enfant: 4,
      // EnfantACharge: 4,
      // EnfantAChargeExclusive: 4,

      //ENVIRONNEMENT JURIDIQUE
      ...RemExpertEnvironnementJuridique,

      //ENVIRONNEMENT SOCIAL
      ...RemExpertPeriodeActuelle,
      ...RemExpertFinPeriodeActuelle,
      ...RemExpertEnvironnementSocial,
      //REVENUS D'ACTIVITE
      ...RemExpertRevenusActivite,
      ...RemExpertForfaitSocial,
      //REVENUS DU FOYER
      ...RemExpertRevenusFoyer,
      //PREVOYANCE
      ...RemExpertContratEnregistrePrevoyance,
      ...RemExpertPrevoyance,

      //SANTE
      ...RemExpertContratEnregistreSante,
      ...RemExpertSante,

      //RETRAITE
      ...RemExpertDateDepartRetraite,
      ...RemExpertPeriodeRetraite,
      TableauPoints: { ...RemExpertTableauPoints },
      TableauPointsConjoint: { ...RemExpertTableauPoints },
      ...RemExpertMajorationEnfantRetraite,
      ...RemExpertParametreRetraite,
      ...RemExpertListeContratRetraite,
      ...RemExpertContratRetraite,
      ...ParamPerpContratRetraite,

      //OPTIMISATION
      ...RemExpertListeOptimisation,
      //ARBITRAGE
      ArbitrageRai: 0,
      ArbitrageReserve: 0,
      ResultatArbitrage: {},
      ArbitrageLoading: false,
      openErreurArbitrage: false,
      OptiRemResultatRetraite: {},
      OptiRemResultatRetraiteConjoint: {},
      OptiRemResultatPrevoyance: {},
      OptiRemResultatPrevoyanceConjoint: {},
      //REMEXPRESS
      ...RemExpress,
      ...RemExpressListeOptimisation,
      //PREVTNS
      ...PrevTns,
      ...PrevTns2,
      //SIMULATEURS
      ...CalculatriceAgircArrco,
      ...DeclaPER,
      ...CorrectionPER,
      ...StrategiePER,
    };
  }

  render() {
    if (window.location.href.indexOf("localhost") > -1 || window.location.href.indexOf("test") > -1) {
      console.log(this.state);
    }
    return (
      <AppContext.Provider
        value={{
          state: this.state,
          handleInputChange: this.handleInputChange,
          handleValueChange: this.handleValueChange,
          handleValueChangeAsync: this.handleValueChangeAsync,
          handleValueChangeNumberForced: this.handleValueChangeNumberForced,
          handleObjectChange: this.handleObjectChange,
          handleObjectValueChange: this.handleObjectValueChange,
          handleDialogClose: this.handleDialogClose,
          handleCheckChange: this.handleCheckChange,
          handleSaveSettings: this.handleSaveSettings,
          handleLogOut: this.handleLogOut,
          handleChangeNomClient: this.handleChangeNomClient,
          handleCreationTableauEnfant: this.handleCreationTableauEnfant,
          handleModificationEnfant: this.handleModificationEnfant,
          handleModificationEnfantPrevTns2: this.handleModificationEnfantPrevTns2,
          handleNewClient: this.handleNewClient,
          handleCiviliteConjoint: this.handleCiviliteConjoint,
          handleCreateUpdateClient: this.handleCreateUpdateClient,
          HandleImportClient: this.HandleImportClient,
          handleDisplayClient: this.handleDisplayClient,
          handleSearchClient: this.handleSearchClient,
          handleNewSimulation: this.handleNewSimulation,
          handleDisplaySimulation: this.handleDisplaySimulation,
          handleSuppressionClient: this.handleSuppressionClient,
          handleListeClient: this.handleListeClient,
          handleAccessListeClient: this.handleAccessListeClient,
          handleListeDossier: this.handleListeDossier,
          handleOpenDossier: this.handleOpenDossier,
          handleDeleteDossier: this.handleDeleteDossier,
          handleSaveDossier: this.handleSaveDossier,
          handleSaveAsDossier: this.handleSaveAsDossier,
          handleSaveAsDossierPartner: this.handleSaveAsDossierPartner,
          handleSaveDossierRemExpress: this.handleSaveDossierRemExpress,
          handleSaveAsDossierRemExpress: this.handleSaveAsDossierRemExpress,
          handleSaveAsDossierPartnerRemExpress: this.handleSaveAsDossierPartnerRemExpress,
          handleSaveAsDossierPartnerPrevTns: this.handleSaveAsDossierPartnerPrevTns,
          handleListeUserContratPrevoyance: this.handleListeUserContratPrevoyance,
          handleNewUserContratPrevoyance: this.handleNewUserContratPrevoyance,
          handleModifyUserContratPrevoyance: this.handleModifyUserContratPrevoyance,
          handleAddUpdateUserContratPrevoyance: this.handleAddUpdateUserContratPrevoyance,
          handlCopyUserContratPrevoyance: this.handlCopyUserContratPrevoyance,
          handleDeleteUserContratPrevoyance: this.handleDeleteUserContratPrevoyance,
          handleInclureConjoint: this.handleInclureConjoint,
          handleFinCarriereActuelle: this.handleFinCarriereActuelle,
          handleFinCarriereActuelleConjoint: this.handleFinCarriereActuelleConjoint,
          handleAddPeriodeActuelle: this.handleAddPeriodeActuelle,
          handleResetPeriodeActuelle: this.handleResetPeriodeActuelle,
          handleModifyDeletePeriodeActuelle: this.handleModifyDeletePeriodeActuelle,
          handleCarriereActuelleConjointAssocie: this.handleCarriereActuelleConjointAssocie,
          handleTauxIS: this.handleTauxIS,
          handleCalculResultatEntreprise: this.handleCalculResultatEntreprise,
          handleCalculResultatEntrepriseConjointOptiRem: this.handleCalculResultatEntrepriseConjointOptiRem,
          handleChoixCaisse: this.handleChoixCaisse,
          handleRemuneration: this.handleRemuneration,
          handleOptionPfu: this.handleOptionPfu,
          handleChoixStatut: this.handleChoixStatut,
          handleChoixStatutConjoint: this.handleChoixStatutConjoint,
          handleAddCotisationManuelle: this.handleAddCotisationManuelle,
          handleDeleteCotisationManuelle: this.handleDeleteCotisationManuelle,
          handleAssietteGarantie: this.handleAssietteGarantie,
          handleGestionIJ: this.handleGestionIJ,
          handleGestionIJ1: this.handleGestionIJ1,
          handleGestionIJ2: this.handleGestionIJ2,
          handleTauxToMontant: this.handleTauxToMontant,
          handleMontantToTaux: this.handleMontantToTaux,
          handleResetPrevoyanceSettings: this.handleResetPrevoyanceSettings,
          handleResetSanteSettings: this.handleResetSanteSettings,
          handleAddContratPrevoyance: this.handleAddContratPrevoyance,
          handleAddContratFraisPro: this.handleAddContratFraisPro,
          handleAddContratSante: this.handleAddContratSante,
          handleModifyDeleteContratPrevoyance: this.handleModifyDeleteContratPrevoyance,
          handleModifyDeleteContratSante: this.handleModifyDeleteContratSante,

          handleDepartRetraite: this.handleDepartRetraite,
          handleReformeRetraite: this.handleReformeRetraite,
          GetDateDepartRetraite: this.GetDateDepartRetraite,
          handleResetPeriodeRetraite: this.handleResetPeriodeRetraite,
          handleAddPeriodeRetraite: this.handleAddPeriodeRetraite,
          handleCreationRevenusRetraite: this.handleCreationRevenusRetraite,
          handleSelectActiviteRetraite: this.handleSelectActiviteRetraite,
          handleResetCheckboxDepartRetraite: this.handleResetCheckboxDepartRetraite,
          handleCheckboxDepartRetraite: this.handleCheckboxDepartRetraite,
          handleCheckboxDetailRetraite: this.handleCheckboxDetailRetraite,
          handleSaisieTrimestre: this.handleSaisieTrimestre,
          handleRevenusAuPASS: this.handleRevenusAuPASS,
          handleRevenusRetraitePasse: this.handleRevenusRetraitePasse,
          handleCalculTrimestrePasse: this.handleCalculTrimestrePasse,
          handleRevenusRetraiteFutur: this.handleRevenusRetraiteFutur,
          handleCalculTrimestreFutur: this.handleCalculTrimestreFutur,
          handleChangeTypeEvolution: this.handleChangeTypeEvolution,
          handleCalculEvolutionRetraite: this.handleCalculEvolutionRetraite,
          handleParametrePeriode: this.handleParametrePeriode,
          handleParametrePeriodeCheck: this.handleParametrePeriodeCheck,
          handleTableauMoyenneProduit: this.handleTableauMoyenneProduit,
          handleTableauCommission: this.handleTableauCommission,
          handleModifyDeletePeriodeRetraite: this.handleModifyDeletePeriodeRetraite,
          handleAddRIS: this.handleAddRIS,
          handleAddReleveRG: this.handleAddReleveRG,
          handlePointRetraite: this.handlePointRetraite,
          handleModalPoint: this.handleModalPoint,
          CalculMajorationEnfant: this.CalculMajorationEnfant,
          handleCarriereMajorationEnfantSaisi: this.handleCarriereMajorationEnfantSaisi,
          handleCarriereSalaireReferenceSaisi: this.handleCarriereSalaireReferenceSaisi,
          handleAddContratRetraite: this.handleAddContratRetraite,
          handleResetContratRetraite: this.handleResetContratRetraite,
          handleCalculEvolutionContrat: this.handleCalculEvolutionContrat,
          handleVersementContrat: this.handleVersementContrat,
          handleMisEnReductionContrat: this.handleMisEnReductionContrat,
          handleModifyDeleteContratRetraite: this.handleModifyDeleteContratRetraite,
          handleChangeFiscalite: this.handleChangeFiscalite,
          handleChangeAssietteCotisation83: this.handleChangeAssietteCotisation83,
          handleSaisieManuelleRente: this.handleSaisieManuelleRente,
          handleDepartRetraiteCumulEmploi: this.handleDepartRetraiteCumulEmploi,
          handleCalculCumulEmploiRetraite: this.handleCalculCumulEmploiRetraite,
          handleCalculCER: this.handleCalculCER,
          handleRecetteBrute: this.handleRecetteBrute,
          handleResetOptimisation: this.handleResetOptimisation,
          handleResetArbitrage: this.handleResetArbitrage,
          handleSaveAsOptimisation: this.handleSaveAsOptimisation,
          handleAccesOptimisation: this.handleAccesOptimisation,
          handleDeleteOptimisation: this.handleDeleteOptimisation,
          handleChoixStatutRemExpress: this.handleChoixStatutRemExpress,
          handleSwitchClient: this.handleSwitchClient,
          handleRemExpressMontantSaisi: this.handleRemExpressMontantSaisi,
          handleCalculRemExpress: this.handleCalculRemExpress,
          handleMontantRemExpress: this.handleMontantRemExpress,
          handleValidationParamRemExpress: this.handleValidationParamRemExpress,
          handleMontantOptiRem: this.handleMontantOptiRem,
          handleCalculOptiRem: this.handleCalculOptiRem,
          handleValidationParametreOptiremTns: this.handleValidationParametreOptiremTns,
          handleValidationOptimumOptiRemTns: this.handleValidationOptimumOptiRemTns,
          handleOpenParametreOptirem: this.handleOpenParametreOptirem,
          handleOpenRemiseDonneeOptirem: this.handleOpenRemiseDonneeOptirem,
          handleChangementStatut: this.handleChangementStatut,
          handleOpenRemiseDonneeRemExpressOptirem: this.handleOpenRemiseDonneeRemExpressOptirem,
          handleMontantOptiRemSalarie: this.handleMontantOptiRemSalarie,
          handleValidationParametreOptiremSalarie: this.handleValidationParametreOptiremSalarie,
          handleCalculOptiRemSalarie: this.handleCalculOptiRemSalarie,
          handleOpenRemiseDonneeOptiremSalarie: this.handleOpenRemiseDonneeOptiremSalarie,
          handleOpenParametreGS: this.handleOpenParametreGS,
          handlePrevTnsAddToPreco: this.handlePrevTnsAddToPreco,
          handlePropals: this.handlePropals,
          handlePrevTnsAddToPreco2: this.handlePrevTnsAddToPreco2,
          handleChoixCaissePrevTns2: this.handleChoixCaissePrevTns2,
          handleChoixSecteurActivitePrevTns2: this.handleChoixSecteurActivitePrevTns2,
          handleRemiseDonneeDeclarationPER: this.handleRemiseDonneeDeclarationPER,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export default RemExpertProvider;
