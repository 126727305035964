import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import CardBox from "components/ui/controls/card-box/card-box";
import { applyFormat } from "components/ui/ui-helpers";

const DetailAssietteCotisation = (props) => {
const {DetailAssiette}=props;
  return (
    <>
        <Column fill>
          <CardBox bpad>
            <Column fill>
              <Row >
                <table className="cotisations">
                  <tbody>
              
                    <tr>
                      <td>Charges sociales obligatoires</td>
                      <td> {applyFormat(DetailAssiette?.DetailAssietteCharges, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    </tr>
                    <tr>
                      <td>Rémunération nette</td>
                      <td> {applyFormat(DetailAssiette?.DetailRemunerationNette, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    </tr>
                    <tr>
                      <td>Dividendes assujettis aux charges sociales</td>
                      <td> {applyFormat(DetailAssiette?.DetailAssietteDividendeBrut, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    </tr>
                    <tr>
                      <td>Cotisations facultatives</td>
                      <td> {applyFormat(DetailAssiette?.DetailCotisationsDeductibles+DetailAssiette?.DetailCotisationsNonDeductibles, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    </tr>
                    <tr>
                      <td>Avantages en nature</td>
                      <td> {applyFormat(DetailAssiette?.DetailAvantageNature, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    </tr>
                          <tr className="light-title">
                      <td>Assiette avant abattement</td>
                      <td> {applyFormat(DetailAssiette?.DetailAssietteAvantAbattement, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    </tr>
                    <tr>
                      <td>Abattement</td>
                      <td> - {applyFormat(DetailAssiette?.DetailAssietteAbattement, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    </tr>
                    <tr className="light-title" style={{borderBottom:"none"}}>
                      <td>Assiette après abattement</td>
                      <td> {applyFormat(DetailAssiette?.DetailAssietteApresAbattement, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    </tr>
                 
                  </tbody>
                </table>
              </Row>
            </Column>
          </CardBox>
        </Column>
    </>
  );
};

export default DetailAssietteCotisation;
