import React, { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import Column from "components/ui/layout/column/column";
import CardBox from "components/ui/controls/card-box/card-box";
import Row from "components/ui/layout/row/row";
import { applyFormat } from "components/ui/ui-helpers";

const countDecimals = function (value) {
  if (Math.floor(value) === value) return 0;
  var str = value.toString();
  if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
    return str.split("-")[1] || 0;
  } else if (str.indexOf(".") !== -1) {
    return str.split(".")[1].length || 0;
  }
  return str.split("-")[1] || 0;
};

const FormatLignePension = ({ keyId, caisse, points, valeurPoint, pensionTheo, coeff, majoEnfant, pension }) => {
  return (
    <tr key={"DetaiPension" + keyId}>
      <td>{caisse}</td>
      <td className="text-center">{points === "-" ? "-" : applyFormat(Math.max(0, points), "ufloat", { thousands: " ", decimal: ",", precision: 2 })}</td>
      <td className="text-center">
        {valeurPoint === "-" ? "-" : applyFormat(Math.max(0, valeurPoint), "ufloat", { thousands: " ", decimal: ",", precision: countDecimals(valeurPoint), unit: " €" })}
      </td>
      <td className="text-center">{pensionTheo === "-" ? "-" : applyFormat(Math.max(0, pensionTheo), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
      <td className="text-center">{coeff === "-" ? "-" : applyFormat(Math.max(0, coeff), "ufloat", { thousands: " ", decimal: ",", precision: 4 })} </td>
      <td className="text-center">{majoEnfant}</td>
      <td className="text-center">{applyFormat(Math.max(0, pension), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
    </tr>
  );
};

const DetailPension = ({ InfosPension }) => {
  const context = useContext(AppContext);
  let index = 0;
  return (
    <Column fill>
      <CardBox>
        <Column fill>
          <Row>
            <table className="detailPension">
              <thead>
                <tr>
                  <th>Détail des pensions à l'âge de {InfosPension?.Infos?.AgeDepartRetraite}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr className="light-text">
                  <td>Rente brute annuelle</td>
                  <td className="text-center">{applyFormat(Math.max(0, InfosPension?.TotalPensionBrute + InfosPension?.TotalRente), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                </tr>
                <tr>
                  <td>Régimes de base obligatoires</td>
                  <td className="text-center">{applyFormat(Math.max(0, InfosPension?.TotalPensionBase), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                </tr>
                <tr>
                  <td>Régimes complémentaires obligatoires</td>
                  <td className="text-center">{applyFormat(Math.max(0, InfosPension?.TotalPensionComplementaire), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                </tr>
                <tr>
                  <td>Régimes supplémentaires obligatoires</td>
                  <td className="text-center">{applyFormat(Math.max(0, InfosPension?.TotalPensionSupplementaire), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                </tr>
                <tr>
                  <td>Régimes facultatifs</td>
                  <td className="text-center">{applyFormat(Math.max(0, InfosPension?.TotalRente), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                </tr>
                <tr className="light-text">
                  <td>Rente nette de prélèvements sociaux annuelle</td>
                  <td className="text-center">
                    {applyFormat(Math.max(0, InfosPension?.TotalPensionNette + InfosPension?.TotalRenteNette), "uint", { thousands: " ", decimal: ",", unit: " €" })}
                  </td>
                </tr>
                <tr className="light-text">
                  <td> Epargne nette en capital (PER, Assurance vie, PERP...)</td>
                  <td className="text-center">{applyFormat(Math.max(0, InfosPension?.TotalCapitalFractionne), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                </tr>
              </tbody>
            </table>
          </Row>
          <Row tpad>
            <table className="detailPension">
              <thead>
                <tr>
                  <th>Régimes</th>
                  <th className="text-center">Points</th>
                  <th className="text-center">Valeur du point</th>
                  <th className="text-center">Retraite théorique</th>
                  <th className="text-center">Coefficient</th>
                  <th className="text-center">Majo. enfants</th>
                  <th className="text-center">Retraite brute</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="dark-title text-center" colSpan="7">
                    Régimes de base
                  </td>
                </tr>
                {InfosPension?.InfosRetraite?.hasOwnProperty("RegimeUnifie") && (
                  <FormatLignePension
                    keyId={index++}
                    caisse={"Régime général unifié"}
                    points={"-"}
                    valeurPoint={"-"}
                    pensionTheo={InfosPension?.InfosRetraite["RegimeUnifie"]["PensionTheorique"]}
                    coeff={InfosPension?.InfosRetraite["RegimeUnifie"]["Coefficient"]}
                    majoEnfant={context.state.TableauEnfant.length >= 3 ? "10%" : "-"}
                    pension={InfosPension?.InfosRetraite["RegimeUnifie"]["Pension"]}
                  />
                )}
                {InfosPension?.InfosRetraite?.hasOwnProperty("Cnavpl") && (
                  <FormatLignePension
                    keyId={index++}
                    caisse={"CNAVPL"}
                    points={InfosPension?.InfosRetraite["Cnavpl"]["General"]["TotalPoint"]}
                    valeurPoint={InfosPension?.InfosRetraite["Cnavpl"]["General"]["ValeurPoint"]}
                    pensionTheo={InfosPension?.InfosRetraite["Cnavpl"]["General"]["PensionTheorique"]}
                    coeff={InfosPension?.InfosRetraite["Cnavpl"]["General"]["Coefficient"]}
                    majoEnfant={InfosPension?.InfosRetraite["Cnavpl"]["General"]["CoeffMajoEnfant"] > 0 ? "10%" : "-"}
                    pension={InfosPension?.InfosRetraite["Cnavpl"]["General"]["Pension"]}
                  />
                )}
                {InfosPension?.InfosRetraite?.hasOwnProperty("FonctionPubliqueBase") && (
                  <FormatLignePension
                    keyId={index++}
                    caisse={"Fonction publique"}
                    points={"-"}
                    valeurPoint={"-"}
                    pensionTheo={InfosPension?.InfosRetraite["FonctionPubliqueBase"]["General"]["PensionTheorique"]}
                    coeff={InfosPension?.InfosRetraite["FonctionPubliqueBase"]["General"]["Coefficient"]}
                    majoEnfant={context.state.TableauEnfant.length >= 3 ? InfosPension?.InfosRetraite["FonctionPubliqueBase"]["General"]["CoeffMajoEnfant"] + "%" : "-"}
                    pension={InfosPension?.InfosRetraite["FonctionPubliqueBase"]["General"]["Pension"]}
                  />
                )}
                {InfosPension?.InfosRetraite?.hasOwnProperty("Crpcen") && (
                  <FormatLignePension
                    keyId={index++}
                    caisse={"CRPCEN"}
                    points={"-"}
                    valeurPoint={"-"}
                    pensionTheo={InfosPension?.InfosRetraite["Crpcen"]["General"]["PensionTheorique"]}
                    coeff={InfosPension?.InfosRetraite["Crpcen"]["General"]["Coefficient"]}
                    majoEnfant={context.state.TableauEnfant.length >= 3 ? InfosPension?.InfosRetraite["Crpcen"]["General"]["CoeffMajoEnfant"] + "%" : "-"}
                    pension={InfosPension?.InfosRetraite["Crpcen"]["General"]["Pension"]}
                  />
                )}
                {InfosPension?.InfosRetraite?.hasOwnProperty("Cnbf") && (
                  <FormatLignePension
                    keyId={index++}
                    caisse={"CNBF"}
                    points={"-"}
                    valeurPoint={"-"}
                    pensionTheo={InfosPension?.InfosRetraite["Cnbf"]["General"]["PensionTheorique"]}
                    coeff={InfosPension?.InfosRetraite["Cnbf"]["General"]["Coefficient"]}
                    majoEnfant={InfosPension?.InfosRetraite["Cnbf"]["General"]["CoeffMajoEnfant"] > 0 ? "10%" : "-"}
                    pension={InfosPension?.InfosRetraite["Cnbf"]["General"]["Pension"]}
                  />
                )}
                {InfosPension?.InfosRetraite?.hasOwnProperty("Msa") && (
                  <FormatLignePension
                    keyId={index++}
                    caisse={"MSA forfaitaire"}
                    points={"-"}
                    valeurPoint={"-"}
                    pensionTheo={InfosPension?.InfosRetraite["Msa"]["General"]["PensionBaseForfaitaireTheorique"]}
                    coeff={InfosPension?.InfosRetraite["Msa"]["General"]["Coefficient"]}
                    majoEnfant={context.state.TableauEnfant.length >= 3 ? "10%" : "-"}
                    pension={InfosPension?.InfosRetraite["Msa"]["General"]["PensionBaseForfaitaire"]}
                  />
                )}
                {InfosPension?.InfosRetraite?.hasOwnProperty("Msa") && (
                  <FormatLignePension
                    keyId={index++}
                    caisse={"MSA proportionnelle"}
                    points={InfosPension?.InfosRetraite["Msa"]["General"]["TotalPointProportionnel"]}
                    valeurPoint={InfosPension?.InfosRetraite["Msa"]["General"]["ValeurPointProportionnel"]}
                    pensionTheo={InfosPension?.InfosRetraite["Msa"]["General"]["PensionBaseProportionnelleTheorique"]}
                    coeff={InfosPension?.InfosRetraite["Msa"]["General"]["Coefficient"]}
                    majoEnfant={context.state.TableauEnfant.length >= 3 ? "10%" : "-"}
                    pension={InfosPension?.InfosRetraite["Msa"]["General"]["PensionBaseProportionnelle"]}
                  />
                )}
                <tr className="light-text">
                  <td colSpan="6"> Total régimes de base </td>
                  <td className="text-center">{applyFormat(Math.max(0, InfosPension?.TotalPensionBase), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                </tr>
                <tr>
                  <td className="dark-title text-center" colSpan="7">
                    Régimes complémentaires
                  </td>
                </tr>

                {InfosPension?.InfosRetraite?.hasOwnProperty("Salarie") && (
                  <>
                    {InfosPension?.InfosRetraite["Salarie"]["General"]["TotalAgircArcco"] > 0 && InfosPension?.InfosRetraite["Salarie"]["General"]["CapitalAgircArrco"] === 0 && (
                      <FormatLignePension
                        keyId={index++}
                        caisse={"AGIRC-ARRCO"}
                        points={InfosPension?.InfosRetraite["Salarie"]["General"]["TotalAgircArcco"]}
                        valeurPoint={InfosPension?.InfosRetraite["Salarie"]["General"]["ValeurPointArrco"]}
                        pensionTheo={InfosPension?.InfosRetraite["Salarie"]["General"]["AgircArrcoPensionTheorique"]}
                        coeff={InfosPension?.InfosRetraite["Salarie"]["General"]["CoeffMino"]}
                        majoEnfant="-"
                        pension={InfosPension?.InfosRetraite["Salarie"]["General"]["AgircArrcoPension"]}
                      />
                    )}
                    {InfosPension.InfosRetraite["Salarie"]["General"]["PointAgircTCAvant2016"] > 0 && InfosPension.InfosRetraite["Salarie"]["General"]["CapitalAgircArrco"] === 0 && (
                      <>
                      {InfosPension.InfosRetraite["Salarie"]["General"]["PensionAgircTCAvant2016"] > 0 ? (
                      <FormatLignePension
                        keyId={index++}
                        caisse={"AGIRC TC"}
                        points={InfosPension?.InfosRetraite["Salarie"]["General"]["PointAgircTCAvant2016"] * InfosPension?.InfosRetraite["Salarie"]["General"]["CoeffConversion"]}
                        valeurPoint={InfosPension?.InfosRetraite["Salarie"]["General"]["ValeurPointArrco"]}
                        pensionTheo={InfosPension?.InfosRetraite["Salarie"]["General"]["PensionTheoAgircTCAvant2016"]}
                        coeff={InfosPension?.InfosRetraite["Salarie"]["General"]["CoeffMinoTCAvant2016"]}
                        majoEnfant="-"
                        pension={InfosPension?.InfosRetraite["Salarie"]["General"]["PensionAgircTCAvant2016"]}
                      />
                      ): (
                        ""
                      )}
                      </>
                    )}
                    {InfosPension?.InfosRetraite["Salarie"]["General"]["PointsMajores"] > 0 && InfosPension?.InfosRetraite["Salarie"]["General"]["CapitalAgircArrco"] === 0 && (
                      <FormatLignePension
                        keyId={index++}
                        caisse={"Majo enfant AGIRC-ARRCO"}
                        points={InfosPension?.InfosRetraite["Salarie"]["General"]["PointsMajores"]}
                        valeurPoint={InfosPension?.InfosRetraite["Salarie"]["General"]["ValeurPointArrco"]}
                        pensionTheo={InfosPension?.InfosRetraite["Salarie"]["General"]["MontantMajoration"]}
                        coeff="-"
                        majoEnfant="-"
                        pension={InfosPension?.InfosRetraite["Salarie"]["General"]["MontantMajoration"]}
                      />
                    )}
                  </>
                )}
                {InfosPension?.InfosRetraite?.hasOwnProperty("Ircantec") && (
                  <>
                    {InfosPension?.InfosRetraite["Ircantec"]["General"]["CapitalIrcantec"] === 0 ? (
                      <FormatLignePension
                        keyId={index++}
                        caisse={"IRCANTEC"}
                        points={InfosPension?.InfosRetraite["Ircantec"]["General"]["TotalPoint"]}
                        valeurPoint={InfosPension?.InfosRetraite["Ircantec"]["General"]["ValeurPoint"]}
                        pensionTheo={InfosPension?.InfosRetraite["Ircantec"]["General"]["PensionTheoriqueIrcantec"]}
                        coeff={InfosPension?.InfosRetraite["Ircantec"]["General"]["Coefficient"]}
                        majoEnfant={
                          InfosPension?.InfosRetraite["Ircantec"]["General"]["PointsMajores"] > 0 ? Math.max(0, Math.min(30, 10 + (context.state.TableauEnfant.length - 3) * 5)) + "%" : "-"
                        }
                        pension={InfosPension?.InfosRetraite["Ircantec"]["General"]["Pension"]}
                      />
                    ) : (
                      ""
                    )}
                  </>
                )}
                {InfosPension?.InfosRetraite?.hasOwnProperty("RsiCommercant") && (
                  <FormatLignePension
                    keyId={index++}
                    caisse={"RSI Commerçant"}
                    points={InfosPension?.InfosRetraite["RsiCommercant"]["General"]["PointSaisi"]}
                    valeurPoint={InfosPension?.InfosRetraite["RsiCommercant"]["General"]["ValeurPoint"]}
                    pensionTheo={InfosPension?.InfosRetraite["RsiCommercant"]["General"]["PensionTheorique"]}
                    coeff={InfosPension?.InfosRetraite["RsiCommercant"]["General"]["Coefficient"]}
                    majoEnfant={"-"}
                    pension={InfosPension?.InfosRetraite["RsiCommercant"]["General"]["Pension"]}
                  />
                )}
                {InfosPension?.InfosRetraite?.hasOwnProperty("RsiArtisan") && (
                  <>
                    {InfosPension?.InfosRetraite["RsiArtisan"]["General"]["PointsAvant79"] > 0 ? (
                      <FormatLignePension
                        keyId={index++}
                        caisse={"RSI Artisan avant 1979"}
                        points={InfosPension?.InfosRetraite["RsiArtisan"]["General"]["PointsAvant79"]}
                        valeurPoint={InfosPension?.InfosRetraite["RsiArtisan"]["General"]["ValeurPointAvant79"]}
                        pensionTheo={InfosPension?.InfosRetraite["RsiArtisan"]["General"]["PensionTheoriqueAvant79"]}
                        coeff={InfosPension?.InfosRetraite["RsiArtisan"]["General"]["Coefficient"]}
                        majoEnfant={"-"}
                        pension={InfosPension?.InfosRetraite["RsiArtisan"]["General"]["PensionAvant79"]}
                      />
                    ) : (
                      ""
                    )}

                    {InfosPension?.InfosRetraite["RsiArtisan"]["General"]["PointsAvant97"] > 0 ? (
                      <FormatLignePension
                        keyId={index++}
                        caisse={"RSI Artisan avant 1997"}
                        points={InfosPension?.InfosRetraite["RsiArtisan"]["General"]["PointsAvant97"]}
                        valeurPoint={InfosPension?.InfosRetraite["RsiArtisan"]["General"]["ValeurPointAvant97"]}
                        pensionTheo={InfosPension?.InfosRetraite["RsiArtisan"]["General"]["PensionTheoriqueAvant97"]}
                        coeff={InfosPension?.InfosRetraite["RsiArtisan"]["General"]["Coefficient"]}
                        majoEnfant={"-"}
                        pension={InfosPension?.InfosRetraite["RsiArtisan"]["General"]["PensionAvant97"]}
                      />
                    ) : (
                      ""
                    )}

                    {InfosPension?.InfosRetraite["RsiArtisan"]["General"]["PointsApres97"] > 0 ? (
                      <FormatLignePension
                        keyId={index++}
                        caisse={"RSI Artisan entre 1997 et 2012"}
                        points={InfosPension?.InfosRetraite["RsiArtisan"]["General"]["PointsApres97"]}
                        valeurPoint={InfosPension?.InfosRetraite["RsiArtisan"]["General"]["ValeurPoint"]}
                        pensionTheo={InfosPension?.InfosRetraite["RsiArtisan"]["General"]["PensionTheoriqueApres1997"]}
                        coeff={InfosPension?.InfosRetraite["RsiArtisan"]["General"]["Coefficient"]}
                        majoEnfant={"-"}
                        pension={InfosPension?.InfosRetraite["RsiArtisan"]["General"]["PensionApres97"]}
                      />
                    ) : (
                      ""
                    )}
                  </>
                )}
                {InfosPension?.InfosRetraite?.hasOwnProperty("RCI") && InfosPension?.InfosRetraite["RCI"]["General"]["TotalPoint"]>0 && (
                  <FormatLignePension
                    keyId={index++}
                    caisse={"RCI"}
                    points={InfosPension?.InfosRetraite["RCI"]["General"]["TotalPoint"]}
                    valeurPoint={InfosPension?.InfosRetraite["RCI"]["General"]["ValeurPoint"]}
                    pensionTheo={InfosPension?.InfosRetraite["RCI"]["General"]["PensionTheorique"]}
                    coeff={InfosPension?.InfosRetraite["RCI"]["General"]["Coefficient"]}
                    majoEnfant={"-"}
                    pension={InfosPension?.InfosRetraite["RCI"]["General"]["Pension"]}
                  />
                )}
                {InfosPension?.InfosRetraite?.hasOwnProperty("Ravgdt") && (
                  <FormatLignePension
                    keyId={index++}
                    caisse={"RAVGDT"}
                    points={InfosPension?.InfosRetraite["Ravgdt"]["General"]["TotalPoint"]}
                    valeurPoint={InfosPension?.InfosRetraite["Ravgdt"]["General"]["ValeurPoint"]}
                    pensionTheo={InfosPension?.InfosRetraite["Ravgdt"]["General"]["PensionTheorique"]}
                    coeff={InfosPension?.InfosRetraite["Ravgdt"]["General"]["Coefficient"]}
                    majoEnfant={"-"}
                    pension={InfosPension?.InfosRetraite["Ravgdt"]["General"]["Pension"]}
                  />
                )}
                {InfosPension?.InfosRetraite?.hasOwnProperty("Cipav") && (
                  <FormatLignePension
                    keyId={index++}
                    caisse={"CIPAV"}
                    points={InfosPension?.InfosRetraite["Cipav"]["General"]["TotalPoint"]}
                    valeurPoint={InfosPension?.InfosRetraite["Cipav"]["General"]["ValeurPoint"]}
                    pensionTheo={InfosPension?.InfosRetraite["Cipav"]["General"]["PensionTheorique"]}
                    coeff={InfosPension?.InfosRetraite["Cipav"]["General"]["Coefficient"]}
                    majoEnfant={context.state.TableauEnfant.length >= 3 ? "10%" : "-"}
                    pension={InfosPension?.InfosRetraite["Cipav"]["General"]["Pension"]}
                  />
                )}
                {InfosPension?.InfosRetraite?.hasOwnProperty("Carpv") && (
                  <FormatLignePension
                    keyId={index++}
                    caisse={"CARPV"}
                    points={InfosPension?.InfosRetraite["Carpv"]["General"]["TotalPoint"]}
                    valeurPoint={InfosPension?.InfosRetraite["Carpv"]["General"]["ValeurPoint"]}
                    pensionTheo={InfosPension?.InfosRetraite["Carpv"]["General"]["PensionTheorique"]}
                    coeff={InfosPension?.InfosRetraite["Carpv"]["General"]["Coefficient"]}
                    majoEnfant={context.state.TableauEnfant.length >= 3 ? "10%" : "-"}
                    pension={InfosPension?.InfosRetraite["Carpv"]["General"]["Pension"]}
                  />
                )}
                {InfosPension?.InfosRetraite?.hasOwnProperty("Cavec") && (
                  <FormatLignePension
                    keyId={index++}
                    caisse={"CAVEC"}
                    points={InfosPension?.InfosRetraite["Cavec"]["General"]["TotalPoint"]}
                    valeurPoint={InfosPension?.InfosRetraite["Cavec"]["General"]["ValeurPoint"]}
                    pensionTheo={InfosPension?.InfosRetraite["Cavec"]["General"]["PensionTheorique"]}
                    coeff={
                      InfosPension?.InfosRetraite["Cavec"]["General"]["MontantMajo"] === 0
                        ? InfosPension?.InfosRetraite["Cavec"]["General"]["CoeffMino"]
                        : Number(InfosPension?.InfosRetraite["Cavec"]["General"]["Pension"] / InfosPension?.InfosRetraite["Cavec"]["General"]["PensionTheorique"]).toFixed(2)
                    }
                    majoEnfant={"-"}
                    pension={InfosPension?.InfosRetraite["Cavec"]["General"]["Pension"]}
                  />
                )}
                {InfosPension?.InfosRetraite?.hasOwnProperty("Cavamac") && (
                  <FormatLignePension
                    keyId={index++}
                    caisse={"CAVAMAC"}
                    points={InfosPension?.InfosRetraite["Cavamac"]["General"]["TotalPoint"]}
                    valeurPoint={InfosPension?.InfosRetraite["Cavamac"]["General"]["ValeurPoint"]}
                    pensionTheo={InfosPension?.InfosRetraite["Cavamac"]["General"]["PensionTheorique"]}
                    coeff={InfosPension?.InfosRetraite["Cavamac"]["General"]["Coefficient"]}
                    majoEnfant={context.state.TableauEnfant.length >= 3 ? "10%" : "-"}
                    pension={InfosPension?.InfosRetraite["Cavamac"]["General"]["Pension"]}
                  />
                )}

                {InfosPension?.InfosRetraite?.hasOwnProperty("Crn") && InfosPension?.InfosRetraite["Crn"]["General"]["TotalPointSectionB"] > 0 && (
                  <>
                    <FormatLignePension
                      keyId={index++}
                      caisse={"CRN section B"}
                      points={InfosPension?.InfosRetraite["Crn"]["General"]["TotalPointSectionB"]}
                      valeurPoint={InfosPension?.InfosRetraite["Crn"]["General"]["ValeurPointSectionB"]}
                      pensionTheo={InfosPension?.InfosRetraite["Crn"]["General"]["PensionTheoriqueSectionB"]}
                      coeff={InfosPension?.InfosRetraite["Crn"]["General"]["Coefficient"]}
                      majoEnfant={"-"}
                      pension={InfosPension?.InfosRetraite["Crn"]["General"]["PensionSectionB"]}
                    />
                    <FormatLignePension
                      keyId={index++}
                      caisse={"CRN section C"}
                      points={InfosPension?.InfosRetraite["Crn"]["General"]["TotalPointSectionC"]}
                      valeurPoint={InfosPension?.InfosRetraite["Crn"]["General"]["ValeurPointSectionC"]}
                      pensionTheo={InfosPension?.InfosRetraite["Crn"]["General"]["PensionTheoriqueSectionC"]}
                      coeff={InfosPension?.InfosRetraite["Crn"]["General"]["Coefficient"]}
                      majoEnfant={"-"}
                      pension={InfosPension?.InfosRetraite["Crn"]["General"]["PensionSectionC"]}
                    />
                  </>
                )}
                {InfosPension?.InfosRetraite?.hasOwnProperty("Carpimko") && InfosPension?.InfosRetraite["Carpimko"]["General"]["TotalPoint"] > 0 && (
                  <>
                    <FormatLignePension
                      keyId={index++}
                      caisse={"CARPIMKO"}
                      points={InfosPension?.InfosRetraite["Carpimko"]["General"]["TotalPoint"]}
                      valeurPoint={InfosPension?.InfosRetraite["Carpimko"]["General"]["ValeurPoint"]}
                      pensionTheo={InfosPension?.InfosRetraite["Carpimko"]["General"]["PensionTheorique"]}
                      coeff={InfosPension?.InfosRetraite["Carpimko"]["General"]["Coefficient"]}
                      majoEnfant={"-"}
                      pension={InfosPension?.InfosRetraite["Carpimko"]["General"]["Pension"]}
                    />
                  </>
                )}
                {InfosPension?.InfosRetraite?.hasOwnProperty("Carmf") && InfosPension?.InfosRetraite["Carmf"]["General"]["TotalPoint"] > 0 && (
                  <FormatLignePension
                    keyId={index++}
                    caisse={"CARMF"}
                    points={InfosPension?.InfosRetraite["Carmf"]["General"]["TotalPoint"]}
                    valeurPoint={InfosPension?.InfosRetraite["Carmf"]["General"]["ValeurPoint"]}
                    pensionTheo={InfosPension?.InfosRetraite["Carmf"]["General"]["PensionTheorique"]}
                    coeff={InfosPension?.InfosRetraite["Carmf"]["General"]["Coefficient"]}
                    majoEnfant={context.state.TableauEnfant.length >= 3 ? "10%" : "-"}
                    pension={InfosPension?.InfosRetraite["Carmf"]["General"]["Pension"]}
                  />
                )}
                {InfosPension?.InfosRetraite?.hasOwnProperty("Carcdsf") && (
                  <FormatLignePension
                    keyId={index++}
                    caisse={"CARCD"}
                    points={InfosPension?.InfosRetraite["Carcdsf"]["General"]["TotalPoint"]}
                    valeurPoint={InfosPension?.InfosRetraite["Carcdsf"]["General"]["ValeurPoint"]}
                    pensionTheo={InfosPension?.InfosRetraite["Carcdsf"]["General"]["PensionTheorique"]}
                    coeff={InfosPension?.InfosRetraite["Carcdsf"]["General"]["Coefficient"]}
                    majoEnfant={context.state.TableauEnfant.length >= 3 ? "10%" : "-"}
                    pension={InfosPension?.InfosRetraite["Carcdsf"]["General"]["Pension"]}
                  />
                )}
                {InfosPension?.InfosRetraite?.hasOwnProperty("Carcdsf2") && (
                  <FormatLignePension
                    keyId={index++}
                    caisse={"CARSF"}
                    points={InfosPension?.InfosRetraite["Carcdsf2"]["General"]["TotalPoint"]}
                    valeurPoint={InfosPension?.InfosRetraite["Carcdsf2"]["General"]["ValeurPoint"]}
                    pensionTheo={InfosPension?.InfosRetraite["Carcdsf2"]["General"]["PensionTheorique"]}
                    coeff={InfosPension?.InfosRetraite["Carcdsf2"]["General"]["Coefficient"]}
                    majoEnfant={context.state.TableauEnfant.length >= 3 ? "10%" : "-"}
                    pension={InfosPension?.InfosRetraite["Carcdsf2"]["General"]["Pension"]}
                  />
                )}
                {InfosPension?.InfosRetraite?.hasOwnProperty("Cavom") && InfosPension?.InfosRetraite["Cavom"]["General"]["TotalPoint"] > 0 && (
                  <FormatLignePension
                    keyId={index++}
                    caisse={"CAVOM"}
                    points={InfosPension?.InfosRetraite["Cavom"]["General"]["TotalPoint"]}
                    valeurPoint={InfosPension?.InfosRetraite["Cavom"]["General"]["ValeurPoint"]}
                    pensionTheo={InfosPension?.InfosRetraite["Cavom"]["General"]["PensionTheorique"]}
                    coeff={InfosPension?.InfosRetraite["Cavom"]["General"]["Coefficient"]}
                    majoEnfant={"-"}
                    pension={InfosPension?.InfosRetraite["Cavom"]["General"]["Pension"]}
                  />
                )}
                {InfosPension?.InfosRetraite?.hasOwnProperty("Cavp") && (
                  <>
                    <FormatLignePension
                      keyId={index++}
                      caisse={"CAVP par répartition"}
                      points={"-"}
                      valeurPoint={"-"}
                      pensionTheo={InfosPension?.InfosRetraite["Cavp"]["General"]["PensionRepartTheorique"]}
                      coeff={InfosPension?.InfosRetraite["Cavp"]["General"]["Coefficient"]}
                      majoEnfant={context.state.TableauEnfant.length >= 3 ? "10%" : "-"}
                      pension={InfosPension?.InfosRetraite["Cavp"]["General"]["PensionRepart"]}
                    />
                    <FormatLignePension
                      keyId={index++}
                      caisse={"CAVP par capitalisation"}
                      points={"-"}
                      valeurPoint={"-"}
                      pensionTheo={"-"}
                      coeff={"-"}
                      majoEnfant={"-"}
                      pension={InfosPension?.InfosRetraite["Cavp"]["General"]["PensionCapi"]}
                    />
                  </>
                )}
                {InfosPension?.InfosRetraite?.hasOwnProperty("Rafp") && (
                  <>
                    {InfosPension?.InfosRetraite["Rafp"]["General"]["TransformationCapitalRafp"] === "Non" ? (
                      <FormatLignePension
                        keyId={index++}
                        caisse={"RAFP"}
                        points={InfosPension?.InfosRetraite["Rafp"]["General"]["TotalPoint"]}
                        valeurPoint={InfosPension?.InfosRetraite["Rafp"]["General"]["ValeurPoint"]}
                        pensionTheo={InfosPension?.InfosRetraite["Rafp"]["General"]["PensionTheorique"]}
                        coeff={InfosPension?.InfosRetraite["Rafp"]["General"]["Coefficient"]}
                        majoEnfant={"-"}
                        pension={InfosPension?.InfosRetraite["Rafp"]["General"]["Pension"]}
                      />
                    ) : (
                      ""
                    )}
                  </>
                )}
                {InfosPension.InfosRetraite?.hasOwnProperty("Cnbf") && (
                  <>
                   {InfosPension.InfosRetraite["Cnbf"]["General"]["TotalPoint"] > 0 ? (
                  <FormatLignePension
                    keyId={index++}
                    caisse={"CNBF"}
                    points={InfosPension.InfosRetraite["Cnbf"]["General"]["TotalPoint"]}
                    valeurPoint={InfosPension.InfosRetraite["Cnbf"]["General"]["ValeurPoint"]}
                    pensionTheo={InfosPension.InfosRetraite["Cnbf"]["General"]["PensionTheoriqueComp"]}
                    coeff={InfosPension.InfosRetraite["Cnbf"]["General"]["CoeffMino"]}
                    majoEnfant={InfosPension.InfosRetraite["Cnbf"]["General"]["CoeffMajoEnfant"] > 0 ? "10%" : "-"}
                    pension={InfosPension.InfosRetraite["Cnbf"]["General"]["PensionComp"]}
                  /> ) : (
                    ""
                  )}
                  </>
                )}
                {InfosPension?.InfosRetraite?.hasOwnProperty("Msa") && (
                  <>
                    {InfosPension?.InfosRetraite["Msa"]["General"]["TotalPointComp"] > 0 ? (
                      <FormatLignePension
                        keyId={index++}
                        caisse={"MSA RCO"}
                        points={InfosPension?.InfosRetraite["Msa"]["General"]["TotalPointComp"]}
                        valeurPoint={InfosPension?.InfosRetraite["Msa"]["General"]["ValeurPointComp"]}
                        pensionTheo={InfosPension?.InfosRetraite["Msa"]["General"]["PensionTheoriqueComp"]}
                        coeff={InfosPension?.InfosRetraite["Msa"]["General"]["CoefficientComp"]}
                        majoEnfant={"-"}
                        pension={InfosPension?.InfosRetraite["Msa"]["General"]["PensionComp"]}
                      />
                    ) : (
                      ""
                    )}
                  </>
                )}
                <tr className="light-text">
                  <td colSpan="6"> Total régimes complémentaires </td>
                  <td className="text-center">{applyFormat(Math.max(0, InfosPension?.TotalPensionComplementaire), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                </tr>
                {InfosPension?.TotalPensionSupplementaire > 0 && (
                  <tr>
                    <td className="dark-title text-center" colSpan="7">
                      Régimes supplémentaires
                    </td>
                  </tr>
                )}
                {InfosPension?.InfosRetraite?.hasOwnProperty("Carcdsf") && (
                  <>
                    {InfosPension?.InfosRetraite["Carcdsf"]["General"]["AsvTotalPointAvant1995"] > 0 ? (
                      <FormatLignePension
                        keyId={index++}
                        caisse={"CARCD ASV avant 1995"}
                        points={InfosPension?.InfosRetraite["Carcdsf"]["General"]["AsvTotalPointAvant1995"]}
                        valeurPoint={InfosPension?.InfosRetraite["Carcdsf"]["General"]["AsvValeurPointAvant1995"]}
                        pensionTheo={InfosPension?.InfosRetraite["Carcdsf"]["General"]["AsvPensionTheoriqueAvant1995"]}
                        coeff={InfosPension?.InfosRetraite["Carcdsf"]["General"]["AsvCoefficient"]}
                        majoEnfant={context.state.TableauEnfant.length >= 3 ? "10%" : "-"}
                        pension={InfosPension?.InfosRetraite["Carcdsf"]["General"]["AsvPensionAvant1995"]}
                      />
                    ) : (
                      ""
                    )}
                    {InfosPension?.InfosRetraite["Carcdsf"]["General"]["AsvTotalPoint19952005"] > 0 ? (
                      <FormatLignePension
                        keyId={index++}
                        caisse={"CARCD ASV entre 1995 et 2005"}
                        points={InfosPension?.InfosRetraite["Carcdsf"]["General"]["AsvTotalPoint19952005"]}
                        valeurPoint={InfosPension?.InfosRetraite["Carcdsf"]["General"]["AsvValeurPoint19952005"]}
                        pensionTheo={InfosPension?.InfosRetraite["Carcdsf"]["General"]["AsvPensionTheorique19952005"]}
                        coeff={InfosPension?.InfosRetraite["Carcdsf"]["General"]["AsvCoefficient"]}
                        majoEnfant={context.state.TableauEnfant.length >= 3 ? "10%" : "-"}
                        pension={InfosPension?.InfosRetraite["Carcdsf"]["General"]["AsvPension19952005"]}
                      />
                    ) : (
                      ""
                    )}
                    {InfosPension?.InfosRetraite["Carcdsf"]["General"]["AsvTotalPointApres2006"] > 0 ? (
                      <FormatLignePension
                        keyId={index++}
                        caisse={"CARCD ASV après 2005"}
                        points={InfosPension?.InfosRetraite["Carcdsf"]["General"]["AsvTotalPointApres2006"]}
                        valeurPoint={InfosPension?.InfosRetraite["Carcdsf"]["General"]["AsvValeurPoint"]}
                        pensionTheo={InfosPension?.InfosRetraite["Carcdsf"]["General"]["AsvPensionTheorique"]}
                        coeff={InfosPension?.InfosRetraite["Carcdsf"]["General"]["AsvCoefficient"]}
                        majoEnfant={context.state.TableauEnfant.length >= 3 ? "10%" : "-"}
                        pension={InfosPension?.InfosRetraite["Carcdsf"]["General"]["AsvPensionApres2006"]}
                      />
                    ) : (
                      ""
                    )}
                  </>
                )}
                {InfosPension?.InfosRetraite?.hasOwnProperty("Carcdsf2") && (
                  <>
                    {InfosPension?.InfosRetraite["Carcdsf2"]["General"]["AsvTotalPointAvant1995"] > 0 ? (
                      <FormatLignePension
                        keyId={index++}
                        caisse={"CARSF ASV avant 1995"}
                        points={InfosPension?.InfosRetraite["Carcdsf2"]["General"]["AsvTotalPointAvant1995"]}
                        valeurPoint={InfosPension?.InfosRetraite["Carcdsf2"]["General"]["AsvValeurPointAvant1995"]}
                        pensionTheo={InfosPension?.InfosRetraite["Carcdsf2"]["General"]["AsvPensionTheoriqueAvant1995"]}
                        coeff={InfosPension?.InfosRetraite["Carcdsf2"]["General"]["AsvCoefficient"]}
                        majoEnfant={context.state.TableauEnfant.length >= 3 ? "10%" : "-"}
                        pension={InfosPension?.InfosRetraite["Carcdsf2"]["General"]["AsvPensionAvant1995"]}
                      />
                    ) : (
                      ""
                    )}
                    {InfosPension?.InfosRetraite["Carcdsf2"]["General"]["AsvTotalPoint19952005"] > 0 ? (
                      <FormatLignePension
                        keyId={index++}
                        caisse={"CARSF ASV entre 1995 et 2005"}
                        points={InfosPension?.InfosRetraite["Carcdsf2"]["General"]["AsvTotalPoint19952005"]}
                        valeurPoint={InfosPension?.InfosRetraite["Carcdsf2"]["General"]["AsvValeurPoint19952005"]}
                        pensionTheo={InfosPension?.InfosRetraite["Carcdsf2"]["General"]["AsvPensionTheorique19952005"]}
                        coeff={InfosPension?.InfosRetraite["Carcdsf2"]["General"]["AsvCoefficient"]}
                        majoEnfant={context.state.TableauEnfant.length >= 3 ? "10%" : "-"}
                        pension={InfosPension?.InfosRetraite["Carcdsf2"]["General"]["AsvPension19952005"]}
                      />
                    ) : (
                      ""
                    )}
                    {InfosPension?.InfosRetraite["Carcdsf2"]["General"]["AsvTotalPointApres2006"] > 0 ? (
                      <FormatLignePension
                        keyId={index++}
                        caisse={"CARSF ASV après 2005"}
                        points={InfosPension?.InfosRetraite["Carcdsf2"]["General"]["AsvTotalPointApres2006"]}
                        valeurPoint={InfosPension?.InfosRetraite["Carcdsf2"]["General"]["AsvValeurPoint"]}
                        pensionTheo={InfosPension?.InfosRetraite["Carcdsf2"]["General"]["AsvPensionTheorique"]}
                        coeff={InfosPension?.InfosRetraite["Carcdsf2"]["General"]["AsvCoefficient"]}
                        majoEnfant={context.state.TableauEnfant.length >= 3 ? "10%" : "-"}
                        pension={InfosPension?.InfosRetraite["Carcdsf2"]["General"]["AsvPensionApres2006"]}
                      />
                    ) : (
                      ""
                    )}
                  </>
                )}
                {InfosPension?.InfosRetraite?.hasOwnProperty("Carmf") && (
                  <>
                    {InfosPension?.InfosRetraite["Carmf"]["General"]["TotalPointAsv"] > 0 ? (
                      <FormatLignePension
                        keyId={index++}
                        caisse={"CARMF ASV"}
                        points={InfosPension?.InfosRetraite["Carmf"]["General"]["TotalPointAsv"]}
                        valeurPoint={InfosPension?.InfosRetraite["Carmf"]["General"]["AsvValeurPoint"]}
                        pensionTheo={InfosPension?.InfosRetraite["Carmf"]["General"]["AsvPensionTheorique"]}
                        coeff={InfosPension?.InfosRetraite["Carmf"]["General"]["AsvCoefficient"]}
                        majoEnfant={context.state.TableauEnfant.length >= 3 ? "10%" : "-"}
                        pension={InfosPension?.InfosRetraite["Carmf"]["General"]["AsvPension"]}
                      />
                    ) : (
                      ""
                    )}
                  </>
                )}
                {InfosPension?.InfosRetraite?.hasOwnProperty("Carpimko") && (
                  <>
                    {InfosPension?.InfosRetraite["Carpimko"]["General"]["AsvTotalPoint19601975"] > 0 ? (
                      <FormatLignePension
                        keyId={index++}
                        caisse={"CARPIMKO ASV entre 1960 et 1975"}
                        points={InfosPension?.InfosRetraite["Carpimko"]["General"]["AsvTotalPoint19601975"]}
                        valeurPoint={InfosPension?.InfosRetraite["Carpimko"]["General"]["AsvValeurPoint19601975"]}
                        pensionTheo={InfosPension?.InfosRetraite["Carpimko"]["General"]["AsvPensionTheorique19601975"]}
                        coeff={InfosPension?.InfosRetraite["Carpimko"]["General"]["AsvCoefficient"]}
                        majoEnfant={"-"}
                        pension={InfosPension?.InfosRetraite["Carpimko"]["General"]["AsvPension19601975"]}
                      />
                    ) : (
                      ""
                    )}
                    {InfosPension?.InfosRetraite["Carpimko"]["General"]["AsvTotalPoint19761987"] > 0 ? (
                      <FormatLignePension
                        keyId={index++}
                        caisse={"CARPIMKO ASV entre 1976 et 1987"}
                        points={InfosPension?.InfosRetraite["Carpimko"]["General"]["AsvTotalPoint19761987"]}
                        valeurPoint={InfosPension?.InfosRetraite["Carpimko"]["General"]["AsvValeurPoint19761987"]}
                        pensionTheo={InfosPension?.InfosRetraite["Carpimko"]["General"]["AsvPensionTheorique19761987"]}
                        coeff={InfosPension?.InfosRetraite["Carpimko"]["General"]["AsvCoefficient"]}
                        majoEnfant={"-"}
                        pension={InfosPension?.InfosRetraite["Carpimko"]["General"]["AsvPension19761987"]}
                      />
                    ) : (
                      ""
                    )}
                    {InfosPension?.InfosRetraite["Carpimko"]["General"]["AsvTotalPoint19881997"] > 0 ? (
                      <FormatLignePension
                        keyId={index++}
                        caisse={"CARPIMKO ASV entre 1988 et 1997"}
                        points={InfosPension?.InfosRetraite["Carpimko"]["General"]["AsvTotalPoint19881997"]}
                        valeurPoint={InfosPension?.InfosRetraite["Carpimko"]["General"]["AsvValeurPoint19881997"]}
                        pensionTheo={InfosPension?.InfosRetraite["Carpimko"]["General"]["AsvPensionTheorique19881997"]}
                        coeff={InfosPension?.InfosRetraite["Carpimko"]["General"]["AsvCoefficient"]}
                        majoEnfant={"-"}
                        pension={InfosPension?.InfosRetraite["Carpimko"]["General"]["AsvPension19881997"]}
                      />
                    ) : (
                      ""
                    )}
                    {InfosPension?.InfosRetraite["Carpimko"]["General"]["AsvTotalPoint19982005"] > 0 ? (
                      <FormatLignePension
                        keyId={index++}
                        caisse={"CARPIMKO ASV entre 1998 et 2005"}
                        points={InfosPension?.InfosRetraite["Carpimko"]["General"]["AsvTotalPoint19982005"]}
                        valeurPoint={InfosPension?.InfosRetraite["Carpimko"]["General"]["AsvValeurPoint19982005"]}
                        pensionTheo={InfosPension?.InfosRetraite["Carpimko"]["General"]["AsvPensionTheorique19982005"]}
                        coeff={InfosPension?.InfosRetraite["Carpimko"]["General"]["AsvCoefficient"]}
                        majoEnfant={"-"}
                        pension={InfosPension?.InfosRetraite["Carpimko"]["General"]["AsvPension19982005"]}
                      />
                    ) : (
                      ""
                    )}
                    {InfosPension?.InfosRetraite["Carpimko"]["General"]["AsvTotalPointApres2005"] > 0 ? (
                      <FormatLignePension
                        keyId={index++}
                        caisse={"CARPIMKO ASV après 2005"}
                        points={InfosPension?.InfosRetraite["Carpimko"]["General"]["AsvTotalPointApres2005"]}
                        valeurPoint={InfosPension?.InfosRetraite["Carpimko"]["General"]["AsvValeurPointApres2005"]}
                        pensionTheo={InfosPension?.InfosRetraite["Carpimko"]["General"]["AsvPensionTheoriqueApres2005"]}
                        coeff={InfosPension?.InfosRetraite["Carpimko"]["General"]["AsvCoefficient"]}
                        majoEnfant={"-"}
                        pension={InfosPension?.InfosRetraite["Carpimko"]["General"]["AsvPensionApres2005"]}
                      />
                    ) : (
                      ""
                    )}
                  </>
                )}
                {InfosPension?.InfosRetraite?.hasOwnProperty("Cavp") && (
                  <>
                    {InfosPension?.InfosRetraite["Cavp"]["General"]["AsvTotalPoint19771981"] > 0 ? (
                      <FormatLignePension
                        keyId={index++}
                        caisse={"CAVP PCV entre 1977 et 1981"}
                        points={InfosPension?.InfosRetraite["Cavp"]["General"]["AsvTotalPoint19771981"]}
                        valeurPoint={InfosPension?.InfosRetraite["Cavp"]["General"]["AsvValeurPoint19771981"]}
                        pensionTheo={InfosPension?.InfosRetraite["Cavp"]["General"]["AsvPensionTheorique19771981"]}
                        coeff={InfosPension?.InfosRetraite["Cavp"]["General"]["AsvCoefficient"]}
                        majoEnfant={context.state.TableauEnfant.length >= 3 ? "10%" : "-"}
                        pension={InfosPension?.InfosRetraite["Cavp"]["General"]["AsvPension19771981"]}
                      />
                    ) : (
                      ""
                    )}
                    {InfosPension?.InfosRetraite["Cavp"]["General"]["AsvTotalPoint19821986"] > 0 ? (
                      <FormatLignePension
                        keyId={index++}
                        caisse={"CAVP PCV entre 1982 et 1986"}
                        points={InfosPension?.InfosRetraite["Cavp"]["General"]["AsvTotalPoint19821986"]}
                        valeurPoint={InfosPension?.InfosRetraite["Cavp"]["General"]["AsvValeurPoint19821986"]}
                        pensionTheo={InfosPension?.InfosRetraite["Cavp"]["General"]["AsvPensionTheorique19821986"]}
                        coeff={InfosPension?.InfosRetraite["Cavp"]["General"]["AsvCoefficient"]}
                        majoEnfant={context.state.TableauEnfant.length >= 3 ? "10%" : "-"}
                        pension={InfosPension?.InfosRetraite["Cavp"]["General"]["AsvPension19821986"]}
                      />
                    ) : (
                      ""
                    )}
                    {InfosPension?.InfosRetraite["Cavp"]["General"]["AsvTotalPoint19871991"] > 0 ? (
                      <FormatLignePension
                        keyId={index++}
                        caisse={"CAVP PCV entre 1987 et 1991"}
                        points={InfosPension?.InfosRetraite["Cavp"]["General"]["AsvTotalPoint19871991"]}
                        valeurPoint={InfosPension?.InfosRetraite["Cavp"]["General"]["AsvValeurPoint19871991"]}
                        pensionTheo={InfosPension?.InfosRetraite["Cavp"]["General"]["AsvPensionTheorique19871991"]}
                        coeff={InfosPension?.InfosRetraite["Cavp"]["General"]["AsvCoefficient"]}
                        majoEnfant={context.state.TableauEnfant.length >= 3 ? "10%" : "-"}
                        pension={InfosPension?.InfosRetraite["Cavp"]["General"]["AsvPension19871991"]}
                      />
                    ) : (
                      ""
                    )}
                    {InfosPension?.InfosRetraite["Cavp"]["General"]["AsvTotalPoint19921996"] > 0 ? (
                      <FormatLignePension
                        keyId={index++}
                        caisse={"CAVP PCV entre 1992 et 1996"}
                        points={InfosPension?.InfosRetraite["Cavp"]["General"]["AsvTotalPoint19921996"]}
                        valeurPoint={InfosPension?.InfosRetraite["Cavp"]["General"]["AsvValeurPoint19921996"]}
                        pensionTheo={InfosPension?.InfosRetraite["Cavp"]["General"]["AsvPensionTheorique19921996"]}
                        coeff={InfosPension?.InfosRetraite["Cavp"]["General"]["AsvCoefficient"]}
                        majoEnfant={context.state.TableauEnfant.length >= 3 ? "10%" : "-"}
                        pension={InfosPension?.InfosRetraite["Cavp"]["General"]["AsvPension19921996"]}
                      />
                    ) : (
                      ""
                    )}
                    {InfosPension?.InfosRetraite["Cavp"]["General"]["AsvTotalPoint19972001"] > 0 ? (
                      <FormatLignePension
                        keyId={index++}
                        caisse={"CAVP PCV entre 1997 et 2001"}
                        points={InfosPension?.InfosRetraite["Cavp"]["General"]["AsvTotalPoint19972001"]}
                        valeurPoint={InfosPension?.InfosRetraite["Cavp"]["General"]["AsvValeurPoint19972001"]}
                        pensionTheo={InfosPension?.InfosRetraite["Cavp"]["General"]["AsvPensionTheorique19972001"]}
                        coeff={InfosPension?.InfosRetraite["Cavp"]["General"]["AsvCoefficient"]}
                        majoEnfant={context.state.TableauEnfant.length >= 3 ? "10%" : "-"}
                        pension={InfosPension?.InfosRetraite["Cavp"]["General"]["AsvPension19972001"]}
                      />
                    ) : (
                      ""
                    )}
                    {InfosPension?.InfosRetraite["Cavp"]["General"]["AsvTotalPoint20022005"] > 0 ? (
                      <FormatLignePension
                        keyId={index++}
                        caisse={"CAVP PCV entre 2002 et 2005"}
                        points={InfosPension?.InfosRetraite["Cavp"]["General"]["AsvTotalPoint20022005"]}
                        valeurPoint={InfosPension?.InfosRetraite["Cavp"]["General"]["AsvValeurPoint20022005"]}
                        pensionTheo={InfosPension?.InfosRetraite["Cavp"]["General"]["AsvPensionTheorique20022005"]}
                        coeff={InfosPension?.InfosRetraite["Cavp"]["General"]["AsvCoefficient"]}
                        majoEnfant={context.state.TableauEnfant.length >= 3 ? "10%" : "-"}
                        pension={InfosPension?.InfosRetraite["Cavp"]["General"]["AsvPension20022005"]}
                      />
                    ) : (
                      ""
                    )}
                    {InfosPension?.InfosRetraite["Cavp"]["General"]["AsvTotalPointAPartir2024"] > 0 ? (
                      <FormatLignePension
                        keyId={index++}
                        caisse={"CAVP PCV après 2005"}
                        points={InfosPension?.InfosRetraite["Cavp"]["General"]["AsvTotalPointAPartir2024"]}
                        valeurPoint={InfosPension?.InfosRetraite["Cavp"]["General"]["ValeurPointAsv"]}
                        pensionTheo={InfosPension?.InfosRetraite["Cavp"]["General"]["AsvPensionTheorique"]}
                        coeff={InfosPension?.InfosRetraite["Cavp"]["General"]["AsvCoefficient"]}
                        majoEnfant={context.state.TableauEnfant.length >= 3 ? "10%" : "-"}
                        pension={InfosPension?.InfosRetraite["Cavp"]["General"]["AsvPensionAPartir2023"]}
                      />
                    ) : (
                      ""
                    )}
                  </>
                )}
                {InfosPension?.TotalPensionSupplementaire > 0 && (
                  <tr className="light-text">
                    <td colSpan="6"> Total régimes supplémentaires</td>
                    <td className="text-center">{applyFormat(Math.max(0, InfosPension?.TotalPensionSupplementaire), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  </tr>
                )}
                <tr className="light-text">
                  <td className=" text-right" colSpan="6">
                    Pension brute
                  </td>
                  <td className="text-center">{applyFormat(Math.max(0, InfosPension?.TotalPensionBrute), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                </tr>
                <tr className="light-text">
                  <td className="text-right" colSpan="6">
                    Pension nette de prélèvements sociaux
                  </td>
                  <td className="text-center">{applyFormat(Math.max(0, InfosPension?.TotalPensionNette), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                </tr>
              </tbody>
            </table>
          </Row>
        </Column>
      </CardBox>
    </Column>
  );
};

export default DetailPension;

//    <FormatLignePension
//   keyId={index++}
//   caisse={"RSI Commerçant"}
//   points={data[1]["General"]["TotalPoint"]}
//   valeurPoint={data[1]["General"]["ValeurPoint"]}
//   pensionTheo={data[1]["General"]["PensionTheorique"]}
//   coeff={data[1]["General"]["Coefficient"]}
//   majoEnfant={"-"}
//   pension={data[1]["General"]["Pension"]}
// />
