import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import DropDown from "components/ui/controls/drop-down/drop-down";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Pager from "components/ui/controls/pager/pager";
import ContributionSpecifiquePageModal from "pages/audit complet/situation professionnelle/modules/contribution specifique modal";
import Button from "components/ui/controls/button/button";
import { FaTrashAlt } from "react-icons/fa";
import { applyFormat } from "components/ui/ui-helpers";

function ParamFicheDePaie(props) {
  const context = useContext(AppContext);

  return (
    <>
      <>
        <Pager
          pages={[
            {
              content: (
                <Column pad fill>
                  <CardBox>
                    <Column fill>
                      <Row>
                        <h1>COTISATIONS URSSAF</h1>
                      </Row>
                      <Row>
                        <Column>
                          <TextField
                            value={context.state[props.Outil + "TauxActivite" + props.ClientConjoint]}
                            onChange={context.handleInputChange(props.Outil + "TauxActivite" + props.ClientConjoint)}
                            label="Taux d'activité"
                            dataType="ufloat"
                            format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                            normal
                          />
                        </Column>
                        <Column hpad>
                          <TextField
                            value={context.state[props.Outil + "AccidentTravail" + props.ClientConjoint]}
                            onChange={context.handleInputChange(props.Outil + "AccidentTravail" + props.ClientConjoint)}
                            label="Accident du travail"
                            dataType="ufloat"
                            format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                            normal
                          />
                        </Column>
                        <Column>
                          <TextField
                            value={context.state[props.Outil + "Transport" + props.ClientConjoint]}
                            onChange={context.handleInputChange(props.Outil + "Transport" + props.ClientConjoint)}
                            label="Versement transport"
                            dataType="ufloat"
                            format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                            normal
                          />
                        </Column>
                      </Row>
                      <Row tpad>
                        <Column>
                          <DropDown
                            label="Contribution à la formation"
                            value={context.state[props.Outil + "Formation" + props.ClientConjoint]}
                            options={[
                              { value: "non", label: "Non" },
                              { value: "oui", label: "Oui" },
                            ]}
                            onChange={context.handleInputChange(props.Outil + "Formation" + props.ClientConjoint)}
                            normal
                          />
                        </Column>
                        <Column hpad>
                          <DropDown
                            label="Taxe d'apprentissage"
                            value={context.state[props.Outil + "Apprentissage" + props.ClientConjoint]}
                            options={[
                              { value: "non", label: "Non" },
                              { value: "oui", label: "Oui" },
                            ]}
                            onChange={context.handleInputChange(props.Outil + "Apprentissage" + props.ClientConjoint)}
                            normal
                          />
                        </Column>
                        <Column>
                          <DropDown
                            label="Effectif salarié"
                            value={context.state[props.Outil + "Effectif" + props.ClientConjoint]}
                            options={[
                              { value: "-11", label: "Moins de 11" },
                              { value: "11-19", label: "Entre 11 et 49" },
                              { value: "+20", label: "A compter de 50" },
                            ]}
                            onChange={context.handleInputChange(props.Outil + "Effectif" + props.ClientConjoint)}
                            normal
                          />
                        </Column>
                      </Row>
                      <Row tpad>
                        <Column>
                          <DropDown
                            label="Assurance chômage"
                            value={context.state[props.Outil + "AssuranceChomage" + props.ClientConjoint]}
                            options={[
                              { value: "non", label: "Non" },
                              { value: "oui", label: "Oui" },
                            ]}
                            onChange={context.handleInputChange(props.Outil + "AssuranceChomage" + props.ClientConjoint)}
                            normal
                          />
                        </Column>
                        <Column hpad>
                          <DropDown
                            label="Réduction générale"
                            value={context.state[props.Outil + "ReductionGenerale" + props.ClientConjoint]}
                            options={[
                              { value: "non", label: "Non" },
                              { value: "oui", label: "Oui" },
                            ]}
                            onChange={context.handleInputChange(props.Outil + "ReductionGenerale" + props.ClientConjoint)}
                            normal
                          />
                        </Column>
                        <Column>
                          <DropDown
                            label="Taxe sur les salaires"
                            value={context.state[props.Outil + "TaxeSalaire" + props.ClientConjoint]}
                            options={[
                              { value: "non", label: "Non" },
                              { value: "oui", label: "Oui" },
                            ]}
                            onChange={context.handleInputChange(props.Outil + "TaxeSalaire" + props.ClientConjoint)}
                            normal
                          />
                        </Column>
                      </Row>
                      <Row tpad>
                        <Column>
                          <DropDown
                            label="Régime Alsace-Moselle"
                            value={context.state[props.Outil + "Regime" + props.ClientConjoint]}
                            options={[
                              { value: "non", label: "Non" },
                              { value: "oui", label: "Oui" },
                            ]}
                            onChange={context.handleInputChange(props.Outil + "Regime" + props.ClientConjoint)}
                            normal
                          />
                        </Column>
                        <Column hpad>
                          <TextField
                            label="Avantages en nature"
                            value={context.state[props.Outil + "AvantageNature" + props.ClientConjoint]}
                            onChange={context.handleInputChange(props.Outil + "AvantageNature" + props.ClientConjoint)}
                            dataType="uint"
                            format={{ thousands: " ", unit: " €" }}
                            normal
                          />
                        </Column>
                        <Column>
                          <DropDown
                            label="Affiliation CAVEC"
                            value={context.state[props.Outil + "CavecSalarie" + props.ClientConjoint]}
                            options={[
                              { value: "Non", label: "Non" },
                              { value: "C", label: "Classe C" },
                              { value: "D", label: "Classe D" },
                            ]}
                            onChange={context.handleInputChange(props.Outil + "CavecSalarie" + props.ClientConjoint)}
                            normal
                          />
                        </Column>
                      </Row>
                      <Row tpad>
                        <Column>
                          <DropDown
                            label="Affiliation CNBF"
                            value={context.state[props.Outil + "CnbfSalarie" + props.ClientConjoint]}
                            options={[
                              { value: "Non", label: "Non" },
                              { value: "Oui", label: "Oui" },
                            ]}
                            onChange={context.handleInputChange(props.Outil + "CnbfSalarie" + props.ClientConjoint)}
                            normal
                          />
                        </Column>
                        {context.state[props.Outil + "CnbfSalarie" + props.ClientConjoint]==="Oui" && (
                          <>
                            <Column hpad>
                              <DropDown
                                label="Ancienneté"
                                value={context.state[props.Outil + "AncienneteCnbf" + props.ClientConjoint]}
                                onChange={context.handleInputChange(props.Outil + "AncienneteCnbf" + props.ClientConjoint)}
                                options={[
                                  {
                                    value: "1",
                                    label: "1",
                                  },
                                  {
                                    value: "2",
                                    label: "2",
                                  },
                                  {
                                    value: "3",
                                    label: "3",
                                  },
                                  {
                                    value: "4",
                                    label: "4",
                                  },
                                  {
                                    value: "5",
                                    label: "5",
                                  },
                                  {
                                    value: "6",
                                    label: "6 et +",
                                  },
                                ]}
                                normal
                              />
                            </Column>
                            <Column >
                              <DropDown
                                label="Classe retraite"
                                value={context.state[props.Outil + "ClasseRetraiteCnbf" + props.ClientConjoint]}
                                onChange={context.handleInputChange(props.Outil + "ClasseRetraiteCnbf" + props.ClientConjoint)}
                                options={[
                                  {
                                    value: "C1",
                                    label: "C1",
                                  },
                                  {
                                    value: "C2",
                                    label: "C2",
                                  },
                             
                                  {
                                    value: "C2+",
                                    label: "C2+",
                                  },
                                ]}
                                normal
                              />
                            </Column>
                          </>
                        )}
                      </Row>
                      <Row tpad>
                        <h1>COTISATIONS RETRAITE AGIRC ARRCO</h1>
                      </Row>
                      <Row tpad>
                        <Column>
                          <table className="mono">
                            <thead>
                              <tr>
                                <th></th>
                                <th style={{ textAlign: "center" }}>Salarié</th>
                                <th style={{ textAlign: "center" }}>Patronale</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Tranche 1</td>
                                <td>
                                  <TextField
                                    value={context.state[props.Outil + "AgircArrcoT1Sal" + props.ClientConjoint]}
                                    onChange={context.handleInputChange(props.Outil + "AgircArrcoT1Sal" + props.ClientConjoint)}
                                    dataType="ufloat"
                                    format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                                    normal
                                    style={{ textAlign: "center" }}
                                  />
                                </td>
                                <td>
                                  <TextField
                                    value={context.state[props.Outil + "AgircArrcoT1Pat" + props.ClientConjoint]}
                                    onChange={context.handleInputChange(props.Outil + "AgircArrcoT1Pat" + props.ClientConjoint)}
                                    dataType="ufloat"
                                    format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                                    normal
                                    style={{ textAlign: "center" }}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Tranche 2</td>
                                <td>
                                  <TextField
                                    value={context.state[props.Outil + "AgircArrcoT2Sal" + props.ClientConjoint]}
                                    onChange={context.handleInputChange(props.Outil + "AgircArrcoT2Sal" + props.ClientConjoint)}
                                    dataType="ufloat"
                                    format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                                    normal
                                    style={{ textAlign: "center" }}
                                  />
                                </td>
                                <td>
                                  <TextField
                                    value={context.state[props.Outil + "AgircArrcoT2Pat" + props.ClientConjoint]}
                                    onChange={context.handleInputChange(props.Outil + "AgircArrcoT2Pat" + props.ClientConjoint)}
                                    dataType="ufloat"
                                    format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                                    normal
                                    style={{ textAlign: "center" }}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Column>
                      </Row>
                      <Row tpad>
                        <h1>CONTRIBUTIONS SPÉCIFIQUES</h1>
                      </Row>
                      <Row tpad>
                        <ContributionSpecifiquePageModal ClientConjoint={props.ClientConjoint} />
                      </Row>
                      <Row tpad>
                        <table>
                          <thead>
                            <tr>
                              <th style={{ width: "33%" }}>Libellé</th>
                              <th className="text-center">Assiette</th>
                              <th className="text-center">Salarié</th>
                              <th className="text-center">Employeur</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {context.state[props.Outil + "TabCotisationsManuelle" + props.ClientConjoint].map((Infos, Index) => (
                              <tr key={"contSpe" + Index}>
                                <td>{Infos.Libelle}</td>
                                <td>{Infos.Assiette}</td>
                                <td>
                                  {applyFormat(
                                    Infos.TauxSalariale,
                                    Infos.TypeCotisation === "proportionnelle" ? "ufloat" : "uint",
                                    Infos.TypeCotisation === "proportionnelle" ? { thousands: " ", decimal: ",", precision: 3, unit: "%" } : { thousands: " ", decimal: ",", unit: " €" }
                                  )}{" "}
                                </td>
                                <td>
                                  {applyFormat(
                                    Infos.TauxPatronale,
                                    Infos.TypeCotisation === "proportionnelle" ? "ufloat" : "uint",
                                    Infos.TypeCotisation === "proportionnelle" ? { thousands: " ", decimal: ",", precision: 3, unit: "%" } : { thousands: " ", decimal: ",", unit: " €" }
                                  )}{" "}
                                </td>
                                <td>
                                  <Button
                                    onClick={() => {
                                      context.handleDeleteCotisationManuelle(Infos.id, "", props.ClientConjoint);
                                    }}
                                    icon
                                  >
                                    <FaTrashAlt className="delete" />
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Row>
                    </Column>
                  </CardBox>
                </Column>
              ),
            },
          ]}
          page={0}
          navButtons={{
            validate: {
              onClick: () => {
                props.onClose();
              },
            },
          }}
        />
      </>
    </>
  );
}

export default ParamFicheDePaie;
