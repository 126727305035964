import { useState, useContext, useEffect } from "react";
import { FaCogs, FaEdit, FaExclamationTriangle, FaFileUpload, FaGlobe, FaPlusCircle, FaPrint, FaTrashAlt, FaUndo, FaUpload } from "react-icons/fa";
import Button from "components/ui/controls/button/button";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Modal from "components/ui/modal/modal";
import { AppContext } from "components/app/RemExprertProvider";
import { applyFormat } from "components/ui/ui-helpers";
import Dialogs from "components/ui/dialogs/dialogs";
import { RemExpertResultatTNS } from "components/app/Api/ResultatRemExpertTNSProvider";
import { RemExpertResultatSalarie } from "components/app/Api/ResultatRemExpertSalarieProvider";
import RGProvider, { RGContext } from "components/app/AuditComplet/Retraite/Parametrage/ReleveRG/RGProvider";
import AjoutPeriodePageModal from "pages/audit complet/retraite/ajout periode modal";
import AjoutRISPageModal from "pages/audit complet/retraite/ajout RIS modal";
import { CalculCumulEmploiRetraite } from "components/app/Api/Retraite/ApiRetraite";
import { IconButton, Tooltip } from "@mui/material";
import AjoutPeriodeCumulEmploiPageModal from "./cumulEmploiRetraite/ajout periode cumul emploi modal";
import ParamRetraitePageModalCumulEmploi from "./cumulEmploiRetraite/parametrage retraite cumul emploi modal";
import _ from "lodash";
import AlertLURAModalCumulEmploi from "./cumulEmploiRetraite/alert LURA";
import { CorrespondanceActivite } from "components/app/RemExpertIntialState";
import DetailPension from "pages/audit complet/résultat/detail/retraite/DetailPension";
import { AuditCERWord } from "components/app/Api/Rapport/ApiRapport";
import Pager from "components/ui/controls/pager/pager";

const CumulEmploiRetraitePage = (props) => {
  const context = useContext(AppContext);
  const [clientConjoint, setClientConjoint] = useState("");
  const [modalPeriodeRetr, setmodalPeriodeRetr] = useState(false);
  const [modalRISRetr, setmodalRISRetr] = useState(false);
  const [modalAgree, setmodalAgree] = useState(true);
  const [modalAlertLURA, setmodalAlertLURA] = useState(false);
  const [modalParamRetrOpen, setmodalParamRetrOpen] = useState(false);
  const ResultatTNS = useContext(RemExpertResultatTNS);
  const ResultatSalarie = useContext(RemExpertResultatSalarie);
  const Periode = context.state["PeriodeActuelle"][0];
  const PeriodeConjoint = context.state["PeriodeActuelleConjoint"][0];

  useEffect(() => {
    context.handleCalculCumulEmploiRetraite(props.ClientConjoint, context.state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (props.ClientConjoint === "") {
      setClientConjoint("");
    } else {
      setClientConjoint("Conjoint");
    }
  }, [props.ClientConjoint]);
  useEffect(() => {
    setmodalAlertLURA(context.state.CumulEmploiAlertLURA);
  }, [context.state.CumulEmploiAlertLURA]);

  const handleRAZ = () => {
    context.handleValueChange("CumulEmploiChoixDepartRetraite" + clientConjoint, "");
    context.handleValueChange("CumulEmploiCarriere" + clientConjoint, []);

    let data = _.cloneDeep(context.state);
    data.CumulEmploiAlertLURA = false;
    data.CumulEmploisIdsLURA = [];
    data["CumulEmploiChoixDepartRetraite" + clientConjoint] = "";
    data["CumulEmploiCarriere" + clientConjoint] = [];
    context.handleCalculCumulEmploiRetraite(props.ClientConjoint, data);
  };

  //   const longText = `
  //   Nous vous rappelons que dans le cadre de la simulation, les charges sociales sont considérées comme provisionnées à la clôture de l’exercice et donc déductibles fiscalement.
  //   Pour qu’elles le soient en pratique, il est indispensable que la distribution de dividendes intervienne avant la clôture de l’exercice en question.

  //   A titre d’exemple, lorsqu’un arbitrage est réalisé en 2023, il est indispensable que la distribution de dividendes intervienne avant la clôture de l’exercice 2023. Le
  //   cas échéant, les charges sociales générées par la distribution de dividendes ne pourront pas être déduites du résultat de l’exercice 2023.
  // `;
  const longText = `
le logiciel ne traite pas à ce jour le cumul emploi retraite sur l'activité saisie.

`;

  return (
    <>
      <Column fill>
        <Row>
          <Column fill>
            <CardBox bpad>
              <Column fill>
                {/* <Row center>
                  <Column></Column>
                  <Column style={{ paddingLeft: 0 }}>
                    <Button
                      onClick={() => {
                        props.accesSimulation();
                      }}
                    >
                      <span>Retour aux simulations</span>
                      <FaPlusCircle />
                    </Button>
                  </Column>
                </Row> */}
                <Row>
                  <Column>
                    <blockquote>
                      Dans le cadre du simulateur de CER, vous avez la possibilité de modifier une période d'activité existante ou de créer une période correspondant à une nouvelle activité.
                      La date de début de la période existante ou créée est au minimum égale à la date de départ en retraite initiale. Par défaut, les périodes existantes sont projetées sur
                      5 ans. Les périodes pour lesquelles les dates de début et de fin sont remplacées par des "-" ne peuvent pas être projetées dans le cadre du simulateur de CER.
                      <br />
                      <br />
                      <FaExclamationTriangle color="red" style={{ width: "15px", height: "15px" }} /> En cas de chargement d'un dossier sauvegardé, la modification de la carrière initiale ne
                      modifie pas la simulation. Pour la remettre à défaut, vous pouvez cliquer sur l'icone "fléché" à droite de l'écran.
                    </blockquote>
                  </Column>
                </Row>
              </Column>
            </CardBox>
          </Column>
        </Row>

        <Row>
          <Column fill>
            <CardBox bpad>
              <Column fill>
                <Row>
                  <h1>Cumul emploi retraite {context.state["Prenom" + clientConjoint]}</h1>
                </Row>
                <Row center>
                  <Column style={{ paddingLeft: 0 }}>
                    <Button
                      onClick={() => {
                        context.handleResetPeriodeRetraite(clientConjoint, "CumulEmploi");
                        setmodalPeriodeRetr(true);
                      }}
                      // disabled={context.state["CumulEmploiCarriere" + clientConjoint].length>0}
                    >
                      <span>Ajouter une période pour {context.state["Prenom" + clientConjoint]}</span>
                      <FaPlusCircle />
                    </Button>
                  </Column>

                  <Column>
                    <Button
                      onClick={() => {
                        setmodalParamRetrOpen(true);
                      }}
                    >
                      <span>Paramétrer la retraite pour {context.state["Prenom" + clientConjoint]}</span>
                      <FaCogs id="paramRetraite" />
                    </Button>
                  </Column>
                  <Column fill right>
                        <Button
                          className="optiIcon"
                          onClick={() => {
                            AuditCERWord(context.state,clientConjoint, ResultatTNS.state,context.state["CumulEmploiResult"+clientConjoint]);
                          }}
                        >
                          <FaPrint title="Imprimer" />
                        </Button>
                      </Column>
                    <Button
                      className="optiIcon"
                      onClick={() => {
                        handleRAZ();
                        // context.handleOpenRemiseDonneeOptirem(ClientConjoint, ResultatTNS, ResultatSalarie);
                      }}
                    >
                      <FaUndo />
                    </Button>
                </Row>
                <Row tpad style={{ maxHeight: "500px", overflow: "auto" }}>
                  <table>
                    <thead>
                      <tr>
                        <th>Date de début</th>
                        <th>Date de fin</th>
                        <th>Activité</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {context.state["CumulEmploiCarriere" + clientConjoint]?.map((Infos, Index) => {
                        return (
                          <tr key={Index}>
                            {Infos.CumulEmploi === true ? (
                              <>
                                <td> {applyFormat(Infos.Debut, "date", "")} </td>
                                <td>{applyFormat(Infos.Fin, "date", "")}</td>
                              </>
                            ) : (
                              <>
                                <td style={{ paddingLeft: "33px" }}>-</td>
                                <td style={{ paddingLeft: "33px" }}>-</td>
                              </>
                            )}
                            <td>{CorrespondanceActivite[Infos.Nature]}</td>
                            <td>
                              {Infos.CumulEmploi === true && (
                                <>
                                  <Button
                                    onClick={() => {
                                      context.handleModifyDeletePeriodeRetraite(Infos.id, "Modifier", "CumulEmploi", clientConjoint);
                                      setmodalPeriodeRetr(true);
                                    }}
                                    icon
                                    style={{ display: "inline-block" }}
                                  >
                                    <FaEdit />
                                  </Button>

                                  <Button
                                    onClick={() => {
                                      Dialogs.confirm("Vous êtes sur le point de supprimer la période.\nVoulez-vous continuer ?", "Suppression de la période", () => {
                                        context.handleModifyDeletePeriodeRetraite(Infos.id, "Supprimer", "CumulEmploi", clientConjoint);
                                      });
                                    }}
                                    icon
                                    style={{ display: "inline-block" }}
                                  >
                                    <FaTrashAlt className="delete" />
                                  </Button>
                                </>
                              )}
                              {Infos.CumulEmploi === false && (
                                <Tooltip title={<div style={{ whiteSpace: "pre-line", textAlign: "justify" }}>{longText}</div>} placement="left" zIndex="9999">
                                  <IconButton style={{ padding: "0", paddingLeft: "16px" }}>
                                    <FaExclamationTriangle color="red" style={{ width: "15px", height: "15px" }} />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Row>
                <Row tpad>
                  <table>
                    <thead>
                      <tr>
                        <th colSpan={2}>Synthèse de la retraite pour {context.state["Prenom" + clientConjoint]}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Age de départ en retraite définitif</td>
                        <td>
                          <Row>
                            <Column style={{ padding: 0 }}>{context.state["CumulEmploiAgeDepartRetraite" + clientConjoint]}</Column>
                            <Column style={{ padding: 0, alignSelf: "flex-end", marginTop: "0px" }}>
                              <Button
                                icon
                                style={{ display: "inline-block" }}
                                onClick={() => {
                                  setmodalParamRetrOpen(true);
                                }}
                              >
                                <FaEdit />
                              </Button>
                            </Column>
                            <Column style={{ padding: 0, alignSelf: "flex-end", marginTop: "0px" }}>
                              <Button
                                icon
                                style={{ display: "inline-block", color: "red" }}
                                onClick={() => {
                                  setmodalParamRetrOpen(true);
                                }}
                              ></Button>
                            </Column>
                          </Row>
                        </td>
                      </tr>
                      <tr>
                        <td>Date de départ en retraite définitive</td>
                        <td>{context.state["CumulEmploiDateDepartRetraiteAffichage" + clientConjoint]}</td>
                      </tr>
                    </tbody>
                  </table>
                </Row>
              </Column>
            </CardBox>
          </Column>
        </Row>
      </Column>
      {/* {context.state.CumulEmploiResult !== undefined && <DetailPension Outil={""} InfosPension={context.state["CumulEmploiResult"+clientConjoint]} />} */}
      <Modal
        width="80%"
        height="80%"
        title="Ajouter une période"
        icon={<FaGlobe />}
        visible={modalPeriodeRetr}
        onClose={() => {
          setmodalPeriodeRetr(false);
        }}
      >
        <AjoutPeriodeCumulEmploiPageModal
          Outil="CumulEmploi"
          ClientConjoint={clientConjoint}
          visible={modalPeriodeRetr}
          onClose={() => {
            setmodalPeriodeRetr(false);
          }}
        />
      </Modal>
      <Modal
        width="80%"
        height="80%"
        title="Paramétrage de la retraite"
        icon={<FaGlobe />}
        visible={modalParamRetrOpen}
        onClose={() => {
          setmodalParamRetrOpen(false);
        }}
      >
        <ParamRetraitePageModalCumulEmploi
          Outil=""
          ClientConjoint={clientConjoint}
          visible={modalParamRetrOpen}
          onClose={() => {
            setmodalParamRetrOpen(false);
          }}
        />
      </Modal>
      <Modal
        width="75%"
        height="55%"
        title="POINT D'ATTENTION SUR LA LURA"
        icon={<FaGlobe />}
        visible={modalAlertLURA}
        hideClose={true}
        onClose={() => {
          context.handleValueChange("CumulEmploiAlertLURA", false);
        }}
      >
        <AlertLURAModalCumulEmploi
          Outil=""
          ClientConjoint={clientConjoint}
          visible={modalAlertLURA}
          onClose={() => {
            context.handleValueChange("CumulEmploiAlertLURA", false);
          }}
        />
      </Modal>
      <Modal
        width="45%"
        height="30%"
        // title="POINT D'ATTENTION SUR LA LURA"
        // icon={<FaGlobe />}
        visible={modalAgree}
        hideClose={true}
        onClose={() => {
          setmodalAgree(false)
        }}
      >
    <Pager
        pages={[
          {
            content: (
              <>
                <Column fill>
                  <CardBox>
                    <Column fill>
                      <Row tpad>
                        <Column>
                          <blockquote>
                          Nous vous alertons sur le fait que le simulateur n'a vocation qu'à déterminer la nouvelle pension issue du CER. Il vous appartient donc de vérifier que les conditions du cumul intégral sont remplies.
                          </blockquote>
                        </Column>
                      </Row>
                    </Column>
                  </CardBox>
                </Column>
              </>
            ),
          },
        ]}
        page={0}
        navButtons={{
          // cancel: {},
          previous: {},
          next: {},
          validate: {
            onClick: (evt) => {
              setmodalAgree(false)
              // context.handleCalculCumulEmploiRetraite(props.ClientConjoint, data);
              // context.handleCalculCumulEmploiRetraite(props.ClientConjoint, context.state);
            },
          },
        }}
      />
      </Modal>
    </>
  );
};

export default CumulEmploiRetraitePage;
